import Icon from 'components/shared/Icon/Icon';
import StaleTooltip from 'components/shared/StaleTooltip/StaleTooltip';
import { Paragraph } from 'components/shared/Typography/Typography';
import { TWriteEventDetails } from 'types';

export const TransactionWriteStatusCell = ({
  writeDetails,
}: {
  writeDetails: TWriteEventDetails;
}) => {
  if (writeDetails.status === 'success') {
    return (
      <StaleTooltip content={<Paragraph color="white">Success</Paragraph>}>
        <Icon icon="done-ico" />
      </StaleTooltip>
    );
  }

  if (writeDetails.status === 'error') {
    return (
      <StaleTooltip content={<Paragraph color="white">Error</Paragraph>}>
        <Icon icon="failed" />
      </StaleTooltip>
    );
  }

  if (writeDetails.status === 'internalError') {
    return (
      <StaleTooltip
        content={<Paragraph color="white">Internal Error</Paragraph>}
      >
        <Icon icon="alert-ico" />
      </StaleTooltip>
    );
  }

  if (writeDetails.status === 'mixed') {
    return (
      <StaleTooltip
        content={<Paragraph color="white">Mixed Success</Paragraph>}
      >
        <Icon icon="failed" />
      </StaleTooltip>
    );
  }

  if (writeDetails.status === 'notFound') {
    return <></>;
  }

  if (writeDetails.status === 'pending') {
    return (
      <StaleTooltip content={<Paragraph color="white">Pending</Paragraph>}>
        <Icon icon="clock-ico" />
      </StaleTooltip>
    );
  }

  return <></>;
};
