import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { Firebase } from 'services';
import { Nullable } from 'types';
import { IBulkPayment } from 'types/bulkPayments';

export interface BulkPaymentsStateModel {
  bulkPayments: IBulkPayment[];
  bulkPaymentById: Computed<
    BulkPaymentsStateModel,
    (id: Nullable<string>) => IBulkPayment | null
  >;
  setState: Action<BulkPaymentsStateModel, [string, IBulkPayment[]]>;
  subscribeToBulkPayments: Thunk<
    BulkPaymentsStateModel,
    Omit<Firebase.SubscribeToTransfersParams, 'callback'>,
    null,
    object,
    (() => void) | undefined
  >;
}

export const BulkPaymentsState: BulkPaymentsStateModel = {
  bulkPayments: [],
  bulkPaymentById: computed(
    [(state) => state.bulkPayments],
    (bulkPayments) => (id) => {
      if (!id) {
        return null;
      }
      return bulkPayments.find((item) => item.id === id) || null;
    }
  ),
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  subscribeToBulkPayments: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToBulkPayments({
      ...payload,
      callback: (bulkPayments) => setState(['bulkPayments', bulkPayments]),
    });

    return subscriber;
  }),
};
