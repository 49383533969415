import { FC, useMemo } from 'react';
import { generateTableColumns } from './tableColumnsGenerator';
import { Table } from 'components';
import { ICashflowFromSearch } from 'services/cashflows';
import { TableProps } from 'components/shared/Table/types';
import { useStoreState } from 'state';

type TTablePropsToPick =
  | 'isVirtualized'
  | 'data'
  | 'withInfiniteLoading'
  | 'onLoadMoreItems'
  | 'itemsCount'
  | 'loadingThreshold'
  | 'isLoadingMoreItems'
  | 'hasMoreToLoad';

interface IOwnProps
  extends Pick<TableProps<ICashflowFromSearch>, TTablePropsToPick> {
  updateInMemoryCashflows: (
    updateFunction: (cashflows: ICashflowFromSearch[]) => ICashflowFromSearch[]
  ) => void;
}

const ContributorsTable: FC<IOwnProps> = ({
  data,
  isVirtualized = true,
  withInfiniteLoading,
  onLoadMoreItems,
  loadingThreshold,
  isLoadingMoreItems,
  itemsCount = 0,
  hasMoreToLoad,
  updateInMemoryCashflows,
}) => {
  const { userEntity } = useStoreState((state) => state.UserState);
  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        customCashflowDefinitions: userEntity.customCashflowDefinitions,
        updateInMemoryCashflows,
      }),
    [updateInMemoryCashflows, userEntity.customCashflowDefinitions]
  );

  return (
    <Table<ICashflowFromSearch>
      autoResetGlobalFilter={false}
      autoResetSortBy={false}
      autoResetFilters={false}
      data={data}
      sortable={false}
      columns={tableColumns}
      isVirtualized={isVirtualized}
      withInfiniteLoading={withInfiniteLoading}
      onLoadMoreItems={onLoadMoreItems}
      loadingThreshold={loadingThreshold}
      isLoadingMoreItems={isLoadingMoreItems}
      itemsCount={itemsCount}
      hasMoreToLoad={hasMoreToLoad}
      isRowDisabled={(record) => !!record.excludeFromRisk}
    />
  );
};

export default ContributorsTable;
