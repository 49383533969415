import styled, { CSSProperties, css } from 'styled-components';

export const Overflow = styled.div<
  Pick<CSSProperties, 'overflow' | 'maxHeight'>
>(
  ({ overflow, maxHeight }) => css`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    overflow: ${overflow ? overflow : 'auto'};
    height: 100%;
    max-height: ${maxHeight};
  `
);

export const StaleOverflowWrap = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    padding-top: 0;
    height: 100%;
  `
);

export const StaleOverflowContent = styled.div<{
  paddingTop?: string;
  color?: string;
}>(
  ({ paddingTop, color = '#fff' }) => css`
    flex: 1 0 auto;
    padding: 14px 16px;
    background: ${color};
    padding-top: ${paddingTop};
  `
);
