import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Col,
  Icon,
  Paragraph,
  Row,
  StaleInfo,
  StaleInputSelect,
  StaleSwitch,
  Table,
  TableSearchInput,
  Title,
} from 'components';
import { ITransaction, TTransactionWithWriteDetails } from 'types/transactions';
import {
  filterTypes,
  generateTableColumns,
  staticTransactionsFilters,
  staticTransactionsFiltersToRender,
} from './utils';
import { useHistory } from 'react-router';
import Drawer from 'components/shared/Drawer/Drawer';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { useTheme } from 'styled-components';
import useTableFilteringExperimental from 'hooks/useTableFilteringExperimental';
import InputDateUncontrolled from 'components/shared/InputDateUncontrolled/InputDateUncontrolled';
import Field from 'components/shared/Field/Field.styles';
import InputBase from 'components/shared/InputBase/InputBase';
import TransactionsPlaceholder from '../TransactionsPlaceholder/TransactionsPlaceholder';
import Button from '../Button/Button';
import { useStoreState } from 'state';
import {
  detectCurrenciesFromTransactions,
  viewTransactionDetails,
} from 'utils/transactions';
import useUrlValues from 'hooks/useUrlValues';
import { FilterButton } from '../FilterButton/FilterButton.styles';
import { getTransactionPageLink, getTransactionsDownloadPageLink } from 'utils';
import CounterTitle from '../CounterTitle/CounterTitle';
import { useGetBffTransactions } from 'hooks/useGetBffTransactions';

interface IOwnProps {
  queryLimit?: number;
  withShowMore?: boolean;
  onlyTransfers?: boolean;
  withTableControls?: boolean;
  transactionTypesToRender?: Array<ITransaction['type']>;
}

const TableTransactions: FC<IOwnProps> = ({
  queryLimit,
  withShowMore,
  onlyTransfers,
  withTableControls = false,
  transactionTypesToRender,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { transactions, isLoadingTransactions } = useGetBffTransactions(
    queryLimit
  );

  const [showFilter, setShowFilter] = useState(false);
  const { setUrlValue, trCurrency: currency, trFilter: filter } = useUrlValues(
    'trCurrency',
    'trFilter'
  );

  useEffect(() => {
    const urlPayload = {} as {
      trCurrency?: string;
      trFilter?: string;
    };

    if (!currency) {
      urlPayload.trCurrency = 'all';
    }

    if (!filter) {
      urlPayload.trFilter = 'all';
    }

    if (Object.keys(urlPayload).length !== 0) {
      setUrlValue(urlPayload);
    }
  }, [currency, filter, setUrlValue]);

  const detectedCurrencies = detectCurrenciesFromTransactions({
    transactions: transactions ?? [],
    currencies,
  });
  const {
    filters,
    setFilter,
    resetFilters,
    searchValue,
    setSearchValue,
    tableRef,
  } = useTableFilteringExperimental(
    staticTransactionsFilters,
    !!transactions.length
  );

  const transactionsToDisplay = useMemo(
    () =>
      transactions
        .filter((transaction) => {
          if (transactionTypesToRender) {
            return transactionTypesToRender.includes(transaction.type);
          }
          if (!filter || filter === 'all') {
            return true;
          }

          return filter === transaction.type;
        })
        .filter((transaction) => {
          if (!currency || currency === 'all') {
            return true;
          }

          return currency === transaction.mainCurrency;
        }),
    [currency, filter, transactions, transactionTypesToRender]
  );

  const tableColumns = useMemo(() => generateTableColumns(), []);

  const selectData = useMemo(() => {
    const sortedDetectedCurrencies = [...detectedCurrencies].sort();

    return [
      {
        id: 'all',
        name: 'All CCY',
        value: 'all',
      },
      ...sortedDetectedCurrencies.map((currencyCode) => ({
        id: currencyCode,
        name: currencyCode,
        value: currencyCode,
        icon: currencyByCode(currencyCode)?.countryCode,
      })),
    ];
  }, [currencyByCode, detectedCurrencies]);

  const onRowClick = (transaction: TTransactionWithWriteDetails) =>
    viewTransactionDetails(setUrlValue, transaction);

  const setFilterUrlValue = (value: string) => () =>
    setUrlValue({ trFilter: value });

  if (!transactionsToDisplay.length) {
    return (
      <TransactionsPlaceholder
        onlyTransfers={onlyTransfers}
        isLoading={isLoadingTransactions}
      />
    );
  }

  return (
    <>
      <Row mb gap={theme.spacing.m} justifyContent="flex-start">
        {!queryLimit && (
          <CounterTitle
            title={onlyTransfers ? 'Transfers' : 'Transactions'}
            count={transactionsToDisplay.length}
          />
        )}

        {queryLimit && (
          <CounterTitle
            title={`Last ${transactionsToDisplay.length} ${
              onlyTransfers ? 'transfers' : 'transactions'
            }`}
            count={queryLimit ? undefined : transactionsToDisplay.length}
          />
        )}

        {withShowMore && (
          <StaleInfo
            infoSize={theme.iconSizes.m}
            mode="hover"
            portal
            placement="top"
            trigger={
              <Button
                variant="link"
                onClick={() => history.push(getTransactionPageLink())}
              >
                <Icon icon="round-plus-ico" />
              </Button>
            }
          >
            <Paragraph color="white">Show more</Paragraph>
          </StaleInfo>
        )}
      </Row>

      {withTableControls && (
        <Row mb>
          <Row gap={theme.spacing.xs}>
            <StaleInputSelect
              selected={currency}
              data={selectData}
              onSelect={(item) => setUrlValue({ trCurrency: item.value })}
              id="invoices-currency"
              style={{ minWidth: '132px' }}
            />

            <TableSearchInput
              placeholder="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />

            <FilterButton
              onClick={setFilterUrlValue(filter !== 'all' ? 'all' : '')}
              active={filter === 'all'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>All</Paragraph>
              </Row>
            </FilterButton>

            <FilterButton
              onClick={setFilterUrlValue(
                filter !== 'fxExchange' ? 'fxExchange' : ''
              )}
              active={filter === 'fxExchange'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>FX Exchange</Paragraph>
              </Row>
            </FilterButton>

            <FilterButton
              onClick={setFilterUrlValue(
                filter !== 'transferIn' ? 'transferIn' : ''
              )}
              active={filter === 'transferIn'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Transfers in</Paragraph>
              </Row>
            </FilterButton>

            <FilterButton
              onClick={setFilterUrlValue(
                filter !== 'transferOut' ? 'transferOut' : ''
              )}
              active={filter === 'transferOut'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Transfers out</Paragraph>
              </Row>
            </FilterButton>

            <FilterButton
              onClick={setFilterUrlValue(filter !== 'fee' ? 'fee' : '')}
              active={filter === 'fee'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Fees</Paragraph>
              </Row>
            </FilterButton>

            <FilterButton
              onClick={setFilterUrlValue(
                filter !== 'rateContract' ? 'rateContract' : ''
              )}
              active={filter === 'rateContract'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Prebookings</Paragraph>
              </Row>
            </FilterButton>
          </Row>

          <Row gap={theme.spacing.s}>
            <StaleInfo
              infoSize={theme.iconSizes.m}
              mode="hover"
              strategy="fixed"
              portal
              placement="top"
              trigger={
                <Button variant="link" onClick={() => setShowFilter(true)}>
                  <Icon icon="filter-ico" />
                </Button>
              }
            >
              <Paragraph color="white">Filter</Paragraph>
            </StaleInfo>

            {withTableControls && (
              <StaleInfo
                infoSize={theme.iconSizes.m}
                mode="hover"
                strategy="fixed"
                portal
                placement="top"
                trigger={
                  <Button
                    variant="link"
                    onClick={() =>
                      history.push(getTransactionsDownloadPageLink())
                    }
                  >
                    <Icon icon="download-ico" />
                  </Button>
                }
              >
                <Paragraph color="white">Download CSV</Paragraph>
              </StaleInfo>
            )}
          </Row>
        </Row>
      )}

      <Table<TTransactionWithWriteDetails>
        onRowClick={onRowClick}
        autoResetGlobalFilter={false}
        autoResetSortBy={false}
        autoResetFilters={false}
        ref={tableRef}
        data={transactionsToDisplay}
        filterTypes={filterTypes}
        initialState={{
          filters: staticTransactionsFilters,
        }}
        globalFilter="text"
        sortable
        columns={tableColumns}
        isVirtualized
        minVisibleRows={6}
        defaultRowHeight={58}
      />

      <Drawer
        show={showFilter}
        onClose={() => setShowFilter(false)}
        width="400px"
        HeaderContent={<Title variant="h3">Filters</Title>}
        FooterContent={
          <Button variant="secondary" onClick={resetFilters}>
            Reset
          </Button>
        }
      >
        {staticTransactionsFiltersToRender.map(
          ({ key, id: filterType, title: filterTypeTitle, options }) => {
            return (
              <Fragment key={key}>
                <Title variant="h5" mb>
                  {filterTypeTitle}
                </Title>
                <Col
                  mb
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  rowGap={theme.spacing.xs}
                >
                  {options.map((option) => {
                    const { id, title, type } = option;
                    const filter = filters.find((val) => val.id === filterType);
                    const isActive = !!filter?.value.find(
                      (val) => val.id === id
                    );
                    const currentFilterValue = filter?.value.find(
                      (val) => val.id === id
                    )?.value;

                    return (
                      <Row key={id} justifyContent="flex-start">
                        {(() => {
                          if (type === 'checkbox') {
                            return (
                              <Checkbox
                                label={title}
                                checked={isActive}
                                onChange={() => setFilter(filterType, option)}
                              />
                            );
                          }

                          if (type === 'radio') {
                            return (
                              <>
                                <StaleSwitch
                                  id={id}
                                  isOn={isActive}
                                  handleToggle={() =>
                                    setFilter(filterType, option)
                                  }
                                />
                                <Paragraph ml mlValue={theme.spacing.xs}>
                                  {title}
                                </Paragraph>
                              </>
                            );
                          }

                          if (type === 'date') {
                            return (
                              <Field mb>
                                <InputDateUncontrolled
                                  id={id}
                                  label={title}
                                  value={currentFilterValue || ''}
                                  onChange={(val) =>
                                    setFilter(filterType, {
                                      ...option,
                                      value: val,
                                    })
                                  }
                                />
                              </Field>
                            );
                          }

                          if (type === 'number') {
                            return (
                              <Field mb>
                                <InputBase
                                  label={title}
                                  type="number"
                                  value={currentFilterValue || ''}
                                  onChange={(event) =>
                                    setFilter(filterType, {
                                      ...option,
                                      value: event.target.value,
                                    })
                                  }
                                />
                              </Field>
                            );
                          }

                          return null;
                        })()}
                      </Row>
                    );
                  })}
                </Col>
              </Fragment>
            );
          }
        )}
      </Drawer>
    </>
  );
};

export default TableTransactions;
