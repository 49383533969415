import { FC } from 'react';
import { StaleInput } from 'components';
import { useForm } from 'react-hook-form';
import { useStoreState } from 'state';
import auth from 'services/auth';
import { SettingsField } from 'pages/Settings/Settings.styles';
import Button from 'components/shared/Button/Button';
import { TotpMultiFactorGenerator } from 'firebase/auth';
import InternalLink from 'components/shared/InternalLink/InternalLink.styles';
import {
  WhiteFooterContainer,
  WhiteContentContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import UserSettings from './components/UserSettings/UserSettings';
import { getTotpSettingsPageLink } from 'utils';

interface OwnProps {
  setShowChangePassword: (value: boolean) => void;
}

const GeneralSettings: FC<OwnProps> = ({ setShowChangePassword }) => {
  const { control } = useForm();
  const { user } = useStoreState((state) => state.UserState);
  // TODO: clarify why do we use photoUrl here
  const { photoURL } = auth.currentUser?.providerData[0] ?? {};

  const isTotpEnrolled = auth.currentUser?.multiFactor.enrolledFactors.find(
    (item) => item.factorId === TotpMultiFactorGenerator.FACTOR_ID
  );

  return (
    <>
      <WhiteContentContainer>
        <SettingsField>
          <StaleInput
            id="email"
            name="email"
            label="Email"
            view="moving"
            type="email"
            defaultValue={user?.email}
            locked={true}
            control={control}
          />
        </SettingsField>
        {!photoURL ? (
          <StaleInput
            id="password"
            name="password"
            label="Password"
            view="moving"
            type="password"
            defaultValue="its-password-variable"
            renderIcon={false}
            locked
            control={control}
          />
        ) : (
          <SettingsField>
            <StaleInput
              id="textWithGoogle"
              name="textWithGoogle"
              view="moving"
              type="text"
              defaultValue="Authenticated using Google credentials"
              locked={true}
              control={control}
            />
          </SettingsField>
        )}
        <UserSettings />
      </WhiteContentContainer>

      <WhiteFooterContainer>
        {!photoURL && (
          <Button
            variant="link"
            type="button"
            onClick={() => setShowChangePassword(true)}
          >
            Change password
          </Button>
        )}

        {!isTotpEnrolled && (
          <InternalLink to={getTotpSettingsPageLink()}>
            Enable 2nd factor code via an authenticator app
          </InternalLink>
        )}
      </WhiteFooterContainer>
    </>
  );
};

export default GeneralSettings;
