import { Col, Row } from 'components';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import CashBalance from './components/CashBalance/CashBalance';
import ShortTermNeeds from './components/ShortTermNeeds/ShortTermNeeds';
import MainBalances from './components/MainBalances/MainBalances';
import { ICashNeedsBffResponse } from 'types';
import { getCashNeeds } from 'services/firebase/analysis';
import { errorHandler } from 'utils/errors';
import CashNeedsSkeleton from './components/CashNeedsSkeleton/CashNeedsSkeleton';
import BalancesByAccount from './components/BalancesByAccount/BalancesByAccount';
import CashByCurrency from './components/CashByCurrency/CashByCurrency';
import useDeviceWidth from 'hooks/useDeviceWidth';
import CashByEntity from './components/CashByEntity/CashByEntity';

interface IOwnProps {
  includeAllUserEntities?: boolean;
  showCashByEntity?: boolean;
}

const CashNeeds: FC<IOwnProps> = ({ includeAllUserEntities }) => {
  const theme = useTheme();
  const [cashNeeds, setCashNeeds] = useState<ICashNeedsBffResponse>();
  const { isLaptop } = useDeviceWidth();

  const fetchGetCashNeeds = useCallback(async () => {
    try {
      const res = await getCashNeeds(!!includeAllUserEntities);
      setCashNeeds(res.data.data);
    } catch (error) {
      errorHandler(error);
    }
  }, [includeAllUserEntities]);

  useEffect(() => {
    fetchGetCashNeeds();
  }, [fetchGetCashNeeds]);

  return (
    <Row gap={theme.spacing.m} flexWrap="wrap">
      {!cashNeeds && <CashNeedsSkeleton />}
      {cashNeeds && (
        <>
          <Col flex={0.6} alignSelf="stretch" gap={theme.spacing.s}>
            <CashBalance
              currencyCode={cashNeeds.currencyCode}
              value={cashNeeds.balancesSum}
            />
            <ShortTermNeeds
              currencyCode={cashNeeds.currencyCode}
              value={cashNeeds.shortTermNeeds}
            />
          </Col>

          {!!includeAllUserEntities && cashNeeds.cashByEntity && (
            <CashByEntity
              currencyCode={cashNeeds.currencyCode}
              data={cashNeeds.cashByEntity}
            />
          )}

          {!includeAllUserEntities && (
            <CashByCurrency
              currencyCode={cashNeeds.currencyCode}
              data={cashNeeds.cashByCurrency}
            />
          )}

          <BalancesByAccount
            data={cashNeeds.balancesByAccountBetweenDates}
            isLoading={!cashNeeds.balancesByAccountBetweenDates}
          />

          {!!includeAllUserEntities && (
            <CashByCurrency
              currencyCode={cashNeeds.currencyCode}
              data={cashNeeds.cashByCurrency}
            />
          )}

          {!includeAllUserEntities && !isLaptop && (
            <MainBalances
              allBalancesToUse={cashNeeds.allBalancesToUse}
              currencyCode={cashNeeds.currencyCode}
            />
          )}
        </>
      )}
    </Row>
  );
};

export default CashNeeds;
