import { Paragraph, Row, Title } from 'components';
import Button from 'components/shared/Button/Button';
import { StyledForm } from 'components/shared/Form/Form.styles';
import { FC, useCallback, useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import { ERROR_MESSAGES } from 'variables';
import { TInputs } from './types';
import Field from 'components/shared/Field/Field.styles';
import InputBase from 'components/shared/InputBase/InputBase';
import {
  MultiFactorResolver,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  getAuth,
  MultiFactorInfo,
} from 'firebase/auth';
import { useRecaptchaVerifier } from 'hooks';
import firebase from 'firebase/compat/app';
import { errorHandler } from 'utils/errors';
import { Notify } from 'utils';

interface IOwnProps {
  multiFactor: MultiFactorInfo;
  mfaResolver: MultiFactorResolver;
  onSwitchFactor: (factorId: string) => void;
  moveBackToSignIn: () => void;
}

const Phone: FC<IOwnProps> = ({
  multiFactor,
  mfaResolver,
  onSwitchFactor,
  moveBackToSignIn,
}) => {
  const { control, handleSubmit, watch } = useForm<TInputs>({
    defaultValues: {
      verificationCode: '',
    },
  });
  const recaptchaVerifier = useRecaptchaVerifier('recaptcha');
  const [verificationId, setVerificationId] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const sendVerificationCode = useCallback(
    async ({
      multiFactor,
      recaptchaVerifier,
    }: {
      multiFactor: MultiFactorInfo;
      recaptchaVerifier: firebase.auth.RecaptchaVerifier | null;
    }) => {
      try {
        if (!recaptchaVerifier) {
          return;
        }

        const phoneInfoOptions = {
          multiFactorHint: multiFactor,
          session: mfaResolver.session,
        };

        const phoneAuthProvider = new PhoneAuthProvider(getAuth());

        const verificationIdResp = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );

        setVerificationId(verificationIdResp);

        Notify.success('Verification code sent');
        inputRef?.current?.focus();
      } catch (error: any) {
        if (error.code === 'auth/invalid-multi-factor-session') {
          moveBackToSignIn();
        }
      }
    },
    [mfaResolver.session, moveBackToSignIn]
  );

  useEffect(() => {
    if (multiFactor && recaptchaVerifier) {
      sendVerificationCode({ multiFactor, recaptchaVerifier });
    }
  }, [multiFactor, recaptchaVerifier, sendVerificationCode]);

  const onSubmit = async ({ verificationCode }: TInputs) => {
    try {
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      await mfaResolver.resolveSignIn(multiFactorAssertion);
    } catch (error: any) {
      if (error.code === 'auth/invalid-verification-code') {
        Notify.error('Invalid verification code. Please try again.');
        return;
      } else {
        errorHandler(error);
      }
    }
  };

  return (
    <>
      <Title variant="h5">Please enter a code from SMS</Title>
      <StyledForm alignItems="stretch" onSubmit={handleSubmit(onSubmit)}>
        <Field fluid>
          <Controller
            name="verificationCode"
            control={control}
            rules={{
              required: ERROR_MESSAGES.requiredField,
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <InputBase
                  inputRef={inputRef}
                  value={value}
                  onChange={onChange}
                  placeholder="Verification code"
                />
              );
            }}
          />
        </Field>

        <Row mb mt alignSelf="stretch">
          <Paragraph>Haven’t received the code?</Paragraph>
          <Button
            variant="link"
            type="button"
            onClick={(event) => {
              event.preventDefault();

              sendVerificationCode({
                multiFactor,
                recaptchaVerifier,
              });
            }}
          >
            Resend
          </Button>
        </Row>

        <Button
          variant="primary"
          disabled={!watch('verificationCode')}
          type="submit"
          flex={1}
        >
          Continue
        </Button>
      </StyledForm>

      {mfaResolver.hints.length > 1 && (
        <Button
          mt
          variant="link"
          onClick={() => onSwitchFactor(multiFactor.uid)}
        >
          Use code from an authenticator app
        </Button>
      )}

      <div id="recaptcha" />
    </>
  );
};

export default Phone;
