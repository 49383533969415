import { StaleRecipientList } from 'components';
import { FC, useState } from 'react';
import { updateInvoice } from 'services/firebase/invoices';
import { useStoreState } from 'state';
import { IContact, IInvoiceFromSearch, IRecipient } from 'types';
import {
  Notify,
  getInitialRecipientTransferType,
  getRecipientsLinkableToInvoice,
} from 'utils';
import Button from 'components/shared/Button/Button';
import Popup from 'components/shared/Popup/Popup';
import { Title } from 'components/shared/Typography/Typography';
import { updateInvoicesBasedOnBackendUpdate } from './utils';
import useInvoiceRecipientPrePopulatedValues from '../hooks/useInvoiceRecipientPrePopulatedValues';
import AddContactForm from 'components/shared/AddContactForm/AddContactForm';

interface OwnProps {
  onClose: () => void;
  invoice: IInvoiceFromSearch;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
}

// TODO: unify component in Transfer Review
const SelectExistingContact: FC<OwnProps> = ({
  onClose,
  invoice,
  updateInMemoryInvoices,
}) => {
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { recipients } = useStoreState((state) => state.RecipientsState);
  const recipientsToShow = getRecipientsLinkableToInvoice(
    recipients,
    invoice.externalRefsSourceSystemContactId,
    invoice.currency
  );
  const [recipient, setRecipient] = useState<IRecipient>();
  const [recipientForEdit, setRecipientForEdit] = useState<
    Partial<IRecipient>
  >();
  const [isAddRecipient, setIsAddRecipient] = useState(false);
  const [isUpdatingInvoice, setIsUpdatingInvoice] = useState(false);
  const buyCurrency = currencyByCode(invoice.currency);
  const sellCurrency = currencyByCode(systemVariables?.defaultSellCurrency);
  const {
    recipientPrePopulatedValues,
    recipientFromInvoiceExternalSystem,
    isLoadingContact,
    resetPrePopulatedValues,
  } = useInvoiceRecipientPrePopulatedValues(invoice);

  const { id } = invoice;

  const onAddRecipient = () => {
    setRecipientForEdit(recipientPrePopulatedValues);
    setIsAddRecipient(true);
  };

  const onEditRecipient = (recipient: IContact) => {
    setRecipientForEdit(recipient);
  };

  const onConfirm = async () => {
    try {
      if (!recipient) {
        return;
      }

      setIsUpdatingInvoice(true);

      const response = await updateInvoice({
        id,
        data: {
          contactId: recipient.id,
        },
        updateForSameContact: true,
        transformToElastic: true,
      });

      if (response.data.success && response.data.data) {
        updateInMemoryInvoices(
          updateInvoicesBasedOnBackendUpdate(response.data.data)
        );
      }

      onClose();
    } catch (error: any) {
      Notify.error(`Failed to update invoice. Error: ${error}`);
    } finally {
      setIsUpdatingInvoice(false);
    }
  };

  const initialCurrency = recipientForEdit
    ? currencyByCode(recipientForEdit.currency)
    : buyCurrency;

  const recipientToUse = recipient ?? recipientFromInvoiceExternalSystem;

  return (
    <>
      {(isAddRecipient || !!recipientForEdit) &&
      sellCurrency &&
      initialCurrency ? (
        <AddContactForm
          isForceCreate={isAddRecipient}
          onClose={() => {
            setRecipientForEdit(undefined);
            setIsAddRecipient(false);
            resetPrePopulatedValues();
          }}
          disableCurrency
          withSaveAsDraft={false}
          sellCurrency={sellCurrency}
          initialCurrency={initialCurrency}
          recipientForEdit={recipientForEdit}
          initialTransferType={getInitialRecipientTransferType(
            recipientForEdit
          )}
          setRecipient={setRecipient}
          onContinue={() => {
            setIsAddRecipient(false);
            setRecipientForEdit(undefined);
            resetPrePopulatedValues();
          }}
        />
      ) : (
        <Popup
          width="539px"
          height="760px"
          onClose={onClose}
          HeaderContent={<Title variant="h3">Select existing contact</Title>}
          FooterContent={
            <>
              <Button
                onClick={onClose}
                disabled={isUpdatingInvoice}
                variant="secondary"
              >
                Close
              </Button>
              <Button
                ml
                onClick={onConfirm}
                disabled={isUpdatingInvoice || !recipient}
                isLoading={isUpdatingInvoice}
              >
                Confirm
              </Button>
            </>
          }
        >
          {buyCurrency && sellCurrency && (
            <StaleRecipientList
              buyCurrency={buyCurrency}
              sellCurrency={sellCurrency}
              onAddRecipient={onAddRecipient}
              onEditRecipient={onEditRecipient}
              recipient={recipientToUse}
              recipients={recipientsToShow}
              setRecipient={setRecipient}
              checkForDisabledRecipients={false}
              recipientItemType="dropdown"
              disabled={isLoadingContact}
            />
          )}
        </Popup>
      )}
    </>
  );
};

export default SelectExistingContact;
