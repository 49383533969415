import { FC } from 'react';
import { Col } from 'components/shared/Col/Col';
import CounterTitle from '../../../CounterTitle/CounterTitle';
import Placeholder from '../../../Placeholder/Placeholder';

const PaymentRunsPlaceholder: FC = () => (
  <Col mb>
    <CounterTitle mb title="Payment runs" />
    <Placeholder
      fullWidth
      description=" You will see your payment runs here when you have some."
    />
  </Col>
);

export default PaymentRunsPlaceholder;
