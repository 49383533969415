import useUrlValues from 'hooks/useUrlValues';
import { IFollowedCurrencyPair } from 'types';

const useFollowedCurrencies = () => {
  const { setUrlValue } = useUrlValues();

  const onEditCurrency = (currency: IFollowedCurrencyPair) =>
    setUrlValue({ currencyForEdit: currency.id });

  const onAddCurrency = (currencyCode?: string) => {
    setUrlValue({ addCurrency: 'true' });
    if (currencyCode) {
      setUrlValue({ foreignCurrency: currencyCode });
    }
  };

  return {
    onEditCurrency,
    onAddCurrency,
  };
};

export default useFollowedCurrencies;
