import { FC, useEffect, useState } from 'react';
import awesomeDebouncePromise from 'awesome-debounce-promise';

import StaleInput, { InputProps } from '../StaleInput/StaleInput';
import { useStoreActions } from 'state';
import { BankInfoWrapper } from './StaleInputSwift.styles';
import { Paragraph } from '../Typography/Typography';
import { usePrevious } from 'hooks';

interface OwnProps extends InputProps {
  withBankDetails?: boolean;
  onGetBankDataCallback?: (bankData: any) => any;
  ibanCountryCode?: string;
  routingNumberCountryCode?: string;
  onChangeCallback?: (e: any) => void;
}

const StaleInputSwift: FC<OwnProps> = ({
  rules,
  withBankDetails = false,
  onGetBankDataCallback,
  ibanCountryCode,
  routingNumberCountryCode,
  control,
  error,
  onChangeCallback,
  validateOnMount,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [bankData, setBankData] = useState<any>(null);

  const { checkRecipientBankDetails } = useStoreActions(
    (actions) => actions.RecipientsState
  );

  const prevRoutingNumberCountryCode = usePrevious(routingNumberCountryCode);

  useEffect(() => {
    const value = control?.getValues('bicSwift');

    if ((value && validateOnMount) || validateOnMount) {
      control?.trigger('bicSwift');
    }

    return () => {
      setBankData(null);
      onGetBankDataCallback?.(null);
    };
  }, [validateOnMount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const value = control?.getValues('bicSwift');

    const isIbanCountryDiff =
      ibanCountryCode &&
      (bankData?.bankCountry_ISO !== ibanCountryCode || error);
    const isRoutingNumberCountryDiff =
      routingNumberCountryCode &&
      (bankData?.bankCountry_ISO !== routingNumberCountryCode || error);

    if (value && (isRoutingNumberCountryDiff || isIbanCountryDiff)) {
      control?.trigger('bicSwift');
    }

    if (routingNumberCountryCode !== prevRoutingNumberCountryCode && error) {
      control?.trigger('bicSwift');
    }
  }, [ibanCountryCode, routingNumberCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StaleInput
        isLoading={isLoading}
        isDone={isDone}
        onChangeCallback={(e) => {
          setIsDone(false);
          setBankData(null);
          onChangeCallback?.(e);
        }}
        control={control}
        error={error}
        rules={{
          ...rules,
          validate: awesomeDebouncePromise(async (value: string) => {
            try {
              setIsDone(false);
              setBankData(null);
              setIsLoading(true);

              const response = await checkRecipientBankDetails({
                type: 'bic_swift',
                value,
              });

              setIsLoading(false);

              if (response?.success) {
                setBankData(response);

                onGetBankDataCallback && onGetBankDataCallback(response);

                if (
                  ibanCountryCode &&
                  ibanCountryCode !== response?.bankCountry_ISO
                ) {
                  return 'IBAN country and BIC / SWIFT country do not match.';
                }

                if (
                  routingNumberCountryCode &&
                  routingNumberCountryCode !== response?.bankCountry_ISO
                ) {
                  return 'Routing number country and BIC / SWIFT country do not match.';
                }

                setIsDone(true);
                return true;
              } else {
                return 'BIC / SWIFT is not valid';
              }
            } catch (error: any) {
              return 'Failed to check BIC / SWIFT, please try again';
            }
          }, 1000),
        }}
        {...restProps}
      />
      {withBankDetails && isDone && bankData && (
        <BankInfoWrapper>
          <Paragraph>
            {bankData.bankName && <b>{`${bankData.bankName}, `}</b>}
            {bankData.bankCity && `${bankData.bankCity}`}
          </Paragraph>
        </BankInfoWrapper>
      )}
    </>
  );
};

export default StaleInputSwift;
