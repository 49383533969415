import { ButtonHTMLAttributes, FC } from 'react';
import { ButtonCopyWrapper } from './ButtonCopy.styles';
import Icon from '../Icon/Icon';
import { useTheme } from 'styled-components';

interface IOwnProps
  extends Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    'onClick' | 'autoFocus'
  > {
  showIcon?: boolean;
}

const ButtonCopy: FC<IOwnProps> = ({
  onClick,
  autoFocus = false,
  showIcon = false,
  children,
}) => {
  const theme = useTheme();

  return (
    <ButtonCopyWrapper autoFocus={autoFocus} onClick={onClick}>
      {children}

      {showIcon && (
        <Icon
          ml
          mlValue={theme.spacing.xxs}
          fill={theme.color.emeraldDark}
          icon="copy-ico"
        />
      )}
    </ButtonCopyWrapper>
  );
};

export default ButtonCopy;
