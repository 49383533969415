import { FC, useEffect, useState } from 'react';

import { Paragraph, Col, Title, Loader, StaleInputRadioNew } from 'components';
import { Notify } from 'utils';
import { useTheme } from 'styled-components';
import { updateEntitySettings } from 'services/firebase';
import { useStoreState } from 'state';
import { useHistory } from 'react-router';
import { FUTURE_CURRENCY_QUESTIONS } from 'variables';
import Button from 'components/shared/Button/Button';
import { getRiskSettingsPageLink } from 'utils/links';

const StepThree: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { userEntity } = useStoreState((state) => state.UserState);
  const [isLoading, setIsLoading] = useState(!userEntity);
  const [question, setQuestion] = useState('');

  const onContinue = async (step: string) => {
    try {
      setIsLoading(true);

      const response = await updateEntitySettings({
        riskSettingExpectedCashFlows: question,
      });

      if (response?.data?.success) {
        history.push(getRiskSettingsPageLink({ step }));
      } else {
        Notify.error(response?.data?.message ?? '');
        setIsLoading(false);
      }
    } catch (error: any) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userEntity.riskSettingExpectedCashFlows) {
      setQuestion(userEntity.riskSettingExpectedCashFlows);
    }
  }, [userEntity]);

  return isLoading ? (
    <Loader size="large" />
  ) : (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Expected cashflows
      </Title>

      <Col justifyContent="center">
        <Paragraph mb mbValue={theme.spacing.xl}>
          Your finances may be exposed to currency fluctuations even before
          invoices are posted. Any foreign currency sales or purchase order
          exposes your profit margins to currency effects.
        </Paragraph>

        <Title variant="h3" mb mbValue={theme.spacing.xxl}>
          How do you want to manage future currency risks?
        </Title>
        <form>
          <Col rowGap={theme.spacing.m} mb mbValue={theme.spacing.xxxl}>
            {FUTURE_CURRENCY_QUESTIONS.map(({ id, value, name }) => (
              <StaleInputRadioNew
                key={id}
                id={value}
                label={name}
                checked={question === value}
                onChange={() => setQuestion(value)}
              />
            ))}
          </Col>

          <Col gap={theme.spacing.xl} justifyContent="center">
            <Button
              disabled={isLoading || !question}
              isLoading={isLoading}
              onClick={() => onContinue('4')}
              variant="secondary"
            >
              Add Hedge Ratios
            </Button>

            <Button
              disabled={isLoading || !question}
              isLoading={isLoading}
              onClick={() => onContinue('5')}
            >
              Continue
            </Button>
          </Col>
        </form>
      </Col>
    </>
  );
};

export default StepThree;
