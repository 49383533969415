import { Wrapper } from './InfoCard.styles';

const InfoCard = ({ skin = 'green', icon = '', children }: any) => {
  return (
    <Wrapper hasIcon={!!icon} skin={skin}>
      {icon && (
        <svg className="icon">
          <use xlinkHref={`#${icon}`} />
        </svg>
      )}

      {children}
    </Wrapper>
  );
};

export default InfoCard;
