import { Col } from 'components';
import { CSSProperties } from 'react';
import styled from 'styled-components';

export const NetWorthSectionExpansionContent = styled(Col)`
  transition: ${({ theme }) => theme.transition};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-height: 100%;
  z-index: 1;
  overflow: hidden;
`;

export const NetWorthSectionMainContent = styled(Col)`
  z-index: 2;
  box-shadow: ${({ theme }) => theme.shadow};
`;

export interface NetWorthSectionWrapperProps {
  zIndex?: CSSProperties['zIndex'];
  isExpanded: boolean;
}

export const NetWorthSectionWrapper = styled(Col)<NetWorthSectionWrapperProps>`
  position: relative;
  z-index: ${({ zIndex = 'unset' }) => zIndex};

  ${({ isExpanded }) =>
    isExpanded &&
    `
    ${NetWorthSectionExpansionContent} {
        top: 100%;
        max-height: 100vh;
      }
  `}
`;
