/**
 * Altered from https://github.com/kentcdodds/kentcdodds.com/blob/main/app/utils/misc.tsx#L296-L323
 */
export const updateQueryStringValueWithoutNavigation = (
  queryKey: string,
  queryValue?: string
) => {
  const currentSearchParams = new URLSearchParams(window.location.search);
  const oldQuery = currentSearchParams.get(queryKey) ?? '';
  if (queryValue === oldQuery) {
    return;
  }

  if (queryValue) {
    currentSearchParams.set(queryKey, queryValue);
  } else {
    currentSearchParams.delete(queryKey);
  }
  const newUrl = [window.location.pathname, currentSearchParams.toString()]
    .filter(Boolean)
    .join('?');

  window.history.replaceState(null, '', newUrl);
};

export const deleteQueryStringKey = (queryKey: string) =>
  updateQueryStringValueWithoutNavigation(queryKey);
