import { useState } from 'react';
import {
  UpdateInvoiceApprovalStatusParams,
  updateInvoicesApprovalStatus,
} from 'services/firebase/invoices';
import { Notify } from 'utils';
import { getInvoiceApprovalNotificationMessage } from 'utils/invoices';

const useInvoicesApprovalStatus = () => {
  const [
    isUpdatingInvoicesApprovalStatus,
    setIsUpdatingInvoiceApprovalStatus,
  ] = useState(false);

  const updateInvoicesApprovalStatusHandler = async (
    data: UpdateInvoiceApprovalStatusParams
  ) => {
    try {
      setIsUpdatingInvoiceApprovalStatus(true);
      const response = await updateInvoicesApprovalStatus(data);

      if (response && response.data?.success) {
        Notify.success(
          getInvoiceApprovalNotificationMessage(
            data.approvalStatus,
            data.invoiceIds.length > 1
          )
        );
      } else {
        Notify.error(response?.data?.message || '');
      }
    } catch (error: any) {
      Notify.error(error.response?.data?.error);
    } finally {
      setIsUpdatingInvoiceApprovalStatus(false);
    }
  };

  return {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus: updateInvoicesApprovalStatusHandler,
  };
};

export default useInvoicesApprovalStatus;
