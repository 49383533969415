import { FC } from 'react';
import Button from 'components/shared/Button/Button';
import { Paragraph, Title } from 'components/shared/Typography/Typography';
import Popup from 'components/shared/Popup/Popup';

interface OwnProps {
  onContinue: () => void;
  onClose: () => void;
}

const CancelBulkPayment: FC<OwnProps> = ({ onContinue, onClose }) => (
  <Popup
    width="400px"
    onClose={onClose}
    HeaderContent={<Title variant="h3">Cancel transaction</Title>}
    FooterContent={
      <>
        <Button onClick={onClose}>No</Button>
        <Button ml variant="secondary" onClick={onContinue}>
          Yes, cancel
        </Button>
      </>
    }
  >
    <Paragraph>Are you sure you want to cancel this transaction?</Paragraph>
  </Popup>
);

export default CancelBulkPayment;
