import { FC } from 'react';
import { Title } from '../Typography/Typography';
import { SpacingCssProps } from '../Spacing/Spacing.styles';
import { useTheme } from 'styled-components';

interface OwnProps extends SpacingCssProps {
  title: string;
  count?: number;
}

const CounterTitle: FC<OwnProps> = ({ title, count, ...rest }) => {
  const theme = useTheme();
  return (
    <Title variant="h5" {...rest}>
      {title}
      {!!count && (
        <Title
          ml
          variant="h5"
          mlValue={theme.spacing.xs}
          color="grey"
          as="span"
        >
          {count}
        </Title>
      )}
    </Title>
  );
};

export default CounterTitle;
