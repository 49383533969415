import { css } from 'styled-components';

export const cardBaseCss = css`
  min-width: ${({ theme }) => theme.cardSizes.xs};

  @media (min-width: ${({ theme }) => theme.breakpoint.smallMin}px) {
    min-width: 98%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.mediumMin}px) {
    max-width: 49%;
    min-width: ${({ theme }) => theme.cardSizes.xs};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.extraLargeMin}px) {
    max-width: 32.7%;
  }
`;

export const cardBaseSmallCss = css`
  ${cardBaseCss}

  min-width: ${({ theme }) => theme.cardSizes.xxxs};

  @media (min-width: ${({ theme }) => theme.breakpoint.mediumMin}px) {
    min-width: ${({ theme }) => theme.cardSizes.xxxs};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.extraLargeMax}px) {
    max-width: 24.5%;
  }
`;

export const expandableCardBaseWrapperCss = css`
  flex: 1;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const expandableCardBaseContentCss = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-height: 100%;
  transition: ${({ theme }) => theme.transition};
  z-index: 1;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.color.transparent};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    &:hover {
      max-height: 100vh;
      border-color: ${({ theme }) => theme.color.dark};
      padding-bottom: ${({ theme }) => theme.spacing.m};
    }
  }
`;
