import styled from 'styled-components';
import { Col } from 'components';
import { Flex } from 'components/shared/Flex/Flex.styles';

export const IdVerificationThanksPageWrapper = styled(Flex)`
  flex: 1;
  background: ${({ theme }) => theme.color.white};
  align-items: center;
`;

export const IdVerificationThanksPageContent = styled(Col)`
  align-items: center;
  max-width: ${({ theme }) => theme.breakpoint.smallMin}px;
  margin: 0 auto;
`;
