import { FC } from 'react';
import { Input, Label } from './StaleSwitch.styles';

interface OwnProps {
  id: string;
  isOn: boolean;
  disabled?: boolean;
  handleToggle?: () => void;
}

const StaleSwitch: FC<OwnProps> = ({
  id,
  isOn,
  disabled = false,
  handleToggle,
}) => {
  return (
    <>
      <Input
        checked={isOn}
        onChange={handleToggle}
        id={id}
        type="checkbox"
        disabled={disabled}
      />
      <Label htmlFor={id} disabled={disabled} />
    </>
  );
};

export default StaleSwitch;
