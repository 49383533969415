import { TInvoicesAggregationPerCurrency } from 'types';

export const getInvoiceAggregationValue = (
  aggregationValues: TInvoicesAggregationPerCurrency | undefined,
  key: keyof TInvoicesAggregationPerCurrency
) => aggregationValues?.[key]?.value ?? 0;

export const getInvoiceAggregationCount = (
  aggregationValues: TInvoicesAggregationPerCurrency | undefined,
  key: keyof TInvoicesAggregationPerCurrency
) => aggregationValues?.[key]?.count ?? 0;
