import styled from 'styled-components';
import Card from 'components/shared/Card/Card.styles';

interface IOwnProps {
  isOverdue?: boolean;
}

export const MobileInvoiceCard = styled(Card)<IOwnProps>`
  border-left: ${({ theme, isOverdue }) =>
    `${theme.spacing.xxs} solid ${
      isOverdue ? theme.color.red : theme.color.transparent
    }`};
`;
