import styled from 'styled-components';
import { Row } from 'components';

export const NameRow = styled(Row)`
  justify-content: flex-start;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    min-width: 20px;
  }
`;
