import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'dayj... Remove this comment to see the full error message
import dayjsBusinessDays from 'dayjs-business-days';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';

import { Title, Subtitle, Col, FundingAccountCredentials } from 'components';
import { useStoreState } from 'state';
import {
  getDashboardPageLink,
  getTransferFeeLabel,
  parseIntoCurrencyString,
} from 'utils';
import { StepsNotification } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import TransferConvertInfo from '../TransferConvertInfo/TransferConvertInfo';
import {
  WHAT_HAPPENS_NEXT_FX_DATA,
  WHAT_HAPPENS_NEXT_SAME_CURRENCY_DATA,
} from './consts';
import { useQuery } from 'hooks';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import Accordion from 'components/shared/Accordion/Accordion';
import useDeviceWidth from 'hooks/useDeviceWidth';
import useBalance from 'hooks/useBalance';
import TransferSummaryInfo from 'components/shared/TransferSummaryInfo/TransferSummaryInfo';
import { TRANSFER_STATUS } from 'types';
import useFundingAccountCredentialsActions from '../FundingAccountCredentials/useFundingAccountCredentialsActions';
import FundingAccountCredentialsActions from '../FundingAccountCredentialsActions/FundingAccountCredentialsActions';

dayjs.extend(dayjsBusinessDays);

interface OwnProps {
  transferType: 'sameCurrency' | 'fx';
  setIsFinishedTransfer: Dispatch<SetStateAction<boolean>>;
}

const TransferSummary: FC<OwnProps> = ({
  transferType,
  setIsFinishedTransfer,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile } = useDeviceWidth();

  const urlQuery = useQuery();
  const transferId = urlQuery.get('transferId');

  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { transferById } = useStoreState((state) => state.TransfersState);
  const createdTransfer = transferById(transferId);

  const transferTypeLabel = getTransferFeeLabel(
    createdTransfer?.recipient.paymentType
  );
  const createdTransferFee = createdTransfer?.priorityTransferFeeAmount ?? 0;
  const sellCurrency = currencyByCode(createdTransfer?.sellCurrency);
  const buyCurrency = currencyByCode(createdTransfer?.buyCurrency);

  const conversionFeeRate = createdTransfer?.conversionFeeRate || 0;

  const { isLoading, isEnoughBalance, onPayWithBalance } = useBalance({
    sellCurrency: sellCurrency?.code,
    sellAmount: createdTransfer?.sellAmount,
  });

  const canBePaidWithBalance =
    createdTransfer?.status === TRANSFER_STATUS.awaitingPayment &&
    isEnoughBalance;

  const fundingAccountCredentialsActions = useFundingAccountCredentialsActions();

  useEffect(() => {
    setIsFinishedTransfer(true);
  }, [setIsFinishedTransfer]);

  if (!createdTransfer) {
    return null;
  }

  const onPayWithBalanceHandler = () =>
    onPayWithBalance({
      payload: {
        currency: createdTransfer.sellCurrency,
        amount: createdTransfer.payAmount,
      },
      onSuccess: () => history.push(getDashboardPageLink()),
    });

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          {createdTransfer && (
            <>
              <StepsNotification>
                <Subtitle color="white">
                  Transaction registered. We are awaiting your payment
                </Subtitle>
              </StepsNotification>

              <Title variant="h5" mb>
                Fund{' '}
                <strong>{`${sellCurrency?.code} ${parseIntoCurrencyString(
                  createdTransfer?.payAmount,
                  sellCurrency?.precision
                )}`}</strong>{' '}
                {`before ${createdTransfer?.payByTimeGMT} ${dayjs(
                  createdTransfer?.payByDate
                ).format('on D MMM YYYY')}`}
              </Title>

              <FundingAccountCredentials
                assetId={createdTransfer.id}
                sellCurrency={createdTransfer.sellCurrency}
                canBePaidWithBalance={canBePaidWithBalance}
                fundingAccountCredentialsActions={
                  fundingAccountCredentialsActions
                }
                status={createdTransfer.status}
                isFundingInitiated={
                  createdTransfer.status !== TRANSFER_STATUS.awaitingPayment
                }
              />
            </>
          )}
        </FlowContentLeft>

        {!isMobile && (
          <FlowContentRight>
            <Col rowGap={theme.spacing.m}>
              {transferType === 'fx' &&
                createdTransfer &&
                buyCurrency &&
                sellCurrency && (
                  <>
                    <TransferConvertInfo
                      buyAmount={createdTransfer.buyAmount}
                      sellAmount={createdTransfer.sellAmount}
                      buyCurrency={buyCurrency}
                      sellCurrency={sellCurrency}
                      rate={createdTransfer.rate}
                      conversionFeeRate={conversionFeeRate}
                      isRateLoading={false}
                      fee={createdTransferFee}
                      paymentFeeLabel={transferTypeLabel}
                      payAmount={createdTransfer.payAmount}
                    />

                    <Accordion
                      isList
                      label="What happens next?"
                      data={WHAT_HAPPENS_NEXT_FX_DATA}
                    />
                  </>
                )}

              {transferType === 'sameCurrency' &&
                createdTransfer &&
                sellCurrency && (
                  <>
                    <TransferSummaryInfo
                      sellCurrency={sellCurrency}
                      sellAmount={createdTransfer.sellAmount}
                      fee={createdTransferFee}
                      recipient={createdTransfer.recipient}
                    />
                    <Accordion
                      isList
                      label="What happens next?"
                      data={WHAT_HAPPENS_NEXT_SAME_CURRENCY_DATA}
                    />
                  </>
                )}
            </Col>
          </FlowContentRight>
        )}
      </FlowStepContent>

      <FlowStepFooter isFinalStep>
        <FundingAccountCredentialsActions
          isFundingInitiated={
            createdTransfer.status !== TRANSFER_STATUS.awaitingPayment
          }
          isLoading={isLoading}
          fundingAccountCredentialsActions={fundingAccountCredentialsActions}
          onPayWithBalanceHandler={onPayWithBalanceHandler}
          onSecondaryButtonClick={() => history.push('/app/dashboard')}
          secondaryButtonText={
            fundingAccountCredentialsActions.isSingleFundingMethod ||
            fundingAccountCredentialsActions.isFundingManually ||
            createdTransfer.status !== TRANSFER_STATUS.awaitingPayment
              ? 'Back to Dashboard'
              : 'Skip for now'
          }
        />

        {isMobile && (
          <Accordion
            isList
            label="What happens next?"
            data={WHAT_HAPPENS_NEXT_FX_DATA}
          />
        )}
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default TransferSummary;
