import { FC } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import SummaryTable from 'components/shared/SummaryTable/SummaryTable';
import { Icon, PermissionsChecker, Row, Loader, Col } from 'components';
import Button from 'components/shared/Button/Button';
import Card from 'components/shared/Card/Card.styles';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import useSwitchClients from 'hooks/useSwitchClients';
import { getXeroNewClientLink } from 'utils/links';

import { Flex } from 'components/shared/Flex/Flex.styles';
import AddAnotherCompany from 'components/shared/AddAnotherCompany/AddAnotherCompany';
import CashNeeds from 'components/shared/CashNeeds/CashNeeds';

const SwitchClients: FC<RouteComponentProps> = () => {
  const theme = useTheme();
  const history = useHistory();
  const {
    clients,
    isLoadingClients,
    onSwitchAccount,
    getUserClients,
  } = useSwitchClients();

  return (
    <Col gap={theme.spacing.xxl}>
      {isLoadingClients && <Loader size="large" />}
      {!isLoadingClients && clients?.length && (
        <>
          <CashNeeds includeAllUserEntities showCashByEntity />
          <SummaryTable shouldFetchForAllEntities />

          <Col>
            <Row>
              <CounterTitle
                mr
                title="Clients"
                count={!!clients.length ? clients.length : undefined}
              />
              <PermissionsChecker action="create" resource="entities">
                <Row gap={theme.spacing.m}>
                  <AddAnotherCompany getUserClients={getUserClients} />

                  <Button
                    variant="link"
                    onClick={() => history.push(getXeroNewClientLink())}
                  >
                    <Icon width="16px" height="16px" icon="show-more-ico" />
                    Add from Xero
                    <Icon ml width="16px" height="16px" icon="xero-ico" />
                  </Button>
                </Row>
              </PermissionsChecker>
            </Row>

            {clients.map((entity) => (
              <Card key={entity.name} mt mtValue={theme.spacing.m}>
                <Row justifyContent="space-between">
                  {entity.name}
                  <Flex flexBasis="20%">
                    <Button onClick={() => onSwitchAccount(entity.entityId)}>
                      Switch Client
                    </Button>
                  </Flex>
                </Row>
              </Card>
            ))}
          </Col>
        </>
      )}
    </Col>
  );
};

export default SwitchClients;
