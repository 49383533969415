import { FC, useState } from 'react';
import { Notify } from 'utils';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { saveRegistrationSettings } from 'services/entities';
import { useQuery } from 'hooks';
import SignUpStepInfoForm, {
  SignUpStepInfoFormInputs,
} from 'components/shared/SignUpStepInfoForm/SignUpStepInfoForm';

const StepInfo: FC = () => {
  const history = useHistory();
  const URLQuery = useQuery();
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: SignUpStepInfoFormInputs) => {
    if (!values.entityCurrency) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await saveRegistrationSettings({
        entityCurrency: values.entityCurrency?.code,
        accountingSystem: values.accountingSystem || 'none',
        howDidYouFindUs: values.howDidYouFindUs || 'none',
      });

      if (response?.data?.success) {
        URLQuery.set('step', '3');
        history.push(`${url}?${URLQuery.toString()}`);
      } else {
        Notify.error(response?.data?.message ?? '');
        setIsLoading(false);
      }
    } catch (error: any) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  return <SignUpStepInfoForm isLoading={isLoading} onSubmit={onSubmit} />;
};

export default StepInfo;
