import { Col, Icon, Paragraph, Row, Subtitle } from 'components';
import { InnerDetailsWrapper } from 'components/shared/InnerDetails/InnerDetails.styles';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useStoreState } from 'state';
import { IBulkPayment } from 'types';
import { DATE_FORMAT } from 'variables';

import {
  getTransferStatusDetails,
  parseIntoCurrencyString,
  parseRateWithPrecision,
} from 'utils';
import { isOneCurrencyBulkPayment } from 'utils/bulkPayments';

interface OwnProps {
  transfer: IBulkPayment;
}

const BulkPaymentDetails: FC<OwnProps> = ({ transfer }) => {
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const statusInfo = getTransferStatusDetails(transfer);
  const isOneCurrency = isOneCurrencyBulkPayment(transfer);
  const buyCurrency = currencyByCode(transfer.buyAmountsBreakdown[0].currency);

  return (
    <InnerDetailsWrapper>
      <Col flex={1} alignSelf="stretch">
        <Subtitle variant="bold" mb>
          Details
        </Subtitle>

        {isOneCurrency && (
          <RowInfo>
            <Paragraph>Currency rate</Paragraph>
            <Row>
              <Icon
                icon={buyCurrency?.countryCode ?? ''}
                width="20px"
                height="20px"
              />
              <Paragraph ml>
                {`1 ${transfer.sellCurrency} = ${parseRateWithPrecision(
                  transfer.buyAmountsBreakdown[0].rate
                )} ${buyCurrency?.code}`}
              </Paragraph>
            </Row>
          </RowInfo>
        )}

        <RowInfo>
          <Paragraph>Created on</Paragraph>
          <Paragraph>{dayjs(transfer._created).format(DATE_FORMAT)}</Paragraph>
        </RowInfo>

        <RowInfo>
          <Paragraph>Scheduled payment date</Paragraph>
          <Paragraph ml>
            {dayjs(transfer.scheduledPaymentDate).format(DATE_FORMAT)}
          </Paragraph>
        </RowInfo>
      </Col>

      <Col flex={1} alignSelf="stretch">
        <Subtitle variant="bold" mb>
          Amount
        </Subtitle>

        <RowInfo>
          <Paragraph>Transfer amount total</Paragraph>
          <Paragraph>
            {parseIntoCurrencyString(transfer.payAmount - transfer.paymentFee)}{' '}
            {transfer.sellCurrency}
          </Paragraph>
        </RowInfo>
        <RowInfo>
          <Paragraph>Fee amount total</Paragraph>
          <Paragraph>
            {transfer.paymentFee} {transfer.sellCurrency}
          </Paragraph>
        </RowInfo>
        <RowInfo>
          <Paragraph variant="bold">Payment amount total</Paragraph>
          <Paragraph variant="bold">
            {parseIntoCurrencyString(transfer.payAmount)}{' '}
            {transfer.sellCurrency}
          </Paragraph>
        </RowInfo>
        <RowInfo>
          <Paragraph variant="bold">Status</Paragraph>
          <div style={{ textAlign: 'right' }} className="col">
            {statusInfo && <Paragraph>{statusInfo.text}</Paragraph>}
            {transfer.status === 'awaiting_payment' && (
              <Paragraph>
                {`(Pay by ${transfer.payByTimeGMT} ${dayjs(
                  transfer.payByDate
                ).format('on D MMM YYYY')})`}
              </Paragraph>
            )}
          </div>
        </RowInfo>
      </Col>
    </InnerDetailsWrapper>
  );
};

export default BulkPaymentDetails;
