import { useEffect, useState } from 'react';
import { validateRecipient } from 'services/firebase';
import { useStoreState } from 'state';
import {
  IContact,
  ICurrency,
  IRecipientValidationResult,
  Nullable,
  TRANSFER_TYPE,
} from 'types';
import { Notify } from 'utils';

const doObjectValuesMatch = (
  obj1: Record<string, string | number | boolean | null | undefined>,
  obj2: Record<string, string | number | boolean | null | undefined>
): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => obj1[key] === obj2[key]);
};

const useIpIdValidation = ({
  currencyValue,
  transferTypeValue,
  accountNumberValue,
  routingNumberValue,
  recipientNameValue,
  existingRecipient,
}: {
  currencyValue: {
    name: ICurrency['name'];
    id: ICurrency['id'];
    icon: ICurrency['countryCode'];
    value: ICurrency;
  };
  transferTypeValue: TRANSFER_TYPE;
  accountNumberValue: string;
  routingNumberValue?: string;
  recipientNameValue: string;
  existingRecipient?: Nullable<IContact | Partial<IContact>>;
}) => {
  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);
  const [
    isRecipientValidationEnabled,
    setIsRecipientValidationEnabled,
  ] = useState(false);

  useEffect(() => {
    if (!featureFlagById('recipientValidationIpId')) {
      return;
    }

    const shouldEnableValidationInGeneral =
      transferTypeValue === TRANSFER_TYPE.regular &&
      currencyValue.value.code === 'GBP';

    // That means we are updating an existing recipient,
    // we need to check if the sensitive values changed, otherwise we don't want to validate with IpId
    if (existingRecipient) {
      const doSensitiveValuesMatchWithExistingRecipient =
        // If recipient is draft, we don't need to validate only sensitive values, we want to always validate
        existingRecipient.status === 'draft'
          ? false
          : doObjectValuesMatch(
              {
                accountNumber: existingRecipient.accountNumber,
                routingNumber: existingRecipient.routingNumber,
                recipientName: existingRecipient.recipientName,
              },
              {
                accountNumber: accountNumberValue,
                routingNumber: routingNumberValue,
                recipientName: recipientNameValue,
              }
            );

      if (
        shouldEnableValidationInGeneral &&
        !doSensitiveValuesMatchWithExistingRecipient &&
        !isRecipientValidationEnabled
      ) {
        setIsRecipientValidationEnabled(true);
      }

      if (
        (!shouldEnableValidationInGeneral ||
          doSensitiveValuesMatchWithExistingRecipient) &&
        isRecipientValidationEnabled
      ) {
        setIsRecipientValidationEnabled(false);
      }
    }

    // That means we are creating a new recipient
    if (!existingRecipient) {
      if (shouldEnableValidationInGeneral && !isRecipientValidationEnabled) {
        setIsRecipientValidationEnabled(true);
      }
    }

    // If the currency is not GBP or the transfer type is not regular, we don't want to validate with IpId
    if (
      (currencyValue.value.code !== 'GBP' ||
        (currencyValue.value.code === 'GBP' &&
          transferTypeValue !== TRANSFER_TYPE.regular)) &&
      isRecipientValidationEnabled
    ) {
      setIsRecipientValidationEnabled(false);
    }
  }, [
    accountNumberValue,
    currencyValue.value.code,
    existingRecipient,
    featureFlagById,
    isRecipientValidationEnabled,
    recipientNameValue,
    routingNumberValue,
    transferTypeValue,
  ]);

  // Reset validation result when at least one of the sensitive fields is changed
  useEffect(() => {
    if (
      isRecipientValidationEnabled &&
      (accountNumberValue || routingNumberValue || recipientNameValue)
    ) {
      setValidationResult(null);
      setValidationError('');
    }
  }, [
    accountNumberValue,
    isRecipientValidationEnabled,
    recipientNameValue,
    routingNumberValue,
  ]);

  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [validationResult, setValidationResult] = useState<
    Nullable<IRecipientValidationResult>
  >(null);

  const validateRecipientHandler = async (existingRecipientId?: string) => {
    try {
      if (!recipientNameValue || !accountNumberValue || !routingNumberValue) {
        Notify.error(
          'Recipient name, account number and routing number are required'
        );
        return;
      }

      setIsValidationLoading(true);
      setValidationError('');

      const result = await validateRecipient({
        recipientId: existingRecipientId,
        recipientName: recipientNameValue,
        accountNumber: accountNumberValue,
        sortCode: routingNumberValue,
      });

      setValidationResult(result.data.data);

      return result.data.data;
    } catch (error: any) {
      setValidationResult(null);
      setValidationError(error.message);
    } finally {
      setIsValidationLoading(false);
    }
  };

  return {
    validationResult,
    validationError,
    isRecipientValidationEnabled,
    isValidationLoading,
    validateRecipientHandler,
  };
};

export default useIpIdValidation;
