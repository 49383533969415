import { FC, useMemo } from 'react';
import { Row, ContextMenu } from 'components';
import {
  getCurrencyExchangePageLink,
  getInvoicesPageLink,
  getSimpleTransferPageLink,
  getPrebookingsPageLink,
  getPrebookPageLink,
  getTransfersPageLink,
} from 'utils';
import { ContextMenuItem } from 'components/shared/ContextMenu/ContextMenu';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import { IAccountsTableDataItem } from 'types/bff';

interface OwnProps {
  data: IAccountsTableDataItem;
}

const ContextMenuCell: FC<OwnProps> = ({ data }) => {
  const history = useHistory();
  const { buyCurrencies, prebookableBuyCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );

  const contextMenuData = useMemo(() => {
    const dataToUse: ContextMenuItem[] = [];
    const { currency, balance, externalHedges, hedges, hasInvoices } = data;
    const prebookedAndHedged = externalHedges + hedges;
    const isPrebookable = !!prebookableBuyCurrencies.find(
      ({ enabled, code }) => enabled && code === currency
    );
    const isTransferable = !!buyCurrencies.find(
      ({ enabled, code }) => enabled && code === currency
    );

    if (isPrebookable) {
      dataToUse.push({
        id: 'prebook-exchange',
        title: 'Prebook FX',
        onClick: () =>
          history.push(
            getPrebookPageLink({
              predefinedBuyCurrency: currency,
            })
          ),
      });
    }

    if (isTransferable) {
      dataToUse.push({
        id: 'fx-transfer',
        title: 'Go to FX Transfer',
        onClick: () =>
          history.push(
            getTransfersPageLink({
              predefinedBuyCurrency: currency,
            })
          ),
      });
    }

    if (!!balance) {
      dataToUse.push({
        id: 'currency-exchange',
        title: 'Convert balance',
        onClick: () =>
          history.push(
            getCurrencyExchangePageLink({
              predefinedSellCurrency: currency,
              step: '1',
            })
          ),
      });
      dataToUse.push({
        id: 'simple-transfer',
        title: 'Go to Simple Transfer',
        onClick: () =>
          history.push(
            getSimpleTransferPageLink({
              predefinedCurrency: currency,
            })
          ),
      });
    }

    if (hasInvoices) {
      dataToUse.push({
        id: 'invoices',
        title: 'Go to Invoices',
        onClick: () =>
          history.push(
            getInvoicesPageLink({
              currency: currency,
            })
          ),
      });
    }

    if (!!prebookedAndHedged) {
      dataToUse.push({
        id: 'prebooks',
        title: 'Go to Prebooks',
        onClick: () => history.push(getPrebookingsPageLink({ currency })),
      });
    }

    return dataToUse;
  }, [history, data, buyCurrencies, prebookableBuyCurrencies]);

  if (!contextMenuData.length) {
    return null;
  }

  return (
    <Row justifyContent="flex-end" flex={1}>
      <ContextMenu list={contextMenuData} />
    </Row>
  );
};

export default ContextMenuCell;
