import { FC } from 'react';
import { Col } from 'components/shared/Col/Col';
import CounterTitle from '../CounterTitle/CounterTitle';
import Placeholder from '../Placeholder/Placeholder';
import Loader from '../Loader/Loader';

const TransactionsPlaceholder: FC<{
  onlyTransfers?: boolean;
  isLoading?: boolean;
}> = ({ isLoading = false, onlyTransfers }) => (
  <Col mb>
    <CounterTitle mb title={onlyTransfers ? 'Transfers' : 'Transactions'} />

    {!isLoading && (
      <Placeholder
        fullWidth
        description={`You will see your ${
          onlyTransfers ? 'transfers' : 'transactions'
        } here when you have some.`}
      />
    )}
    {isLoading && <Loader />}
  </Col>
);

export default TransactionsPlaceholder;
