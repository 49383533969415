import { FC, useMemo } from 'react';
import { Paragraph, InlineLoader } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { getInvoiceRemainingAmount } from 'utils/invoices';
import { useStoreState } from 'state';
import { IInvoiceFromSearch } from 'types';
import useInvoiceFromSearchRecord from 'hooks/useInvoiceFromSearchRecord';

interface OwnProps {
  record: IInvoiceFromSearch;
}

const AmountSellCurrencyCell: FC<OwnProps> = ({ record }) => {
  const { isSameCurrency } = useInvoiceFromSearchRecord({ record });
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const { transferById } = useStoreState((state) => state.TransfersState);
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  const rateToUse = useMemo(() => {
    // Although invoice.transferId is deprecated, when we save to Elastic we take the
    // last index from invoice.transferIds and store it in Elastic as transferId.
    // That's why using transferId here is valid.
    const { transferId, contractId, invoiceRate } = record;
    if (transferId) {
      return transferById(transferId)?.rate ?? invoiceRate;
    }
    if (contractId) {
      return rateContractById(contractId)?.rate ?? invoiceRate;
    }

    return invoiceRate;
  }, [rateContractById, record, transferById]);

  if (isSameCurrency) {
    return null;
  }

  if (!rateToUse) {
    return <InlineLoader />;
  }

  return (
    <Paragraph>
      {`${parseIntoCurrencyStringWithSymbol(
        (getInvoiceRemainingAmount(record) ?? 0) / rateToUse,
        entityCurrency?.symbol,
        entityCurrency?.precision
      )}`}
    </Paragraph>
  );
};

export default AmountSellCurrencyCell;
