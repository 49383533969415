import { CSSProperties, FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Wrapper } from './StalePopup.styles';
import { Title } from 'components';

interface OwnProps {
  title?: string;
  onClose: (...args: any) => any;
  theme?: string;
  width?: string;
  minHeight?: string;
  headContentAdditional?: any;
  autoFocusOnClose?: boolean;
  // TODO: NIKITA clarify what is that
  brim?: string;
  contentStyle?: CSSProperties;
}

/** @deprecated use Popup instead */
const StalePopup: FC<OwnProps> = ({
  title = '',
  onClose,
  theme = 'white',
  children,
  width = 'auto',
  minHeight = '538px',
  headContentAdditional = null,
  autoFocusOnClose = false,
  contentStyle,
}) => {
  useEffect(() => {
    const handler = (e: any) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        onClose();
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [onClose]);

  return (
    // TODO: move theme to the separate prop
    createPortal(
      <Wrapper popupTheme={theme} width={width} minHeight={minHeight}>
        <div className="backdrop" onClick={onClose} />

        <div className="head">
          {theme === 'white' && (
            <button autoFocus={autoFocusOnClose} onClick={onClose}>
              <svg>
                <use xlinkHref="#arrow-left" />
              </svg>
            </button>
          )}
          <Title variant="h5">{title}</Title>

          {headContentAdditional}

          {(theme === 'grey' || theme === 'red' || theme === 'small') && (
            <button
              autoFocus={autoFocusOnClose}
              onClick={() => onClose(false)}
              type="button"
            >
              <svg>
                <use xlinkHref="#cross-ico" />
              </svg>
            </button>
          )}
        </div>

        <div className="wrap-popup" style={contentStyle}>
          {children}
        </div>
      </Wrapper>,
      document.body
    )
  );
};
export default StalePopup;
