import styled, { css } from 'styled-components';
import { Paragraph, Subtitle } from 'components';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';

export const ErrorParagraph = styled(Paragraph)`
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: 0;
`;

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    .outcome {
      &-wrap {
        padding: 0 16px 0 16px;
        width: 100%;
        background: ${theme.color.greyLight_3};
        border-radius: 6px;
        position: relative;
        height: 52px;
        border: 1px solid ${theme.color.transparent};
        display: flex;
        align-items: center;
        font-weight: 500;

        svg {
          width: 24px;
          height: 24px;
          margin: -3px 8px 0 0;
        }

        &.focused {
          box-shadow: inset 0 0 0 1px ${theme.color.emeraldDark};
        }
      }

      .hide-input {
        position: absolute;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
      }

      .hide-input,
      .input {
        margin: 0 5px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        max-width: 180px;
        min-width: 1px;

        &::placeholder {
          color: ${theme.color.transparent};
        }
        &:-ms-input-placeholder {
          color: ${theme.color.transparent};
        }
        &::-ms-input-placeholder {
          color: ${theme.color.transparent};
        }
      }
    }
  `
);

export const FieldCol = styled(Col)`
  margin-bottom: 16px;
`;

export const ConvertRow = styled(Row)`
  margin-bottom: 24px;
`;

export const ConvertParagraph = styled(Paragraph)`
  margin-right: 8px;
`;

export const ConvertSvg = styled.svg`
  margin-right: 8px;
  width: 24px;
  height: 24px;
`;

export const RateTypeRow = styled(Row)`
  align-items: flex-start;
`;

export const RateTypeSubtitle = styled(Subtitle)`
  margin-bottom: 8px;
`;

export const OutcomeSubtitle = styled(Subtitle)(
  ({ theme }) => css`
    color: ${theme.color.grey};
  `
);
