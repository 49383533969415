import styled, { css } from 'styled-components';
import { Row } from 'components/shared/Row/Row';

export type TPillVariant =
  | 'standard'
  | 'purple'
  | 'purple2'
  | 'blue'
  | 'emerald';

export const StyledPill = styled(Row)<{
  variant?: TPillVariant;
}>`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.greyLight_1};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.l}`};
  justify-content: center;

  ${({ variant }) =>
    variant === 'purple' &&
    css`
      background: ${({ theme }) => theme.color.purpleLight_1};
      border-color: ${({ theme }) => theme.color.purpleLight_2};
    `}

  ${({ variant }) =>
    variant === 'purple2' &&
    css`
      background: ${({ theme }) => theme.color.purpleLight_3};
      border-color: ${({ theme }) => theme.color.purple};
    `}

  ${({ variant }) =>
    variant === 'blue' &&
    css`
      background: ${({ theme }) => theme.color.blueLight_1};
      border-color: ${({ theme }) => theme.color.blueLight_2};
    `}

    ${({ variant }) =>
    variant === 'emerald' &&
    css`
      background: ${({ theme }) => theme.color.emeraldLight};
      border-color: ${({ theme }) => theme.color.emeraldDark};
    `}
`;
