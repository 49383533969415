import styled from 'styled-components';

export const WhiteCenteredContainer = styled.div<{ fullWidth?: boolean }>`
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : '956px')};
  margin: 0 auto;
  box-shadow: ${({ theme }) => theme.shadow};
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;
