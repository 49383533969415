import { FC } from 'react';
import { Row } from '../../../../../../../Row/Row';
import Checkbox from '../../../../../../../Checkbox/Checkbox';
import { ITableFilterOption } from '../../../../../../types';

interface OwnProps {
  filterId: string;
  isActive: boolean;
  filterOption: ITableFilterOption;
  setFilter: (filterId: string, filterValue: ITableFilterOption) => void;
}

const FilterOptionCheckbox: FC<OwnProps> = ({
  filterId,
  isActive,
  filterOption,
  setFilter,
}) => {
  const { title } = filterOption;
  return (
    <Row>
      <Checkbox
        label={title}
        checked={isActive}
        onChange={() => setFilter(filterId, filterOption)}
      />
    </Row>
  );
};

export default FilterOptionCheckbox;
