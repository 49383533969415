import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';

export const ACCOUNTING_SYSTEMS: ISelectItem[] = [
  {
    id: 'xero',
    value: 'xero',
    name: 'Xero',
    icon: 'xero-ico',
  },
  {
    id: 'ndsk',
    value: 'ndsk',
    name: 'QuickBooks',
    icon: 'quick-books-ico',
  },
  {
    id: 'trji',
    value: 'trji',
    name: 'Dynamics 365 Business Central',
    icon: 'dynamics-365-ico',
  },
  {
    id: 'akxx',
    value: 'akxx',
    name: 'Oracle NetSuite',
    icon: 'netsuite-ico',
  },
  {
    id: 'other',
    value: 'other',
    name: 'Other',
    icon: 'other-ico',
  },
];

export const HOW_DID_YOU_FIND_US_DATA: ISelectItem[] = [
  {
    id: 'searchEngine',
    value: 'searchEngine',
    name: 'Search engine',
  },
  {
    id: 'socialMedia',
    value: 'socialMedia',
    name: 'Social media',
  },
  {
    id: 'publication',
    value: 'publication',
    name: 'Blog or publication',
  },
  {
    id: 'xero',
    value: 'xero',
    name: 'Xero Marketplace/Forums',
  },
  {
    id: 'recommendation',
    value: 'recommendation',
    name: 'Recommendation',
  },
  {
    id: 'other',
    value: 'other',
    name: 'Other',
  },
];
