import { Col, Table } from 'components';
import { FC, useMemo, useState } from 'react';
import { useStoreState } from 'state';
import {
  IPaymentRunItemSummary,
  IPaymentRunCurrencyTotal,
} from 'types/paymentRuns';
import { generateConfirmationTableColumns } from '../../tableColumnsGenerator';
import ConfirmationStepFooter from './components/ConfirmationStepFooter/ConfirmationStepFooter';
import MobilePaymentRunItemSummaryList from '../AuthoriseStep/components/MobilePaymentRunItemSummaryList/MobilePaymentRunItemSummaryList';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { payPaymentRunWithBalance } from 'services/paymentRuns';
import { errorHandler } from 'utils/errors';

interface OwnProps {
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  data: IPaymentRunItemSummary[];
  paymentDate: string;
  paymentRunId: string;
}

const ConfirmationStep: FC<OwnProps> = ({
  paymentRunTotals,
  data,
  paymentDate,
  paymentRunId,
}) => {
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const { isMobile } = useDeviceWidth();
  const [isLoading, setIsLoading] = useState(false);
  const [hidePayWithButton, setHidePayWithButton] = useState(false);
  const [tableData, setTableData] = useState<IPaymentRunItemSummary[]>(data);

  const tableColumns = useMemo(
    () =>
      generateConfirmationTableColumns({
        currencyByCode,
        entityDefaultCurrencyCode: entityCurrencyCode,
      }),
    [currencyByCode, entityCurrencyCode]
  );

  const useMyBalanceHandler = async () => {
    try {
      setIsLoading(true);
      const { data } = await payPaymentRunWithBalance({ paymentRunId });

      if (data.success) {
        setTableData(data.data || []);
        if (data.data) {
          // Check if there are any items that were not paid with balance
          const succeededPayments = data.data.filter(
            (item) => item.isPayWithBalanceSuccess
          );

          if (data.data.length === succeededPayments.length) {
            // All payments were successful
            setHidePayWithButton(true);
          }
        }
      } else {
        errorHandler(data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const footerProps = {
    paymentDate,
    paymentRunTotals,
    isLoading,
    useMyBalanceHandler,
    hidePayWithButton,
  };

  return (
    <Col>
      {!isMobile && (
        <Table<IPaymentRunItemSummary>
          data={tableData}
          columns={tableColumns}
          defaultRowHeight={56}
          renderFooterContent={<ConfirmationStepFooter {...footerProps} />}
          sortable
        />
      )}

      {isMobile && (
        <MobilePaymentRunItemSummaryList
          paymentDate={paymentDate}
          data={tableData}
          renderFooterContent={<ConfirmationStepFooter {...footerProps} />}
        />
      )}
    </Col>
  );
};

export default ConfirmationStep;
