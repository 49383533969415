import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Notify } from 'utils';
import { useStoreActions, useStoreState } from 'state';
import { saveRegistrationSettings } from 'services/entities';
import { BUSINESS_TYPE } from 'types';
import {
  getDashboardPageLink,
  getMyClientsLink,
  getSettingsPageLink,
} from 'utils/links';
import SignUpStepInfoForm, {
  SignUpStepInfoFormInputs,
} from 'components/shared/SignUpStepInfoForm/SignUpStepInfoForm';

interface OwnProps {
  firstName: string;
  lastName: string;
  companyCountry: string;
  companyType: BUSINESS_TYPE;
  companyName: string;
  isAccountant: boolean;
  onNavItemClick: (stepNumber?: number) => void;
  onCloseHandler: () => void;
}

const StepFour: FC<OwnProps> = ({
  firstName,
  lastName,
  companyCountry,
  companyName,
  companyType,
  isAccountant,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const shouldIntegrate = false;
  const history = useHistory();
  const { userEmail } = useStoreState((state) => state.UserState);
  const { createUser } = useStoreActions(({ UserState }) => UserState);

  const onSubmit = async (values: SignUpStepInfoFormInputs) => {
    try {
      if (!userEmail || !values.entityCurrency) {
        return;
      }

      setIsLoading(true);

      const response = await createUser({
        email: userEmail,
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        companyCountry,
        companyType,
        companyName,
        isAccountant,
      });

      if (!response?.success) {
        Notify.error(response?.message ?? '');
        setIsLoading(false);
        return;
      }

      const res = await saveRegistrationSettings({
        entityCurrency: values.entityCurrency.code,
        accountingSystem: values.accountingSystem || 'none',
        howDidYouFindUs: values.howDidYouFindUs || 'none',
      });

      if (res.data.success) {
        if (shouldIntegrate) {
          // TODO: Once the integration flows have been updated, this should kick off the relevant integration
          history.push(getSettingsPageLink({ tab: 'integrations' }));
        } else if (isAccountant) {
          history.push(getMyClientsLink());
        } else {
          history.push(getDashboardPageLink());
        }
      } else {
        Notify.error(res.data?.message ?? '');
      }
    } catch (error: any) {
      Notify.error(
        'Sign up was unsuccessful. If the issue persists then please contact support (support@hedgeflows.com)'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return <SignUpStepInfoForm isLoading={isLoading} onSubmit={onSubmit} />;
};

export default StepFour;
