import { CSSProperties, FC, ReactNode, useState } from 'react';
import { Modifier, usePopper } from 'react-popper';
import { Placement, PositioningStrategy } from '@popperjs/core';
import OutsideClickHandler from 'react-outside-click-handler';
import { createPortal } from 'react-dom';
import { Wrapper, Info, InfoPopup } from './StaleInfo.styles';
import Icon from '../Icon/Icon';

interface OwnProps {
  disabled?: boolean;
  trigger?: ReactNode;
  labelContent?: ReactNode;
  mode?: 'click' | 'hover';
  placement?: Placement;
  strategy?: PositioningStrategy;
  popupStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
  infoStyle?: CSSProperties;
  modifiers?: ReadonlyArray<Modifier<string>>;
  portal?: boolean;
  infoSize?: string;
}

const StaleInfo: FC<OwnProps> = ({
  disabled = false,
  labelContent = null,
  placement = 'auto',
  mode = 'click',
  strategy = 'absolute',
  trigger,
  popupStyle = {},
  wrapperStyle = {},
  infoStyle = {},
  modifiers = [],
  children,
  portal = false,
  infoSize = '24px',
}) => {
  const [show, setShow] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      ...modifiers,
    ],
    placement,
    strategy,
  });

  const renderContent = () => (
    <OutsideClickHandler
      onOutsideClick={() => {
        setTimeout(() => {
          setShow(false);
        });
      }}
    >
      <InfoPopup
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        ref={setPopperElement}
        style={{
          ...styles.popper,
          ...popupStyle,
        }}
        {...attributes.popper}
      >
        <div
          className="arrow"
          // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<null>>' is not assig... Remove this comment to see the full error message
          ref={setArrowElement}
          style={styles.arrow}
          {...attributes.arrow}
        />
        <div className="wrap" style={wrapperStyle}>
          {children}
        </div>
      </InfoPopup>
    </OutsideClickHandler>
  );

  return (
    <Wrapper>
      <Info
        disabled={disabled}
        type="button"
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        ref={setReferenceElement}
        onClick={
          mode === 'click'
            ? (event) => {
                event.stopPropagation();
                event.preventDefault();

                setShow(!show);
              }
            : undefined
        }
        onMouseEnter={mode === 'hover' ? () => setShow(true) : undefined}
        onMouseLeave={mode === 'hover' ? () => setShow(false) : undefined}
        onPointerEnter={mode === 'hover' ? () => setShow(true) : undefined}
        onPointerLeave={mode === 'hover' ? () => setShow(false) : undefined}
        style={{
          ...infoStyle,
          width: infoSize,
          height: infoSize,
        }}
      >
        {trigger ?? (
          <Icon
            icon="info-ico"
            width={infoSize}
            height={infoSize}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          />
        )}
      </Info>

      {!!labelContent && (
        <div onClick={() => setShow(!show)}>{labelContent}</div>
      )}

      {show && children && !disabled
        ? portal
          ? createPortal(renderContent(), document.body)
          : renderContent()
        : null}
    </Wrapper>
  );
};

export default StaleInfo;
