import Checkbox from 'components/shared/Checkbox/Checkbox';
import { IOpenBankingTableAccount } from 'pages/Settings/components/OpenBankingSettings/useOpenBankingSettings';
import { FC, useState } from 'react';
import { updateEntitySettings } from 'services/firebase';
import { useStoreState } from 'state';

interface IOwnProps {
  record: IOpenBankingTableAccount;
}

const IncludeInTotalsCell: FC<IOwnProps> = ({ record }) => {
  const { userEntity } = useStoreState((state) => state.UserState);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async () => {
    try {
      setIsLoading(true);
      if (userEntity.excludedAccountIds?.includes(record.id)) {
        await updateEntitySettings({
          excludedAccountIds: userEntity.excludedAccountIds.filter(
            (id) => id !== record.id
          ),
        });
      }

      if (!userEntity.excludedAccountIds?.includes(record.id)) {
        await updateEntitySettings({
          excludedAccountIds: [
            ...(userEntity.excludedAccountIds || []),
            record.id,
          ],
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Checkbox
      checked={!userEntity.excludedAccountIds?.includes(record.id)}
      onChange={handleChange}
      disabled={isLoading}
    />
  );
};

export default IncludeInTotalsCell;
