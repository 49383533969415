import { Icon } from 'components';
import { FC, useRef, useState } from 'react';
import {
  EditButton,
  EditableCellInput,
  HeaderEditableWrapper,
} from './HeaderEditable.styles';

interface IOwnProps {
  title: string;
  onEdit: (value: string) => void;
}

const HeaderEditable: FC<IOwnProps> = ({ onEdit, title }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(title || '-');

  const onBlurHandler = () => {
    if (title !== inputValue) {
      onEdit(inputValue);
    }
  };

  const onEditClick = () => {
    inputRef.current?.focus();
  };

  return (
    <HeaderEditableWrapper>
      <EditableCellInput
        ref={inputRef}
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        onBlur={onBlurHandler}
      />

      <EditButton onClick={onEditClick}>
        <Icon width="16px" height="16px" icon="edit-ico" />
      </EditButton>
    </HeaderEditableWrapper>
  );
};

export default HeaderEditable;
