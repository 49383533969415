import { useEffect } from 'react';
import { IRateContract, ICurrency, Nullable } from 'types';
import { useStoreState } from 'state';

interface UseAmountsParams {
  selectedRateContract?: Nullable<IRateContract>;
  updateBuyCurrency: (value: ICurrency) => void;
  updateSellCurrency: (value: ICurrency) => void;
}

const useSelectedRateContract = ({
  selectedRateContract,
  updateBuyCurrency,
  updateSellCurrency,
}: UseAmountsParams) => {
  const { sellCurrencies, buyCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );

  useEffect(() => {
    if (selectedRateContract) {
      const newBuyCurrency = buyCurrencies.find(
        (item) => item.code === selectedRateContract.buyCurrency
      );

      if (newBuyCurrency) {
        updateBuyCurrency(newBuyCurrency);
      }

      // we still need to set sell currency
      const sellCurrencyToUse = sellCurrencies.find(
        (item) => item.code === selectedRateContract.sellCurrency
      );

      if (sellCurrencyToUse) {
        updateSellCurrency(sellCurrencyToUse);
      }
    }
  }, [
    buyCurrencies,
    selectedRateContract,
    sellCurrencies,
    updateBuyCurrency,
    updateSellCurrency,
  ]);
};

export default useSelectedRateContract;
