import { Button, Row } from 'components';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IPaymentRunCurrencyTotal } from 'types/paymentRuns';
import { useHistory } from 'react-router';
import { getPaymentRunPageLink } from 'utils';
import { MobileInvoicesFooterWrapper } from './MobileInvoicesFooter.styles';
import usePermissionsCheck from 'hooks/usePermissionsCheck';
import { PaymentRunFooterTotalsWrapper } from 'components/shared/PaymentRunFooterWrappers/PaymentRunFooterWrappers.styles';

interface IOwnProps {
  currencyTotals: Pick<
    IPaymentRunCurrencyTotal,
    'currency' | 'amount' | 'amountInLocalCurrency'
  >[];
}

const MobileInvoicesFooter: FC<IOwnProps> = ({ currencyTotals }) => {
  const theme = useTheme();
  const history = useHistory();
  const hasPermissionsToContinue = usePermissionsCheck({
    action: 'update',
    resource: 'payment_runs',
  });

  return (
    <MobileInvoicesFooterWrapper>
      <PaymentRunFooterTotalsWrapper>
        <InvoicesCurrencyTotals
          title="Totals :"
          currencyTotals={currencyTotals}
        />
      </PaymentRunFooterTotalsWrapper>

      <Row gap={theme.spacing.m}>
        <Button
          disabled={!hasPermissionsToContinue}
          onClick={() =>
            history.push(
              getPaymentRunPageLink({
                step: '1',
              })
            )
          }
        >
          Continue
        </Button>
      </Row>
    </MobileInvoicesFooterWrapper>
  );
};

export default MobileInvoicesFooter;
