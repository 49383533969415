import dayjs from 'dayjs';
import { DATE_FORMAT } from 'variables';

export const showLockIcon = ({
  value,
  prebooked,
  paymentDate,
}: {
  value: boolean;
  prebooked: boolean;
  paymentDate: string;
}) => {
  if (prebooked) {
    return true;
  }

  if (
    value &&
    dayjs(paymentDate, DATE_FORMAT).isAfter(dayjs().add(1, 'day').endOf('day'))
  ) {
    return true;
  }

  return false;
};
