import { IRecipient } from 'types';

export const getInvoiceContactBillingAddress = (
  invoiceContact?: IRecipient
) => {
  let address = '';
  if (invoiceContact?.recipientAddress) {
    address += invoiceContact?.recipientAddress;
  }
  if (invoiceContact?.recipientPostcode) {
    address += ` ${invoiceContact?.recipientPostcode}`;
  }
  if (invoiceContact?.recipientCity) {
    address += ` ${invoiceContact?.recipientCity}`;
  }

  return `${address.trim()} `;
};
