import { FC } from 'react';
import { Row, Button } from 'components';
import { useTheme } from 'styled-components';
import { IFundingAccountCredentialsActions } from '../FundingAccountCredentials/useFundingAccountCredentialsActions';

interface IOwnProps {
  onPayWithBalanceHandler?: () => void;
  onSecondaryButtonClick: () => void;
  isLoading?: boolean;
  fundingAccountCredentialsActions: IFundingAccountCredentialsActions;
  secondaryButtonText: string;
  isFundingInitiated?: boolean;
}

const FundingAccountCredentialsActions: FC<IOwnProps> = ({
  onPayWithBalanceHandler,
  onSecondaryButtonClick,
  isLoading,
  fundingAccountCredentialsActions,
  secondaryButtonText,
  isFundingInitiated = false,
}) => {
  const theme = useTheme();
  const {
    isSingleFundingMethod,
    activeRadioButton,
    isConfirmed,
    setIsConfirmed,
    isFundingManually,
  } = fundingAccountCredentialsActions;

  return (
    <Row gap={theme.spacing.m}>
      {!isFundingManually && !isFundingInitiated && !isSingleFundingMethod && (
        <Button
          variant="primary"
          disabled={!activeRadioButton || isLoading}
          onClick={
            activeRadioButton === 'payWithBalance'
              ? onPayWithBalanceHandler
              : () => setIsConfirmed((prev) => !prev)
          }
        >
          {isConfirmed ? 'Go back' : 'Confirm'}
        </Button>
      )}

      <Button
        disabled={isLoading}
        variant={
          isFundingManually || isSingleFundingMethod || isFundingInitiated
            ? 'primary'
            : 'link'
        }
        onClick={onSecondaryButtonClick}
      >
        {secondaryButtonText}
      </Button>
    </Row>
  );
};

export default FundingAccountCredentialsActions;
