import { ERROR_MESSAGES } from 'variables';

export const defaultHedgeRatioValues = {
  3: {
    min: 50,
    max: 90,
  },
  6: {
    min: 30,
    max: 60,
  },
  9: {
    min: 20,
    max: 40,
  },
  12: {
    min: 10,
    max: 30,
  },
  24: {
    min: 0,
    max: 100,
  },
  36: {
    min: 0,
    max: 100,
  },
} as const;

export const hedgeRatioInputRules = {
  required: ERROR_MESSAGES.requiredField,
  min: {
    value: 0,
    message: 'Value must be 0 or greater',
  },
  max: {
    value: 100,
    message: 'Value must be 100 or less',
  },
};
