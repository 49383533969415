import useUrlValues from 'hooks/useUrlValues';
import { FC, useMemo } from 'react';
import TableHorizontal from 'components/shared/TableHorizontal/TableHorizontal';
import { generateTableColumns } from './tableColumnsGenerator';
import { getTdCellTheme, getThCellTheme } from '../../utils';
import { useStoreState } from 'state';
import { TRisksDataPeriod } from 'pages/Risks/types';
import {
  IRiskGroupedByEntityData,
  PerPeriodData,
} from 'services/firebase/analysis';

interface IOwnProps {
  cashflowsRisksData: IRiskGroupedByEntityData['perPeriod'];
  entityIds: string[];
  period: TRisksDataPeriod;
}

const CashflowsTable: FC<IOwnProps> = ({
  cashflowsRisksData,
  entityIds,
  period,
}) => {
  const { currency } = useUrlValues('currency');
  const { userEntity } = useStoreState(({ UserState }) => UserState);

  const columns = useMemo(
    () =>
      currency
        ? generateTableColumns({
            currencyCode: currency,
            entityIds,
            entityCurrency: userEntity?.entityCurrency,
            period,
          })
        : [],
    [currency, entityIds, period, userEntity?.entityCurrency]
  );

  return (
    <TableHorizontal<PerPeriodData>
      getTdCellTheme={getTdCellTheme}
      getThCellTheme={getThCellTheme}
      columns={columns}
      initialState={{
        hiddenColumns: [
          ...entityIds.map((id) => `outflows-${id}`),
          ...entityIds.map((id) => `inflows-${id}`),
          ...entityIds.map((id) => `hedges-${id}`),
        ],
      }}
      data={cashflowsRisksData}
    />
  );
};

export default CashflowsTable;
