import PermissionsChecker from 'components/PermissionsChecker/PermissionsChecker';
import Button from 'components/shared/Button/Button';
import StaleTooltip from 'components/shared/StaleTooltip/StaleTooltip';
import { Paragraph } from 'components/shared/Typography/Typography';
import { FC } from 'react';
import { IContact, Nullable } from 'types';

interface IOwnProps {
  recipientForEdit: Nullable<IContact | Partial<IContact>>;
  isEntityOnboarded: boolean;
  isCreatingRecipient: boolean;
  isSubmitting: boolean;
  withSaveAsDraft: boolean;
  isCreatingDraftRecipient: boolean;
  isSaveFullContactButtonDisabled: boolean;
  isSaveAsDraftButtonDisabled: boolean;
  onSaveAsDraft: () => Promise<void>;
}

const FooterContent: FC<IOwnProps> = ({
  recipientForEdit,
  isEntityOnboarded,
  isCreatingRecipient,
  isSubmitting,
  withSaveAsDraft,
  isCreatingDraftRecipient,
  isSaveFullContactButtonDisabled,
  isSaveAsDraftButtonDisabled,
  onSaveAsDraft,
}) => {
  return (
    <PermissionsChecker
      action={recipientForEdit ? 'update' : 'create'}
      resource="recipients"
    >
      <StaleTooltip
        disabled={isEntityOnboarded}
        placement="top"
        content={
          <Paragraph color="white">
            Only fully onboarded companies can create full contacts.
          </Paragraph>
        }
      >
        <Button
          isLoading={isCreatingRecipient || isSubmitting}
          disabled={isSaveFullContactButtonDisabled}
          form="add-contact-form"
        >
          Save contact
        </Button>
      </StaleTooltip>
      {withSaveAsDraft &&
        (!recipientForEdit || recipientForEdit?.status === 'draft') && (
          <Button
            ml
            variant="secondary"
            isLoading={isCreatingDraftRecipient}
            disabled={isSaveAsDraftButtonDisabled}
            onClick={onSaveAsDraft}
          >
            Save as draft
          </Button>
        )}
    </PermissionsChecker>
  );
};

export default FooterContent;
