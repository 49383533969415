import { FC, useMemo } from 'react';
import { useStoreState } from 'state';
import { isLocal, isProduction } from 'utils';
import { isIntegrationConnected } from 'utils/integrations';
import { INTEGRATIONS_DATA } from 'variables';
import IntegrationTile from '../IntegrationTile/IntegrationTile';
import { Col } from 'components';

INTEGRATIONS_DATA.accounting = INTEGRATIONS_DATA.accounting.filter(
  (integration) => !isProduction() || integration.name !== 'QuickBooks Sandbox'
);

interface OwnProps {
  variant: 'accounting' | 'business';
}

const Integrations: FC<OwnProps> = ({ variant }) => {
  const { integrationsSummary } = useStoreState((state) => state.UserState);

  const connectedIntegration = useMemo(
    () =>
      INTEGRATIONS_DATA[variant].find((integrationDoc) =>
        isIntegrationConnected({
          integrationsSummary,
          integrationDoc,
        })
      ),
    [integrationsSummary, variant]
  );

  const integrationsToRender = useMemo(
    () =>
      INTEGRATIONS_DATA[variant].filter((integration) => {
        if (!connectedIntegration) {
          return true;
        } else {
          return (
            isLocal() ||
            integration.id === 'csv' ||
            integration.name === connectedIntegration.name
          );
        }
      }),
    [connectedIntegration, variant]
  );

  return (
    <Col flex={1}>
      {integrationsToRender.map((integration) => (
        <IntegrationTile integration={integration} key={integration.name} />
      ))}
    </Col>
  );
};

export default Integrations;
