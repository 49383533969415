import { FC } from 'react';
import { Title, Paragraph, StaleBtnGroup } from 'components';
import Button from 'components/shared/Button/Button';
import { Wrapper } from './StaleCancelTransfer.styles';

interface OwnProps {
  onCancel: () => void;
  onContinue: () => void;
  onClose: () => void;
}

const StaleCancelTransfer: FC<OwnProps> = ({
  onCancel,
  onContinue,
  onClose,
}) => (
  <Wrapper>
    <div className="popup">
      <button className="close" onClick={onClose}>
        <svg width="24" height="24">
          <use xlinkHref="#cross-ico" />
        </svg>
      </button>

      <Title variant="h4">Cancel transfer?</Title>

      <Paragraph>
        Are you sure you want to close transfer process? Changes will not be
        saved.
      </Paragraph>

      <StaleBtnGroup horizontal container={false}>
        <Button variant="secondary" onClick={onContinue}>
          Continue
        </Button>
        <Button onClick={onCancel}>Yes, cancel</Button>
      </StaleBtnGroup>
    </div>
  </Wrapper>
);

export default StaleCancelTransfer;
