import { Paragraph, ParagraphWithEllipsis } from 'components';
import CellAmountWithDirection from 'components/shared/CellAmountWithDirection/CellAmountWithDirection';
import CurrencyCell from 'components/shared/CurrencyCell/CurrencyCell';
import DateCell from 'components/shared/DateCell/DateCell';
import { CellProps, Column } from 'react-table';
import { CASHFLOW_TYPE_NEW, ICashflowFromSearch } from 'services/cashflows';
import { ICustomCashflowDefinition } from 'state/user';
import ThreeDotsMenu from './components/ThreeDotsMenu/ThreeDotsMenu';
import { firstCharacterToUppercase } from 'utils';
import { CASHFLOW_TYPE_TO_TITLE } from '../../consts';

export const generateTableColumns = ({
  customCashflowDefinitions,
  updateInMemoryCashflows,
}: {
  customCashflowDefinitions?: ICustomCashflowDefinition[];
  updateInMemoryCashflows: (
    updateFunction: (cashflows: ICashflowFromSearch[]) => ICashflowFromSearch[]
  ) => void;
}): Column<ICashflowFromSearch>[] => {
  return [
    {
      Header: 'Ref',
      accessor: 'externalRefsSourceSystemRef', // Assuming this is the reference field
      // ... Cell definition or you can use default cell rendering
      Cell: ({ value }) => (
        <ParagraphWithEllipsis>{value}</ParagraphWithEllipsis>
      ),
      width: 110,
    },
    {
      Header: 'Name',
      accessor: 'counterParty', // This needs to come from additional contact data if available
      Cell: ({ value, row }) => (
        <ParagraphWithEllipsis>
          {row.original.type === 'custom'
            ? customCashflowDefinitions?.find(
                (definition) => definition.id === row.original.counterParty
              )?.name || ''
            : value}
        </ParagraphWithEllipsis>
      ),
      width: 180,
      minWidth: 150,
    },
    {
      Header: 'Date',
      accessor: 'creationDate',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 100,
      minWidth: 80,
    },
    {
      Header: 'Due date',
      accessor: 'dueDate',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 100,
      minWidth: 80,
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      Cell: ({ value }) => <CurrencyCell currencyCode={value} />,
      width: 50,
      minWidth: 50,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value, row }) => (
        <CellAmountWithDirection
          value={value}
          currencyCode={row.original.currency}
          direction={row.original.direction}
        />
      ),
      width: 160,
      minWidth: 150,
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }: CellProps<ICashflowFromSearch>) => (
        <Paragraph>
          {CASHFLOW_TYPE_TO_TITLE[value as CASHFLOW_TYPE_NEW]}
        </Paragraph>
      ),
      width: 100,
      minWidth: 80,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row, value }) => (
        <Paragraph>
          {row.original.excludeFromRisk
            ? 'Excluded'
            : firstCharacterToUppercase(value)}
        </Paragraph>
      ),
      width: 150,
      minWidth: 120,
    },
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <ThreeDotsMenu
          record={row.original}
          updateInMemoryCashflows={updateInMemoryCashflows}
        />
      ),
      width: 60,
      minWidth: 55,
    },
  ];
};
