import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { IEntity } from 'state/user';
dayjs.extend(isSameOrBefore);

export const isEntityAddAuthenticatorDeferred = (entity?: IEntity) => {
  /* Need to check because by default we have empty object as IEntity */
  if (!entity?.name) {
    return true;
  }

  if (!entity.deferAddAuthenticator) {
    return false;
  }

  return dayjs().isSameOrBefore(entity.deferAddAuthenticator);
};
