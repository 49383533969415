import styled, { css } from 'styled-components';

const StaleHexagonList = styled.ul<{ color: string }>(
  ({ color = '#1CBD85' }) => css`
    margin: 13px 0;

    li {
      padding: 0 16px 12px 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      position: relative;

      &:last-of-type {
        padding-bottom: 0;

        &:after {
          display: none;
        }
      }

      svg {
        position: absolute;
        top: 3px;
        left: 0;
        width: 13px;
        height: 13px;
        fill: ${color};
      }

      &:after {
        content: '';
        position: absolute;
        top: 6px;
        left: 6px;
        height: 100%;
        border-left: 1px dashed ${color};
      }
    }
  `
);

export default StaleHexagonList;
