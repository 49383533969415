import { FC, useState } from 'react';

import { Paragraph, StaleInfo, Row, Expansion } from '..';
import {
  StaleRecipientWrapper,
  TitleParagraph,
  ShowSvg,
  RecipientButtons,
  RecipientContent,
  RecipientHeader,
} from './StaleRecipientDropdown.styles';
import {
  RECIPIENT_TYPE,
  IRecipient,
  SWIFT_TYPE,
  TRANSFER_TYPE,
  ICurrency,
  RECIPIENT_STATUS,
  Nullable,
} from 'types';
import {
  parseIntoCurrencyString,
  isRecipientSwift,
  isRecipientDisabled,
} from 'utils';
import { isMobile } from 'react-device-detect';
import { useStoreState } from 'state';
import Button from '../Button/Button';

interface OwnProps {
  isInvoice?: boolean;
  selectedRecipient?: Nullable<IRecipient>;
  recipient: IRecipient;
  sellCurrency: ICurrency;
  transferType?: TRANSFER_TYPE;
  setRecipient?: (recipient: Nullable<IRecipient>) => void;
  onEditRecipient?: (recipient: IRecipient) => void;
  checkDisabled?: boolean;
}

const StaleRecipientDropdown: FC<OwnProps> = ({
  selectedRecipient,
  recipient,
  transferType,
  sellCurrency,
  setRecipient,
  onEditRecipient,
  checkDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { getPriorityFee } = useStoreState((state) => state.ReferenceDataState);

  const isSelected = selectedRecipient?.id === recipient?.id;
  const isSwift = isRecipientSwift(recipient);
  const isDisabled = transferType
    ? isRecipientDisabled(transferType, recipient, isSwift, !!checkDisabled)
    : false;

  const fee =
    (recipient &&
      recipient.swiftType &&
      parseIntoCurrencyString(
        getPriorityFee(
          recipient.swiftType,
          sellCurrency.code,
          recipient.accountCountry
        ) || undefined
      )) ||
    null;

  const selectRecipient = (e: any) => {
    if (
      e.target?.dataset?.id !== 'show-more' &&
      e.target?.dataset?.id !== 'edit'
    ) {
      !isDisabled && setRecipient?.(isSelected ? null : recipient);
    }
  };

  return (
    recipient && (
      <StaleRecipientWrapper
        key={recipient.id}
        onClick={selectRecipient}
        as="button"
      >
        <RecipientHeader isSelected={isSelected} isDisabled={isDisabled}>
          <TitleParagraph
            variant="bold"
            isDropdown={true}
            style={{
              marginRight: 'auto',
              marginBottom: 0,
            }}
          >
            <svg>
              <use
                xlinkHref={
                  recipient.recipientEntityType === RECIPIENT_TYPE.company
                    ? '#briefcase-ico'
                    : '#user-ico'
                }
              />
            </svg>
            <span>{recipient.recipientName}</span>
          </TitleParagraph>

          {isSwift && fee && (
            <Row style={{ margin: 0, marginRight: 8 }}>
              <svg style={{ marginRight: 8 }}>
                <use xlinkHref={`#recipient_swift_blue`} />
              </svg>

              <Paragraph variant="bold">
                {`${sellCurrency.symbol} ${fee} ${
                  recipient.swiftType === SWIFT_TYPE.ours ? '(OUR)' : '(SHA)'
                }`}
              </Paragraph>
            </Row>
          )}

          {recipient.status === RECIPIENT_STATUS.draft && (
            <StaleInfo
              mode={isMobile ? 'click' : 'hover'}
              placement="top"
              strategy="fixed"
              trigger={
                <svg>
                  <use xlinkHref="#warning-yellow-ico" />
                </svg>
              }
            >
              <Paragraph color="white">
                Recipient has missing information. Please edit the recipient and
                save so it can be used here.
              </Paragraph>
            </StaleInfo>
          )}

          <ShowSvg data-id="show-more" onClick={() => setIsOpen(!isOpen)}>
            <use xlinkHref={isOpen ? '#show-less-ico' : `#show-more-ico`} />
          </ShowSvg>
        </RecipientHeader>

        <Expansion delay={false} isOpen={isOpen}>
          <RecipientContent>
            <Row>
              <Paragraph>{`${
                isSwift ? 'Account number:' : 'IBAN:'
              }`}</Paragraph>
              <Paragraph
                variant="bold"
                as="span"
                error={!recipient.accountNumber}
              >
                {recipient.accountNumber
                  ? ` *******${recipient.accountNumber?.substr(
                      recipient.accountNumber.length - 4
                    )}`
                  : ` missing`}
              </Paragraph>
            </Row>
            <Row>
              <Paragraph>Bank name:</Paragraph>
              <Paragraph
                variant="bold"
                className="bank-name"
                error={!recipient.bankName}
              >
                {recipient.bankName ?? (
                  <>
                    <svg style={{ marginRight: 6 }}>
                      <use xlinkHref="#warning-yellow-ico" />
                    </svg>
                    Missing information
                  </>
                )}
              </Paragraph>
            </Row>
            {isSwift && (
              <Row>
                <Paragraph>SWIFT / BIC:</Paragraph>
                <Paragraph variant="bold" as="span" error={!recipient.bicSwift}>
                  {recipient.bicSwift ?? (
                    <>
                      <svg style={{ marginRight: 6 }}>
                        <use xlinkHref="#warning-yellow-ico" />
                      </svg>
                      Missing information
                    </>
                  )}
                </Paragraph>
              </Row>
            )}
          </RecipientContent>
          {!!onEditRecipient && (
            <RecipientButtons>
              <Button
                variant="link"
                onClick={() => onEditRecipient(recipient)}
                icon="edit-ico"
              >
                Edit
              </Button>
            </RecipientButtons>
          )}
        </Expansion>
      </StaleRecipientWrapper>
    )
  );
};

export default StaleRecipientDropdown;
