import {
  FC,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { ContextMenu } from 'components';
import { IInvoiceFromSearch, INVOICE_STATUSES } from 'types';
import { useStoreActions, useStoreState } from 'state';

import {
  isInvoiceDisabled,
  isInvoiceStatusInPayableState,
  isPayableInvoice,
  getInvoiceDaysLeft,
  isInvoiceFromSearchFromXero,
  isInvoiceFromSearchNotPaid,
  isInvoiceTrackable,
  isApprovalFlowAllowToPayInvoiceFromSearch,
  isInvoiceEditable,
  getManualInvoiceTypeFromInvoice,
  isInvoiceFromSearchProcessingPayment,
  isHedgeflowsInvoice,
} from 'utils/invoices';
import { useHistory } from 'react-router-dom';
import {
  getInvoiceTransferOrSimpleTransferLink,
  getInvoiceSimpleTransferLink,
  getCurrencyExchangePageLink,
  getInvoiceManualUploadLink,
  getInvoiceDetailsLink,
} from 'utils/links';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import StaleLimitedAccess from 'components/shared/StaleLimitedAccess/StaleLimitedAccess';
import { isCurrencyEnabledForBuying } from 'utils/currencies';
import useInvoicePrebook from '../../hooks/useInvoicePrebookAndTransfer';
import useInvoiceFromSearchRecord from 'hooks/useInvoiceFromSearchRecord';
import {
  useCreatePrebooksPermissionsCheck,
  useCreateRecipientsPermissionsCheck,
  useCreateTransfersPermissionsCheck,
  usePauseHideExcludeRecipientsPermissionsCheck,
  useUpdateBalancesPermissionsCheck,
  useUpdateRecipientsPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';
import { Notify, isRateContractCanBeUsedNow } from 'utils';
import PopupUnlinkContact from './components/PopupUnlinkContact/PopupUnlinkContact';
import PopupPlannedPaymentDateUpdate from './components/PopupPlannedPaymentDateUpdate/PopupPlannedPaymentDateUpdate';
import { errorHandler } from 'utils/errors';
import { hideContactBasedInvoices } from 'services/firebase';
import { removeInvoicesBasedOnSameContactId } from '../Popups/SelectExistingContact/utils';

interface OwnProps {
  paymentRunId?: string;
  record: IInvoiceFromSearch;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setInvoicesForAllocateFx: Dispatch<SetStateAction<IInvoiceFromSearch[]>>;
  setDeleteManualInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setRemoveExistingPrebookInvoice: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  setTransferIdToShowPaymentDetails: Dispatch<SetStateAction<string | null>>;
  setExistingInvoiceTracking: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
  removeInvoiceFromPaymentRun?: (paymentRunInvoiceId: string) => Promise<void>;
  isUpdatingPaymentRun?: boolean;
}

const InvoiceActionsMenu: FC<OwnProps> = ({
  paymentRunId,
  record,
  setInvoiceDecide,
  setCancelPrebookInvoice,
  setInvoicesForAllocateFx,
  setDeleteManualInvoice,
  setRemoveExistingPrebookInvoice,
  setShowAllDecideFields,
  setTransferIdToShowPaymentDetails,
  setExistingInvoiceTracking,
  updateInMemoryInvoices,
  removeInvoiceFromPaymentRun,
  isUpdatingPaymentRun,
}) => {
  const history = useHistory();
  const [
    shouldShowPopupForPlannedPaymentDateUpdate,
    setShouldShowPopupForPlannedPaymentDateUpdate,
  ] = useState(false);
  const { isSameCurrency, isCanBePaid } = useInvoiceFromSearchRecord({
    record,
  });
  const {
    deleteInvoiceTracking,
    unbindContractRateToInvoice,
  } = useStoreActions((actions) => actions.InvoicesState);
  const { rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );
  const {
    hasApprovalFlow,
    isUserApprover,
    isUserSubmitter,
    entityCurrencyCode,
  } = useStoreState(({ UserState }) => UserState);
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { balanceByCurrencyCode } = useStoreState(
    (state) => state.BalancesState
  );
  const { updateInvoicesApprovalStatus } = useInvoicesApprovalStatus();
  const {
    showLimitedAccess,
    setShowLimitedAccessFalse,
    goToInvoicePrebook,
  } = useInvoicePrebook();
  const [
    shouldShowUnlinkContactPopup,
    setShouldShowUnlinkContactPopup,
  ] = useState(false);
  const availableRateContractsByCurrencyPair =
    record.type === 'Receivable'
      ? rateContractsByCurrencyPair(record.currency, entityCurrencyCode)
      : rateContractsByCurrencyPair(entityCurrencyCode, record.currency);
  const rateContractsToUse = availableRateContractsByCurrencyPair.filter(
    (item) =>
      item.remainingBuyAmount >= (record.amountDue ?? 0) &&
      isRateContractCanBeUsedNow(item)
  );

  const [isLoadingHideInvoices, setIsLoadingHideInvoices] = useState(false);

  const hideInvoicesHandler = useCallback(
    async (invoiceId: string) => {
      try {
        setIsLoadingHideInvoices(true);
        const { data } = await hideContactBasedInvoices({
          invoiceId,
        });

        if (data.success && data.data) {
          Notify.success('Invoices hidden successfully');

          updateInMemoryInvoices(removeInvoicesBasedOnSameContactId(data.data));
        } else {
          Notify.error('Failed to hide invoices');
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoadingHideInvoices(false);
      }
    },
    [updateInMemoryInvoices]
  );
  const balanceByCurrency = balanceByCurrencyCode(record.currency);
  const canPayWithBalance =
    isCanBePaid && !isSameCurrency && !!balanceByCurrency;
  const hasUpdateBalancesPermission = useUpdateBalancesPermissionsCheck();
  const hasCreateTransfersPermission = useCreateTransfersPermissionsCheck();
  const hasCreatePrebookPermission = useCreatePrebooksPermissionsCheck();
  const hasUpdateRecipientsPermission = useUpdateRecipientsPermissionsCheck();
  const hasCreateRecipientsPermission = useCreateRecipientsPermissionsCheck();
  const hasPauseHideExcludeRecipientsPermissions = usePauseHideExcludeRecipientsPermissionsCheck();

  const dropdownItems = useMemo(() => {
    const dropdownItemsToReturn: any[] = [];

    const canUnlinkInvoiceContact =
      record.contactId && record.status === INVOICE_STATUSES.authorised;
    const isInvoiceWithContact = !!record.contactId;
    const isPayable = isPayableInvoice(record);
    const isStatusInPayableState = isInvoiceStatusInPayableState(record);
    const isNotPaid = isInvoiceFromSearchNotPaid(record);
    const isProcessingPayment = isInvoiceFromSearchProcessingPayment(record);
    const isFromXero = isInvoiceFromSearchFromXero(record);
    const daysLeft = getInvoiceDaysLeft(record);
    const isDisabled = isInvoiceDisabled(record);
    const isTrackable = isInvoiceTrackable(record);
    const isInvoiceCurrencyEnabledForBuying = isCurrencyEnabledForBuying({
      currencyCode: record.currency,
      currencies,
    });
    const isApprovalFlowAllowToPay = isApprovalFlowAllowToPayInvoiceFromSearch({
      hasApprovalFlow,
      record,
    });

    if ((!isNotPaid || isProcessingPayment) && record.transferId) {
      dropdownItemsToReturn.push({
        id: 'payment-details',
        title: 'Payment details',
        onClick: () =>
          record.transferId &&
          setTransferIdToShowPaymentDetails(record.transferId),
      });
    }

    if (isFromXero) {
      dropdownItemsToReturn.push({
        id: 'show-in-xero',
        title: 'Open in Xero',
        onClick: () => {
          if (record.externalRefsDeepLinkUrl) {
            window.open(record.externalRefsDeepLinkUrl);
          } else {
            Notify.info('Xero link is not available');
          }
        },
      });
    }

    if (!isDisabled && !paymentRunId) {
      if (isPayable) {
        if (
          !isSameCurrency &&
          isApprovalFlowAllowToPay &&
          isStatusInPayableState &&
          !record.contractId &&
          isInvoiceCurrencyEnabledForBuying
        ) {
          dropdownItemsToReturn.push({
            id: 'prebook',
            title: 'Prebook',
            onClick: () => goToInvoicePrebook(record),
          });
        }

        if (
          !isSameCurrency &&
          isStatusInPayableState &&
          isApprovalFlowAllowToPay &&
          !record.contractId &&
          !!rateContractsToUse?.length &&
          isInvoiceCurrencyEnabledForBuying
        ) {
          dropdownItemsToReturn.push({
            id: 'apply-prebooked-rate',
            title: 'Apply prebooked rate',
            onClick: () => {
              setInvoicesForAllocateFx([record]);
            },
          });
        }

        if (!!record.contractId && record.contractAssignment) {
          dropdownItemsToReturn.push({
            id: 'remove-prebook',
            title: 'Remove prebooked rate',
            onClick: () => {
              setRemoveExistingPrebookInvoice(record);
              if (record.contractId) {
                unbindContractRateToInvoice({
                  contractId: record.contractId,
                  payload: { invoiceId: record.id },
                });
              }
            },
          });
        }

        if (!!record.contractId && !record.contractAssignment) {
          dropdownItemsToReturn.push({
            id: 'cancel-prebook',
            title: 'Cancel prebooking',
            onClick: () => setCancelPrebookInvoice(record),
          });
        }

        if (
          isInvoiceWithContact &&
          isApprovalFlowAllowToPay &&
          isStatusInPayableState &&
          isInvoiceCurrencyEnabledForBuying &&
          hasCreateTransfersPermission
        ) {
          dropdownItemsToReturn.push({
            id: 'pay',
            title: `Pay ${canPayWithBalance ? entityCurrencyCode : ''}`,
            onClick: () => {
              history.push(
                getInvoiceTransferOrSimpleTransferLink(
                  record,
                  entityCurrencyCode
                )
              );
            },
          });

          if (isInvoiceWithContact && canPayWithBalance) {
            dropdownItemsToReturn.push({
              id: 'payWithBalance',
              title: `Pay in ${record.currency}`,
              onClick: () => {
                history.push(getInvoiceSimpleTransferLink(record));
              },
            });
          }
        }

        if (
          hasApprovalFlow &&
          (isUserApprover || isUserSubmitter) &&
          !!record.approvalStatus &&
          !record.contactShouldPausePayments
        ) {
          dropdownItemsToReturn.push({
            id: 'reject',
            title: 'Reject',
            onClick: () =>
              updateInvoicesApprovalStatus({
                invoiceIds: [record.id],
                approvalStatus: 'rejected',
              }),
          });
        }

        if (
          isTrackable &&
          isNotPaid &&
          !record.contractId &&
          !record.trackingId &&
          daysLeft >= 7
        ) {
          dropdownItemsToReturn.push({
            id: 'track',
            title: 'Track',
            onClick: () => {
              setInvoiceDecide(record);
              setShowAllDecideFields(true);
            },
          });
        }

        if (record.trackingId) {
          dropdownItemsToReturn.push({
            id: 'review-tracking',
            title: 'Review tracking',
            onClick: () => {
              setExistingInvoiceTracking(record);
            },
          });

          if (isNotPaid) {
            dropdownItemsToReturn.push({
              id: 'stop-tracking',
              title: 'Stop tracking',
              onClick: () => {
                if (record.trackingId) {
                  deleteInvoiceTracking({
                    trackingId: record.trackingId,
                  });
                }
              },
            });
          }
        }
      } else {
        if (isNotPaid && hasUpdateBalancesPermission && !!record.contractId) {
          dropdownItemsToReturn.push({
            id: 'collect',
            title: 'Collect',
            onClick: () => {
              const link = getCurrencyExchangePageLink({
                predefinedSellAmount: record.amountDue.toString(),
                predefinedSellCurrency: record.currency,
                predefinedRateContractId: record.contractId,
                step: '2',
                invoiceId: record.id,
              });

              history.push(link);
            },
          });
        }

        if (
          !isSameCurrency &&
          isNotPaid &&
          !record.contractId &&
          isInvoiceCurrencyEnabledForBuying &&
          hasCreatePrebookPermission
        ) {
          dropdownItemsToReturn.push({
            id: 'prebook',
            title: 'Prebook',
            onClick: () => goToInvoicePrebook(record),
          });
        }

        if (
          !isSameCurrency &&
          isNotPaid &&
          !record.contractId &&
          !!rateContractsToUse?.length &&
          isInvoiceCurrencyEnabledForBuying
        ) {
          dropdownItemsToReturn.push({
            id: 'apply-prebooked-rate',
            title: 'Apply prebooked rate',
            onClick: () => {
              setInvoicesForAllocateFx([record]);
            },
          });
        }

        if (!!record.contractId && !record.contractAssignment) {
          dropdownItemsToReturn.push({
            id: 'cancel-prebook',
            title: 'Cancel prebooking',
            onClick: () => setCancelPrebookInvoice(record),
          });
        }
      }
    }

    if (isHedgeflowsInvoice(record)) {
      dropdownItemsToReturn.push({
        id: 'delete',
        title: 'Delete',
        onClick: () => setDeleteManualInvoice(record),
      });
    }

    dropdownItemsToReturn.push({
      id: 'see-details',
      title: 'Details',
      link: getInvoiceDetailsLink({
        invoiceId: record.id,
      }),
    });

    if (isNotPaid && !isProcessingPayment) {
      dropdownItemsToReturn.push({
        id: 'plannedPaymentDate',
        title: record.plannedPaymentDate
          ? 'Edit planned payment date'
          : 'Add planned payment date',
        onClick: () => {
          setShouldShowPopupForPlannedPaymentDateUpdate(true);
        },
      });
    }

    if (isInvoiceEditable(record) && !paymentRunId) {
      dropdownItemsToReturn.push({
        id: 'edit',
        title: 'Edit',
        link: getInvoiceManualUploadLink({
          tab: 'manual',
          dataType: getManualInvoiceTypeFromInvoice(record),
          currency: record.currency,
          invoiceToEditId: record.id,
        }),
      });
    }

    if (canUnlinkInvoiceContact && !paymentRunId) {
      dropdownItemsToReturn.push({
        id: 'unlinkContact',
        title: 'Unlink contact',
        onClick: () => {
          setShouldShowUnlinkContactPopup(true);
        },
      });
    }

    if (!!paymentRunId && !!removeInvoiceFromPaymentRun) {
      dropdownItemsToReturn.push({
        id: 'removeFromPaymentRun',
        title: 'Remove from payment run',
        onClick: () => removeInvoiceFromPaymentRun(record.id),
        loading: isUpdatingPaymentRun,
      });
    }

    if (hasPauseHideExcludeRecipientsPermissions) {
      if (
        record.contactId
          ? hasUpdateRecipientsPermission
          : hasCreateRecipientsPermission
      ) {
        dropdownItemsToReturn.push({
          id: 'hide',
          title: 'Hide invoices',
          onClick: () => hideInvoicesHandler(record.id),
          disabled: isLoadingHideInvoices,
        });
      }
    }

    return dropdownItemsToReturn;
  }, [
    record,
    currencies,
    hasApprovalFlow,
    paymentRunId,
    isSameCurrency,
    rateContractsToUse?.length,
    hasCreateTransfersPermission,
    isUserApprover,
    isUserSubmitter,
    goToInvoicePrebook,
    setInvoicesForAllocateFx,
    setDeleteManualInvoice,
    setRemoveExistingPrebookInvoice,
    unbindContractRateToInvoice,
    setCancelPrebookInvoice,
    canPayWithBalance,
    entityCurrencyCode,
    history,
    updateInvoicesApprovalStatus,
    setInvoiceDecide,
    setShowAllDecideFields,
    setTransferIdToShowPaymentDetails,
    setExistingInvoiceTracking,
    deleteInvoiceTracking,
    hasUpdateBalancesPermission,
    hasCreatePrebookPermission,
    isUpdatingPaymentRun,
    removeInvoiceFromPaymentRun,
    hasUpdateRecipientsPermission,
    hasCreateRecipientsPermission,
    hasPauseHideExcludeRecipientsPermissions,
    hideInvoicesHandler,
    isLoadingHideInvoices,
  ]);

  return (
    <>
      <ContextMenu list={dropdownItems} strategy="fixed" portal />

      {showLimitedAccess && (
        <StaleLimitedAccess onClose={setShowLimitedAccessFalse} />
      )}

      {shouldShowUnlinkContactPopup && (
        <PopupUnlinkContact
          updateInMemoryInvoices={updateInMemoryInvoices}
          invoiceId={record.id}
          onClose={() => setShouldShowUnlinkContactPopup(false)}
        />
      )}

      {shouldShowPopupForPlannedPaymentDateUpdate && (
        <PopupPlannedPaymentDateUpdate
          updateInMemoryInvoices={updateInMemoryInvoices}
          invoice={record}
          onClose={() => setShouldShowPopupForPlannedPaymentDateUpdate(false)}
        />
      )}
    </>
  );
};

export default InvoiceActionsMenu;
