import { FC } from 'react';
import { Title, Col, Loader, Paragraph } from 'components';
import { useTheme } from 'styled-components';
import {
  WhiteContentContainer,
  WhiteFooterContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import Button from 'components/shared/Button/Button';
import useOpenBankingSettings from './useOpenBankingSettings';
import OpenBankingTableTile from './components/OpenBankingTableTile/OpenBankingTableTile';

const OpenBankingSettings: FC = () => {
  const theme = useTheme();

  const {
    connectedAccounts,
    notConnectedAccounts,
    isLoading,
    onDisconnectAccountIntegration,
    hasConnectedAccounts,
    hasNotConnectedAccounts,
    hasAccounts,
  } = useOpenBankingSettings();

  if (isLoading) {
    return (
      <WhiteContentContainer>
        <Loader size="large" />
      </WhiteContentContainer>
    );
  }

  if (!hasAccounts) {
    return (
      <WhiteContentContainer>
        <Paragraph>You do not have any accounts yet.</Paragraph>
      </WhiteContentContainer>
    );
  }

  return (
    <>
      <WhiteContentContainer>
        <Col>
          {hasConnectedAccounts && (
            <>
              <Title mb variant="h4">
                Connected accounts
              </Title>

              <OpenBankingTableTile
                data={connectedAccounts}
                isAccountsConnected={true}
                onDisconnectAccountIntegration={onDisconnectAccountIntegration}
              />
            </>
          )}

          {hasNotConnectedAccounts && (
            <>
              <Title mb mt mtValue={theme.spacing.xl} variant="h4">
                ERP accounts, not yet connected
              </Title>

              <OpenBankingTableTile
                data={notConnectedAccounts}
                isAccountsConnected={false}
                onDisconnectAccountIntegration={onDisconnectAccountIntegration}
              />
            </>
          )}
        </Col>
      </WhiteContentContainer>
      <WhiteFooterContainer>
        <Button disabled onClick={() => {}}>
          Save
        </Button>
      </WhiteFooterContainer>
    </>
  );
};

export default OpenBankingSettings;
