import { Col, Paragraph, Row } from 'components';
import Button from 'components/shared/Button/Button';
import dayjs from 'dayjs';
import { FC } from 'react';
import { getDashboardPageLink, getInvoicesPageLink } from 'utils/links';
import { DATE_FORMAT } from 'variables';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import { useHistory } from 'react-router';
import { IPaymentRunCurrencyTotal } from 'types/paymentRuns';
import {
  ConfirmationStepButtonsWrapper,
  ConfirmationStepCurrencyTotalsWrapper,
  ConfirmationStepFooterWrapper,
} from './ConfirmationStepFooter.styles';

interface IOwnProps {
  paymentDate: string;
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  isLoading: boolean;
  useMyBalanceHandler: () => Promise<void>;
  hidePayWithButton: boolean;
}

const ConfirmationStepFooter: FC<IOwnProps> = ({
  paymentDate,
  paymentRunTotals,
  isLoading,
  useMyBalanceHandler,
  hidePayWithButton,
}) => {
  const history = useHistory();

  return (
    <ConfirmationStepFooterWrapper>
      <Col flex={2.5}>
        <Paragraph mb>
          Your payment run has been booked. Please follow payment instructions
          provided and ensure sufficient funds are on your HedgeFlows account
          before 1pm on the Scheduled date.
        </Paragraph>
        <ConfirmationStepButtonsWrapper>
          {!hidePayWithButton && (
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              onClick={useMyBalanceHandler}
            >
              Use my balance
            </Button>
          )}
          <Button
            disabled={isLoading}
            variant={hidePayWithButton ? 'primary' : 'secondary'}
            onClick={() =>
              history.replace(
                getInvoicesPageLink({
                  currency: 'all',
                })
              )
            }
          >
            Go to invoices
          </Button>
          <Button
            disabled={isLoading}
            variant={hidePayWithButton ? 'secondary' : 'link'}
            onClick={() => history.replace(getDashboardPageLink())}
          >
            Go to dashboard
          </Button>
        </ConfirmationStepButtonsWrapper>
      </Col>

      <ConfirmationStepCurrencyTotalsWrapper>
        <Row alignItems="flex-start" alignSelf="stretch">
          <Paragraph variant="bold">Payments scheduled for:</Paragraph>
          <Paragraph variant="bold">
            {dayjs(paymentDate).format(DATE_FORMAT)}
          </Paragraph>
        </Row>
        <InvoicesCurrencyTotals
          title="Funds required:"
          currencyTotals={paymentRunTotals}
        />
      </ConfirmationStepCurrencyTotalsWrapper>
    </ConfirmationStepFooterWrapper>
  );
};

export default ConfirmationStepFooter;
