import { BULK_PAYMENT_STATUS } from './bulkPayments';
import { CONTRACT_STATUS } from './contracts';
import { TRANSFER_STATUS } from './transfers';

interface ISystemFields {
  id: string;
  _created: string;
  _createdBy: string;
  _updated?: string;
  _updatedBy?: string;
  _version: number;
  _owner: string;
}

enum CONVERSION_STATUS {
  awaitingPayment = 'awaiting_payment',
  /** Client has initiated funding, e.g. via Open Banking, but we've not received the funds */
  fundingInitiated = 'funding_initiated',
  funded = 'funded',
  processing = 'processing',
  completed = 'completed',
  cancelled = 'cancelled',
}

type FUNDING_STATUS = 'completed';

interface ITransactionInput extends Omit<ISystemFields, 'id'> {
  /** ISO date string */
  transactedOn: string;
  /** ISO date string */
  settledOn?: string;
  type: 'fxExchange' | 'transferIn' | 'transferOut' | 'fee' | 'rateContract';
  counterPartyName: string;
  amount: number;
  /** amount of an exchange when transaction has FX */
  fxAmount?: number;
  reference?: string;
  mainCurrency: string;
  /** we only have that when transaction has FX */
  fxCurrency?: string;
  direction: 'in' | 'out';
  sourceRecordId: string;
  sourceRecordType:
    | 'transfer'
    | 'conversion'
    | 'rateContract'
    | 'ledgerRecord'
    | 'funding';
  status:
    | CONVERSION_STATUS
    | TRANSFER_STATUS
    | CONTRACT_STATUS
    | BULK_PAYMENT_STATUS
    | FUNDING_STATUS;
}

export interface ITransaction extends ITransactionInput {
  id: string;
}

type TEventResultStatus = 'success' | 'error' | 'unsupported' | 'pending';
type TRootStatus =
  | TEventResultStatus
  | 'notFound'
  | 'internalError'
  | 'mixed'
  | 'permission-error';
type TWriteEventDocStatus =
  | TEventResultStatus
  | 'internalError'
  | 'mixed'
  | 'permission-error';

export type TWriteEventDetailsSubDetails = {
  id: string;
  action: string;
  status: TWriteEventDocStatus;
};

export type TWriteEventDetails = {
  /**
   * Optional as transactions do not have 1 to 1 relationship with write-events.
   *
   * For those that do, enter the write event doc id here.
   */
  writeEventDocId?: string;
  /**
   * success - completed successfully
   *
   * Unsupported is when source systems don't handle this event.
   *
   * notFound, so trigger hasn't happened yet, or it has but didn't fire a root write event message to some internal logic.
   *
   * Unattempted, this code can't possibly know that.
   *
   * Error - need to differentiate between the types of errors.
   * */
  status: TRootStatus;
  mappingType: 'direct' | 'aggregated' | 'none';
  errorMessage?: string;
  errorDetails?: string[];
  subWriteEventDocs?: TWriteEventDetailsSubDetails[];
};

export const isTransactionRetryable = (
  transaction: ITransaction | TTransactionWithWriteDetails
) =>
  isTransactionWithWriteDetails(transaction) &&
  (transaction.writeDetails?.status === 'error' ||
    transaction.writeDetails?.status === 'mixed');

export type TTransactionWithWriteDetails = ITransaction & {
  writeDetails: TWriteEventDetails;
};

export const isTransactionWithWriteDetails = (
  transaction: TTransactionWithWriteDetails | ITransaction
): transaction is TTransactionWithWriteDetails =>
  (transaction as TTransactionWithWriteDetails).writeDetails !== undefined;
