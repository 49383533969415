import { sum } from 'lodash';
import { useMemo } from 'react';
import {
  addInvoicesToPaymentRun,
  getDraftPaymentRun,
} from 'services/paymentRuns';
import { useStoreState, useStoreActions } from 'state';
import { IInvoiceFromSearch } from 'types';
import { Notify, isRateContractCanBeUsedNow } from 'utils';
import { errorHandler } from 'utils/errors';
import {
  isInvoiceFromSearchApprovableByUser,
  isInvoiceFromSearchSubmittableForReview,
  isInvoicePrebookable,
  isInvoiceStatusInPayableState,
  isPayableInvoice,
  isReceivableInvoice,
} from 'utils/invoices';

interface IOwnProps {
  selectedInvoices: IInvoiceFromSearch[];
}

const useInvoicesFooterActions = ({ selectedInvoices }: IOwnProps) => {
  const {
    hasApprovalFlow,
    entityId,
    entityCurrencyCode,
    userId,
  } = useStoreState((state) => state.UserState);
  const { rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );
  const { setState } = useStoreActions((state) => state.PaymentRunsState);

  const data = useMemo(
    () =>
      selectedInvoices.reduce<{
        invoiceCurrencies: string[];
        payableInvoices: IInvoiceFromSearch[];
        invoicesForApproval: IInvoiceFromSearch[];
        invoicesForSubmission: IInvoiceFromSearch[];
        invoicesForPayment: IInvoiceFromSearch[];
        invoicesForAllocateFx: IInvoiceFromSearch[];
        hasReceivableInvoices: boolean;
      }>(
        (acc, invoice) => {
          acc.invoiceCurrencies = Array.from(
            new Set([...acc.invoiceCurrencies, invoice.currency])
          );

          if (isReceivableInvoice(invoice)) {
            acc.hasReceivableInvoices = true;
          }

          if (
            isPayableInvoice(invoice) &&
            isInvoiceStatusInPayableState(invoice) &&
            (!hasApprovalFlow ||
              (hasApprovalFlow && invoice.approvalStatus === 'approved'))
          ) {
            acc.invoicesForPayment = [...acc.invoicesForPayment, invoice];
          }

          if (isInvoiceFromSearchApprovableByUser(invoice, userId)) {
            acc.invoicesForApproval = [...acc.invoicesForApproval, invoice];
          }

          if (isInvoiceFromSearchSubmittableForReview(invoice)) {
            acc.invoicesForSubmission = [...acc.invoicesForSubmission, invoice];
          }

          if (isInvoicePrebookable(invoice, entityCurrencyCode)) {
            acc.invoicesForAllocateFx = [...acc.invoicesForAllocateFx, invoice];
          }

          return acc;
        },
        {
          invoiceCurrencies: [],
          payableInvoices: [],
          invoicesForApproval: [],
          invoicesForSubmission: [],
          invoicesForPayment: [],
          invoicesForAllocateFx: [],
          hasReceivableInvoices: false,
        }
      ),
    [selectedInvoices, hasApprovalFlow, entityCurrencyCode, userId]
  );

  const rateContractsForCurrencyPair = rateContractsByCurrencyPair(
    entityCurrencyCode,
    data.invoiceCurrencies[0]
  );
  const rateContractsToUse = rateContractsForCurrencyPair.filter(
    isRateContractCanBeUsedNow
  );
  const shouldShowAllocatedFxButton =
    data.invoicesForAllocateFx.length > 0 &&
    data.invoiceCurrencies.length === 1 &&
    sum(data.invoicesForAllocateFx.map((invoice) => invoice.amountDue)) <=
      sum(rateContractsToUse.map((contract) => contract.remainingBuyAmount));

  const onAddToPaymentRun = async () => {
    try {
      setState(['isUpdatingPaymentRun', true]);

      if (!entityId) {
        return;
      }
      // TODO: consider removing the need for payment run id from payment run invoices update, so we can avoid this call
      const { data: response } = await getDraftPaymentRun({ entityId });

      if (!response.data) {
        return;
      }

      await addInvoicesToPaymentRun({
        paymentRunId: response.data.id,
        invoiceIds: selectedInvoices.map((invoice) => invoice.id),
      });

      Notify.success('Invoices added to payment run');
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setState(['isUpdatingPaymentRun', false]);
    }
  };

  return {
    onAddToPaymentRun,
    shouldShowAllocatedFxButton,
    ...data,
  };
};

export default useInvoicesFooterActions;
