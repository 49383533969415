import { FC, useState, useMemo, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form7';
import orderBy from 'lodash.orderby';
import { Paragraph, StaleInputSelect, Row } from 'components';
import { useStoreState, useStoreActions } from 'state';
import { ERROR_MESSAGES, BUSINESS_TYPES } from 'variables';
import InputBase from 'components/shared/InputBase/InputBase';
import Field from 'components/shared/Field/Field.styles';
import RadioButtons from 'components/shared/RadioButtons/RadioButtons';

interface IOwnProps {
  control: UseFormReturn<any>['control'];
  watch: UseFormReturn<any>['watch'];
  withNameFields?: boolean;
}

const EntitySignUpFormFields: FC<IOwnProps> = ({
  control,
  watch,
  withNameFields = true,
}) => {
  const { countries } = useStoreState((state) => state.ReferenceDataState);
  const { getCountries } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );
  const [companiesSearchValue, setCompaniesSearchValue] = useState('');
  const isCompanyWatch = watch('isCompany');
  const isCompanyAnswered = isCompanyWatch !== undefined;

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const orderedCountries = useMemo(
    () =>
      orderBy(countries, 'name', 'asc').filter((item) => item.alpha2 !== 'gb'),
    [countries]
  );

  return (
    <>
      {withNameFields && (
        <>
          <Paragraph>What is your name?</Paragraph>

          <Row mt mb alignSelf="stretch">
            <Field fluid mr flexDirection="column">
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: ERROR_MESSAGES.requiredField,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputBase
                    label="First name"
                    type="text"
                    autoFocus
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Field>

            <Field fluid flexDirection="column">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: ERROR_MESSAGES.requiredField,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputBase
                    label="Last name"
                    type="text"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Field>
          </Row>
        </>
      )}

      <Paragraph>What's the name of the business you represent?</Paragraph>
      <Field fluid mt flexDirection="column">
        <Controller
          name="companyName"
          control={control}
          rules={{
            required: ERROR_MESSAGES.requiredField,
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputBase
              label="Business name"
              type="text"
              error={error?.message}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Field>

      <Paragraph mt mb>
        Are you representing a Limited Liability company registered in UK?
      </Paragraph>
      <Field fluid mb>
        <Controller
          name="isCompany"
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <RadioButtons
              flex={1}
              data={[
                {
                  id: 'isCompany-yes',
                  value: 'true',
                  checked: value === 'true',
                  name,
                  displayName: <p>Yes</p>,
                  onChange,
                },
                {
                  id: 'isCompany-no',
                  value: 'false',
                  checked: value === 'false',
                  name,
                  displayName: <p>No</p>,
                  onChange,
                },
              ]}
            />
          )}
        />
      </Field>

      {isCompanyAnswered && isCompanyWatch === 'false' && (
        <Row alignSelf="stretch">
          {!!orderedCountries.length && (
            <Field mr fluid>
              <Controller
                control={control}
                name="country"
                rules={{ required: ERROR_MESSAGES.requiredField }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <StaleInputSelect
                      id="country"
                      label="Country of business registration"
                      view="moving"
                      strategy="fixed"
                      data={[
                        countries.find((item) => item.id === 'GB'),
                        ...orderedCountries,
                      ]
                        .filter((item) =>
                          item?.name
                            .toLowerCase()
                            .trim()
                            .includes(companiesSearchValue.toLowerCase().trim())
                        )
                        .map((item) => ({
                          name: item?.name ?? '',
                          id: item?.name,
                          icon: item?.alpha2.toLowerCase(),
                          value: { ...item, id: item?.name },
                        }))}
                      selected={value}
                      onSelect={(item) => {
                        onChange(item.value);
                      }}
                      withSearch
                      searchValue={companiesSearchValue}
                      onSearch={(e) =>
                        setCompaniesSearchValue(e.currentTarget.value)
                      }
                      onClose={() => setCompaniesSearchValue('')}
                      error={error?.message}
                      style={{
                        flex: 1,
                      }}
                    />
                  );
                }}
              />
            </Field>
          )}

          <Field fluid>
            <Controller
              control={control}
              name="businessType"
              rules={{ required: ERROR_MESSAGES.requiredField }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <StaleInputSelect
                    id="businessType"
                    label="Type of business"
                    view="moving"
                    strategy="fixed"
                    data={BUSINESS_TYPES}
                    selected={value}
                    onSelect={(item) => onChange(item.value)}
                    error={error?.message}
                    style={{
                      flex: 1,
                    }}
                  />
                );
              }}
            />
          </Field>
        </Row>
      )}
    </>
  );
};

export default EntitySignUpFormFields;
