import { Paragraph, Row, StaleInputSelect, Col, Loader } from 'components';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  CurrencyInfoTableWrapper,
  CurrencyInfoWrapper,
} from './CurrencyInfo.styles';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { AccountsRow } from 'components/shared/AccountsRow/AccountsRow.styles';
import Field from 'components/shared/Field/Field.styles';
import InfoValue from '../InfoValue/InfoValue';
import InfoTitle from './components/InfoTitle/InfoTitle';
import { TNumberOFMonthsType } from './types';
import { PERIOD_VALUES } from './consts';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import { getCurrencyDropdownValues } from './utils';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import ChartReports from './components/ChartReports/ChartReports';
import { getMonthlyReports } from 'services/firebase/analysis';
import dayjs from 'dayjs';
import { DB_DATE_FORMAT } from 'variables';
import { generateSearchQuery } from 'utils';
import { IReportsMonthlyData } from 'types';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(utcPlugin);

interface OwnProps {
  currencyCodes: string[];
  setActiveCurrencyCode: (code: string) => void;
  activeCurrencyCode: string;
  setActiveMonth: Dispatch<SetStateAction<string>>;
}

const CurrencyInfo: FC<OwnProps> = ({
  currencyCodes,
  setActiveCurrencyCode,
  activeCurrencyCode,
  setActiveMonth,
}) => {
  const theme = useTheme();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { isIntegratedWithXero } = useIntegrationEngine();
  const [months, setMonths] = useState<TNumberOFMonthsType>(6);
  const [monthlyReports, setMonthlyReports] = useState<IReportsMonthlyData>();
  const entityCurrency = currencyByCode(entityCurrencyCode);

  const dropdownValues = getCurrencyDropdownValues({
    currencyCodes,
    currencyByCode,
    isIntegratedWithXero: !!isIntegratedWithXero,
  });

  const onSelectCurrency = (item: ISelectItem) => {
    setActiveCurrencyCode(item.value);
  };

  useEffect(() => {
    const dateFrom = dayjs()
      .utc(true)
      .subtract(months - 1, 'month')
      .startOf('month')
      .format(DB_DATE_FORMAT);
    const dateTo = dayjs().utc(true).endOf('month').format(DB_DATE_FORMAT);
    const searchQuery = generateSearchQuery({
      dateFrom,
      dateTo,
      currency: activeCurrencyCode,
    });

    getMonthlyReports(searchQuery).then((response) => {
      if (!response.data.data) {
        return;
      }

      setMonthlyReports(response.data.data);
    });
  }, [activeCurrencyCode, months]);

  return (
    <Row
      flex={1}
      columnGap={theme.spacing.s}
      mbValue={theme.spacing.xxl}
      mb
      style={{
        maxHeight: 500,
      }}
    >
      <CurrencyInfoWrapper>
        {!monthlyReports && <Loader size="large" />}
        {!!monthlyReports && (
          <>
            <AccountsRow columnGap={theme.spacing.xxl}>
              <Field fluid flex={1}>
                <StaleInputSelect
                  selected={activeCurrencyCode}
                  data={dropdownValues}
                  onSelect={onSelectCurrency}
                  id="backtester-currency"
                  style={{ width: '100%' }}
                />
              </Field>
              <Field fluid flex={1}>
                <StaleInputSelect
                  selected={months}
                  data={PERIOD_VALUES}
                  onSelect={(item) => setMonths(item.value)}
                  id="backtester-data-duration"
                  style={{ width: '100%' }}
                />
              </Field>
            </AccountsRow>

            <Col>
              <AccountsRow flex={1}>
                <Row flex={1.2} justifyContent="flex-start">
                  <Paragraph variant="thin">Metrics</Paragraph>
                </Row>
                <Row flex={1} justifyContent="flex-end">
                  <Paragraph variant="thin">Total</Paragraph>
                </Row>
                <Row flex={1} justifyContent="flex-end">
                  <Paragraph variant="thin">Monthly</Paragraph>
                </Row>
              </AccountsRow>
              {activeCurrencyCode !== 'all' && (
                <AccountsRow flex={1}>
                  <InfoTitle
                    title="FX Turnover"
                    info="Total turnover (in your main currency) of foreign currency
                  cashflows paid or received over the period."
                  />
                  <InfoValue
                    value={monthlyReports.totals.totalAmountWithDirection}
                    currencySymbol={entityCurrency?.symbol}
                  />
                  <InfoValue
                    value={monthlyReports.totals.avgTotalAmountWithDirection}
                    currencySymbol={entityCurrency?.symbol}
                  />
                </AccountsRow>
              )}

              {activeCurrencyCode === 'all' && (
                <AccountsRow flex={1}>
                  <InfoTitle
                    title="FX Gains & Losses (Xero)"
                    info="FX Gains & Losses (in your main currency) from Xero."
                  />
                  <InfoValue
                    value={
                      monthlyReports.totals.totalPnlFromExternalSystem ?? 0
                    }
                    currencySymbol={entityCurrency?.symbol}
                  />
                  <InfoValue
                    value={monthlyReports.totals.avgPnlFromExternalSystem ?? 0}
                    currencySymbol={entityCurrency?.symbol}
                  />
                </AccountsRow>
              )}

              <AccountsRow flex={1}>
                <InfoTitle
                  title="Realised impact from invoices"
                  info="Changes in the value of foreign currency cashflows due to
                  exchange rate fluctuations between cashflow date and payment
                  date for each cashflow."
                />
                <InfoValue
                  value={monthlyReports.totals.realisedPnl}
                  currencySymbol={entityCurrency?.symbol}
                />
                <InfoValue
                  value={monthlyReports.totals.avgRealisedPnl}
                  currencySymbol={entityCurrency?.symbol}
                />
              </AccountsRow>
              <AccountsRow flex={1}>
                <InfoTitle
                  title="Transaction cost"
                  info="Estimated costs of exchanging currencies based on the
                  difference between the official FX rates (Bank of England
                  data) on each payment date and actual rates used for each
                  cashflow payment."
                />
                <InfoValue
                  value={monthlyReports.totals.fxCost}
                  currencySymbol={entityCurrency?.symbol}
                />
                <InfoValue
                  value={monthlyReports.totals.avgFxCost}
                  currencySymbol={entityCurrency?.symbol}
                />
              </AccountsRow>
              <AccountsRow flex={1}>
                <InfoTitle
                  title="Unrealised Impact"
                  info="Changes in the value of foreign currency cashflows due to
                  exchange rate fluctuations between cashflow date and last
                  available rate."
                />
                <InfoValue
                  value={monthlyReports.totals.unrealisedPnl}
                  currencySymbol={entityCurrency?.symbol}
                />
                <InfoValue
                  value={monthlyReports.totals.avgUnrealisedPnl}
                  currencySymbol={entityCurrency?.symbol}
                />
              </AccountsRow>
              <AccountsRow flex={1}>
                <InfoTitle
                  title="Explained gains & losses"
                  info="Sum of market impact and transaction costs for a given period."
                />
                <InfoValue
                  value={monthlyReports.totals.inceptionPnlFxImpactFxCost}
                  currencySymbol={entityCurrency?.symbol}
                />
                <InfoValue
                  value={monthlyReports.totals.avgInceptionPnlFxImpactFxCost}
                  currencySymbol={entityCurrency?.symbol}
                />
              </AccountsRow>
            </Col>
          </>
        )}
      </CurrencyInfoWrapper>

      <CurrencyInfoTableWrapper>
        <Row alignItems="stretch" flex={1}>
          {!monthlyReports?.monthlyBreakdown && <Loader size="large" />}
          {!!monthlyReports?.monthlyBreakdown && (
            <ChartReports
              isAllCurrencies={activeCurrencyCode === 'all'}
              data={monthlyReports.monthlyBreakdown}
              currencyCode={entityCurrencyCode}
              onChartClick={(period) => {
                setActiveMonth((prevState) => {
                  if (prevState === period) {
                    return '';
                  }

                  return period;
                });
              }}
            />
          )}
        </Row>
      </CurrencyInfoTableWrapper>
    </Row>
  );
};

export default CurrencyInfo;
