import { FC } from 'react';
import { AwaitingRegistrationDashboardSectionWrapper } from '../../AwaitingRegistrationDashboard.styles';
import { Col, Title } from 'components';
import { useTheme } from 'styled-components';
import FaqBlock from 'components/shared/FaqBlock/FaqBlock';

const AwaitingRegistrationFaqBlock: FC = () => {
  const theme = useTheme();
  return (
    <Col gap={theme.spacing.m}>
      <Title variant="h4">Frequently Asked Questions</Title>

      <AwaitingRegistrationDashboardSectionWrapper>
        <FaqBlock hideTitle />
      </AwaitingRegistrationDashboardSectionWrapper>
    </Col>
  );
};

export default AwaitingRegistrationFaqBlock;
