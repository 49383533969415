import Button from '../Button/Button';

interface Props {
  onSignInWithGoogle: () => void;
}
const StaleGoogleSignInButton: React.FC<Props> = ({ onSignInWithGoogle }) => (
  <Button
    mb
    variant="secondary"
    onClick={(event) => {
      event.preventDefault();
      onSignInWithGoogle();
    }}
    type="button"
    icon="google-ico"
  >
    Log in with Google
  </Button>
);

export default StaleGoogleSignInButton;
