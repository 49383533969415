import { useCallback, useMemo, useState } from 'react';
import { TEntityPackageKeys } from 'types';
import {
  getSubscriptionSessionUrl,
  getCustomerPortalUrl,
} from 'services/billing';
import { errorHandler } from 'utils/errors';

interface IUseSubscriptionActionProps {
  key: TEntityPackageKeys;
  packageName?: string;
  isAutomationPackageEnabled: boolean;
  isFxManagementPackageEnabled: boolean;
  isAutomationPackageFreeTrialEnabled: boolean;
  isFxManagementPackageFreeTrialEnabled: boolean;
}

interface ISubscriptionAction {
  actionText: string;
  isActionDisabled: boolean;
  onActionClickHandler?: (entityId: string) => void;
}

const useSubscriptionAction = ({
  key,
  packageName,
  isAutomationPackageEnabled,
  isFxManagementPackageEnabled,
  isAutomationPackageFreeTrialEnabled,
  isFxManagementPackageFreeTrialEnabled,
}: IUseSubscriptionActionProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const openStripePackageSubscription = useCallback(
    async (entityId: string, packageValue: string) => {
      try {
        setIsLoading(true);
        const subscriptionSessionResponse = await getSubscriptionSessionUrl({
          entityId,
          packageName: packageValue,
          redirectUrl: window.location.href,
        });
        const stripeRedirectUrl =
          subscriptionSessionResponse.data.data?.redirectUrl;
        if (!stripeRedirectUrl) {
          throw new Error('Unable to establish a connection with Stripe');
        }
        setIsLoading(false);
        window.location.href = stripeRedirectUrl;
      } catch (error: any) {
        setIsLoading(false);
        errorHandler(error);
      }
    },
    []
  );

  const openStripeCustomerPortal = useCallback(async (entityId: string) => {
    try {
      setIsLoading(true);
      const customerPortalResponse = await getCustomerPortalUrl({
        entityId,
        redirectUrl: window.location.href,
      });
      const stripeRedirectUrl = customerPortalResponse.data.data?.redirectUrl;
      if (!stripeRedirectUrl) {
        throw new Error('Unable to establish a connection with Stripe');
      }
      setIsLoading(false);
      window.location.href = stripeRedirectUrl;
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error);
    }
  }, []);
  const subscriptionAction = useMemo<ISubscriptionAction>(() => {
    if (key === 'automation') {
      if (isFxManagementPackageEnabled) {
        return {
          actionText: 'Included',
          isActionDisabled: true,
        };
      }

      if (isAutomationPackageEnabled) {
        return {
          actionText: 'Active - Manage',
          isActionDisabled: false,
          onActionClickHandler: openStripeCustomerPortal,
        };
      }

      if (isAutomationPackageFreeTrialEnabled) {
        return {
          actionText: 'Subscribe',
          isActionDisabled: false,
          onActionClickHandler: (entityId: string) =>
            packageName && openStripePackageSubscription(entityId, packageName),
        };
      }
    }

    if (key === 'fxManagement') {
      if (isFxManagementPackageEnabled) {
        return {
          actionText: 'Active - Manage',
          isActionDisabled: false,
          onActionClickHandler: openStripeCustomerPortal,
        };
      }

      if (isAutomationPackageEnabled) {
        return {
          actionText: 'Upgrade',
          isActionDisabled: false,
          onActionClickHandler: openStripeCustomerPortal,
        };
      }

      if (isFxManagementPackageFreeTrialEnabled) {
        return {
          actionText: 'Subscribe',
          isActionDisabled: false,
          onActionClickHandler: (entityId: string) =>
            packageName && openStripePackageSubscription(entityId, packageName),
        };
      }
    }

    return {
      actionText: 'Free trial',
      isActionDisabled: false,
      onActionClickHandler: (entityId: string) =>
        packageName && openStripePackageSubscription(entityId, packageName),
    };
  }, [
    isAutomationPackageEnabled,
    isAutomationPackageFreeTrialEnabled,
    isFxManagementPackageEnabled,
    isFxManagementPackageFreeTrialEnabled,
    openStripeCustomerPortal,
    openStripePackageSubscription,
    packageName,
    key,
  ]);

  return {
    subscriptionAction,
    isLoading,
  };
};

export default useSubscriptionAction;
