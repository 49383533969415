import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { FAQ_DATA } from './variables';
import {
  Col,
  Expansion,
  Icon,
  Paragraph,
  Row,
  Title,
  Subtitle,
} from 'components';
import Card from 'components/shared/Card/Card.styles';

interface IOwnProps {
  hideTitle?: boolean;
}
const FaqBlock: FC<IOwnProps> = ({ hideTitle }) => {
  const theme = useTheme();
  const [openedFaqs, setOpenedFaqs] = useState<string[]>([]);

  return (
    <Card
      padding={`${theme.spacing.xl} ${theme.spacing.xxl}`}
      gap={theme.spacing.xl}
    >
      {!hideTitle && <Title variant="h4">Frequently Asked Questions</Title>}

      {FAQ_DATA.map(({ id, title, text }) => {
        const isActive = openedFaqs.includes(id);
        return (
          <Col key={id}>
            <Row
              as="button"
              onClick={(e: any) => {
                e.preventDefault();
                setOpenedFaqs((prev) =>
                  openedFaqs.includes(id)
                    ? prev.filter((el) => el !== id)
                    : [...prev, id]
                );
              }}
            >
              <Subtitle variant="bold">{title}</Subtitle>
              <Icon
                ml
                icon="arrow-down"
                style={{
                  transition: theme.transition,
                  transform: isActive ? 'rotate(180deg)' : '',
                }}
              />
            </Row>
            <Expansion isOpen={isActive}>
              <Row>
                <Paragraph mt mtValue={theme.spacing.xs}>
                  {text}
                </Paragraph>
              </Row>
            </Expansion>
          </Col>
        );
      })}
    </Card>
  );
};

export default FaqBlock;
