import styled, { DefaultThemeColorKey } from 'styled-components';
import { Flex } from '../Flex/Flex.styles';

export const NumberCircleWrapper = styled(Flex)<{
  background?: DefaultThemeColorKey;
}>`
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  background: ${({ theme, background }) =>
    background ? theme.color[background] : theme.color.greyLight_2};
  width: fit-content;
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
  height: 24px;
`;
