import {
  TRANSFER_TYPE,
  RECIPIENT_STATUS,
  PAYMENT_TYPE,
  IRecipient,
  IContact,
} from 'types';

export const isRecipientSwift = (recipient: IRecipient) =>
  recipient?.paymentType === PAYMENT_TYPE.swift;

export const isRecipientDisabled = (
  transferType: TRANSFER_TYPE,
  recipient: IRecipient,
  isSwift: boolean,
  checkDisabled: boolean
) =>
  (checkDisabled &&
    (transferType === TRANSFER_TYPE.regular ? isSwift : !isSwift)) ||
  recipient.status === RECIPIENT_STATUS.draft;

export const isContactValid = (contact?: IContact) =>
  !!contact && !!contact.enabled && !contact.shouldPausePayments;

export const getRecipientsLinkableToInvoice = (
  recipients: IRecipient[],
  matchingSourceSystemContactId?: string,
  invoiceCurrencyCode?: string
) =>
  recipients.filter(
    (recipient) =>
      (!recipient.externalRefs?.sourceSystemId ||
        recipient.externalRefs.sourceSystemId ===
          matchingSourceSystemContactId) &&
      recipient.currency === invoiceCurrencyCode
  );

export const getInitialRecipientTransferType = (
  recipient?: Partial<IRecipient>
) => {
  if (
    recipient?.paymentType === PAYMENT_TYPE.local ||
    recipient?.paymentType === PAYMENT_TYPE.iban
  ) {
    return TRANSFER_TYPE.regular;
  }
  return TRANSFER_TYPE.priority;
};
