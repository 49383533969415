import { FC } from 'react';
import { Popup, Title, Paragraph } from 'components';
import { useHistory } from 'react-router';
import { useStoreActions } from 'state';
import Button from '../Button/Button';

interface IProps {
  onClose?: () => void;
}

const TooManyTimePasswordAttemptsPopup: FC<IProps> = ({ onClose }) => {
  const history = useHistory();
  const { signOut } = useStoreActions((actions) => actions.UserState);
  const onResetPasswordClick = () => {
    signOut();
    history.push('/reset-password');
  };
  return (
    <Popup
      onClose={onClose}
      width="500px"
      HeaderContent={<Title variant="h3">Too many password attempts</Title>}
      FooterContent={
        <Button onClick={onResetPasswordClick}>Reset Password</Button>
      }
    >
      <Paragraph>
        You've made too many wrong password attempts so we are putting a
        temporary halt on any more attempts.
      </Paragraph>
      <Paragraph>
        We would advise you to reset your password and try again.
      </Paragraph>
    </Popup>
  );
};

export default TooManyTimePasswordAttemptsPopup;
