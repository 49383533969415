import {
  IResponse,
  ICountry,
  IUser,
  BUSINESS_TYPE,
  IUserSettings,
} from 'types';
import { openDoc, openQuery } from 'utils';
import { AxiosPrivateFirebaseInstance, AxiosWest3Instance } from 'settings';
import db from 'services/firestore';
import database from 'services/database';
import { IClient, IEntity, IEntitySettings } from 'state/user';
import { IIntegrationStatusSummary } from 'types/integrations';
import { errorHandler } from 'utils/errors';

export interface CreateUserPayload {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  companyCountry: ICountry['alpha2'];
  companyType: BUSINESS_TYPE;
  companyName: string;
  isAccountant: boolean;
}

export const createUser = async (payload: CreateUserPayload) => {
  try {
    const { data } = await AxiosWest3Instance.post<IResponse>(
      'registration/user',
      payload
    );

    return data;
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface GetUserParams {
  id: string;
}

export const getUser = async ({
  id,
}: GetUserParams): Promise<IUser | undefined> => {
  try {
    const user = await db
      .collection('users')
      .doc(id)
      .get()
      // TODO: type openDoc properly so it returns correct type
      .then((doc) => openDoc(doc) as IUser);

    if (user) {
      return user;
    } else {
      console.warn('User not found.');
    }
  } catch (error: any) {
    errorHandler(error);
  }

  return undefined;
};

export interface GetUserEntityParams {
  entityId: string;
}

export const getUserEntity = async ({ entityId }: GetUserEntityParams) => {
  try {
    const entity = await db
      .collection('entities')
      .doc(entityId)
      .get()
      .then((doc) => openDoc(doc));

    if (entity) {
      return entity;
    } else {
      console.warn('User not found.');
    }
  } catch (error: any) {
    errorHandler(error);
  }

  return;
};

export interface ISubscribeToUserEntityIntegrationsSummaryParams
  extends GetUserEntityParams {
  callback: (integrationsSummary: IIntegrationStatusSummary | null) => void;
}

export const subscribeToUserEntityIntegrationsSummary = ({
  entityId,
  callback,
}: ISubscribeToUserEntityIntegrationsSummaryParams) =>
  db
    .collection('entities')
    .doc(entityId)
    .collection('integrations')
    .doc('summary')
    .onSnapshot(
      (doc) => {
        callback(openDoc(doc));
      },
      (error) =>
        console.log(
          'Failed to subscribe to user entity integrations summary. Error: ',
          error
        )
    );

export interface ISubscribeToUserEntityParams extends GetUserEntityParams {
  callback: (entity: IEntity | null) => void;
}

export const subscribeToEntity = ({
  entityId,
  callback,
}: ISubscribeToUserEntityParams) => {
  return db
    .collection('entities')
    .doc(entityId)
    .onSnapshot((query) =>
      callback(openDoc<Omit<IEntity, 'integrations'>>(query))
    );
};

export interface RegisterUserConnectionParams {
  userId: string;
}

export const registerUserConnection = async ({
  userId,
}: RegisterUserConnectionParams) => {
  // Since I can connect from multiple devices or browser tabs, we store each connection instance separately
  // any time that connectionsRef's value is null (i.e. has no children) I am offline
  const myConnectionsRef = database.ref(`users/${userId}/connections`);
  const connectedRef = database.ref('.info/connected');

  connectedRef.on('value', (snap) => {
    if (snap.val() === true) {
      // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
      const con = myConnectionsRef.push(true);

      // When I disconnect, remove this device
      con.onDisconnect().remove();
    }
  });
};

export interface RemoveUserConnectionsParams {
  userId: string;
}

export const removeUserConnections = async ({
  userId,
}: RemoveUserConnectionsParams) => {
  const snapshot = await database
    .ref(`users/${userId}/connections`)
    .once('value');

  if (snapshot.exists()) {
    await database.ref(`users/${userId}/connections`).remove();
  }
};

export const closeSuperAdminConnection = async () => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    '/admin_actions/removeactonbehalfof'
  );
};

export interface GetEntityAccountDetailsParams {
  entityId: string;
}

export const getEntityAccountDetails = async ({
  entityId,
}: GetEntityAccountDetailsParams) => {
  return db
    .collection('entities')
    .doc(entityId)
    .collection('entityAccountDetails')
    .get()
    .then((query) => openQuery(query));
};

export const updateEntitySettings = async (payload: IEntitySettings) => {
  return AxiosPrivateFirebaseInstance.put<IResponse>(
    '/entities/settings',
    payload
  );
};

export const updateUser = async (payload: { user: Partial<IUser> }) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>('/users', payload);
};

export const getUserEntitySummary = async ({
  entityId,
}: {
  entityId: string;
}) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IClient>>(
    `entities/${entityId}/summary`
  );

export const getChatToken = async () => {
  const { data } = await AxiosPrivateFirebaseInstance.get<
    IResponse<{ token?: string }>
  >(`users/chat`);

  if (data?.success) {
    return data.data?.token;
  }

  throw new Error('Unable to retrieve chat token');
};

export const getUserSettings = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IUserSettings>>('/users/settings');

export const updateUserSettings = async (payload: Partial<IUserSettings>) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IUserSettings>>(
    '/users/settings',
    payload
  );
