import { Col, Paragraph, Loader, Table, Popup, Title } from 'components';
import Button from 'components/shared/Button/Button';
import StaleReAuthenticate from 'components/shared/StaleReAuthenticate/StaleReAuthenticate';
import TooManyTimePasswordAttemptsPopup from 'components/shared/TooManyTimePasswordAttemptsPopup/TooManyTimePasswordAttemptsPopup';
import dayjs from 'dayjs';
import { useReAuthenticate } from 'hooks';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Firebase } from 'services';
import {
  confirmPaymentRun,
  getChallengeForPaymentRun,
  updatePaymentRun,
  updatePaymentRunItemSummary,
} from 'services/paymentRuns';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IPaymentRun, IPaymentRunItemSummary } from 'types/paymentRuns';
import { getFirstValidDay } from 'utils/dates';
import { errorHandler } from 'utils/errors';
import { DATE_FORMAT, DB_DATE_FORMAT, ERROR_MESSAGES } from 'variables';
import { generateAuthoriseTableColumns } from '../../tableColumnsGenerator';
import useDeviceWidth from 'hooks/useDeviceWidth';
import AuthoriseStepFooter from './components/AuthoriseStepFooter/AuthoriseStepFooter';
import MobilePaymentRunItemSummaryList from './components/MobilePaymentRunItemSummaryList/MobilePaymentRunItemSummaryList';
import { AuthoriseStepConfirmPopupWrapper } from './AuthoriseStep.styles';

interface IOwnProps {
  paymentRun: IPaymentRun;
  setPaymentRun: Dispatch<SetStateAction<IPaymentRun | undefined>>;
  onContinue: () => void;
}

const AuthoriseStep: FC<IOwnProps> = ({
  paymentRun,
  setPaymentRun,
  onContinue,
}) => {
  const theme = useTheme();
  const { isMobile } = useDeviceWidth();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBuyFxAll, setIsLoadingBuyFxAll] = useState(false);
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [nonTradingDays, setNonTradingDays] = useState<string[]>([]);

  const {
    onReAuthenticateClick,
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  } = useReAuthenticate();

  const paymentRunId = paymentRun.id;
  const initialDate = paymentRun.instructions.paymentDate ?? '';
  const paymentRunError = paymentRun.error;
  const data = useMemo(() => paymentRun.paymentRunItemSummary || [], [
    paymentRun,
  ]);
  const nonTradingDaysToUse = new Set(nonTradingDays);
  const canSelectBuyFx = data.some(({ canChooseBuyFx }) => canChooseBuyFx);

  const { control, errors, handleSubmit, watch } = useForm<{
    password: string;
    date: string;
  }>({
    defaultValues: {
      date: initialDate ? dayjs(initialDate).format(DATE_FORMAT) : '',
    },
  });
  const date = watch('date');

  useEffect(() => {
    const getNonTradingDays = async () => {
      const detectedCurrencies = new Set<string>();

      data.forEach(({ invoicesCurrency }) =>
        detectedCurrencies.add(invoicesCurrency)
      );

      const bulkNonTradingDaysResponse = await Promise.all(
        Array.from(detectedCurrencies).map((currencyCode) =>
          Firebase.getNonTradingDays({
            ccyPair: `${currencyCode}${entityCurrencyCode}`,
          })
        )
      );

      bulkNonTradingDaysResponse.forEach((response) => {
        if (response?.success && response?.data) {
          setNonTradingDays((prevState) => [...prevState, ...response.data]);
        }
      });
    };

    getNonTradingDays();
  }, [data, entityCurrencyCode]);

  // calender props
  const minDate = getFirstValidDay(
    new Date(),
    /** after noon do not allow select today's date */
    dayjs().hour() > 12 ? 1 : 0,
    Array.from(nonTradingDaysToUse)
  );
  const defaultActiveStartDate = initialDate
    ? dayjs(initialDate).toDate()
    : dayjs().add(7, 'days').toDate();

  useEffect(() => {
    if (
      dayjs(date, DATE_FORMAT).format(DB_DATE_FORMAT) !== initialDate &&
      dayjs(date, DATE_FORMAT).isValid()
    ) {
      const updatePaymentRunPaymentDate = async () => {
        try {
          setIsLoading(true);
          const { data: response } = await updatePaymentRun({
            paymentRunId,
            paymentDate: dayjs(date, DATE_FORMAT).format(DB_DATE_FORMAT),
          });

          if (response.data) {
            setPaymentRun(response.data);
          }
        } catch (error: any) {
          errorHandler(error);
        } finally {
          setIsLoading(false);
        }
      };

      updatePaymentRunPaymentDate();
    }
  }, [date, initialDate, paymentRunId, setPaymentRun]);

  const onEdit = useCallback(
    async (recordId: string, updatedData: IPaymentRunItemSummary) => {
      try {
        setIsLoading(true);
        const { data: response } = await updatePaymentRunItemSummary({
          paymentRunId,
          summaryItemId: recordId,
          buyFx: updatedData.buyFx,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const onBuyFxAll = async () => {
    try {
      const currentValue = !data.some(
        (item) => item.canChooseBuyFx && !item.buyFx
      );

      setIsLoadingBuyFxAll(true);
      const { data: response } = await updatePaymentRunItemSummary({
        paymentRunId,
        summaryItemId: 'all',
        buyFx: !currentValue,
      });

      if (response.data) {
        setPaymentRun(response.data);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingBuyFxAll(false);
    }
  };

  const isBuyFxOn = !data.some((item) => item.canChooseBuyFx && !item.buyFx);

  const tableColumns = useMemo(
    () =>
      generateAuthoriseTableColumns({
        // @ts-expect-error TS(2322) FIXME: Type '(recordId: string, updatedData: IPaymentRunI... Remove this comment to see the full error message
        onEdit,
        currencyByCode,
        entityDefaultCurrencyCode: entityCurrencyCode,
        isLoadingBuyFxAll,
        paymentDate: date,
      }),
    [currencyByCode, date, entityCurrencyCode, isLoadingBuyFxAll, onEdit]
  );

  const onSubmit = async (values: any) => {
    try {
      setIsLoadingSubmission(true);

      await onReAuthenticateClick(values);

      const challenge = await getChallengeForPaymentRun(paymentRunId);

      if (!challenge) {
        throw new Error(ERROR_MESSAGES.noChallengeReturned);
      }

      await confirmPaymentRun({
        paymentRunId,
        paymentRunTotals: paymentRun.paymentRunTotals,
        challenge,
      });

      onContinue();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingSubmission(false);
    }
  };

  const footerProps = {
    onSubmit: handleSubmit(onSubmit),
    control,
    errors,
    paymentRunError,
    paymentRun,
    setShowConfirmPopup,
    defaultActiveStartDate,
    minDate,
    nonTradingDaysToUse,
    isBuyFxOn,
    onBuyFxAll,
    isLoading,
    isLoadingBuyFxAll,
    canSelectBuyFx,
  };

  return (
    <>
      <Col style={{ position: 'relative' }}>
        {(isLoading || isLoadingBuyFxAll || isLoadingSubmission) && (
          <Loader
            withBackdrop
            size="large"
            style={{ position: 'absolute', inset: 0, zIndex: 3 }}
          />
        )}

        {!isMobile && (
          <Table<IPaymentRunItemSummary>
            data={data}
            columns={tableColumns}
            isRowDisabled={(record) => !record.valid}
            defaultRowHeight={56}
            renderFooterContent={<AuthoriseStepFooter {...footerProps} />}
            sortable
          />
        )}

        {isMobile && (
          <MobilePaymentRunItemSummaryList
            isLoadingBuyFxAll={isLoadingBuyFxAll}
            paymentDate={date}
            onEdit={onEdit}
            data={data}
            renderFooterContent={<AuthoriseStepFooter {...footerProps} />}
          />
        )}
      </Col>

      {isTooManyPasswordAttemptsError && (
        <TooManyTimePasswordAttemptsPopup
          onClose={() => setIsTooManyPasswordAttemptsError(false)}
        />
      )}

      {showConfirmPopup && (
        <Popup
          height={theme.popupHeights.xs}
          onClose={() => setShowConfirmPopup(false)}
          HeaderContent={<Title variant="h5">Confirm payment run</Title>}
          FooterContent={
            <AuthoriseStepConfirmPopupWrapper>
              <StaleReAuthenticate
                control={control}
                errors={errors}
                withLabel={false}
              >
                <Button
                  type="submit"
                  form="payment-run-authorise-form"
                  isLoading={isLoadingSubmission}
                  disabled={isLoadingSubmission}
                >
                  Authorize payments
                </Button>
              </StaleReAuthenticate>

              <Button
                variant="secondary"
                onClick={() => setShowConfirmPopup(false)}
              >
                Cancel
              </Button>
            </AuthoriseStepConfirmPopupWrapper>
          }
        >
          <Paragraph>
            Payment run scheduled payment date is <b>{date}</b>. Do you want to
            proceed?
          </Paragraph>
        </Popup>
      )}
    </>
  );
};

export default AuthoriseStep;
