// TODO: Kind of a duplicate of StaleStepsHorizontal, investigate and remove one of those
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';
import { StepNumber, StepperItem } from './HorizontalStepper.styles';

interface HorizontalStepperProps {
  activeStep: number;
  data:
    | {
        title: string;
        disabled?: boolean;
        onClick?: () => void;
      }[]
    | null;
}

const HorizontalStepper: FC<HorizontalStepperProps> = ({
  activeStep,
  data,
  children,
}) => {
  const theme = useTheme();

  return (
    <Col>
      <Row justifyContent="flex-start" mb mbValue={theme.spacing.xxl}>
        {data?.map(({ title, disabled, onClick }, i) => (
          <StepperItem
            onClick={activeStep > i ? onClick : undefined}
            key={title}
            current={activeStep === i + 1}
            passed={activeStep > i + 1}
            disabled={disabled}
          >
            <StepNumber>0{i + 1}</StepNumber>
            {title}
          </StepperItem>
        ))}
      </Row>
      <Col flex={1} alignSelf="stretch">
        {children}
      </Col>
    </Col>
  );
};

export default HorizontalStepper;
