import styled, { css } from 'styled-components';
import { StyledButton } from 'components/shared/Button/Button.styles';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: ${theme.color.dark};

    @media (max-width: ${theme.breakpoint.largeMax}px) {
      padding-top: 56px;
    }

    .field {
      margin-top: 16px;
    }
  `
);

export const InnerWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-top: 0;
    height: 100%;
    box-shadow: inset 0 80px 0 0 ${theme.color.dark};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      flex-direction: row;
    }

    .rounded {
      background: ${theme.color.white};

      @media (max-width: ${theme.breakpoint.largeMax}px) {
        border-radius: 12px 12px 0 0;
        padding: 14px 0 0;
        box-shadow: 0 -3px 9px -3px rgba(0, 0, 0, 0.1);
      }
    }
  `
);

export const NavWrap = styled.div(
  ({ theme }) => css`
    background: ${theme.color.dark};
    padding: 16px;
    color: ${theme.color.white};
    min-height: 56px;
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      padding: 120px 70px;
      flex: 0 0 50%;
      max-width: 50%;
      justify-content: center;
    }

    @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
      padding: 160px 154px;
    }

    @media (max-width: ${theme.breakpoint.largeMax}px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 12;
    }

    .icon {
      margin: 68px auto 65px;
      fill: ${theme.color.white};
    }

    .slick-slider {
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        display: none;
      }

      .wrap {
        display: flex;
        flex-direction: column;
      }
    }

    .back {
      position: absolute;
      right: 16px;
      top: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      svg {
        fill: ${theme.color.white};
      }

      &.previous {
        display: flex;
        align-items: center;
        left: 12px;
        right: inherit;
        right: initial;

        svg {
          margin-right: 4px;
        }
      }
    }

    ${StyledButton} {
      color: ${theme.color.white};

      svg {
        fill: ${theme.color.white};
      }
    }
  `
);

export const ContentWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    overflow: auto;
    height: 100%;
    background: ${theme.color.white};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      flex: 0 0 50%;
      max-width: 50%;
      justify-content: center;
    }

    .cross {
      position: absolute;
      right: 28px;
      top: 28px;
      z-index: 10;

      @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
        right: 48px;
        top: 62px;
      }
    }

    .block {
      padding: 10px 16px 0;
      position: relative;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        flex: 0 0 auto;
        padding: 0 70px;
        min-height: 545px;
      }

      @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
        flex: 0 0 auto;
        padding: 0 90px;
        min-height: 545px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    ${StyledButton} {
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        margin: 36px 0 16px;
      }
    }
  `
);
