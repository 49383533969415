import { IInvoiceFromSearch, INVOICE_STATUSES } from 'types';

interface IGetUpdatedApprovedInvoicesProps {
  invoices: IInvoiceFromSearch[];
  invoicesForMatch: IInvoiceFromSearch[];
  fundingId: string;
}
export const getUpdatedApprovedInvoices = ({
  invoices,
  invoicesForMatch,
  fundingId,
}: IGetUpdatedApprovedInvoicesProps): IInvoiceFromSearch[] =>
  invoices.map((invoiceFromMemory) => {
    const invoice = invoicesForMatch.find(
      (invoiceForMatch) => invoiceForMatch.id === invoiceFromMemory.id
    );

    const fundingIds = invoiceFromMemory.fundingIds || [];

    if (invoice) {
      return {
        ...invoiceFromMemory,
        status: INVOICE_STATUSES.paid,
        fundingIds: [...fundingIds, fundingId],
      };
    }

    return invoiceFromMemory;
  });
