import styled, { css } from 'styled-components';

const paddingCss = css`
  padding: ${({ theme }) => theme.spacing.xs};

  @media (min-width: ${({ theme }) => theme.breakpoint.mediumMin}px) {
    padding: ${({ theme }) => theme.spacing.s};
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    padding: ${({ theme }) => theme.spacing.m};
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  min-width: 860px;
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const StyledTh = styled.th`
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.greyLight_1};
  text-align: left;

  &:first-child {
    border-radius: ${({ theme }) =>
      `${theme.borderRadius.m} 0 0 ${theme.borderRadius.m}`};
  }

  &:last-child {
    border-radius: ${({ theme }) =>
      `0 ${theme.borderRadius.m} ${theme.borderRadius.m} 0`};
  }

  ${paddingCss}
`;

export const StyledTd = styled.td`
  padding: ${({ theme }) =>
    `${theme.spacing.xxs} ${theme.spacing.xxs}   ${theme.spacing.xxs} 0`};
  height: 40px;
  vertical-align: middle;

  &:last-child {
    @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
      width: 106px;
    }
  }
`;

export const CellWrapper = styled.div`
  flex: 1 0 auto;
  height: 100%;
  min-width: 48px;
  background: ${({ theme }) => theme.color.greyLight_3};
  border-radius: ${({ theme }) => theme.borderRadius.m};

  ${paddingCss}
`;
