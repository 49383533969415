import styled from 'styled-components';
import { Col } from 'components';

export const ErrorPageTemplateWrapper = styled(Col)`
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.greyLight_1};
  padding: ${(props) => props.theme.spacing.m};
`;
