import { ContextMenu, Row } from 'components';
import { useStoreState } from 'state';
import useFollowedCurrencies from 'hooks/useFollowedCurrencies';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { getRisksPageLink } from 'utils';
import { ISummaryItem } from '../../types';

interface OwnProps {
  record: ISummaryItem;
  detectedCurrencies: string[];
}

const ContextMenuCell: FC<OwnProps> = ({ record, detectedCurrencies }) => {
  const history = useHistory();
  const { onEditCurrency, onAddCurrency } = useFollowedCurrencies();
  const { followedCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );
  const savedCurrencyPair = followedCurrencies.find(
    (followedCurrency) => followedCurrency.buyCurrency === record.currency
  );

  return (
    <Row justifyContent="flex-end" flex={1}>
      <ContextMenu
        list={[
          {
            id: 0,
            title: `${savedCurrencyPair ? 'Edit' : 'Add'} target rate`,
            onClick: () =>
              savedCurrencyPair
                ? onEditCurrency(savedCurrencyPair)
                : onAddCurrency(record.currency),
          },
          {
            id: 1,
            title: 'See details',
            onClick: () =>
              history.push(getRisksPageLink({ currency: record.currency }), {
                detectedCurrencies,
              }),
          },
        ]}
      />
    </Row>
  );
};

export default ContextMenuCell;
