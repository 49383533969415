import { FC } from 'react';
import { Row } from '../../../../../../../Row/Row';
import { ITableFilterOption } from '../../../../../../types';
import Field from '../../../../../../../Field/Field.styles';
import InputBase from '../../../../../../../InputBase/InputBase';

interface OwnProps {
  filterId: string;
  filterOption: ITableFilterOption;
  setFilter: (filterId: string, filterValue: ITableFilterOption) => void;
}

const FilterOptionNumber: FC<OwnProps> = ({
  filterId,
  filterOption,
  setFilter,
}) => {
  const { title, value } = filterOption;

  return (
    <Row>
      <Field mb>
        <InputBase
          label={title}
          type="number"
          value={value}
          onChange={(event) =>
            setFilter(filterId, {
              ...filterOption,
              value: event.target.value,
            })
          }
        />
      </Field>
    </Row>
  );
};

export default FilterOptionNumber;
