import { useState, useMemo } from 'react';
import {
  Col,
  Paragraph,
  Row,
  Title,
  Subtitle,
  Icon,
  StaleButtonsPopup,
} from 'components';
import { useTheme } from 'styled-components';
import {
  GuruCard,
  GuruTitleWrapper,
  GuruCardClose,
  GuruCardsWrapper,
} from './HFGuru.styles';
import StaleRatingHexagons from 'components/shared/StaleRatingHexagons/StaleRatingHexagons';
import { useHistory } from 'react-router';
import { IHfGuru, IHfAccountantGuru, IGuruCard } from 'types/hfGuru';
import { getSettingsPageLink } from 'utils/links';
import Button from 'components/shared/Button/Button';
import { Nullable } from 'types';
import { useStoreState } from 'state';

interface OwnProps<T> {
  isLoading: boolean;
  data: Nullable<IHfGuru | IHfAccountantGuru>;
  onSeeRemainingTasks: () => void;
  activeTasks: Nullable<IGuruCard<T>[]>;
  updateStatus: (id: T) => void;
  showRemainingTasksLink: boolean;
  checkToShowTaskPopup?: (id: T) => boolean;
  basic?: boolean;
  isAccountant?: boolean;
}

const HFGuru = <T extends {}>({
  isLoading,
  data,
  onSeeRemainingTasks,
  activeTasks,
  updateStatus,
  showRemainingTasksLink,
  checkToShowTaskPopup,
  basic,
  isAccountant,
}: OwnProps<T>) => {
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);
  const { showReports, integrationsSummary } = useStoreState(
    (state) => state.UserState
  );

  const isXeroIntegration = integrationsSummary?.system === 'xero';
  const isBacktesterEnabled = featureFlagById('backtester');

  const calculatedData = useMemo(() => {
    if (!showReports) {
      return {
        hexagonsCount: 3,
        ...(data?.progress ? { progressValue: data.progress * 33 } : {}),
      };
    }

    if (isXeroIntegration && showReports && isBacktesterEnabled) {
      return {
        hexagonsCount: 5,
        ...(data?.progress ? { progressValue: data.progress * 20 } : {}),
      };
    }

    return {
      hexagonsCount: 4,
      ...(data?.progress ? { progressValue: data.progress * 25 } : {}),
    };
  }, [isBacktesterEnabled, isXeroIntegration, showReports, data]);

  return (
    <>
      <Col justifyContent={isLoading ? 'center' : 'flex-start'}>
        <GuruTitleWrapper>
          <Row gap={theme.spacing.xs} flexWrap="wrap">
            {basic ? (
              <Title variant="h5">Suggested tasks</Title>
            ) : (
              <Title variant="h5">Become a HedgeFlows guru</Title>
            )}

            {!basic && data?.progress !== undefined ? (
              <Row justifyContent="flex-start" columnGap={theme.spacing.xs}>
                <StaleRatingHexagons
                  hexagonsCount={calculatedData.hexagonsCount}
                  rating={data.progress}
                />
                <Paragraph variant="bold">
                  {calculatedData.progressValue} %
                </Paragraph>
              </Row>
            ) : null}
          </Row>

          {showRemainingTasksLink && (
            <Button variant="link" onClick={onSeeRemainingTasks}>
              See remaining tasks
            </Button>
          )}
        </GuruTitleWrapper>
        {activeTasks?.length ? (
          <GuruCardsWrapper>
            {activeTasks
              .slice(0, 3)
              .map(({ id, icon, title, text, link, buttonText }) => (
                <GuruCard columnGap={theme.spacing.m} key={icon}>
                  <GuruCardClose onClick={() => updateStatus(id)}>
                    <Icon icon="cross-ico" width="20px" height="20px" />
                  </GuruCardClose>
                  <Icon icon={icon} width="72px" height="72px" />
                  <Col rowGap={theme.spacing.xs} alignItems="flex-start">
                    <Subtitle variant="bold">{title}</Subtitle>

                    <Paragraph>{text}</Paragraph>

                    <Button
                      variant="link"
                      onClick={() => {
                        if (checkToShowTaskPopup?.(id)) {
                          setOpen(true);
                        } else {
                          history.push(link);
                        }
                      }}
                    >
                      {buttonText}
                    </Button>
                  </Col>
                </GuruCard>
              ))}
          </GuruCardsWrapper>
        ) : null}
      </Col>
      {open && (
        <StaleButtonsPopup
          title={
            isAccountant
              ? 'Review client’s FX gains & losses and Reports'
              : 'Currency Health-check'
          }
          text={`Please integrate accounting system first in order to enable ${
            isAccountant
              ? 'FX gains & losses and Reports'
              : 'Currency Health-check'
          }`}
          mainButtonText="Integrate"
          secondaryButtonText="Cancel"
          width="347px"
          onCancel={() => setOpen(false)}
          onClose={() => setOpen(false)}
          onContinue={() =>
            history.push(getSettingsPageLink({ tab: 'integrations' }))
          }
        />
      )}
    </>
  );
};

export default HFGuru;
