import { ChartData } from 'chart.js';
import {
  parseIntoCurrencyStringWithSymbol,
  parseIntoShortNumberWithSymbol,
} from 'utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Chart from 'components/shared/Chart/Chart';
import { useStoreState } from 'state';
import { getDateFormatFromPeriodType } from 'pages/Risks/components/DataTable/components/CashflowsTable/utils';
import { useRef } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { getElementFromEvent } from 'components/shared/Chart/utils';
import { useTheme } from 'styled-components';
import { IMonthlyReport } from 'types';
import { getDatasets } from './utils';

dayjs.extend(utc);

const ChartReports = ({
  isAllCurrencies,
  data,
  currencyCode,
  onChartClick,
}: {
  isAllCurrencies: boolean;
  data: IMonthlyReport[];
  currencyCode: string | null;
  /** @param period date string */
  onChartClick: (period: string, periodIndex: number) => void;
}) => {
  const theme = useTheme();
  const labels = data.map((item) => dayjs.utc(item.date, 'YYYY-MM').toDate());
  const currencyByCode = useStoreState(
    (state) => state.CurrenciesState.currencyByCode
  );
  const dateDisplayFormat = getDateFormatFromPeriodType('month');
  const chartRef = useRef<ChartJSOrUndefined<'line' | 'bar'>>();
  const chartData: ChartData<'bar', number[], Date> = {
    labels,
    datasets: getDatasets(data, isAllCurrencies, theme),
  };

  return (
    <Chart
      ref={chartRef}
      type="line"
      data={chartData}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (context) =>
                `${context.dataset.label}: ${parseIntoCurrencyStringWithSymbol(
                  context.raw as number,
                  currencyByCode(currencyCode)?.symbol,
                  0
                )}`,
            },
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              tooltipFormat: dateDisplayFormat,
              displayFormats: {
                week: dateDisplayFormat,
                month: dateDisplayFormat,
                quarter: dateDisplayFormat,
              },
            },
            grid: {
              display: false,
            },
            ticks: {
              source: 'data',
            },
            stacked: true,
          },
          y: {
            ticks: {
              callback: (value) =>
                parseIntoShortNumberWithSymbol(value as number),
            },
            stacked: true,
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
      }}
      onClick={(event) => {
        if (!chartRef.current) {
          return;
        }

        const element = getElementFromEvent(chartRef.current, event);

        if (!element) {
          return;
        }

        onChartClick(data[element.index].date, element.index);
      }}
    />
  );
};

export default ChartReports;
