import { FC, useRef, useState } from 'react';
import { Icon, Title, Paragraph } from 'components';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IMaskInput } from 'react-imask';
import {
  AmountCellEditableWrapper,
  EditButton,
} from './AmountCellEditable.styles';

interface IOwnProps {
  amount?: number;
  currencyCode: string;
  withFlag?: boolean;
  withCurrencyPrecision?: boolean;
  variant?: 'medium' | 'large';
  withMinusSign?: boolean;
  onEdit: (value: number) => void;
}

const AmountCellEditable: FC<IOwnProps> = ({
  amount = 0,
  currencyCode,
  withFlag,
  variant = 'medium',
  withMinusSign = false,
  onEdit,
}) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(currencyCode);
  const [inputValue, setInputValue] = useState(amount);

  const generateCellFlag = () => {
    if (!currency || !withFlag) {
      return null;
    }

    if (variant === 'large') {
      return (
        <Icon
          width={theme.iconSizes.xl}
          height={theme.iconSizes.xl}
          icon={currency.countryCode}
        />
      );
    }

    return <Icon icon={currency.countryCode} />;
  };

  const generateCurrencySign = () => {
    if (!currency) {
      return null;
    }

    if (variant === 'large') {
      return (
        <Title variant="h3">
          {withMinusSign && '-'}
          {currency.symbol}
        </Title>
      );
    }

    return (
      <Paragraph>
        {withMinusSign && '-'}
        {currency.symbol}
      </Paragraph>
    );
  };

  const onBlurHandler = () => {
    if (amount !== inputValue) {
      onEdit(inputValue);
    }
  };

  const onEditClick = () => {
    inputRef.current?.focus();
  };

  return (
    <AmountCellEditableWrapper variant={variant}>
      {generateCellFlag()}
      {generateCurrencySign()}

      <IMaskInput
        /* No way / takes too much time to us styled components with this one */
        className="iMaskInput"
        inputMode="decimal"
        value={inputValue}
        mask={Number}
        radix="."
        scale={2}
        thousandsSeparator=","
        placeholder="Amount"
        autoComplete="new-password"
        unmask="typed"
        onAccept={(value) => {
          if (typeof value === 'number') {
            setInputValue(value);
          }
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            inputRef.current?.blur();
          }
        }}
        onBlur={onBlurHandler}
        // @ts-expect-error - outdated types
        inputRef={(ref) => (inputRef.current = ref)}
      />

      <EditButton onClick={onEditClick}>
        <Icon width="16px" height="16px" icon="edit-ico" />
      </EditButton>
    </AmountCellEditableWrapper>
  );
};

export default AmountCellEditable;
