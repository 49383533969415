import { BULK_PAYMENT_STATUS } from 'types';

export const MAP_BULK_PAYMENT_STATUS_TO_TEXT: Record<
  `${BULK_PAYMENT_STATUS}`,
  string
> = {
  awaiting_payment: 'Scheduled',
  funding_initiated: 'Funding Initiated',
  funded: 'Funded',
  processing: 'Processing',
  completed: 'Completed',
  cancelled: 'Cancelled',
  transfers_failed: 'Transfers Failed',
};
