import { FC, useState, useEffect } from 'react';
import {
  Title,
  StaleInput,
  StaleBtnGroup,
  Paragraph,
  StaleSelectLocation,
  Overflow,
} from 'components';
import { useForm, Controller } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'state';
import { Firebase } from 'services';
import { ENTITY_TYPE, IOwner } from 'types';
import { SignUpContent } from 'pages/CompanyRegistration/components';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';
import { StyledButton } from 'components/shared/Button/Button.styles';

type Inputs = {
  name: string;
  businessName: string;
  address: string;
  VAT: string;
  email: string;
};

interface OwnProps {
  onSaveValues: (values: any) => void;
  onContinue: () => void;
  setOnfidoApplicantId: () => void;
  // TODO: add correct interface
  stepOneValues: any;
  stepTwoValues: any;
}

const StepTwo: FC<OwnProps> = ({
  onContinue,
  setOnfidoApplicantId,
  stepOneValues,
  stepTwoValues,
}) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(false);
  const { user, userEntity } = useStoreState((state) => state.UserState);
  const { getUser, getUserEntity } = useStoreActions(
    (actions) => actions.UserState
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      ...stepTwoValues,
    },
  });

  const address = watch('address');

  const Line1 = watch('address.value.addressLine1');
  const Line2 = watch('address.value.addressLine2');
  const City = watch('address.value.city');
  const Country = watch('address.value.country');
  const PostalCode = watch('address.value.postalCode');

  useEffect(() => {
    setValue('address', {
      label: `${Line1 && `${Line1},`} ${Line2 && `${Line2},`} ${
        PostalCode && `${PostalCode},`
      } ${City && `${City},`} ${Country}`.trim(),
    });
  }, [City, Country, Line1, Line2, PostalCode, setValue]);

  const onSubmit = async (values: any) => {
    if (!user || !userEntity) return;

    const { name, businessName, VAT, email } = values;

    const officersData: IOwner[] = [
      {
        address: {
          // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'string'.
          addressLine1: watch('address')?.label,
          // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'string'.
          ...watch('address')?.value,
          // TODO: remove hardcoded value later
          country: 'gb',
        },
        name,
        email,
        isUser: true,
        isRepresentingEntity: true,
        isPsc: false,
        role: 'owner',
      },
    ];

    setIsLoading(true);

    const response = await Firebase.registerCompany({
      // TODO: remove hardcoded value
      companyCountry: 'gb',
      companyType: ENTITY_TYPE.soleTrader,
      expectedAnnualTurnover: stepOneValues.expectedAnnualTurnover,
      expectedFxTurnoverUpperLimit:
        stepOneValues.expectedMonthlyForeignCurrencyTurnover.value
          .expectedFxTurnoverUpperLimit,
      name: businessName,
      website: 'socialMediaLink hardcoded',
      companyDetails: {
        address: {
          // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'string'.
          addressLine1: watch('address')?.label,
          // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'string'.
          ...watch('address')?.value,
          // TODO: remove hardcoded value later
          country: 'gb',
        },
        addressCorrespondence: {
          // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'string'.
          addressLine1: watch('address')?.label,
          // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'string'.
          ...watch('address')?.value,
          // TODO: remove hardcoded value later
          country: 'gb',
        },
        countriesSendingMoneyTo: stepOneValues.countries.map(
          (item: any) => item.value.alpha2
        ),
        natureOfBusiness:
          stepOneValues.businessNature.code === 'other'
            ? stepOneValues.otherNatureOfBusinessLabel
            : stepOneValues.businessNature.label,
        natureOfBusinessCode: stepOneValues.businessNature.code,
        purposeOfAccount: stepOneValues.accountPurpose,
        vat: !!VAT,
        vatNumber: VAT,
      },
      officers: officersData,
    });

    if (response?.success && response.id) {
      // TODO: remove later when we get entity from the registration response
      await getUserEntity({
        entityId: response.id,
      });

      // we need to refetch the user because it has onboarding ID now
      await getUser({
        id: user.id,
      });

      setIsLoading(false);
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      setOnfidoApplicantId(response.onfidoApplicantId);
      onContinue();
      return;
    }

    setIsLoading(false);
  };

  return (
    <>
      <SignUpContent>
        {!isMobile && (
          <StyledButton
            variant="link"
            className="cross"
            onClick={() => history.push('/app/dashboard')}
          >
            <svg width="24" height="24">
              <use xlinkHref="#cross-ico" />
            </svg>
          </StyledButton>
        )}

        <Overflow>
          <div className="block">
            {!isMobile && (
              <StyledButton
                variant="link"
                onClick={history.goBack}
                className="back"
              >
                <svg width="24" height="24">
                  <use xlinkHref="#arrow-left" />
                </svg>
                Back
              </StyledButton>
            )}

            {isMobile ? (
              <Title variant="h5">Please fill your personal entity data</Title>
            ) : (
              <Title>Fill your personal entity data</Title>
            )}

            <form
              id="solo-trader-registration-step-two-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="field">
                <StaleInput
                  id="name"
                  name="name"
                  label="Your full name"
                  view="moving"
                  control={control}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  error={errors.name?.message}
                />
              </div>

              <div className="field">
                <StaleInput
                  id="businessName"
                  name="businessName"
                  label="Trading as (business name)"
                  view="moving"
                  control={control}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  error={errors.businessName?.message}
                />
              </div>
              <div className="col">
                <div className="field">
                  <Controller
                    control={control}
                    name="address"
                    defaultValue={null}
                    rules={{ required: ERROR_MESSAGES.requiredField }}
                    render={({ onChange, value, name }) => {
                      return (
                        <StaleSelectLocation
                          id="address"
                          label="Address"
                          name={name}
                          value={value}
                          onChange={(values: any) => {
                            onChange({
                              label: values.label,
                            });
                            if (values.dataForPaste) {
                              Object.entries(values.dataForPaste).forEach(
                                ([key, value]) => {
                                  // @ts-expect-error TS(2345) FIXME: Argument of type '`address.value.${string}`' is no... Remove this comment to see the full error message
                                  setValue(`address.value.${key}`, value);
                                  // @ts-expect-error TS(2345) FIXME: Argument of type '`address.value.${string}`' is no... Remove this comment to see the full error message
                                  trigger(`address.value.${key}`);
                                }
                              );
                            }
                          }}
                        />
                      );
                    }}
                  />
                </div>

                <div
                  className="col"
                  style={{
                    height: showAddressDetails ? 'auto' : 0,
                    overflow: 'hidden',
                  }}
                >
                  <div className="field">
                    <StaleInput
                      locked={!showAddressDetails}
                      control={control}
                      name="address.value.addressLine1"
                      id="address.value.addressLine1"
                      label="Address Line 1"
                      view="moving"
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                    />
                  </div>
                  <div className="field">
                    <StaleInput
                      locked={!showAddressDetails}
                      control={control}
                      name="address.value.addressLine2"
                      id="address.value.addressLine2"
                      label="Address Line 2 (optional)"
                      view="moving"
                    />
                  </div>

                  <div className="field">
                    <StaleInput
                      locked={!showAddressDetails}
                      control={control}
                      name="address.value.city"
                      id="address.value.city"
                      label="City"
                      view="moving"
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                    />
                  </div>

                  <div className="row">
                    <div
                      className="field"
                      style={{ width: 116, marginRight: 16 }}
                    >
                      <StaleInput
                        locked={!showAddressDetails}
                        control={control}
                        name="address.value.postalCode"
                        id="address.value.postalCode"
                        label="Post code"
                        view="moving"
                        rules={{
                          required: ERROR_MESSAGES.requiredField,
                        }}
                      />
                    </div>
                    <div className="field" style={{ flex: 1 }}>
                      <StaleInput
                        locked={!showAddressDetails}
                        control={control}
                        name="address.value.stateOrProvince"
                        id="address.value.stateOrProvince"
                        label="Region (optional)"
                        view="moving"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <StaleInput
                      locked={!showAddressDetails}
                      control={control}
                      name="address.value.country"
                      id="address.value.country"
                      label="Country"
                      view="moving"
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                    />
                  </div>
                </div>

                <StyledButton
                  variant="link"
                  type="button"
                  onClick={() => setShowAddressDetails(!showAddressDetails)}
                  style={{
                    textDecoration: 'underline',
                    marginTop: 6,
                    marginBottom: 16,
                  }}
                >
                  <Paragraph>
                    {showAddressDetails
                      ? 'Apply'
                      : // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'string'.
                      address?.label
                      ? 'Edit'
                      : 'Enter manually'}
                  </Paragraph>
                </StyledButton>
              </div>

              <div className="field">
                <StaleInput
                  id="email"
                  label="Email"
                  view="moving"
                  type="email"
                  control={control}
                  name="email"
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                />
              </div>
              <div className="field">
                <StaleInput
                  id="VAT"
                  name="VAT"
                  label="VAT Number (optional)"
                  view="moving"
                  control={control}
                />
              </div>
            </form>

            {!isMobile && (
              <StaleBtnGroup>
                <Button
                  disabled={!isValid}
                  form="solo-trader-registration-step-two-form"
                >
                  Continue
                  {isLoading && (
                    <svg width="16px" height="16px" className="loader">
                      <use xlinkHref="#loader-ico" />
                    </svg>
                  )}
                </Button>
              </StaleBtnGroup>
            )}
          </div>
        </Overflow>
      </SignUpContent>

      {isMobile && (
        <StaleBtnGroup>
          <Button
            disabled={!isValid}
            form="solo-trader-registration-step-two-form"
          >
            Continue
            {isLoading && (
              <svg width="16px" height="16px" className="loader">
                <use xlinkHref="#loader-ico" />
              </svg>
            )}
          </Button>
        </StaleBtnGroup>
      )}
    </>
  );
};

export default StepTwo;
