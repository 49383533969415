import { Col } from 'components/shared/Col/Col';
import { Paragraph } from 'components/shared/Typography/Typography';
import { FC } from 'react';
import { useStoreState } from 'state';

const DisabledCheckboxHint: FC = () => {
  const { hasApprovalFlow } = useStoreState(({ UserState }) => UserState);

  return (
    <Col>
      <Paragraph color="white">
        Invoice is disabled, receivable, cannot be approved or has no valid
        contact
      </Paragraph>
      {hasApprovalFlow && (
        <Paragraph color="white">
          To add invoice to payment run please go to "Approved" tab
        </Paragraph>
      )}
    </Col>
  );
};
export default DisabledCheckboxHint;
