import { useCallback, useState } from 'react';
import { getInvoicesPaginated } from 'services/firebase/invoices';
import { IGroupedInvoicesFromSearch, IInvoiceFromSearch } from 'types';
import {
  IGenerateSearchQueryParams,
  generateInvoicesSearchQuery,
} from 'utils/search';
import { isGroupedInvoicesTypeGuard } from '../pages/Invoices/components/InvoicesTable/utils';
import { errorHandler } from 'utils/errors';

interface IOwnProps {
  initialInvoices?: IInvoiceFromSearch[] | IGroupedInvoicesFromSearch[];
  initialPage?: number;
}

const useInvoices = (params?: IOwnProps) => {
  const [isLoadingMoreInvoices, setIsLoadingMoreInvoices] = useState(false);
  const [currentPage, setCurrentPage] = useState(params?.initialPage || 1);
  const [afterKey, setAfterKey] = useState('');
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true);
  const [invoices, setInvoices] = useState<
    IInvoiceFromSearch[] | IGroupedInvoicesFromSearch[]
  >(params?.initialInvoices || []);

  const fetchInvoices = useCallback(
    async ({
      page,
      searchQuery,
      filters,
      sortFields,
      size = 30,
      grouped = false,
      afterKey,
      withRiskContributions,
    }: {
      page: number;
      searchQuery: string;
      filters?: IGenerateSearchQueryParams['filters'];
      sortFields?: IGenerateSearchQueryParams['sortFields'];
      size?: number;
      grouped?: boolean;
      afterKey?: string;
      withRiskContributions?: boolean;
    }) => {
      const query = generateInvoicesSearchQuery({
        searchQuery,
        filters,
        sortFields,
        size,
        page,
        grouped,
        afterKey,
        withRiskContributions,
      });

      const response = await getInvoicesPaginated(query);

      return response.data.data;
    },
    []
  );

  const fetchInvoicesWithSideEffects = useCallback(
    async ({
      page,
      searchQuery,
      filters,
      sortFields,
      size = 30,
      grouped = false,
      reset = false,
      afterKey,
      withRiskContributions
    }: {
      page: number;
      searchQuery: string;
      filters?: IGenerateSearchQueryParams['filters'];
      sortFields?: IGenerateSearchQueryParams['sortFields'];
      size?: number;
      grouped?: boolean;
      reset?: boolean;
      afterKey?: string;
      withRiskContributions?: boolean;
    }) => {
      try {
        setIsLoadingMoreInvoices(true);

        if (reset) {
          setCurrentPage(1);
          setHasMoreToLoad(true);
        }
        const response = await fetchInvoices({
          page,
          searchQuery,
          filters,
          sortFields,
          size,
          grouped,
          afterKey,
          withRiskContributions,
        });

        const results = response?.results || [];

        setInvoices((prevState) => {
          // grouped queries may return empty results
          if (grouped && !results.length) {
            return prevState;
          }
          const prevStateToUse = reset ? [] : prevState;
          const isPrevStateGrouped = isGroupedInvoicesTypeGuard(prevStateToUse);
          const isResultsGrouped = isGroupedInvoicesTypeGuard(results);

          if (isPrevStateGrouped && isResultsGrouped) {
            return [...prevStateToUse, ...results];
          }

          if (!isPrevStateGrouped && !isResultsGrouped) {
            return [...prevStateToUse, ...results];
          }

          return results;
        });

        // afterKey is used for paginating grouped invoices
        if (grouped) {
          if (response?.meta.afterKey) {
            setAfterKey(response.meta.afterKey);
            setHasMoreToLoad(true);
          } else {
            setAfterKey('');
            setHasMoreToLoad(false);
          }
          return;
        }

        setCurrentPage(page);

        const currentPageFromResponse = response?.meta.page.current;
        const totalPagesFromResponse = response?.meta.page.total_pages;

        if (
          currentPageFromResponse &&
          totalPagesFromResponse &&
          currentPageFromResponse === totalPagesFromResponse
        ) {
          setHasMoreToLoad(false);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoadingMoreInvoices(false);
      }
    },
    [fetchInvoices]
  );

  /**
   * we support in memory updates only for non grouped invoices
   */
  const updateInMemoryInvoices = useCallback(
    (
      updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
    ) => {
      setInvoices((prevState) =>
        isGroupedInvoicesTypeGuard(prevState)
          ? prevState
          : updateFunction(prevState)
      );
    },
    [setInvoices]
  );

  return {
    afterKey,
    invoices,
    currentPage,
    hasMoreToLoad,
    isLoadingMoreInvoices,
    fetchInvoices,
    fetchInvoicesWithSideEffects,
    setInvoices,
    updateInMemoryInvoices,
  };
};

export default useInvoices;
