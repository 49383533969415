import styled from 'styled-components';
import { InputErrorMessage } from '../InputErrorMessage/InputErrorMessage.styles';
import { Paragraph } from '../Typography/Typography';

export const CreatableSelectMenuWrapper = styled.div<{
  filled: boolean;
  focused: boolean;
  disabled?: boolean;
}>`
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-height: 52px;
  color: ${({ theme }) => theme.color.dark};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  .select {
    &__control {
      display: flex;
      align-items: flex-end;
      border: none;
      box-shadow: none;
      min-height: 52px;
      background: ${({ theme }) => theme.color.greyLight_3};
      border-radius: ${({ theme }) => theme.borderRadius.m};

      &--is-focused:not(.select__control--menu-is-open) {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.emeraldDark};
      }
    }

    &__placeholder {
      position: absolute;
      color: ${({ theme }) => theme.color.grey};
      font-size: ${({ theme }) => theme.fontSize.s};
      line-height: ${({ theme }) => theme.fontSize.xl};
    }

    &__value-container {
      position: relative;
      gap: ${({ theme }) => theme.spacing.xxs};
      border-radius: ${({ theme }) => theme.borderRadius.m};
      background-color: ${({ theme }) => theme.color.transparent};
      margin: ${({ theme }) =>
        `${theme.spacing.xl} ${theme.spacing.xl} ${theme.spacing.xxs} ${theme.spacing.m}`};
      margin: ${({ theme, focused, filled }) =>
        (focused || filled) &&
        `${theme.spacing.xl} ${theme.spacing.m} ${theme.spacing.xxs}`};
      opacity: ${({ focused, filled }) => (focused || filled ? 1 : 0)};
      padding: 0;
      padding: ${({ theme, filled }) => filled && `${theme.spacing.xxs} 0 0`};
    }

    &__clear-indicator,
    &__indicator-separator {
      display: none;
    }

    &__multi-value {
      height: 32px;
      display: flex;
      align-items: center;
      background: ${({ theme }) => theme.color.greyLight_1};
      border-radius: ${({ theme }) => theme.borderRadius.s};

      &__label {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.color.dark};
        font-size: ${({ theme }) => theme.fontSize.s};
        line-height: ${({ theme }) => theme.fontSize.xl};
        font-weight: 500;
      }
    }
  }
`;

export const Label = styled(Paragraph)<{
  filled: boolean;
  focused: boolean;
  disabled?: boolean;
}>`
  height: 62px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: ${({ theme, focused, filled }) =>
    focused || filled ? `-${theme.spacing.s}` : 0};
  padding: 0 ${({ theme }) => theme.spacing.m};
  transition: ${({ theme }) => theme.transition};
  font-size: ${({ focused, theme, filled }) =>
    (focused || filled) && theme.fontSize.xs};
  font-weight: 400;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: none;
  color: ${({ disabled, theme }) => disabled && theme.color.grey};
`;

export const CreatableSelectErrorMessage = styled(InputErrorMessage)`
  position: relative;
`;
