import { FC, useState, useMemo } from 'react';

import {
  StaleButtonsPopup,
  InputSearch,
  StaleRecipient,
  Row,
  Subtitle,
  Title,
  StaleRecipientDropdown,
  Paragraph,
  Loader,
  PermissionsChecker,
} from 'components';
import { Wrapper, AddRecipientButtonBig } from './StaleRecipientList.styles';
import { RECIPIENT_STATUS, ICurrency, IRecipient, TRANSFER_TYPE } from 'types';
import { useStoreActions } from 'state';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import { useTheme } from 'styled-components';
import { Col } from '../Col/Col';
import usePermissionsCheck from 'hooks/usePermissionsCheck';

interface OwnProps {
  recipientItemType?: 'dropdown' | 'card';
  buyCurrency: ICurrency;
  disabled?: boolean;
  isInvoice?: boolean;
  onAddRecipient: () => void;
  onEditRecipient: (recipient: IRecipient) => void;
  recipient?: IRecipient;
  recipients: IRecipient[];
  sellCurrency: ICurrency;
  setRecipient: (recipient: IRecipient) => void;
  transferType?: TRANSFER_TYPE;
  checkForDisabledRecipients?: boolean;
}

const StaleRecipientList: FC<OwnProps> = ({
  recipientItemType = 'card',
  buyCurrency,
  disabled = false,
  isInvoice = false,
  onAddRecipient,
  onEditRecipient,
  recipient,
  recipients,
  sellCurrency,
  setRecipient,
  transferType,
  checkForDisabledRecipients,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const [showDeleteRecipient, setShowDeleteRecipient] = useState(false);
  const [recipientIdForDelete, setRecipientIdForDelete] = useState('');
  const { deleteRecipient } = useStoreActions(
    (actions) => actions.RecipientsState
  );

  const hasCreateRecipientsPermission = usePermissionsCheck({
    action: 'create',
    resource: 'recipients',
  });

  const filteredRecipients = useMemo(
    () =>
      recipients.filter(
        (item) =>
          item.currency === buyCurrency?.code &&
          item.status !== RECIPIENT_STATUS.deleted &&
          item.recipientName
            .toLowerCase()
            .trim()
            .includes(searchValue.toLowerCase().trim()) &&
          !item.shouldPausePayments &&
          !item.shouldHideInvoices
      ),
    [recipients, searchValue, buyCurrency]
  );

  const isList = useMemo(() => !!filteredRecipients.length, [
    filteredRecipients,
  ]);

  const onDeleteRecipient = async () => {
    await deleteRecipient(recipientIdForDelete);

    setRecipientIdForDelete('');
    setShowDeleteRecipient(false);
  };

  // TODO: rework, condition is redundant
  const isListWithSearch = (isList && !searchValue) || searchValue;

  return (
    <Wrapper>
      <Row justifyContent="space-between">
        <Title variant="h5">Select the recipient</Title>

        {isListWithSearch && (
          <PermissionsChecker action="create" resource="recipients">
            <Button
              variant="link"
              disabled={disabled}
              isLoading={disabled}
              onClick={() => onAddRecipient()}
              icon={disabled ? undefined : 'user-plus-ico'}
              ml
              mlValue={theme.spacing.xs}
            >
              {disabled ? 'Loading...' : 'Add recipient'}
            </Button>
          </PermissionsChecker>
        )}
      </Row>

      {isListWithSearch && (
        <InputSearch
          isGray={true}
          style={{ marginBottom: 16 }}
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      )}

      <Col>
        {isListWithSearch && !isList && (
          <Paragraph style={{ textAlign: 'center' }} variant="bold">
            No results found
          </Paragraph>
        )}

        {!isListWithSearch && (
          <>
            <PermissionsChecker action="create" resource="recipients">
              <AddRecipientButtonBig type="button" onClick={onAddRecipient}>
                {disabled ? (
                  <Loader style={{ minHeight: 55 }} />
                ) : (
                  <Col alignItems="center">
                    <Icon icon="user-plus-ico" />
                    <Subtitle>Add new recipient</Subtitle>
                  </Col>
                )}
              </AddRecipientButtonBig>
            </PermissionsChecker>
            {!isList && !hasCreateRecipientsPermission && (
              <Paragraph variant="bold">
                No recipients to add. Please contact your administrator to add a
                recipient.
              </Paragraph>
            )}
          </>
        )}

        {isList &&
          filteredRecipients.map((item) =>
            recipientItemType === 'card' ? (
              <StaleRecipient
                key={item.id}
                isInvoice={isInvoice}
                selectedRecipient={recipient}
                transferType={transferType}
                recipient={item}
                sellCurrency={sellCurrency}
                // @ts-expect-error TS(2322) FIXME: Type '(recipient: IRecipient) => void' is not assi... Remove this comment to see the full error message
                setRecipient={setRecipient}
                onEditRecipient={onEditRecipient}
                setShowDeleteRecipient={setShowDeleteRecipient}
                setRecipientIdForDelete={setRecipientIdForDelete}
                checkDisabled={checkForDisabledRecipients}
              />
            ) : (
              <StaleRecipientDropdown
                key={item.id}
                isInvoice={isInvoice}
                selectedRecipient={recipient}
                transferType={transferType}
                recipient={item}
                sellCurrency={sellCurrency}
                // @ts-expect-error TS(2322) FIXME: Type '(recipient: IRecipient) => void' is not assi... Remove this comment to see the full error message
                setRecipient={setRecipient}
                onEditRecipient={onEditRecipient}
                checkDisabled={checkForDisabledRecipients}
              />
            )
          )}
      </Col>

      {showDeleteRecipient && (
        <StaleButtonsPopup
          title="Delete recipient?"
          text="Please confirm that you want to delete this recipient."
          mainButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          width="347px"
          onCancel={() => {
            setShowDeleteRecipient(false);
            setRecipientIdForDelete('');
          }}
          onClose={() => {
            setShowDeleteRecipient(false);
            setRecipientIdForDelete('');
          }}
          onContinue={() => {
            onDeleteRecipient();
          }}
        />
      )}
    </Wrapper>
  );
};

export default StaleRecipientList;
