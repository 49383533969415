import { AxiosIntegrationEnginePrivateInstance } from 'settings';
import { IIntegrationLog, IIntegrationPermissions, IResponse } from 'types';
import {
  IIntegrationSettingsAll,
  IIntegrationSettingsAccount,
  IIntegrationSettingsInput,
  IIntegrationTaxRate,
  TAccountingSystem,
} from 'types/integrationEngine';
import { errorHandler } from 'utils/errors';

// CONNECTIVITY

export const connectionGetRedirectUrl = async (
  entityId: string,
  system: TAccountingSystem
) => {
  try {
    const { data } = await AxiosIntegrationEnginePrivateInstance.post<
      IResponse<string>
    >(`/${entityId}/connection/${system}`);

    return data.data;
  } catch (error: any) {
    errorHandler(error);
    return;
  }
};

export const connectionDisconnect = async (entityId: string) => {
  try {
    await AxiosIntegrationEnginePrivateInstance.post(`/${entityId}/disconnect`);
  } catch (error: any) {
    errorHandler(error);
  }
};

// SETTINGS

export const saveIntegrationEnginePermissions = async ({
  entityId,
  permissions,
}: {
  entityId: string;
  permissions: Partial<IIntegrationPermissions>;
}) => {
  try {
    return AxiosIntegrationEnginePrivateInstance.post<
      IResponse<IIntegrationPermissions>
    >(`/${entityId}/permissions`, { permissions });
  } catch (error: any) {
    errorHandler(error);
  }
};

export const getAccounts = async (entityId: string) =>
  AxiosIntegrationEnginePrivateInstance.get<
    IResponse<IIntegrationSettingsAccount[]>
  >(`/${entityId}/accounts`);

export const getSettings = async (entityId: string) =>
  AxiosIntegrationEnginePrivateInstance.get<IResponse<IIntegrationSettingsAll>>(
    `/${entityId}/settings`
  );

export const saveIntegrationEngineSettings = async ({
  entityId,
  settings,
  disableReload,
}: {
  entityId: string;
  settings: IIntegrationSettingsInput;
  disableReload?: boolean;
}) => {
  try {
    const queryString = disableReload ? '?disableReload=true' : '';
    return AxiosIntegrationEnginePrivateInstance.post<IResponse<void>>(
      `/${entityId}/settings${queryString}`,
      { settings }
    );
  } catch (error: any) {
    errorHandler(error);
  }
};

// DATA IMPORTS ASYNC

/** Gets all data for this integration.  */
export const importAllData = async (entityId: string) =>
  AxiosIntegrationEnginePrivateInstance.post<IResponse>(`/${entityId}/import`); // Same as /getall

// DATA GET SYNC

export const getTaxRates = async (entityId: string) =>
  AxiosIntegrationEnginePrivateInstance.get<IResponse<IIntegrationTaxRate[]>>(
    `/${entityId}/tax-rates`
  );

// OTHER

export const getIntegrationLogs = async (entityId: string) =>
  AxiosIntegrationEnginePrivateInstance.get<IResponse<IIntegrationLog[]>>(
    `/${entityId}/logs`
  );
