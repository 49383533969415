import { useCallback, useEffect, useState } from 'react';
import {
  IOpenBankingInstitution,
  IOpenBankingTransaction,
  IOpenBankingPaymentAuthResponseData,
} from 'types';
import * as FirebasePaymentInitiation from 'services/firebase/paymentInitiation';
import { errorHandler } from 'utils/errors';
import { callExternalApiWithLoading } from 'utils/fetchers';

const useOpenBankingAccounts = () => {
  const [institutions, setInstitutions] = useState<IOpenBankingInstitution[]>(
    []
  );
  const [
    selectedInstitution,
    setSelectedInstitution,
  ] = useState<IOpenBankingInstitution>();
  const [
    accountAuthResponse,
    setAccountAuthResponse,
  ] = useState<IOpenBankingPaymentAuthResponseData>();
  const [isRedirected, setIsRedirected] = useState(false);
  const [isLoadingInstitutions, setIsLoadingInstitutions] = useState(false);
  const [isLoadingAccountAuthUrl, setIsLoadingAccountAuthUrl] = useState(false);
  const [
    opTransaction,
    setOpTransaction,
  ] = useState<IOpenBankingTransaction | null>(null);

  const onAccountAuthorisationInstitutionSelect = useCallback(
    async (institution: IOpenBankingInstitution) => {
      try {
        setIsLoadingAccountAuthUrl(true);
        setSelectedInstitution(institution);

        const {
          data,
        } = await FirebasePaymentInitiation.postAccountAuthorisation({
          institutionId: institution.id,
        });

        if (data.success && data.data?.authorisationUrl) {
          setAccountAuthResponse(data.data);
        } else {
          errorHandler(data);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingAccountAuthUrl(false);
      }
    },
    []
  );

  const onUnselectInstitution = useCallback(() => {
    setSelectedInstitution(undefined);
    setAccountAuthResponse(undefined);
    setIsRedirected(false);
  }, []);

  useEffect(() => {
    callExternalApiWithLoading({
      externalApiCall: () =>
        FirebasePaymentInitiation.getAccountsInstitutions(),
      loadingHandler: setIsLoadingInstitutions,
      responseHandler: (response) => {
        if (response.data.data) {
          setInstitutions(response.data.data);
        }
      },
    });
  }, []);

  useEffect(() => {
    let unsubscribe: () => void;

    if (accountAuthResponse) {
      unsubscribe = FirebasePaymentInitiation.subscribeToObTransactionById({
        obTransactionId: accountAuthResponse.id,
        callback: setOpTransaction,
      });
    }

    return () => unsubscribe?.();
  }, [accountAuthResponse]);

  return {
    institutions,
    selectedInstitution,
    isRedirected,
    isLoadingInstitutions,
    opTransaction,

    accountAuthResponse,
    isLoadingAccountAuthUrl,

    onAccountAuthorisationInstitutionSelect,
    onUnselectInstitution,
    setIsRedirected,
  };
};

export default useOpenBankingAccounts;
