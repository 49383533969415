import { FC } from 'react';
import Slider from 'react-slick';
import { StaleCarouselWrapper } from './StaleCarousel.styles';
import { Subtitle, Title } from '../Typography/Typography';
import { Col } from '../Col/Col';
import { SLIDER_DATA, SLIDER_OPTIONS } from 'variables';
import { useTheme } from 'styled-components';

interface ISliderData {
  id: number;
  icon: string;
  title: string;
  text: string;
}

interface StaleCarouselProps {
  data?: ISliderData[];
}

const StaleCarousel: FC<StaleCarouselProps> = ({ data = SLIDER_DATA }) => {
  const theme = useTheme();

  return (
    <StaleCarouselWrapper>
      <Slider {...SLIDER_OPTIONS}>
        {data.map((elem) => (
          <div key={elem.id}>
            <Col mb alignItems="center">
              <svg
                width="200px"
                height="200px"
                style={{ fill: theme.color.white }}
              >
                <use xlinkHref={`#${elem.icon}`} />
              </svg>

              <Title mb color="white" variant="h3">
                {elem.title}
              </Title>
              <Subtitle color="greyLight_1">{elem.text}</Subtitle>
            </Col>
          </div>
        ))}
      </Slider>
    </StaleCarouselWrapper>
  );
};

export default StaleCarousel;
