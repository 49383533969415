import { Column, FilterTypes } from 'react-table';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Col, Paragraph, Row, Title, ParagraphWithEllipsis } from 'components';
import {
  camelCaseToSentenceCase,
  getPrebookStatusDetails,
  parseIntoCurrencyStringWithSymbol,
} from 'utils';
import { CONTRACT_STATUS, IRateContract, ICurrency } from 'types';
import { IExternalHedge } from 'types/externalHedges';
import { isExternalHedge } from './utils';
import {
  isRateContractAvailable,
  isRateContractExpired,
  isRateContractUsed,
} from 'utils/rateContracts';
import CurrencyRateCell from './components/CurrencyRateCell/CurrencyRateCell';
import ContextMenuCell from './components/ContextMenuCell/ContextMenuCell';
dayjs.extend(relativeTime);

export type TPrebookTableFilterValue =
  | 'default'
  | 'available'
  | 'expired'
  | 'used';

export const filterTypes: FilterTypes<IRateContract | IExternalHedge> = {
  filtering: (rows, _, filterValue) => {
    if (filterValue === 'default') {
      return rows;
    }

    if (filterValue === 'available') {
      return rows.filter(({ original }) => isRateContractAvailable(original));
    }

    if (filterValue === 'expired') {
      return rows.filter(({ original }) => isRateContractExpired(original));
    }

    if (filterValue === 'used') {
      return rows.filter(({ original }) => isRateContractUsed(original));
    }

    return rows;
  },
};

export const generatePrebookTableColumns = (
  currencyByCode: (currencyCode: string) => ICurrency | null,
  onRowClick?: (record: IRateContract | IExternalHedge) => void
): Column<IRateContract | IExternalHedge>[] => {
  return [
    {
      id: 'rate',
      Header: 'Currency rate',
      disableSortBy: true,
      Cell: ({ row }: any) => <CurrencyRateCell data={row.original} />,
      width: 100,
    },
    {
      id: 'filtering',
      filter: 'filtering',
      Header: 'Buy amount',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const isRecordExternalHedge = isExternalHedge(row.original);
        const {
          buyCurrency: buyCurrencyCode,
          remainingBuyAmount,
          buyAmount,
        } = row.original;

        const buyCurrency = currencyByCode(buyCurrencyCode);

        return (
          <Col>
            <Title as="span" style={{ lineHeight: '24px' }} variant="h3">
              {parseIntoCurrencyStringWithSymbol(
                buyAmount,
                buyCurrency?.symbol,
                buyCurrency?.precision
              )}
            </Title>

            {!isRecordExternalHedge && (
              <Paragraph>
                Unused:{' '}
                {parseIntoCurrencyStringWithSymbol(
                  remainingBuyAmount,
                  buyCurrency?.symbol,
                  buyCurrency?.precision
                )}
              </Paragraph>
            )}
          </Col>
        );
      },
      width: 100,
    },
    {
      accessor: 'sellAmount',
      filter: 'filtering',
      Header: 'Sell amount',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const isRecordExternalHedge = isExternalHedge(row.original);
        const {
          sellCurrency: sellCurrencyCode,
          totalAmount,
          remainingTotalAmount,
        } = row.original;
        const sellCurrency = currencyByCode(sellCurrencyCode);

        return (
          <Col>
            <Title as="span" style={{ lineHeight: '24px' }} variant="h3">
              {parseIntoCurrencyStringWithSymbol(
                totalAmount,
                sellCurrency?.symbol,
                sellCurrency?.precision
              )}
            </Title>

            {!isRecordExternalHedge && (
              <Paragraph>
                Unused:{' '}
                {parseIntoCurrencyStringWithSymbol(
                  remainingTotalAmount,
                  sellCurrency?.symbol,
                  sellCurrency?.precision
                )}
              </Paragraph>
            )}
          </Col>
        );
      },
      width: 100,
    },
    {
      id: 'use',
      Header: 'Use by',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const { status, expiryDate } = row.original;
        const expiryDateTime = dayjs().isSame(expiryDate) ? '23:59:59' : '';
        const isExpiredDate = dayjs().isAfter(expiryDate);

        return (
          <Paragraph>
            {`${
              status === CONTRACT_STATUS.expired || isExpiredDate
                ? '0 days'
                : camelCaseToSentenceCase(
                    dayjs().to(dayjs(`${expiryDate} ${expiryDateTime}`), true)
                  )
            } left | ${dayjs(expiryDate).format('DD.MM.YYYY')}`}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      id: 'status',
      Header: 'Status',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const isRecordExternalHedge = isExternalHedge(row.original);
        const statusInfo = getPrebookStatusDetails(row.original);
        const { invoiceId, status } = row.original;

        const showInvoiceStatus =
          status !== CONTRACT_STATUS.expired &&
          status !== CONTRACT_STATUS.cancelled;

        return (
          <>
            {!isRecordExternalHedge &&
              ((!invoiceId && statusInfo) || !showInvoiceStatus) && (
                <Row>
                  <Paragraph
                    className="status"
                    style={{ color: statusInfo?.color }}
                  >
                    {statusInfo?.text}
                  </Paragraph>
                </Row>
              )}

            {!isRecordExternalHedge && !!invoiceId && showInvoiceStatus && (
              <Row>
                <Paragraph className="status">
                  Prebooked for an invoice
                </Paragraph>
              </Row>
            )}

            {isRecordExternalHedge && (
              <Row>
                <Paragraph className="status">External hedge</Paragraph>
              </Row>
            )}
          </>
        );
      },
      width: 100,
    },
    {
      id: 'notes',
      Header: 'Notes',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <ParagraphWithEllipsis title={row.original.notes}>
          {row.original.notes}
        </ParagraphWithEllipsis>
      ),
      width: 100,
    },
    {
      id: 'reference',
      Header: 'Reference',
      disableSortBy: true,
      Cell: ({ row: { original: record } }: any) => {
        const isRecordExternalHedge = isExternalHedge(record);

        if (isRecordExternalHedge) {
          return null;
        }

        return (
          <Paragraph>
            {record?.bookingName
              ? `${record.bookingName}(${record._shortId})`
              : record._shortId}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      id: 'dropdown',
      disableSortBy: true,
      Header: () => null,
      Cell: ({ row }: any) => (
        <ContextMenuCell record={row.original} onRowClick={onRowClick} />
      ),
      width: 100,
    },
  ];
};
