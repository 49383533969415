import { FC } from 'react';
import { AwaitingRegistrationDashboardSectionWrapper } from '../../AwaitingRegistrationDashboard.styles';
import { Col, Title } from 'components';
import { useTheme } from 'styled-components';
import SuggestedPlans from 'components/shared/SuggestedPlans/SuggestedPlans';

const AwaitingRegistrationSuggestedPlans: FC = () => {
  const theme = useTheme();
  return (
    <Col gap={theme.spacing.m}>
      <Title variant="h4">Select your package</Title>

      <AwaitingRegistrationDashboardSectionWrapper>
        <SuggestedPlans withCashVisibilityItem />
      </AwaitingRegistrationDashboardSectionWrapper>
    </Col>
  );
};

export default AwaitingRegistrationSuggestedPlans;
