import styled, { css } from 'styled-components';
import { ResponsiveCenteredContainer } from 'components';

export const SignUpWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-top: 0;
    height: 100%;
    box-shadow: inset 0 80px 0 0 ${theme.color.dark};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      flex-direction: row;
    }

    ${ResponsiveCenteredContainer} {
      padding: 16px 16px 12px;
    }

    .rounded {
      background: ${theme.color.white};

      @media (max-width: ${theme.breakpoint.largeMax}px) {
        border-radius: 12px 12px 0 0;
        padding: 14px 0 0;
        box-shadow: 0 -3px 9px -3px rgba(0, 0, 0, 0.1);
      }
    }
  `
);
