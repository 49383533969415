import { useTheme } from 'styled-components';
import { Icon, Row, Subtitle } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';

interface OwnProps {
  currencyCode: string;
}

const CurrencyCell: FC<OwnProps> = ({ currencyCode }) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const currency = currencyByCode(currencyCode);

  if (!currency) {
    return null;
  }

  return (
    <Row gap={theme.spacing.s}>
      <Icon
        width={theme.iconSizes.l}
        height={theme.iconSizes.l}
        icon={currency.countryCode}
      />{' '}
      <Subtitle variant="bold">{currency.code}</Subtitle>
    </Row>
  );
};

export default CurrencyCell;
