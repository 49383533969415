import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Title } from 'components';
import Button from 'components/shared/Button/Button';
import { useTheme } from 'styled-components';
import ErrorPageTemplate from 'components/pageTemplates/ErrorPageTemplate/ErrorPageTemplate';

const PageNotFound: FC<RouteComponentProps> = ({ history }) => {
  const theme = useTheme();

  return (
    <ErrorPageTemplate>
      <Title>Page not found.</Title>

      <Button mt mtValue={theme.spacing.xxl} onClick={history.goBack}>
        Go back
      </Button>
    </ErrorPageTemplate>
  );
};

export default PageNotFound;
