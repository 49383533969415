import { CSSProperties, FC, ReactNode } from 'react';
import { Wrapper } from './StaleInputRadioNew.styles';

interface OwnProps {
  id: HTMLInputElement['id'];
  name?: string;
  onChange?: () => any;
  checked?: HTMLInputElement['checked'];
  value?: HTMLInputElement['value'];
  autoFocus?: HTMLInputElement['autofocus'];
  disabled?: HTMLInputElement['disabled'];
  label?: string | ReactNode;
  style?: CSSProperties;
}

const StaleInputRadioNew: FC<OwnProps> = ({
  id,
  name,
  onChange,
  checked,
  value,
  autoFocus,
  label,
  disabled,
  style,
}) => (
  <Wrapper style={style} disabled={disabled}>
    <input
      type="radio"
      id={id}
      name={name}
      checked={checked}
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      disabled={disabled}
    />
    <label htmlFor={id}>{label}</label>
  </Wrapper>
);

StaleInputRadioNew.defaultProps = {
  name: '',
  onChange: () => {},
};

export default StaleInputRadioNew;
