import { Col } from 'components/shared/Col/Col';
import InlineLoader from 'components/shared/InlineLoader/InlineLoader';
import InputBase from 'components/shared/InputBase/InputBase';
import { Paragraph, Subtitle } from 'components/shared/Typography/Typography';
import { FC } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { IRecipientValidationResult, Nullable } from 'types';
import { ERROR_MESSAGES } from 'variables';
import { AddContactInputs } from '../../types';
import { Row } from 'components/shared/Row/Row';
import Icon from 'components/shared/Icon/Icon';
import RadioButtons from 'components/shared/RadioButtons/RadioButtons';
import StaleSwitch from 'components/shared/StaleSwitch/StaleSwitch';

interface IOwnProps {
  validationResult: Nullable<IRecipientValidationResult>;
  validationError?: string;
  isValidationLoading: boolean;
  control: Control<any>;
  doesUserAgreeToProceedWithoutValidationMatch: boolean;
  errors: DeepMap<AddContactInputs, FieldError>;
  shouldAllowUpdatingName: boolean;
}

const ValidationSection: FC<IOwnProps> = ({
  validationResult,
  validationError,
  isValidationLoading,
  control,
  doesUserAgreeToProceedWithoutValidationMatch,
  errors,
  shouldAllowUpdatingName,
}) => {
  const theme = useTheme();

  if (isValidationLoading && !validationResult) {
    return (
      <Paragraph>
        Validating recipient data... <InlineLoader />
      </Paragraph>
    );
  }

  if (validationError) {
    return (
      <Col rowGap={theme.spacing.xs}>
        <Row
          justifyContent="flex-start"
          alignItems="center"
          columnGap={theme.spacing.s}
        >
          <Icon icon="warning-ico" fill="red" width={20} height={20} />
          <Subtitle variant="bold">Unable to verify account details</Subtitle>
        </Row>
        <Paragraph>
          We were unable to verify account details. We recommend you save draft
          contact and try again later.
        </Paragraph>
      </Col>
    );
  }

  const { matchScoreDescription, suggestedRecipientName } =
    validationResult ?? {};

  if (validationResult) {
    if (matchScoreDescription === 'Strong Match') {
      return (
        <Col rowGap={theme.spacing.xs}>
          <Row justifyContent="flex-start" alignItems="center">
            <Icon
              icon="checkbox-checkmark"
              fill="green"
              width={20}
              height={20}
            />
            <Subtitle variant="bold">Account verified</Subtitle>
          </Row>
          <Paragraph>
            The name you entered matches the name on the account.
          </Paragraph>
        </Col>
      );
    }

    if (matchScoreDescription === 'Partial Match') {
      return (
        <Col rowGap={theme.spacing.xs}>
          <Row
            justifyContent="flex-start"
            alignItems="center"
            columnGap={theme.spacing.s}
          >
            <Icon icon="warning-yellow-ico" width={20} height={20} />
            <Subtitle variant="bold">Account partially matched</Subtitle>
          </Row>
          <Paragraph>
            The name you entered is similar to the name on the account, but not
            an exact match. You can update the details and try again, or contact
            the person or business you're paying to check the details. If you
            continue without updating the details, you're accepting the risk
            that if the payment goes to the wrong account, we might not be able
            to get it back.
          </Paragraph>

          <Paragraph>
            Recipient account name: <b>{suggestedRecipientName}</b>
          </Paragraph>

          <Paragraph variant="bold">How do you want to proceed?</Paragraph>

          <Row>
            <Controller
              name="howToProceed"
              control={control}
              render={({ value, name, onChange }) => {
                const radioButtons = [
                  {
                    id: 'keepMyInstructions',
                    value: 'keepMyInstructions',
                    checked: value === 'keepMyInstructions',
                    name,
                    displayName: 'Keep my instructions',
                    onChange,
                  },
                ];

                if (shouldAllowUpdatingName && !!suggestedRecipientName) {
                  radioButtons.unshift({
                    id: 'updateAccountName',
                    value: 'updateAccountName',
                    checked: value === 'updateAccountName',
                    name,
                    displayName: 'Update account name',
                    onChange,
                  });
                }

                return <RadioButtons flex={1} data={radioButtons} />;
              }}
            />
          </Row>
        </Col>
      );
    }

    if (matchScoreDescription === 'Weak Match') {
      return (
        <Col rowGap={theme.spacing.xs}>
          <Row
            justifyContent="flex-start"
            alignItems="center"
            columnGap={theme.spacing.s}
          >
            <Icon icon="warning-ico" width={20} height={20} fill="red" />
            <Subtitle variant="bold">
              No match – the name you entered doesn't match the account
            </Subtitle>
          </Row>
          <Paragraph>
            You need to contact the person or company you are trying to pay to
            check their details. If you continue without updating the details,
            you're accepting the risk that if the payment goes to the wrong
            account, we might not be able to get it back.
          </Paragraph>

          <Controller
            name="doesUserAgreeToProceedWithWeakValidationMatch"
            control={control}
            rules={{ required: ERROR_MESSAGES.requiredField }}
            render={({ name, value, onChange }) => (
              <Row mt columnGap={theme.spacing.m} justifyContent="flex-start">
                <StaleSwitch
                  id={name}
                  isOn={value}
                  handleToggle={() => onChange(!value)}
                />
                <Subtitle variant="bold">
                  I understand the risks and took necessary steps
                </Subtitle>
              </Row>
            )}
          />

          {doesUserAgreeToProceedWithoutValidationMatch && (
            <Controller
              name="userExplanationForSaveWithWeakMatch"
              control={control}
              rules={{
                required: ERROR_MESSAGES.requiredField,
              }}
              render={({ value, onChange, name }) => (
                <InputBase
                  id={name}
                  value={value}
                  onChange={onChange}
                  error={
                    errors.userExplanationForSaveWithWeakMatch?.message
                  }
                  placeholder="Describe the steps you've taken to verify recipient details"
                />
              )}
            />
          )}
        </Col>
      );
    }
  }

  return null;
};

export default ValidationSection;
