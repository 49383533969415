import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Paragraph, ParagraphProps } from 'components/shared';
import Icon, { IconProps } from 'components/shared/Icon/Icon';

interface SidebarLinkIconProps {
  isIconsOnly: boolean;
  active: boolean;
}

export const SidebarMenuItemLink = styled(Link)<SidebarLinkIconProps>`
  display: flex;
  align-items: center;

  padding: ${({ theme }) => `${theme.spacing.s} 0 ${theme.spacing.l}`};
  transition: ${({ theme }) => theme.transition};
`;

export const SidebarMenuIcon = styled(Icon)<IconProps & SidebarLinkIconProps>`
  fill: ${({ theme, active }) =>
    active ? theme.color.emeraldDark : theme.color.white};
  margin-right: ${({ theme, isIconsOnly }) =>
    isIconsOnly ? 0 : theme.spacing.s};
  transition: ${({ theme }) => theme.transition};
  ${SidebarMenuItemLink}:hover & {
    fill: ${({ theme }) => theme.color.emeraldDark};
  } ;
`;

export const SidebarMenuParagraph = styled(Paragraph)<
  ParagraphProps & SidebarLinkIconProps
>`
  font-size: ${({ isIconsOnly, theme }) =>
    isIconsOnly ? 0 : theme.fontSize.s};
  line-height: ${({ isIconsOnly, theme }) =>
    isIconsOnly ? theme.fontSize.xs : '21px'};
  color: ${({ theme, active }) =>
    active ? theme.color.emeraldDark : theme.color.white};
  transition: ${({ theme }) => theme.transition};
  ${SidebarMenuItemLink}:hover & {
    color: ${({ theme }) => theme.color.emeraldDark};
  } ;
`;
