import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IOpenBankingInstitution } from 'types';
import { Col, Subtitle, Paragraph, Row, Loader } from 'components';
import { StyledOpenBankingInstitutionImage } from 'components/shared/FundingBulkPayment/FundingBulkPayment.styles';

interface IOwnProps {
  institution?: IOpenBankingInstitution;
}

const OpenBankingInstitutionTile: FC<IOwnProps> = ({ institution }) => {
  const theme = useTheme();

  return (
    <Row>
      {!institution && <Loader size="medium" />}

      {!!institution && (
        <>
          <StyledOpenBankingInstitutionImage
            src={
              institution.media?.find((item) => item.type === 'icon')?.source
            }
            alt={institution.name}
          />

          <Col ml gap={theme.spacing.xs}>
            <Paragraph textAlign="center">
              Payment run will be initiated directly from your bank account (via
              Open Banking):
            </Paragraph>

            <Subtitle variant="bold">{institution.name}</Subtitle>
          </Col>
        </>
      )}
    </Row>
  );
};

export default OpenBankingInstitutionTile;
