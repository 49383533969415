import { FC, useMemo, useState } from 'react';
import {
  canUseRateContract,
  getInvoiceDetailsLink,
  getInvoiceManualUploadLink,
  getTransfersPageLink,
} from 'utils';
import { Row } from 'components/shared/Row/Row';
import { useHistory } from 'react-router';
import { isExternalHedge } from '../../utils';
import { CONTRACT_STATUS, IExternalHedge, IRateContract } from 'types';
import {
  useAllocatePrebooksPermissionsCheck,
  useCreateTransfersPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';
import ContextMenu from 'components/shared/ContextMenu/ContextMenu';
import PopupExtendDueDate from 'components/shared/PopupExtendDueDate/PopupExtendDueDate';

interface IOwnProps {
  record: IRateContract | IExternalHedge;
  onRowClick?: (record: IRateContract | IExternalHedge) => void;
}

const ContextMenuCell: FC<IOwnProps> = ({ record, onRowClick }) => {
  const history = useHistory();
  const hasAllocatePrebooksPermission = useAllocatePrebooksPermissionsCheck();
  const hasCreateTransfersPermission = useCreateTransfersPermissionsCheck();
  const [showExtendDueDate, setShowExtendDueDate] = useState(false);

  const isRecordExternalHedge = isExternalHedge(record);

  const dropdownItems = useMemo(() => {
    const dropdownItemsToReturn: any[] = [];

    if (!!onRowClick) {
      dropdownItemsToReturn.push({
        id: 'details',
        title: 'Details',
        onClick: () => onRowClick(record),
      });
    }

    if (!isRecordExternalHedge) {
      const invoiceId = record.invoiceId;
      const isExtendable =
        record.status !== CONTRACT_STATUS.expired &&
        record.status !== CONTRACT_STATUS.used &&
        record.status !== CONTRACT_STATUS.cancelled;

      if (isExtendable) {
        dropdownItemsToReturn.push({
          id: 'extendDueDate',
          title: 'Extend Due Date',
          onClick: () => setShowExtendDueDate(true),
        });
      }

      if (
        hasCreateTransfersPermission &&
        canUseRateContract({
          rateContract: record,
          hasAllocatePrebooksPermission,
          invoiceId,
        })
      ) {
        dropdownItemsToReturn.push({
          id: 'transferNow',
          title: 'Transfer now',
          onClick: () => {
            history.push(
              getTransfersPageLink({
                predefinedBuyAmount: record.remainingBuyAmount.toString(),
                predefinedBuyCurrency: record.buyCurrency,
                predefinedRateContractId: record.id,
              })
            );
          },
        });
      }

      if (!!invoiceId) {
        dropdownItemsToReturn.push({
          id: 'invoiceDetails',
          title: 'Invoice details',
          onClick: () => {
            history.push(getInvoiceDetailsLink({ invoiceId }));
          },
        });
      }
    }

    if (isRecordExternalHedge) {
      dropdownItemsToReturn.push({
        id: 'edit',
        title: 'Edit',
        onClick: () => {
          history.push(
            getInvoiceManualUploadLink({
              tab: 'hedges',
              dataType: 'externalHedge',
              buyCurrency: record.buyCurrency,
              currency: record.sellCurrency,
              externalHedgeToEditId: record.id,
            })
          );
        },
      });
    }

    return dropdownItemsToReturn;
  }, [
    record,
    hasAllocatePrebooksPermission,
    hasCreateTransfersPermission,
    history,
    isRecordExternalHedge,
    onRowClick,
  ]);

  return (
    <Row justifyContent="flex-end" flex={1}>
      <ContextMenu list={dropdownItems} portal />

      {!isRecordExternalHedge && showExtendDueDate && (
        <PopupExtendDueDate
          selectedRateContract={record}
          onClose={() => setShowExtendDueDate(false)}
        />
      )}
    </Row>
  );
};

export default ContextMenuCell;
