import AmountCell from 'components/shared/AmountCell/AmountCell';
import { CellProps, Column } from 'react-table';
import { IGroupedInvoicesFromSearch, Nullable } from 'types';
import GroupedContactCell from './components/GroupedContactCell/GroupedContactCell';
import GroupedDoActionCell from './components/GroupedDoActionCell/GroupedDoActionCell';

export const generateTableColumns = ({
  tab,
  entityCurrencyCode,
  onViewDetails,
}: {
  tab: string | null;
  entityCurrencyCode: Nullable<string>;
  onViewDetails: (groupedInvoices: IGroupedInvoicesFromSearch) => void;
}): Column<IGroupedInvoicesFromSearch>[] => {
  const actionCells: Column<IGroupedInvoicesFromSearch>[] = [
    {
      id: 'toDo',
      disableSortBy: true,
      Cell: ({ row }: CellProps<IGroupedInvoicesFromSearch>) => (
        <GroupedDoActionCell
          tab={tab}
          data={row.original}
          onViewDetails={onViewDetails}
        />
      ),
      width: 100,
      minWidth: 100,
    },
  ];

  return [
    {
      id: 'contact_recipient_name',
      accessor: 'key',
      Header: 'Name',
      Cell: ({
        row: {
          original: { key, docsCount, results },
        },
      }: CellProps<IGroupedInvoicesFromSearch>) => (
        <GroupedContactCell
          value={key}
          docsCount={docsCount}
          invoices={results}
        />
      ),
      width: 180,
      minWidth: 150,
    },
    {
      id: 'amount',
      Header: tab === 'paid' ? 'Paid Amount' : 'Amount Due',
      Cell: ({ row }: CellProps<IGroupedInvoicesFromSearch>) => {
        const currencyCode = row.original.results[0].currency;
        return (
          <AmountCell
            size="large"
            amount={row.original.totalAmountDueOrTotal}
            currencyCode={currencyCode}
          />
        );
      },
      width: 120,
      minWidth: 100,
    },
    {
      id: entityCurrencyCode ?? undefined,
      Header: `${entityCurrencyCode ?? ''} Value`,
      Cell: ({ row }: CellProps<IGroupedInvoicesFromSearch>) => {
        // all results belong to the same recipient, so currency and rate are the same
        const currencyCode = row.original.results[0].currency;
        const rateToUse = row.original.results[0].invoiceRate;
        return (
          <AmountCell
            amount={
              currencyCode
                ? row.original.totalAmountDueOrTotal / (rateToUse ?? 1)
                : 0
            }
            currencyCode={entityCurrencyCode}
          />
        );
      },
      width: 120,
      minWidth: 100,
    },
    ...(tab !== 'paid' ? actionCells : []),
  ];
};
