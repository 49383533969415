import styled, { CSSProperties, DefaultThemeColorKey } from 'styled-components';
import { Col } from '../Col/Col';

const Card = styled(Col)<{
  color?: DefaultThemeColorKey;
  padding?: CSSProperties['padding'];
  position?: CSSProperties['position'];
  flex?: CSSProperties['flex'];
  alignSelf?: CSSProperties['alignSelf'];
  shadow?: CSSProperties['boxShadow'];
}>`
  background: ${({ theme, color = 'white' }) => theme.color[color]};
  padding: ${({ theme, padding = theme.spacing.xl }) => padding};
  box-shadow: ${({ theme, shadow }) => shadow || theme.shadow};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  flex-direction: ${({ flexDirection }) => flexDirection};
  position: ${({ position }) => position};
  flex: ${({ flex }) => flex};
  align-self: ${({ alignSelf }) => alignSelf};
`;

export default Card;
