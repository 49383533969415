import { FC, useEffect, useState, InputHTMLAttributes } from 'react';
import awesomeDebouncePromise from 'awesome-debounce-promise';

import StaleInput, { InputProps } from '../StaleInput/StaleInput';
import { BankInfoWrapper } from './StaleInputIban.styles';
import { useStoreActions } from 'state';
import { Paragraph } from '../Typography/Typography';

interface OwnProps extends InputProps {
  autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus'];
  withBankDetails?: boolean;
  onGetBankDataCallback?: (bankData: any) => any;
  swiftCountryCode?: string;
}

const StaleInputIban: FC<OwnProps> = ({
  autoFocus = false,
  rules,
  withBankDetails = false,
  onGetBankDataCallback,
  control,
  validateOnMount = false,
  swiftCountryCode,
  error,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [bankData, setBankData] = useState<any>(null);

  const { checkRecipientBankDetails } = useStoreActions(
    (actions) => actions.RecipientsState
  );

  useEffect(() => {
    const value = control?.getValues('accountNumber');

    if ((value && validateOnMount) || validateOnMount) {
      control?.trigger('accountNumber');
    }

    return () => {
      setBankData(null);
      onGetBankDataCallback?.(null);
    };
  }, [validateOnMount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const value = control?.getValues('accountNumber');

    if (
      value &&
      swiftCountryCode &&
      (bankData?.bankCountry_ISO !== swiftCountryCode || error)
    ) {
      control?.trigger('accountNumber');
    }
  }, [swiftCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StaleInput
        control={control}
        isLoading={isLoading}
        isDone={isDone}
        onChangeCallback={() => {
          setIsDone(false);
          setBankData(null);
        }}
        error={error}
        autoFocus={autoFocus}
        rules={{
          ...rules,
          validate: awesomeDebouncePromise(async (value: string) => {
            try {
              if (!value && !rules?.required) {
                return true;
              }

              const noWhiteSpaceValue = value.replaceAll(' ', '');
              if (noWhiteSpaceValue !== value) {
                control?.setValue('accountNumber', noWhiteSpaceValue);
              }

              setIsDone(false);
              setBankData(null);
              setIsLoading(true);

              const response = await checkRecipientBankDetails({
                type: 'iban',
                value: noWhiteSpaceValue,
              });

              setIsLoading(false);

              if (
                swiftCountryCode &&
                swiftCountryCode !== response?.bankCountry_ISO
              ) {
                return 'BIC / SWIFT country and IBAN country do not match.';
              }

              if (response?.success) {
                setBankData(response);
                onGetBankDataCallback && onGetBankDataCallback(response);
                setIsDone(true);

                return true;
              } else {
                return 'IBAN is not valid';
              }
            } catch (error: any) {
              return 'Failed to check IBAN, please try again';
            }
          }, 1000),
        }}
        {...restProps}
      />
      {withBankDetails && isDone && bankData && (
        <BankInfoWrapper>
          <Paragraph>
            {bankData.bankName && <b>{`${bankData.bankName}, `}</b>}
            {bankData.bankCity && `${bankData.bankCity}`}
          </Paragraph>
        </BankInfoWrapper>
      )}
    </>
  );
};

export default StaleInputIban;
