import { FC } from 'react';
import { Paragraph } from 'components';
import { ITransaction } from 'types/transactions';
import { getTransactionStatusDetails } from 'utils/transactions';

interface OwnProps {
  transactionStatus: ITransaction['status'];
}

const TransactionStatusCell: FC<OwnProps> = ({ transactionStatus }) => {
  const transactionStatusDetails = getTransactionStatusDetails(
    transactionStatus
  );

  if (!transactionStatusDetails) {
    return <Paragraph>-</Paragraph>;
  }

  return (
    <Paragraph style={{ color: transactionStatusDetails.color }}>
      {transactionStatusDetails.text}
    </Paragraph>
  );
};

export default TransactionStatusCell;
