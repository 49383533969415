import { FC } from 'react';
import { Paragraph, Title, Row, Icon } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { Nullable } from 'types';

interface OwnProps {
  amount?: number;
  currencyCode: Nullable<string>;
  withFlag?: boolean;
  withCurrencyPrecision?: boolean;
  size?: 'medium' | 'large';
  withMinusSign?: boolean;
}

const AmountCell: FC<OwnProps> = ({
  amount = 0,
  currencyCode,
  withFlag,
  withCurrencyPrecision = true,
  size = 'medium',
  withMinusSign = false,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(currencyCode);

  const generateCellFlag = () => {
    if (!currency || !withFlag) {
      return null;
    }

    if (size === 'large') {
      return (
        <Icon
          width={theme.iconSizes.xl}
          height={theme.iconSizes.xl}
          icon={currency.countryCode}
        />
      );
    }

    return <Icon icon={currency.countryCode} />;
  };

  const generateCellAmount = () => {
    const precisionToUse = withCurrencyPrecision ? currency?.precision : 0;

    if (size === 'large') {
      return (
        <Title variant="h4">
          {withMinusSign && '-'}
          {parseIntoCurrencyStringWithSymbol(
            amount,
            currency?.symbol,
            precisionToUse
          )}
        </Title>
      );
    }

    return (
      <Paragraph>
        {withMinusSign && '-'}
        {parseIntoCurrencyStringWithSymbol(
          amount,
          currency?.symbol,
          precisionToUse
        )}
      </Paragraph>
    );
  };

  return (
    <Row gap={theme.spacing.xs}>
      {generateCellFlag()}
      {generateCellAmount()}
    </Row>
  );
};

export default AmountCell;
