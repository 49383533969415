import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Button, Col, Loader, Paragraph, Popup, Row, Title } from 'components';
import InstitutionsList from './components/InstitutionsList/InstitutionsList';
import InstitutionTile from './components/InstitutionTile/InstitutionTile';
import {
  IOpenBankingInstitution,
  IOpenBankingPaymentAuthResponseData,
} from 'types';
import Link from 'components/shared/Link/Link';

interface OwnProps {
  onClose: () => void;
  institutions: IOpenBankingInstitution[];
  selectedInstitution?: IOpenBankingInstitution;
  paymentAuthResponse?: IOpenBankingPaymentAuthResponseData;
  isLoadingPaymentAuthUrl?: boolean;
  isLoadingInstitutions: boolean;
  setIsRedirected: React.Dispatch<React.SetStateAction<boolean>>;
  onPaymentAuthorisationInstitutionSelect: (
    institution: IOpenBankingInstitution
  ) => Promise<void>;
  onUnselectInstitution: () => void;
}

const FundingOpenBankingPopup: FC<OwnProps> = ({
  institutions,
  selectedInstitution,
  paymentAuthResponse,
  isLoadingPaymentAuthUrl,
  isLoadingInstitutions,
  setIsRedirected,
  onPaymentAuthorisationInstitutionSelect,
  onUnselectInstitution,
  onClose,
}) => {
  const theme = useTheme();

  const onAuthorisePayment = (authorisationUrl: string) => {
    window.open(authorisationUrl, '_blank');
    setIsRedirected(true);
  };

  const renderContent = () => {
    if (isLoadingInstitutions || isLoadingPaymentAuthUrl) {
      return (
        <Row mt mtValue={theme.spacing.xxxl} justifyContent="center">
          <Loader size="large" />
        </Row>
      );
    }

    if (!!selectedInstitution && !!paymentAuthResponse) {
      return (
        <InstitutionTile
          selectedInstitution={selectedInstitution}
          paymentAuthResponse={paymentAuthResponse}
        />
      );
    }

    return (
      <InstitutionsList
        institutions={institutions}
        onInstitutionSelect={onPaymentAuthorisationInstitutionSelect}
        isLoadingInstitutions={isLoadingInstitutions}
        isLoadingPaymentAuthUrl={isLoadingPaymentAuthUrl}
      />
    );
  };

  return (
    <Popup
      HeaderContent={
        <Title variant="h3">
          {!selectedInstitution && !paymentAuthResponse
            ? 'Select your bank'
            : 'Consent your transfer'}
        </Title>
      }
      FooterContent={
        !!selectedInstitution && !!paymentAuthResponse ? (
          <Col gap={theme.spacing.m}>
            <Paragraph>
              By clicking 'Continue' you agree that Yapily Connect Ltd can
              initiate this payment for you in accordance with their{' '}
              <Link
                href="https://www.yapily.com/legal/end-user-terms"
                target="_blank"
                rel="noreferrer"
                display="inline"
              >
                Terms & Conditions
              </Link>{' '}
              and{' '}
              <Link
                href="https://www.yapily.com/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
                display="inline"
              >
                Privacy Notice
              </Link>
              .
            </Paragraph>

            <Row gap={theme.spacing.m}>
              <Button
                flex={1}
                onClick={() =>
                  onAuthorisePayment(paymentAuthResponse.authorisationUrl)
                }
              >
                Continue
              </Button>
              <Button
                flex={1}
                variant="secondary"
                onClick={onUnselectInstitution}
              >
                Cancel
              </Button>
            </Row>
          </Col>
        ) : null
      }
      width="439px"
      height="788px"
      onClose={onClose}
    >
      {renderContent()}
    </Popup>
  );
};

export default FundingOpenBankingPopup;
