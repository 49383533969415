import styled, { css } from 'styled-components';

import { Paragraph } from '../Typography/Typography';
import { Row } from '../Row/Row';
import { Col } from '../Col/Col';

interface RecipientProps {
  isSelected?: boolean;
  isDisabled?: boolean;
}

export const StaleRecipientWrapper = styled.div<RecipientProps>(
  ({ theme, isSelected = false, isDisabled = false }) => css`
    border: 1px solid ${theme.color.greyLight_1};
    background: ${theme.color.white};
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    padding: 16px 16px 12px;

    ${isSelected &&
    css`
      background: ${theme.color.emeraldLight};
    `}

    ${isDisabled &&
    css`
      background: ${theme.color.greyLight_1};
    `}
  
      &:not(:last-of-type) {
      margin-bottom: 12px;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    ${Row} {
      margin-bottom: 7px;
      justify-content: space-between;
    }

    .bank-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 190px;
      margin-right: 15px;
      overflow: hidden;
    }

    ${Col} {
      flex: 0 0 80%;
      max-width: 80%;
    }
  `
);

export const TitleParagraph = styled(Paragraph)`
  padding-left: 32px;
  line-height: 24px;
  margin-bottom: 15px;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  svg {
    position: absolute;
    top: 14px;
    left: 16px;
  }
`;

export const CurrencyParagraph = styled(Paragraph)`
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    min-width: 24px;
  }
`;
