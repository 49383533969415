import { riskMonthlyBreakdownContentTypes } from './consts';
import { TRisksMonthlyBreakdownContentType } from './types';
import { TRisksDataPeriod } from 'pages/Risks/types';

export const getMonthlyBreakdownTabs = (
  isEntityCurrency: boolean,
  isAllCurrencies: boolean,
  period: TRisksDataPeriod | null
) => {
  let tabsToReturn: Array<{
    id: TRisksMonthlyBreakdownContentType;
    title: string;
  }> = [
    {
      id: 'cashflows',
      title: 'Cashflows',
    },
  ];

  if (!isEntityCurrency && period !== 'week') {
    tabsToReturn = [
      ...tabsToReturn,
      {
        id: 'hedgeRatio',
        title: 'Hedge Ratios',
      },
    ];
  }

  if (!isEntityCurrency && !isAllCurrencies && period !== 'week') {
    tabsToReturn = [
      ...tabsToReturn,
      {
        id: 'rates',
        title: 'Rates',
      },
    ];
  }

  return tabsToReturn;
};

export const isRiskMonthlyBreakdownContentType = (
  contentType: string | null
): contentType is TRisksMonthlyBreakdownContentType => {
  return (
    contentType !== null &&
    riskMonthlyBreakdownContentTypes.includes(
      contentType as TRisksMonthlyBreakdownContentType
    )
  );
};
