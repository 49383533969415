import { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IInvoice, IInvoiceFromSearch } from 'types';
import { generateInvoiceTableColumns } from './utils';
import Table from '../Table/Table';
import { TableProps } from '../Table/types';
import { getInvoiceDetailsLink } from 'utils';

interface OwnProps
  extends Pick<
    TableProps<IInvoice | IInvoiceFromSearch>,
    'isVirtualized' | 'data'
  > {
  data: IInvoice[] | IInvoiceFromSearch[];
}

const TransferInvoicesTable: FC<OwnProps> = ({ data, isVirtualized }) => {
  const history = useHistory();

  const goToInvoice = useCallback(
    (record) =>
      history.push(
        getInvoiceDetailsLink({
          invoiceId: record.id,
        })
      ),
    [history]
  );

  const invoiceTableColumns = useMemo(
    () => generateInvoiceTableColumns(goToInvoice),
    [goToInvoice]
  );

  return (
    <Table<IInvoice | IInvoiceFromSearch>
      onRowClick={goToInvoice}
      data={data}
      columns={invoiceTableColumns}
      sortable
      isVirtualized={isVirtualized}
      defaultRowHeight={48}
    />
  );
};
export default TransferInvoicesTable;
