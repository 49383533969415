import { useStoreState } from 'state';
import { IEntityAccountDetailsType } from 'types';

interface IDataFields {
  [key: string]: string;
}

interface IEntityAccountDetailsData {
  order: number;
  accountDetailsType: IEntityAccountDetailsType;
  title: string;
  data: IDataFields;
}

const insertInOrder = (
  total: IEntityAccountDetailsData[],
  newItem: IEntityAccountDetailsData
) => {
  const index = total.findIndex((entry) => entry.order > newItem.order);
  if (index === -1) {
    total.push(newItem);
  } else {
    total.splice(index, 0, newItem);
  }
};

const useEntityAccountDetails = () => {
  const { entityAccountDetails } = useStoreState((state) => state.UserState);
  const { countryByCode } = useStoreState((state) => state.ReferenceDataState);

  return entityAccountDetails.reduce<IEntityAccountDetailsData[]>(
    (total, item) => {
      const accountHolder: IDataFields = {
        'Account holder': item.accountHolder,
      };
      const bankData: IDataFields =
        item.type === IEntityAccountDetailsType.priority ||
        item.type === IEntityAccountDetailsType.ach
          ? {
              Bank: item.bankName,
              'Bank address': item.bankAddress,
              'Bank country':
                countryByCode(item.bankCountry)?.name ?? item.bankCountry,
            }
          : {};

      switch (item.type) {
        case IEntityAccountDetailsType.priority:
          insertInOrder(total, {
            order: 1,
            accountDetailsType: item.type,
            title: 'For SWIFT payments in 30+ currencies',
            data: {
              ...accountHolder,
              IBAN: item.iban,
              BIC: item.bic,
              ...bankData,
            },
          });
          break;

        case IEntityAccountDetailsType.regular:
          insertInOrder(total, {
            order: 2,
            accountDetailsType: item.type,
            title: 'For fast payments in GBP',
            data: {
              ...accountHolder,
              'Account number': item.accountNumber,
              'Sort Code': item.sortCode,
            },
          });
          break;

        case IEntityAccountDetailsType.regularSwift:
          insertInOrder(total, {
            order: 3,
            accountDetailsType: item.type,
            title: 'For SEPA Payments in EUR',
            data: { ...accountHolder, IBAN: item.iban },
          });
          break;

        case IEntityAccountDetailsType.ach:
          insertInOrder(total, {
            order: 4,
            accountDetailsType: item.type,
            title: 'For ACH payments',
            data: {
              ...accountHolder,
              'Account number': item.accountNumber,
              'ACH Routing Number': item.routingNumber,
              ...bankData,
            },
          });
          break;

        default:
          return total;
      }

      return total;
    },
    []
  );
};

export default useEntityAccountDetails;
