import styled from 'styled-components';
import { Row } from 'components';

export const FollowedCurrenciesWrapper = styled(Row)`
  flex: 1;
  overflow-x: auto;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.s};
  padding: ${({ theme }) => `0 ${theme.spacing.m} ${theme.spacing.m}`};
  margin: ${({ theme }) => `0 -${theme.spacing.m} 0`};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    overflow-x: unset;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    gap: ${({ theme }) => theme.spacing.m};
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.xxs};
  right: ${({ theme }) => theme.spacing.xxs};
`;
