import styled, { css } from 'styled-components';
import { Overflow } from 'components';
import { isMobile } from 'react-device-detect';
import { StyledButton } from '../Button/Button.styles';

export const Wrapper = styled.div<{ showMore: boolean }>(
  ({ theme, showMore }) => css`
    border-radius: 8px;
    padding: ${isMobile ? '14px 16px 18px' : '18px 16px 14px 16px'};
    background: ${theme.color.emeraldLight};
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${showMore
      ? css`
          transition: height ${theme.transition} ${theme.transition} linear;
          height: 56px;

          .bottom {
            transition: opacity ${theme.transition} 0s linear;
            opacity: 0;
            height: 0;
          }
        `
      : css`
          transition: height ${theme.transition} 0s linear;
          height: 116px;

          .bottom {
            transition: opacity ${theme.transition} ${theme.transition} linear;
          }
        `}

    ${StyledButton} {
      position: absolute;
      right: 16px;
      bottom: 14px;

      svg {
        transition: ${theme.transition};
      }
    }

    &:not(:last-child) {
      margin-bottom: ${isMobile && '18px'};
    }

    .bottom {
      display: flex;
      align-items: flex-end;
      margin-top: auto;
      max-width: 80%;
      gap: ${theme.spacing.l};

      button {
        min-width: 70px;
        margin-left: auto;
      }

      svg {
        transition: fill ${theme.transition}, transform 0.2s;
      }
    }
  `
);

export const WrapperContent = styled.div<{ showMore: boolean }>(
  ({ theme, showMore }) => css`
    border: 2px solid
      ${showMore ? theme.color.emeraldLight : theme.color.transparent};
    transition: ${theme.transition};
    overflow: auto;
    border-radius: 0 0 8px 8px;
    box-shadow: ${showMore ? '0 -8px 0 0 #DBF4EB' : '0 0px 0 -8px #DBF4EB'};
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 33px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        ${theme.color.white} 100%
      );
    }

    .content:first-of-type {
      padding-top: 8px;
    }

    &:not(:last-child) {
      margin-bottom: ${!isMobile && '18px'};
    }

    ${Overflow} {
      height: ${!isMobile && 'auto'};
      flex: ${!isMobile && '0 0 auto'};
      overflow: ${!isMobile && 'visible'};
    }
  `
);
