import styled, { css, CSSProperties } from 'styled-components';
import { StyledButton } from '../Button/Button.styles';

export const Wrapper = styled.div<{
  error: boolean;
  disabled: boolean;
  withLabel: boolean;
  inputHeight?: CSSProperties['height'];
}>(
  ({ theme, error, disabled, withLabel, inputHeight = '52px' }) => css`
    color: ${theme.color.dark};
    font-size: 14px;
    position: relative;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      cursor: pointer;
    }

    select {
      opacity: 0;
      height: 0;
      position: absolute;
      z-index: -1;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .placeholder {
      font-weight: 400;
    }

    .input {
      padding: 2px 48px 0 16px;
      width: 100%;
      background: ${theme.color.greyLight_3};
      border-radius: 6px;
      position: relative;
      height: ${inputHeight};
      border: 1px solid ${theme.color.transparent};
      display: flex;
      align-items: center;
      font-weight: 500;
      box-shadow: ${error && `inset 0 0 0 1px ${theme.color.red}`};
      color: ${({ theme }) => disabled && theme.color.grey};

      ${error &&
      css`
        color: ${theme.color.red};
      `}

      .i-arrow {
        position: absolute;
        right: 8px;
        transition: ${theme.transition};
      }

      span {
        display: flex;
        align-items: center;

        svg {
          margin: -3px 8px 0 0;
        }
      }

      &.disabled {
        cursor: default;
      }
    }

    &.focused .input {
      border-color: ${theme.color.emeraldDark};
    }

    .list {
      position: fixed;
      z-index: 11;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 76px 16px 0;
      background: ${theme.color.white};
      overflow: auto;
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        margin: 0 -16px;

        &.selected {
          background: ${theme.color.greyLight_3};
        }

        .tick {
          position: absolute;
          right: 16px;
          top: 0;
          bottom: 0;
          margin: auto;
          fill: ${theme.color.emeraldDark};
        }

        &:last-of-type {
          margin-bottom: 20px;
        }
      }

      ${StyledButton} {
        margin: auto 0 16px;
      }

      svg {
        margin-right: 8px;
      }
    }

    &.show {
      border-color: ${theme.color.emeraldDark};

      .list {
        height: auto;
        opacity: 1;
      }

      .i-arrow {
        transform: rotate(180deg);
      }

      .input {
        box-shadow: inset 0 0 0 1px ${theme.color.emeraldDark};
      }
    }

    .label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      max-width: 100%;
      display: block;
      margin-bottom: 4px;

      @media (min-width: 1025px) and (min-height: 568px) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .error {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin: 3px 0;
      color: ${theme.color.red};
    }

    &.moving {
      .label {
        position: absolute;
        z-index: 1;
        padding: 0 16px;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        cursor: text;
        font-size: 14px;
        transition: ${theme.transition};
        color: ${error ? theme.color.red : theme.color.greyDark};
        font-weight: normal;
      }

      &.filled,
      &.focused {
        .label {
          top: 0;
          font-size: 12px;
        }

        .input {
          span {
            margin-top: ${withLabel ? '16px' : ''};
          }
        }
      }
    }
  `
);
