import Button from 'components/shared/Button/Button';
import { Col } from 'components/shared/Col/Col';
import Popup from 'components/shared/Popup/Popup';
import RadioButton from 'components/shared/RadioButton/RadioButton';
import { Row } from 'components/shared/Row/Row';
import {
  Paragraph,
  Subtitle,
  Title,
} from 'components/shared/Typography/Typography';
import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { errorHandler } from 'utils/errors';

interface IOwnProps {
  onClose: () => void;
  onSubmit: (values: {
    recipientName?: string;
    currency?: string;
  }) => Promise<void> | void;
  existingRecipientName: string;
  existingCurrency: string;
  newRecipientName: string;
  newCurrency?: string;
  disableRecipientName: boolean;
  disableCurrency: boolean;
}

const ImportConfirmationPopup: FC<IOwnProps> = ({
  onClose,
  onSubmit,
  existingRecipientName,
  existingCurrency,
  newRecipientName,
  newCurrency = '',
  disableRecipientName,
  disableCurrency,
}) => {
  const theme = useTheme();
  const [recipientNameToUse, setRecipientNameToUse] = useState(
    existingRecipientName
  );
  const [currencyToUse, setCurrencyToUse] = useState(existingCurrency);

  const shouldShowRecipientNameSubmissionVariant =
    !disableRecipientName &&
    existingRecipientName &&
    newRecipientName &&
    existingRecipientName !== newRecipientName;
  const shouldShowCurrencySubmissionVariant =
    !disableCurrency &&
    existingCurrency &&
    newCurrency &&
    existingCurrency !== newCurrency;

  return (
    <Popup
      HeaderContent={<Title variant="h4">Confirmation</Title>}
      FooterContent={
        <Row flex={1}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              const payload: {
                recipientName?: string;
                currency?: string;
              } = {};

              if (recipientNameToUse) {
                payload.recipientName = recipientNameToUse;
              }

              if (currencyToUse) {
                payload.currency = currencyToUse;
              }

              const result = onSubmit(payload);

              if (result instanceof Promise) {
                result.catch(errorHandler);
              }

              onClose();
            }}
          >
            Continue
          </Button>
        </Row>
      }
      onClose={onClose}
    >
      <Col rowGap={theme.spacing.xl}>
        <Paragraph>
          The recipient name or currency does not match. How do you want to
          continue?
        </Paragraph>

        {shouldShowRecipientNameSubmissionVariant && (
          <Col rowGap={theme.spacing.m}>
            <Subtitle variant="bold">Recipient name</Subtitle>
            <RadioButton
              id="useExistingRecipientName"
              displayName={existingRecipientName}
              value={existingRecipientName}
              onChange={() => setRecipientNameToUse(existingRecipientName)}
              checked={recipientNameToUse === existingRecipientName}
            />
            <RadioButton
              id="useNewRecipientName"
              displayName={newRecipientName}
              value={newRecipientName}
              onChange={() => {
                setRecipientNameToUse(newRecipientName);
              }}
              checked={recipientNameToUse === newRecipientName}
            />
          </Col>
        )}

        {shouldShowCurrencySubmissionVariant && (
          <Col rowGap={theme.spacing.m}>
            <Subtitle variant="bold">Currency</Subtitle>
            <RadioButton
              id="useExistingCurrency"
              displayName={existingCurrency}
              value={existingCurrency}
              onChange={() => setCurrencyToUse(existingCurrency)}
              checked={currencyToUse === existingCurrency}
            />
            <RadioButton
              id="useNewCurrency"
              displayName={newCurrency}
              value={newCurrency}
              onChange={() => {
                setCurrencyToUse(newCurrency);
              }}
              checked={currencyToUse === newCurrency}
            />
          </Col>
        )}
      </Col>
    </Popup>
  );
};

export default ImportConfirmationPopup;
