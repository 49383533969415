import {
  FC,
  useEffect,
  useRef,
  useCallback,
  useState,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useTheme } from 'styled-components';
import Tabs from './components/Tabs/Tabs';
import Controls from './components/Controls/Controls';
import { Loader, IntegrationSettings } from 'components';
import { useStoreState } from 'state';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import InvoicesTableShared from 'components/shared/InvoicesTable/InvoicesTable';
import { IInvoiceFromSearch } from 'types';
import { getSortQueryStringFromTableSort } from 'utils/search';
import { getInvoicesFilters } from 'pages/Invoices/utils';
import { isEqual } from 'lodash';
import { SortingRule } from 'react-table';
import useInvoices from 'hooks/useInvoices';
import useUrlValues from 'hooks/useUrlValues';
import { IntegrationWrapper } from 'pages/Invoices/Invoices.styles';
import { ExposedUseTableProps } from 'components/shared/Table/types';
import { IInvoicesTableActions } from 'pages/Invoices/components/InvoicesTable/types';
import ReceivedFundingsTable from './components/ReceivedFundingsTable/ReceivedFundingsTable';
import useFundingsToCollect from 'hooks/useFundingsToCollect';

const InvoicesToCollect: FC = () => {
  const theme = useTheme();
  const { hasInvoices, isLoadingHasInvoicesFlag } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const { isIntegrated } = useIntegrationEngine();
  const {
    currency,
    tab,
    search,
    filter,
    dueDateFrom,
    dueDateTo,
    fullyPaidOnDateFrom,
    fullyPaidOnDateTo,
  } = useUrlValues(
    'currency',
    'tab',
    'search',
    'filter',
    'dueDateFrom',
    'dueDateTo',
    'fullyPaidOnDateFrom',
    'fullyPaidOnDateTo'
  );
  const [isLoadingFirstInvoicesPage, setIsLoadingFirstInvoicesPage] = useState(
    true
  );
  const [sortState, setSortState] = useState<SortingRule<IInvoiceFromSearch>[]>(
    []
  );
  const tableRef = useRef<ExposedUseTableProps<IInvoiceFromSearch>>(null);
  const invoicesTableActionsRef = useRef<IInvoicesTableActions>();
  const {
    invoices,
    currentPage,
    hasMoreToLoad,
    isLoadingMoreInvoices,
    fetchInvoicesWithSideEffects,
    updateInMemoryInvoices,
  } = useInvoices();
  const {
    fundings,
    isLoading: isLoadingFundingsToCollect,
    getFundingsToCollectHandler,
  } = useFundingsToCollect();

  const fundingsToDisplay = useMemo(
    () =>
      fundings.filter((funding) => {
        if (!currency || currency === 'all') {
          return true;
        }

        return currency === funding.currency;
      }),
    [currency, fundings]
  );

  const fetchFirstInvoicesPage = useCallback(async () => {
    if (!tab || tab === 'received') {
      return;
    }

    const filters = getInvoicesFilters({
      tab,
      filterName: filter,
      currency,
      dueDateFrom,
      dueDateTo,
      fullyPaidOnDateFrom,
      fullyPaidOnDateTo,
    });
    const sortFields = getSortQueryStringFromTableSort(sortState);

    tableRef.current?.toggleAllRowsSelected(false);

    fetchInvoicesWithSideEffects({
      page: 1,
      searchQuery: search ?? '',
      filters,
      sortFields,
      reset: true,
    }).finally(() => {
      setIsLoadingFirstInvoicesPage(false);
    });
  }, [
    tab,
    filter,
    currency,
    dueDateFrom,
    dueDateTo,
    fullyPaidOnDateFrom,
    fullyPaidOnDateTo,
    sortState,
    fetchInvoicesWithSideEffects,
    search,
  ]);

  useEffect(() => {
    fetchFirstInvoicesPage();
  }, [fetchFirstInvoicesPage]);

  const handleSortChange = useCallback(
    (tableSortState: SortingRule<IInvoiceFromSearch>[]) => {
      if (isEqual(tableSortState, sortState)) {
        return;
      }

      setSortState(tableSortState);
    },
    [sortState]
  );

  const onLoadMoreItems = useCallback(async () => {
    if (isLoadingMoreInvoices) {
      return;
    }

    return fetchInvoicesWithSideEffects({
      page: currentPage + 1,
      searchQuery: search ?? '',
      filters: getInvoicesFilters({
        tab,
        filterName: filter,
        currency,
        dueDateFrom,
        dueDateTo,
        fullyPaidOnDateFrom,
        fullyPaidOnDateTo,
      }),
      sortFields: getSortQueryStringFromTableSort(sortState),
    });
  }, [
    currency,
    currentPage,
    dueDateFrom,
    dueDateTo,
    fetchInvoicesWithSideEffects,
    filter,
    fullyPaidOnDateFrom,
    fullyPaidOnDateTo,
    isLoadingMoreInvoices,
    search,
    sortState,
    tab,
  ]);

  useImperativeHandle(
    invoicesTableActionsRef,
    () => ({
      updateInMemoryInvoices,
      fetchFirstInvoicesPage,
    }),
    [fetchFirstInvoicesPage, updateInMemoryInvoices]
  );

  if (tab !== 'received' && isLoadingHasInvoicesFlag) {
    return <Loader size="large" />;
  }

  if (isIntegrated || hasInvoices) {
    return (
      <>
        <TableHeader flexDirection="column" gap={theme.spacing.m}>
          <Tabs
            invoicesTableActionsRef={invoicesTableActionsRef}
            fundingsCount={fundingsToDisplay.length}
          />
          <Controls />
        </TableHeader>

        {((tab === 'receivables' && isLoadingFirstInvoicesPage) ||
          (tab === 'received' && isLoadingFundingsToCollect)) && (
          <Loader size="large" />
        )}

        {!isLoadingFirstInvoicesPage && tab === 'receivables' && (
          <InvoicesTableShared
            isVirtualized
            tableRef={tableRef}
            data={invoices as IInvoiceFromSearch[]}
            itemsCount={invoices.length}
            withInfiniteLoading
            loadingThreshold={10}
            onSort={handleSortChange}
            hasMoreToLoad={hasMoreToLoad}
            onLoadMoreItems={onLoadMoreItems}
            isLoadingMoreItems={isLoadingMoreInvoices}
            updateInMemoryInvoices={updateInMemoryInvoices}
            manualSortBy
            autoResetSelectedRows={false}
            selectable={false}
          />
        )}
        {!isLoadingFundingsToCollect && tab === 'received' && (
          <ReceivedFundingsTable
            data={fundingsToDisplay}
            getFundingsToCollectHandler={getFundingsToCollectHandler}
          />
        )}
      </>
    );
  }

  return (
    <IntegrationWrapper>
      <IntegrationSettings />
    </IntegrationWrapper>
  );
};

export default InvoicesToCollect;
