import styled from 'styled-components';
import { Row } from '../Row/Row';

export const DirectionSellWrapper = styled(Row)`
  justify-content: flex-start;

  .pay-ico {
    margin-right: 4px;
    fill: ${({ theme }) => theme.color.red};

    &.get {
      fill: ${({ theme }) => theme.color.green};
      transform: rotate(180deg);
    }
  }
`;
