import { useStoreState } from 'state';
import { useEffect, useState } from 'react';
import { getTaxRates as getIntegrationEngineTaxRates } from 'services/firebase/integrationEngine';
import { IIntegrationSettingsTaxRate, TPlatformNames } from 'types';
import { errorHandler } from 'utils/errors';
import { callExternalApiWithLoading } from 'utils/fetchers';
import { ISelectItem } from '../StaleInputSelect/StaleInputSelect';

const useIntegrationGetTaxRates = (platformName: TPlatformNames) => {
  const [isLoadingTaxRates, setIsLoadingTaxRates] = useState(false);
  const { entityId } = useStoreState((state) => state.UserState);
  const [taxRates, setTaxRates] = useState<
    Array<ISelectItem<IIntegrationSettingsTaxRate>>
  >();
  const [savedTaxRateForBankFees, setSavedTaxRateForBankFees] = useState<
    ISelectItem<IIntegrationSettingsTaxRate>
  >();
  const { integrationEngineSettings } = useStoreState(
    ({ UserState }) => UserState
  );

  const areTaxRatesRequired = platformName === 'Dynamics 365 Business Central';

  // get list of tax rates
  useEffect(() => {
    const getTaxRates = async () => {
      if (!entityId) {
        console.warn(`entityId is undefined`);
        return undefined;
      }

      return callExternalApiWithLoading({
        externalApiCall: () => getIntegrationEngineTaxRates(entityId),
        responseHandler: (response) =>
          setTaxRates(
            response.data?.data?.map((taxRate) => ({
              id: taxRate.id,
              name: taxRate.code,
              value: taxRate,
            }))
          ),
        loadingHandler: setIsLoadingTaxRates,
        errorHandler,
      });
    };

    if (areTaxRatesRequired) {
      getTaxRates();
    }
  }, [areTaxRatesRequired, entityId]);

  useEffect(() => {
    if (areTaxRatesRequired) {
      // get existing set tax rate if it exists to use as a defaultValue
      if (integrationEngineSettings.taxRateForBankFees) {
        const taxRate = integrationEngineSettings.taxRateForBankFees;
        setSavedTaxRateForBankFees({
          id: taxRate.id,
          name: taxRate.code,
          value: taxRate,
        });
      }
    }
  }, [areTaxRatesRequired, integrationEngineSettings?.taxRateForBankFees]);

  return {
    isLoadingTaxRates,
    taxRates,
    areTaxRatesRequired,
    savedTaxRateForBankFees,
  };
};

export default useIntegrationGetTaxRates;
