import styled, { css } from 'styled-components';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';

export const FlowStepWrapper = styled(Col)`
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.m};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.m};
  flex: 1;
  align-self: stretch;
  overflow-y: auto;
`;

export const FlowStepContent = styled(Col)`
  padding: ${({ theme }) => theme.spacing.m};
  gap: ${({ theme }) => theme.spacing.xl};
  flex: 1;
  align-self: stretch;
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex: unset;
    align-self: unset;
    overflow-y: overlay;
    height: 490px;
    flex-direction: row;
    gap: ${({ theme }) => `${theme.spacing.xxl}`};
    padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.xxl}`};
  }
`;

export const FlowStepFooter = styled(Row)<{ isFinalStep?: boolean }>`
  padding: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.color.white};
  border-top: ${({ theme }) => `1px solid ${theme.color.greyLight_2}`};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.m};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.m};
  justify-content: flex-start;
  align-self: stretch;

  @media (max-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    ${({ isFinalStep }) =>
      isFinalStep &&
      css`
        flex-direction: column;
        margin-bottom: ${({ theme }) => theme.spacing.xxxl}; ;
      `}
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    min-height: ${({ theme }) => theme.minFlowStepFooterHeight};
    padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xxl}`};
  }
`;

export const FlowContentLeft = styled(Col)`
  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex: 1;
    align-self: flex-start;
  }
`;

export const FlowContentRight = styled(Col)`
  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex: 0.75;
    align-self: flex-start;
  }
`;
