import { ISummaryItem } from 'components/shared/SummaryTable/types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import {
  IInvoice,
  IResponse,
  ITransfer,
  TTransactionWithWriteDetails,
} from 'types';
import { IAccountsTableDataItem } from 'types/bff';
import { IPlatformEventsSearchResult } from 'types/events';
import { generateSearchQuery } from 'utils';

export const getAccountsTableData = () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IAccountsTableDataItem[]>>(
    'bff/accounts'
  );

export const getAssetEvents = (assetId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IPlatformEventsSearchResult[]>>(
    `bff/events/${assetId}`
  );

export const getTransferInfo = (transferId: string) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<{
      transfer: ITransfer;
      invoices: IInvoice[];
    }>
  >(`bff/transfers/${transferId}`);

export const getCashflowSummaries = (shouldFetchForAllEntities: boolean) =>
  AxiosPrivateFirebaseInstance.get<IResponse<ISummaryItem[]>>(
    `bff/cashflows/summary${shouldFetchForAllEntities ? '?all=true' : ''}`
  );

export const retryWriteEventsByTransactionId = (transactionId: string) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `bff/transactions/${transactionId}/retry`
  );

/**
 * Here, write event details do not exactly match those from the collection,
 * and a computed sometime aggregate value.
 *
 * Note that transactions to do not have a 1-2-1 mapping with write events.
 * Some do, like writing priority transfer fees.
 *
 * Others such as transfers have a different representation in the write event.
 */
export const getTransactionsAugmentWithWriteEventDetails = (limit?: number) => {
  const qs = generateSearchQuery({ limit });

  return AxiosPrivateFirebaseInstance.get<
    IResponse<TTransactionWithWriteDetails[]>
  >(`/bff/transactions${qs}`);
};
