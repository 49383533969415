import {
  Col,
  Row,
  Subtitle,
  Paragraph,
  Icon,
  Loader,
  TextHint,
} from 'components';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IPaymentRunCurrencyTotal } from 'types/paymentRuns';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';

import {
  IOpenBankingInstitution,
  IOpenBankingPaymentAuthResponseData,
} from 'types';
import {
  StyledOpenBankingInstitutionImage,
  StyledOpenBankingQRCol,
  StyledOpenBankingQRImage,
} from './FundingBulkPayment.styles';
import OpenBankingRedirectResponse from 'components/shared/OpenBankingRedirectResponse/OpenBankingRedirectResponse';

interface IOwnProps {
  isFundingInitiated?: boolean;
  isRedirected?: boolean;
  institution?: IOpenBankingInstitution;
  paymentAuthResponse?: IOpenBankingPaymentAuthResponseData;
  totals?: IPaymentRunCurrencyTotal[];
  setIsRedirected: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectInstitution: (institution: IOpenBankingInstitution) => Promise<void>;
  QRCodeMaxWidth?: string;
  showQRCodeText?: boolean;
  isLoading?: boolean;
}

const FundingBulkPayment: FC<IOwnProps> = ({
  isFundingInitiated,
  isRedirected,
  institution,
  paymentAuthResponse,
  totals,
  setIsRedirected,
  onSelectInstitution,
  QRCodeMaxWidth,
  showQRCodeText = false,
  isLoading,
}) => {
  const theme = useTheme();

  if (isLoading || (!isFundingInitiated && !isRedirected && !institution)) {
    return (
      <Row mt mtValue={theme.spacing.l} mb mbValue={theme.spacing.l}>
        <Loader size="large" />
      </Row>
    );
  }

  return (
    <Row
      mb
      mbValue={theme.spacing.l}
      gap={theme.spacing.l}
      alignItems="flex-start"
    >
      {!isFundingInitiated && !isRedirected && institution && (
        <>
          <Row>
            <StyledOpenBankingInstitutionImage
              src={
                institution.media?.find((item) => item.type === 'icon')?.source
              }
              alt={institution.name}
            />

            <Col ml gap={theme.spacing.xs}>
              <Subtitle variant="bold">{institution.name}</Subtitle>

              <Paragraph>
                You will be redirected to the {institution.name} securely to
                complete payment
              </Paragraph>
            </Col>
          </Row>

          {!!paymentAuthResponse?.qrCodeUrl && (
            <StyledOpenBankingQRCol maxWidth={QRCodeMaxWidth} flex={1}>
              <StyledOpenBankingQRImage
                src={paymentAuthResponse.qrCodeUrl}
                alt={institution.name}
              />
              {showQRCodeText && (
                <TextHint mt mtValue={theme.spacing.xs} textAlign="center">
                  Scan with camera to continue on smartphone
                </TextHint>
              )}
            </StyledOpenBankingQRCol>
          )}
        </>
      )}

      {!isFundingInitiated && isRedirected && institution && (
        <Col mb mbValue={theme.spacing.xxxl} flex={1}>
          <OpenBankingRedirectResponse
            onRetry={() => {
              onSelectInstitution(institution);
              setIsRedirected(false);
            }}
          />
        </Col>
      )}

      {isFundingInitiated && !!paymentAuthResponse && (
        <Col mb mbValue={theme.spacing.xxxl} flex={1}>
          <Row gap={theme.spacing.xs} mt justifyContent="flex-start">
            <Icon icon="done-ico" />
            <Paragraph>
              Payments are being made to all recipients by {institution?.name}.
            </Paragraph>
          </Row>
        </Col>
      )}

      {!!totals && (
        <Col alignItems="flex-start" alignSelf="stretch">
          <InvoicesCurrencyTotals
            title="Payment amount:"
            currencyTotals={totals}
          />
          <Row mt alignSelf="stretch">
            <Subtitle variant="bold">Beneficiary:</Subtitle>
            <Paragraph>Multiple recipients</Paragraph>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default FundingBulkPayment;
