import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form7';

import { Overflow, Col, Title, Paragraph, Row, Icon } from 'components';
import { Firebase } from 'services';
import { useStoreActions, useStoreState } from 'state';
import { Notify, getSubstractedEmail } from 'utils';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';
import Field from 'components/shared/Field/Field.styles';
import { useTheme } from 'styled-components';
import { Controller } from 'react-hook-form7';
import InputBase from 'components/shared/InputBase/InputBase';

type Inputs = {
  verificationCode: string;
};

interface OwnProps {
  email: string;
  password: string;
  onContinueHandler: () => void;
}

const StepTwo: FC<OwnProps> = ({ email, password, onContinueHandler }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const theme = useTheme();

  const { userId, user } = useStoreState((state) => state.UserState);
  const { createAuthUser, signOut } = useStoreActions(
    (actions) => actions.UserState
  );

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<Inputs>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (!userId) {
      createAuthUser({
        email,
        password,
      });
    }
  }, [createAuthUser, email, password, userId]);

  useEffect(() => {
    if (userId) {
      Firebase.generateVerificationCode();
    }
  }, [userId]);

  const onSubmit = async (values: any) => {
    setIsLoading(true);

    const verificationResponse = await Firebase.verifyVerificationCode({
      code: values.verificationCode.trim(),
    });

    if (!verificationResponse?.success) {
      Notify.error(
        verificationResponse?.message || 'Verification code is not valid.'
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(false);

    onContinueHandler();
  };

  return (
    <Overflow>
      <Col alignItems="flex-start">
        <Button
          variant="link"
          onClick={() => {
            signOut();
            history.push('/');
          }}
        >
          <Icon icon="arrow-left" />
          Change account
        </Button>

        <Title mt>Please confirm your email</Title>

        <Paragraph mt mb>
          {`We’ve just sent you an email with verification code to
                  ${getSubstractedEmail(email ? email : user?.email)} `}
        </Paragraph>

        <form
          style={{ display: 'flex', alignSelf: 'stretch', flex: 1 }}
          id="verification-code-form"
        >
          <Field fluid mb>
            <Controller<Inputs>
              control={control}
              name="verificationCode"
              rules={{
                required: ERROR_MESSAGES.requiredField,
              }}
              render={({ field: { onChange, value } }) => (
                <InputBase
                  value={value}
                  onChange={onChange}
                  autoFocus
                  label="Verification code"
                />
              )}
            />
          </Field>
        </form>

        <Row>
          <Paragraph>Haven’t received the code? </Paragraph>
          <Button
            ml
            mlValue={theme.spacing.xxs}
            type="button"
            variant="link"
            onClick={async (event) => {
              event.preventDefault();

              if (email || user?.email) {
                await Firebase.generateVerificationCode();

                Notify.info('Verification code has been resent.');
              }
            }}
          >
            Resend
          </Button>
        </Row>

        <Row alignSelf="stretch" mt>
          <Button
            form="verification-code-form"
            disabled={!isValid || isLoading}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            flex={1}
          >
            Continue
          </Button>
        </Row>
      </Col>
    </Overflow>
  );
};

export default StepTwo;
