import { Button, Paragraph, ParagraphWithEllipsis } from 'components';
import { FC, useState } from 'react';
import { IPastPerformanceItem } from 'types';
import PopupInvoice from './components/PopupInvoice/PopupInvoice';

interface IOwnProps {
  item: IPastPerformanceItem;
}

const CashflowCell: FC<IOwnProps> = ({ item }) => {
  const isInvoice = item.recordType === 'invoice';
  const [isShowInvoicePopup, setIsShowInvoicePopup] = useState(false);
  const valueToDisplay = item.reference ?? 'no ref';

  return (
    <>
      <ParagraphWithEllipsis title={item.recordId} maxWidth="100px">
        {isInvoice ? (
          <Button variant="link" onClick={() => setIsShowInvoicePopup(true)}>
            {valueToDisplay}
          </Button>
        ) : (
          <Paragraph>{valueToDisplay}</Paragraph>
        )}
      </ParagraphWithEllipsis>
      {isShowInvoicePopup && (
        <PopupInvoice
          invoiceId={item.recordId}
          onClose={() => setIsShowInvoicePopup(false)}
        />
      )}
    </>
  );
};

export default CashflowCell;
