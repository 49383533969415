import { useEffect, useState } from 'react';
import { init as onfidoInit, StepConfig, StepTypes } from 'onfido-sdk-ui';

import {
  InvalidLinkWrapper,
  Wrapper,
  CenteredTitle,
} from './IdVerification.styles';
import { Overflow } from 'components';

import { useParams } from 'react-router-dom';
import { AxiosPublicFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import { Notify } from 'utils';

const stepsWithPhoto: (StepTypes | StepConfig)[] = [
  {
    type: 'welcome',
    options: {
      title: 'Onboarding - Verification',
      descriptions: [
        'In order to prevent fraud and financial crime we need to check your identity through a simple online process.',
      ],
      nextButton: 'Verify Your Identity',
    },
  },
  'document',
  'face',
  'complete',
];

const stepsWithoutPhoto = [
  {
    type: 'welcome',
    options: {
      title: 'Onboarding - Verification',
      descriptions: [
        'In order to prevent fraud and financial crime we need to check your identity through a simple online process.',
      ],
      nextButton: 'Verify Your Identity',
    },
  },
  'document',
  'complete',
];

const IdVerification = ({ history }: any) => {
  const [isInvalid, setIsInvalid] = useState(false);
  // @ts-expect-error TS(2339) FIXME: Property 'applicantId' does not exist on type '{}'... Remove this comment to see the full error message
  const { applicantId, entityId } = useParams();

  useEffect(() => {
    const onfidoTrigger = async (entityId: string, applicantId: string) => {
      try {
        if (applicantId) {
          const { data } = await AxiosPublicFirebaseInstance.post<IResponse>(
            `/idverification/${entityId}/${applicantId}/token`
          );

          if (data.success) {
            // @ts-expect-error TS(2339) FIXME: Property 'requiresPhotoCheck' does not exist on ty... Remove this comment to see the full error message
            const steps = data.requiresPhotoCheck
              ? stepsWithPhoto
              : stepsWithoutPhoto;

            onfidoInit({
              useModal: false,
              // @ts-expect-error TS(2339) FIXME: Property 'token' does not exist on type 'IResponse... Remove this comment to see the full error message
              token: data.token,
              containerId: 'onfido-verification-container',
              onComplete: function () {
                AxiosPublicFirebaseInstance.post<IResponse>(
                  `/idverification/${entityId}/${applicantId}/submit`
                )
                  .then(() => {
                    setTimeout(() => {
                      history.replace('/idverification/next-step');
                    }, 5000);
                  })
                  .catch(() => {
                    Notify.error('Failed to submit Onfido ID verification.');
                  });
              },
              // @ts-expect-error TS(2322) FIXME: Type '(StepTypes | StepConfig)[] | (string | { typ... Remove this comment to see the full error message
              steps,
            });
          } else {
            setIsInvalid(true);
          }
        }
      } catch (error: any) {
        setIsInvalid(true);
      }
    };

    if (applicantId && entityId) {
      onfidoTrigger(entityId, applicantId);
    }
  }, [entityId, applicantId, history]);

  useEffect(() => {
    if (isInvalid) {
      setTimeout(() => {
        history.replace('/');
      }, 5000);
    }
  }, [isInvalid, history]);

  return (
    <Wrapper>
      <Overflow>
        <div
          className="block"
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            padding: 0,
            backgroundColor: 'rgb(242, 242, 242)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            id="onfido-verification-container"
          >
            {isInvalid ? (
              <InvalidLinkWrapper>
                <CenteredTitle>
                  Verification link is invalid. <br />
                  Please contact support if the error persists.
                </CenteredTitle>
              </InvalidLinkWrapper>
            ) : null}
          </div>
        </div>
      </Overflow>
    </Wrapper>
  );
};

export default IdVerification;
