export const AUTOMATION_PACKAGE_ADVANCES = [
  'Payment runs in 30 currencies',
  'Role-based approval process',
  'Schedule up to 3 months in advance',
  'Auditable trial',
];

export const CASH_VISIBILITY_ADVANCES = [
  'Bank balances & transactions in real-time',
  'Consolidate across entities & currencies',
  'Generate 13 week plan',
  'Variance analysis',
];

export const RISK_PACKAGE_ADVANCES = [
  'Live data from your accounting/ERP',
  'Quantify foreign exchange risks',
  'Manage and track FX hedges',
  'Automated reports and alerts',
];
