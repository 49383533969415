import { AxiosPrivateFirebaseInstance } from 'settings';
import {
  IResponse,
  IOpenBankingPaymentAuthResponseData,
  IOpenBankingInstitution,
  IOpenBankingTransaction,
  TOpenBankingAccountMappingData,
  IOpenBankingAccount,
  IOpenBankingIntegration,
  IOpenBankingSettings,
} from 'types';
import { openDoc } from 'utils';
import db from 'services/firestore';

export const getPaymentsInstitutions = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IOpenBankingInstitution[]>>(
    `/openbanking/payments/institutions`
  );

export const getPaymentsInstitutionById = async (institutionId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IOpenBankingInstitution>>(
    `/openbanking/payments/institutions/${institutionId}`
  );

export const getAccountsInstitutions = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IOpenBankingInstitution[]>>(
    `/openbanking/accounts/institutions`
  );

export const getAccountsIntegrations = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IOpenBankingIntegration[]>>(
    `/openbanking/accounts/integrations`
  );

export const deleteAccountsIntegration = async (integrationId: string) =>
  AxiosPrivateFirebaseInstance.delete<IResponse<IOpenBankingIntegration>>(
    `/openbanking/accounts/integrations/${integrationId}`
  );

// gets a url that can be used to get a consent token
export const postPaymentAuthorisation = async (payload: {
  assetId: string;
  institutionId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<
    IResponse<IOpenBankingPaymentAuthResponseData>
  >(`/openbanking/payments/auth`, payload);

export const postAccountAuthorisation = async ({
  institutionId,
}: {
  institutionId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<
    IResponse<IOpenBankingPaymentAuthResponseData>
  >(`/openbanking/accounts/auth`, { institutionId });

export const getOpenBankingAccounts = async (transactionID: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IOpenBankingAccount[]>>(
    `/openbanking/accounts/balances?transaction-id=${transactionID}`
  );

export const getOpenBankingAccountsMapping = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<TOpenBankingAccountMappingData[]>>(
    `/openbanking/accounts/mapping`
  );

export interface IOpenBankingAccountMappingInstructions {
  externalAccountingSystemId: string;
  openBankingAccountId: string;
}

export const postOpenBankingAccountMappingInstructions = async (
  payload: IOpenBankingAccountMappingInstructions[]
) =>
  AxiosPrivateFirebaseInstance.post<IResponse<any[]>>(
    `/openbanking/accounts/mapping`,
    payload
  );

export interface SubscribeToObTransactionByIdParams {
  obTransactionId: string;
  callback: (obTransaction: IOpenBankingTransaction | null) => void;
}

export const subscribeToObTransactionById = ({
  obTransactionId,
  callback,
}: SubscribeToObTransactionByIdParams) =>
  db
    .collection('obTransactions')
    .doc(obTransactionId)
    .onSnapshot((doc) => callback(openDoc(doc)));

export const getOpenBankingSettings = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IOpenBankingSettings>>(
    `/openbanking/settings`
  );

export const postDefaultInstitutionIdToOpenBankingSettings = async (payload?: {
  defaultInstitutionId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IOpenBankingSettings>>(
    `/openbanking/settings`,
    payload
  );
