import { FC } from 'react';
import { Paragraph, StaleBtnGroup, StalePopup } from 'components';
import { Container } from '@nivo/core';
import Button from '../Button/Button';

interface OwnProps {
  title: string;
  text?: string;
  content?: JSX.Element;
  mainButtonText?: string;
  secondaryButtonText?: string;
  onCancel?: () => void;
  onContinue?: () => void;
  onClose: () => void;
  horizontal?: boolean;
  width?: string;
  isLoading?: boolean;
}

const StaleButtonsPopup: FC<OwnProps> = ({
  onCancel,
  onContinue,
  onClose,
  title,
  text,
  content,
  mainButtonText,
  secondaryButtonText,
  horizontal = true,
  width = 'auto',
  isLoading,
}) => (
  <StalePopup
    title={title}
    theme="small"
    width={width}
    minHeight="auto"
    onClose={onClose}
  >
    <Container>
      {text && <Paragraph>{text}</Paragraph>}
      {content}
      <StaleBtnGroup horizontal={horizontal} container={false}>
        {mainButtonText && (
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onContinue}
          >
            {mainButtonText}
          </Button>
        )}
        {secondaryButtonText && (
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={onCancel || onClose}
          >
            {secondaryButtonText}
          </Button>
        )}
      </StaleBtnGroup>
    </Container>
  </StalePopup>
);

export default StaleButtonsPopup;
