import { FC } from 'react';
import { TextHint, Col, Paragraph, Row } from 'components';
import { parseIntoCurrencyString, parseRateWithPrecision } from 'utils';
import { getRateHint } from './utils';

interface OwnProps {
  amount: number;
  rate: number;
  currencyCode: string;
  isRateIndicative?: boolean;
  isPrebooked?: boolean;
  withTitle?: boolean;
}

const AuthoriseGBPCostCell: FC<OwnProps> = ({
  amount,
  rate,
  currencyCode,
  isRateIndicative,
  isPrebooked,
  withTitle,
}) => (
  <Col>
    {withTitle && <Paragraph variant="bold">{currencyCode} Cost:</Paragraph>}
    <Paragraph variant="bold">
      {parseIntoCurrencyString(amount)} {currencyCode}
    </Paragraph>
    <Row>
      <TextHint>{`${parseRateWithPrecision(rate)} ${getRateHint({
        isRateIndicative,
        isPrebooked,
      })} rate`}</TextHint>
    </Row>
  </Col>
);

export default AuthoriseGBPCostCell;
