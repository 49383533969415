import { FC, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Title, Col, Row, FundingAccountCredentials, Button } from 'components';
import useUrlValues from 'hooks/useUrlValues';
import Popup from 'components/shared/Popup/Popup';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import TransferDetails from './components/TransferDetails/TransferDetails';
import RecipientDetails from './components/RecipientDetails/RecipientDetails';
import MT103Details from './components/MT103Details/MT103Details';
import { InnerDetailsWrapper } from '../InnerDetails/InnerDetails.styles';
import useTransferOrBulkPaymentInner from 'hooks/useTransferOrBulkPaymentInner';
import TransferOrBulkPaymentInnerInvoices from '../TransferOrBulkPaymentInnerInvoices/TransferOrBulkPaymentInnerInvoices';
import useBalance from 'hooks/useBalance';
import { IInvoice, ITransfer, TRANSFER_STATUS } from 'types';
import { getTransferInfo } from 'services/bff';
import { errorHandler } from 'utils/errors';
import { useStoreState } from 'state';
import useFundingAccountCredentialsActions from '../FundingAccountCredentials/useFundingAccountCredentialsActions';
import FundingAccountCredentialsActions from '../FundingAccountCredentialsActions/FundingAccountCredentialsActions';
import PopupAssetEvents from '../PopupAssetEvents/PopupAssetEvents';

interface IOwnProps {
  transferId?: string;
  onClose?: () => void;
}
const TransferPopup: FC<IOwnProps> = ({
  transferId: propsTransferId,
  onClose: propsOnClose,
}) => {
  const theme = useTheme();
  const { transferId: queryTransferId, setUrlValue } = useUrlValues(
    'transferId'
  );
  const [isLoadingTransferData, setIsLoadingTransferData] = useState(true);
  const [showEvents, setShowEvents] = useState(false);
  const [transfer, setTransfer] = useState<ITransfer | null>(null);
  const [transferInvoices, setTransferInvoices] = useState<IInvoice[]>([]);
  const { tabs, contentType, setContentType } = useTransferOrBulkPaymentInner({
    selectedTransferOrBulkPayment: transfer,
  });
  const { isLoading, isEnoughBalance, onPayWithBalance } = useBalance({
    sellCurrency: transfer?.sellCurrency,
    sellAmount: transfer?.payAmount,
  });

  const fundingAccountCredentialsActions = useFundingAccountCredentialsActions();
  const transferId = propsTransferId || queryTransferId;

  /**
   * Hack to force getTransferHandler when transfer status changes to * do remove after removing transfers subscription in useBootstrap
   */
  const { transferById } = useStoreState((state) => state.TransfersState);
  const transferFromState = transferById(transferId);

  const canBePaidWithBalance =
    isEnoughBalance &&
    transfer?.status === TRANSFER_STATUS.awaitingPayment &&
    !transfer.bulkPaymentId;

  const onClose = () => {
    if (queryTransferId) {
      setUrlValue({ transferId: '' });
    }
    propsOnClose?.();
  };

  const getTransferHandler = useCallback(async () => {
    if (!transferId) {
      return;
    }

    try {
      setIsLoadingTransferData(true);
      const { data } = await getTransferInfo(transferId);

      if (data.data) {
        setTransfer(data.data.transfer);
        setTransferInvoices(data.data.invoices);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoadingTransferData(false);
    }
  }, [transferId]);

  useEffect(() => {
    getTransferHandler();
  }, [getTransferHandler, transferFromState?.status]);

  if (!transfer) {
    return null;
  }

  const onPayWithBalanceHandler = () =>
    onPayWithBalance({
      payload: {
        currency: transfer.sellCurrency,
        amount: transfer.payAmount,
      },
    });

  return (
    <>
      <Popup
        HeaderContent={
          <Row flex={1} alignItems="flex-start">
            <Title variant="h3">Transfer</Title>
            <Button variant="link" mr onClick={() => setShowEvents(true)}>
              Show events
            </Button>
          </Row>
        }
        FooterContent={
          <FundingAccountCredentialsActions
            isFundingInitiated={
              transfer.status !== TRANSFER_STATUS.awaitingPayment
            }
            isLoading={isLoading}
            fundingAccountCredentialsActions={fundingAccountCredentialsActions}
            onPayWithBalanceHandler={onPayWithBalanceHandler}
            onSecondaryButtonClick={onClose}
            secondaryButtonText="Close"
          />
        }
        width="900px"
        height="800px"
        onClose={onClose}
      >
        <Col rowGap={theme.spacing.xl}>
          <InnerDetailsWrapper>
            <TransferDetails transfer={transfer} />
            <RecipientDetails transfer={transfer} />
          </InnerDetailsWrapper>

          <Row justifyContent="flex-start">
            {tabs.map(({ title, id }) => (
              <StepperItem
                onClick={() => setContentType(id)}
                key={id}
                current={id === contentType}
              >
                {title}
              </StepperItem>
            ))}
          </Row>

          {contentType === 'invoices' && (
            <TransferOrBulkPaymentInnerInvoices
              isLoading={isLoadingTransferData}
              selectedTransfer={transfer}
              relatedInvoices={transferInvoices}
            />
          )}

          {contentType === 'payment' &&
            transfer.status === TRANSFER_STATUS.awaitingPayment && (
              <FundingAccountCredentials
                assetId={transfer.id}
                sellCurrency={transfer.sellCurrency}
                canBePaidWithBalance={canBePaidWithBalance}
                fundingAccountCredentialsActions={
                  fundingAccountCredentialsActions
                }
              />
            )}

          {contentType === 'mt103' && <MT103Details transfer={transfer} />}
        </Col>
      </Popup>

      {showEvents && (
        <PopupAssetEvents
          assetId={transfer.id}
          onClose={() => setShowEvents(false)}
        />
      )}
    </>
  );
};

export default TransferPopup;
