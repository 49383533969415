import { FC, useState } from 'react';
import { Expansion, Paragraph, Row } from 'components';
import {
  BankRecipientItemButtonWrapper,
  BankRecipientItemWrapper,
} from './BankRecipientItem.styles';

interface BankRecipientItemProps {
  accountNumber: string;
  sortCode: string;
  recipientName: string;
  onClick: () => void;
  isSelected: boolean;
}

const BankRecipientItem: FC<BankRecipientItemProps> = ({
  accountNumber,
  sortCode,
  recipientName,
  isSelected,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <BankRecipientItemButtonWrapper type="button" onClick={onClick}>
      <BankRecipientItemWrapper isSelected={isSelected}>
        <Row justifyContent="space-between">
          <Paragraph variant="bold" as="span">
            {recipientName}
          </Paragraph>

          <svg
            onClick={(event) => {
              event.stopPropagation();
              setIsOpen(!isOpen);
            }}
            style={{ height: 20, width: 20 }}
          >
            <use xlinkHref={isOpen ? '#show-less-ico' : `#show-more-ico`} />
          </svg>
        </Row>

        <Expansion delay={false} isOpen={isOpen}>
          <Row>
            <Paragraph>Account number:</Paragraph>
            <Paragraph variant="bold" as="span">
              {accountNumber}
            </Paragraph>
          </Row>

          <Row>
            <Paragraph>Sort code:</Paragraph>
            <Paragraph variant="bold" as="span">
              {sortCode}
            </Paragraph>
          </Row>
        </Expansion>
      </BankRecipientItemWrapper>
    </BankRecipientItemButtonWrapper>
  );
};

export default BankRecipientItem;
