import { Paragraph, Row, Icon } from 'components';
import { NameRow } from '../Contacts.styles';

import { Column, FilterTypes } from 'react-table';
import { IContact, RECIPIENT_TYPE } from 'types';
import { getCountryCodeByCurrency } from 'utils';
import EmailCell from '../components/EmailCell/EmailCell';
import ContextMenuCell from '../components/ContextMenuCell/ContextMenuCell';
import ContactReviewStatus from 'components/shared/ContactReviewStatus/ContactReviewStatus';

export const filterTypes: FilterTypes<IContact> = {
  text: (rows, _, filterValue) => {
    if (!filterValue) {
      return rows;
    }

    return rows.filter((row) => {
      const value = row.original;

      if (
        value.recipientName?.toLowerCase().includes(filterValue.toLowerCase())
      ) {
        return true;
      }

      return false;
    });
  },
};

export const generateTableColumns = ({
  setShowDeleteRecipient,
  setRecipientIdForDelete,
  countryByCode,
  currencies,
  setRecipientForEdit,
  setIsAddRecipient,
}: any): Column<IContact>[] => {
  return [
    {
      accessor: 'recipientName',
      Header: 'Company name',
      Cell: ({ row }) => {
        const { recipientEntityType, recipientName } = row.original;
        return (
          <NameRow
            onClick={() => {
              setIsAddRecipient(true);
              setRecipientForEdit(row.original);
            }}
          >
            <Icon
              icon={
                recipientEntityType === RECIPIENT_TYPE.company
                  ? 'briefcase-ico'
                  : 'user-ico'
              }
            />

            <Paragraph>{recipientName}</Paragraph>
          </NameRow>
        );
      },
      width: 100,
    },
    {
      accessor: 'currency',
      Header: 'Currency',
      Cell: ({ value }) => (
        <Row>
          <Icon icon={`${getCountryCodeByCurrency(value, currencies)}`} />
          <Paragraph ml>{value}</Paragraph>
        </Row>
      ),
      width: 60,
    },
    {
      accessor: 'contactEmail',
      Header: 'Email',
      Cell: ({ row }) => <EmailCell record={row.original} />,
      width: 100,
    },
    {
      accessor: 'accountCountry',
      Header: 'Account country',
      disableSortBy: true,
      Cell: ({ value }) => (
        <Paragraph>{countryByCode(value)?.name || '-'}</Paragraph>
      ),
      width: 100,
    },
    {
      id: 'accountNumber',
      Header: 'Account number',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const { accountNumber, iban } = row.original;

        return <Paragraph>{accountNumber || iban || '-'}</Paragraph>;
      },
      width: 100,
    },
    {
      id: 'status',
      Header: 'Status',
      disableSortBy: true,
      Cell: ({ row }: any) => <ContactReviewStatus contact={row.original} />,
      width: 100,
    },
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <ContextMenuCell
          record={row.original}
          setRecipientForEdit={setRecipientForEdit}
          setIsAddRecipient={setIsAddRecipient}
          setShowDeleteRecipient={setShowDeleteRecipient}
          setRecipientIdForDelete={setRecipientIdForDelete}
        />
      ),
      width: 48,
      minWidth: 40,
    },
  ];
};
