import ContextMenu, {
  ContextMenuItem,
} from 'components/shared/ContextMenu/ContextMenu';
import { Row } from 'components/shared/Row/Row';
import { useHistory, useRouteMatch } from 'react-router';
import { BULK_PAYMENT_STATUS } from 'types';

interface OwnProps {
  bulkPaymentId: string;
  onCancelClick: (bulkPaymentId: string) => void;
  status: BULK_PAYMENT_STATUS;
}

const ActionCell: React.FC<OwnProps> = ({
  bulkPaymentId,
  onCancelClick,
  status,
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const list: ContextMenuItem[] = [
    {
      id: 'removeTransfer',
      title: 'See details',
      onClick: () => history.push(`${url}?bulkPaymentId=${bulkPaymentId}`),
    },
  ];

  if (status === BULK_PAYMENT_STATUS.awaitingPayment) {
    list.unshift({
      id: 'cancel',
      title: 'Cancel',
      onClick: () => onCancelClick(bulkPaymentId),
    });
  }

  return (
    <Row justifyContent="flex-end" flex={1}>
      <ContextMenu list={list} strategy="fixed" portal />
    </Row>
  );
};

export default ActionCell;
