import { FC } from 'react';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import { isDateOverdue } from 'utils/dates';
import { parseIntoCurrencyString } from 'utils';
import useBalance from 'hooks/useBalance';
import { BULK_PAYMENT_STATUS, IBulkPayment } from 'types/bulkPayments';
import { Row } from 'components/shared/Row/Row';
import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Button/Button';
import { Paragraph, Title } from 'components/shared/Typography/Typography';
import { MAP_BULK_PAYMENT_STATUS_TO_TEXT } from '../../constants';
import Card from 'components/shared/Card/Card.styles';

interface IOwnProps {
  bulkPayment: IBulkPayment;
  onRowClick: (bulkPayment: IBulkPayment) => void;
}

const MobilePaymentRun: FC<IOwnProps> = ({ bulkPayment, onRowClick }) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const {
    fundedTimestamp,
    scheduledPaymentDate,
    payByTimeGMT,
    payByDate,
    payAmount,
    sellCurrency: sellCurrencyCode,
  } = bulkPayment;
  const buyAmountsBreakdown = bulkPayment.buyAmountsBreakdown || [];
  const sellCurrency = currencyByCode(sellCurrencyCode);
  const isAwaitingPayment =
    bulkPayment.status === BULK_PAYMENT_STATUS.awaitingPayment;
  const isOverdue =
    isAwaitingPayment &&
    !!scheduledPaymentDate &&
    isDateOverdue(scheduledPaymentDate);

  const { isLoading, isEnoughBalance, onPayWithBalance } = useBalance({
    sellCurrency: sellCurrencyCode,
    sellAmount: payAmount,
  });
  const canBePaidWithBalance =
    isEnoughBalance &&
    bulkPayment?.status === BULK_PAYMENT_STATUS.awaitingPayment;

  return (
    <Card padding={theme.spacing.m} onClick={() => onRowClick(bulkPayment)}>
      <Row>
        <Row gap={theme.spacing.xs}>
          {buyAmountsBreakdown.slice(0, 3).map(({ currency: currencyCode }) => {
            const currency = currencyByCode(currencyCode);
            if (!currency) {
              return null;
            }
            return <Icon icon={currency.countryCode} />;
          })}

          {buyAmountsBreakdown.length > 3 && (
            <Paragraph>+{buyAmountsBreakdown.length - 3}</Paragraph>
          )}
        </Row>
        <Paragraph>
          {MAP_BULK_PAYMENT_STATUS_TO_TEXT[bulkPayment.status]}
        </Paragraph>
      </Row>

      <Row
        mt
        mtValue={theme.spacing.xs}
        justifyContent="flex-start"
        gap={theme.spacing.xs}
        alignItems="flex-end"
      >
        <Title variant="h2">
          {parseIntoCurrencyString(payAmount, sellCurrency?.precision)}
        </Title>
        <Paragraph mb mbValue={theme.spacing.xxs}>
          {sellCurrencyCode}
        </Paragraph>
      </Row>

      <Paragraph color={isOverdue ? 'red' : 'dark'}>
        {isAwaitingPayment && payByDate && (
          <>
            Fund by {dayjs(payByDate).format('DD MMM YYYY')} @{' '}
            {dayjs(payByTimeGMT, 'HH:mm').format('HH:mm')}
          </>
        )}
        {!isAwaitingPayment && fundedTimestamp && (
          <>Funded on {dayjs(fundedTimestamp).format('DD MMM YYYY')}</>
        )}
      </Paragraph>

      {canBePaidWithBalance && (
        <Row mt justifyContent="flex-end">
          <Button
            variant="secondary"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              onPayWithBalance({
                payload: {
                  currency: sellCurrencyCode,
                  amount: payAmount,
                },
              });
            }}
          >
            Use my balance
          </Button>
        </Row>
      )}
    </Card>
  );
};

export default MobilePaymentRun;
