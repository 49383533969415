import { Row, Col, Subtitle, Icon, Paragraph } from 'components';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { FC } from 'react';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IPaymentRunCurrencyTotal } from 'types/paymentRuns';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

interface IOwnProps {
  title: string;
  currencyTotals?: Pick<
    IPaymentRunCurrencyTotal,
    'amount' | 'currency' | 'amountInLocalCurrency'
  >[];
}

const InvoicesCurrencyTotals: FC<IOwnProps> = ({ title, currencyTotals }) => {
  const theme = useTheme();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { isMobile } = useDeviceWidth();

  if (!currencyTotals?.length) {
    return null;
  }

  const entityCurrency = currencyByCode(entityCurrencyCode);
  const estimatedCost = currencyTotals.reduce(
    (acc, currencyTotal) => acc + currencyTotal.amountInLocalCurrency,
    0
  );

  const estimatedCostText =
    entityCurrency &&
    estimatedCost &&
    `Estimated Cost: ${parseIntoCurrencyStringWithSymbol(
      estimatedCost,
      entityCurrency.symbol,
      entityCurrency.precision
    )}`;

  return (
    <Col>
      <Row alignItems="flex-start" alignSelf="stretch">
        {isMobile ? (
          <Paragraph variant="bold">{title}</Paragraph>
        ) : (
          <Subtitle variant="bold">{title}</Subtitle>
        )}

        <Col alignItems="flex-end" ml>
          {currencyTotals.map(({ currency, amount }) => {
            const totalCurrency = currencyByCode(currency);
            const text = parseIntoCurrencyStringWithSymbol(
              amount,
              totalCurrency?.symbol,
              totalCurrency?.precision
            );
            return (
              <Row justifyContent="flex-end">
                {isMobile ? (
                  <Paragraph variant="bold">{text}</Paragraph>
                ) : (
                  <Subtitle variant="bold">{text}</Subtitle>
                )}

                {!!totalCurrency && (
                  <Icon icon={totalCurrency.countryCode} ml />
                )}
              </Row>
            );
          })}
        </Col>
      </Row>

      {estimatedCostText && (
        <Row mt gap={theme.spacing.s}>
          {isMobile ? (
            <Paragraph variant="bold">{estimatedCostText}</Paragraph>
          ) : (
            <Subtitle variant="bold">{estimatedCostText}</Subtitle>
          )}

          <Icon icon={entityCurrency.countryCode} />
        </Row>
      )}
    </Col>
  );
};

export default InvoicesCurrencyTotals;
