import { FC } from 'react';
import { Paragraph, PermissionsChecker } from 'components';
import { useTheme } from 'styled-components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { ICurrency } from 'types';
import { useStoreState } from 'state';
import { AccountsRow } from 'components/shared/AccountsRow/AccountsRow.styles';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import { useHistory } from 'react-router';
import { getPrebookingsPageLink, getPrebookPageLink } from 'utils/links';
import { DirectionRowWrapper } from '../../Accounts.styles';

interface OwnProps {
  currencyCode: ICurrency['code'];
  currencySymbol?: ICurrency['symbol'];
  prebookedAndHedged: number;
  withPrebookingsButton?: boolean;
}

const PrebookedRow: FC<OwnProps> = ({
  currencySymbol,
  prebookedAndHedged,
  withPrebookingsButton,
  currencyCode,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { prebookableBuyCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const isCurrencyPrebookable = !!prebookableBuyCurrencies.find(
    ({ code }) => code === currencyCode
  );

  return (
    <AccountsRow gap={theme.spacing.m}>
      <Paragraph>Prebooked:</Paragraph>

      {!!prebookedAndHedged && (
        <>
          <DirectionRowWrapper>
            <Paragraph>
              {parseIntoCurrencyStringWithSymbol(
                prebookedAndHedged,
                currencySymbol,
                0
              )}
            </Paragraph>
          </DirectionRowWrapper>

          {withPrebookingsButton && (
            <ActionButton
              maxWidth="78px"
              onClick={(event) => {
                event.stopPropagation();
                history.push(
                  getPrebookingsPageLink({ currency: currencyCode })
                );
              }}
            >
              Prebooks
            </ActionButton>
          )}
        </>
      )}

      {!prebookedAndHedged && (
        <>
          <DirectionRowWrapper>
            <Paragraph>Nil</Paragraph>
          </DirectionRowWrapper>

          {isCurrencyPrebookable && (
            <PermissionsChecker action="create" resource="prebooks">
              <ActionButton
                maxWidth="78px"
                onClick={(event) => {
                  event.stopPropagation();
                  history.push(
                    getPrebookPageLink({
                      predefinedSellCurrency:
                        currencyCode === entityCurrencyCode ? 'USD' : '',
                      predefinedBuyCurrency: currencyCode,
                    })
                  );
                }}
              >
                Prebook
              </ActionButton>
            </PermissionsChecker>
          )}
        </>
      )}
    </AccountsRow>
  );
};

export default PrebookedRow;
