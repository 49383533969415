import { FC } from 'react';
import { useTheme } from 'styled-components';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { Icon, Col, Row, Title, Paragraph } from 'components';
import Pill from 'components/shared/Pill/Pill';
import AwaitingRegistrationFaqBlock from './components/AwaitingRegistrationFaqBlock/AwaitingRegistrationFaqBlock';
import AwaitingRegistrationSuggestedPlans from './components/AwaitingRegistrationSuggestedPlans/AwaitingRegistrationSuggestedPlans';
import CTACards from './components/CTACards/CTACards';
import TransferPrebookTile from './components/TransferPrebookTile/TransferPrebookTile';
import ConnectionTile from './components/ConnectionTile/ConnectionTile';

const AwaitingRegistrationDashboard: FC = () => {
  const theme = useTheme();
  const { isMobile } = useDeviceWidth();

  return (
    <Col gap={theme.spacing.xl}>
      <Col
        mt
        mb
        alignItems={isMobile ? 'flex-start' : 'center'}
        gap={theme.spacing.xs}
      >
        <Row justifyContent="center" gap={theme.spacing.xs}>
          <Icon icon="notification-success-ico" />
          <Title variant="h4">Welcome to HedgeFlows</Title>
        </Row>
        {!isMobile && (
          <>
            <Pill text="Basic Package" />
            <Paragraph>Your limited access is here</Paragraph>
          </>
        )}
      </Col>

      <CTACards />
      <AwaitingRegistrationSuggestedPlans />
      <ConnectionTile />
      <TransferPrebookTile />
      <AwaitingRegistrationFaqBlock />
    </Col>
  );
};

export default AwaitingRegistrationDashboard;
