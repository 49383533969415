import { AxiosPrivateFirebaseInstance } from 'settings';
import { IForwardRateAndFeesResponse, IRate } from 'types';
import { request } from 'utils/network';

export const getRate = async ({
  sellCurrency,
  buyCurrency,
  withCancel = true,
}: {
  sellCurrency: string;
  buyCurrency: string;
  withCancel?: boolean;
}) => {
  const url = `/rates/${sellCurrency}${buyCurrency}`;

  const { data: rateResponse } = await request<IRate>(
    {
      url,
      method: 'GET',
    },
    true,
    withCancel
  );

  return rateResponse;
};

export interface GeForwardRateAndFeesParams {
  buyCurrency: string;
  sellCurrency: string;
  dateString: string;
  useableFromDateString?: string;
}

export const getForwardRateAndFees = async ({
  buyCurrency,
  sellCurrency,
  dateString,
  useableFromDateString,
}: GeForwardRateAndFeesParams) => {
  const {
    data,
  } = await AxiosPrivateFirebaseInstance.get<IForwardRateAndFeesResponse>(
    `/rates/${sellCurrency}${buyCurrency}/${dateString}${
      useableFromDateString ? `?useable_from=${useableFromDateString}` : ''
    }`
  );

  return data;
};
