import { FC, useState, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form7';

import { useStoreActions, useStoreState } from 'state';
import { useUpdateSettingsPermissionsCheck } from 'hooks/useSpecificPermissionsCheck';
import { Row, Title, StaleSwitch, Subtitle, Col } from 'components';
import { IEntityApprovalSettings } from 'state/user';
import ApproveFlowSettings from './components/ApprovalFlowSettings/ApprovalFlowSettings';
import { WhiteContentContainer } from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import Button from 'components/shared/Button/Button';
import { getFormDefaultValues } from './utils';
import { Firebase } from 'services';
import { Notify } from 'utils';
import { errorHandler } from 'utils/errors';

type Select = { value: string; label: string };
export type TInputs = Omit<
  IEntityApprovalSettings,
  | 'defaultAmount' // un-used
  | 'autoApproveRecipientList'
  | 'approver1List'
  | 'approver2List'
  | 'exceptionalRecipientList'
  | 'exceptionalApproverList'
> & {
  autoApproveRecipientList: Select[];
  approver1List: Select[];
  approver2List: Select[];
  exceptionalRecipientList: Select[];
  exceptionalApproverList: Select[];
  twoApprovers?: boolean;
};

const ApprovalSettings: FC = () => {
  const theme = useTheme();

  const {
    isAutomationPackageEnabled,
    userEntity,
    entityId,
    entityUsers,
  } = useStoreState(({ UserState }) => UserState);
  const { getUserPermissionsOnEntity } = useStoreActions(
    ({ UserState }) => UserState
  );
  const hasUpdateSettingsPermission = useUpdateSettingsPermissionsCheck();
  const [isApprovalFlowEnabled, setIsApprovalFlowEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = useMemo(() => getFormDefaultValues({ userEntity }), [
    userEntity,
  ]);

  const form = useForm<TInputs>({
    defaultValues: defaultValues,
  });

  const { reset } = form;

  const switchApprovalFlowForEntity = async () => {
    if (!entityId) {
      return;
    }

    const { data } = await Firebase.switchApprovalFlowForEntity({
      entityId,
    });

    if (data?.success) {
      /**
       * update permissions, as switching the approval flow can change user permissions.
       *
       * note, we can don't this call in parallel with enableApprovalFlowForEntity,
       * as that call needs to complete first to get the correct permissions.
       */
      await getUserPermissionsOnEntity();
      Notify.success(
        `Approval flow ${!isApprovalFlowEnabled ? 'disabled' : 'enabled'}`
      );
    } else {
      errorHandler(data.message);
    }
  };

  const onSwitchApprovalFlowForEntity = async () => {
    try {
      setIsLoading(true);
      await switchApprovalFlowForEntity();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!userEntity.approvalSettings?.hasApprovalFlow) {
      setIsApprovalFlowEnabled(true);
    }
  }, [userEntity]);

  useEffect(() => {
    if (userEntity) {
      reset(defaultValues);
    }
  }, [userEntity, reset, defaultValues]);

  return (
    <>
      <WhiteContentContainer gap={theme.spacing.xxl}>
        <Col gap={theme.spacing.m}>
          <Title variant="h4">Approval settings</Title>
          {isAutomationPackageEnabled && (
            <Row flex={1}>
              <Subtitle mr variant="bold">
                Approval flow:
              </Subtitle>
              <StaleSwitch
                id="approval-flow"
                isOn={isApprovalFlowEnabled}
                handleToggle={() => setIsApprovalFlowEnabled((prev) => !prev)}
                disabled={!userEntity || !hasUpdateSettingsPermission}
              />
            </Row>
          )}

          {isApprovalFlowEnabled && (
            <ApproveFlowSettings
              isApprovalFlowEnabled={isApprovalFlowEnabled}
              setIsLoading={setIsLoading}
              form={form}
              switchApprovalFlowForEntity={switchApprovalFlowForEntity}
            />
          )}

          <Row>
            {isApprovalFlowEnabled ? (
              <Button
                mt
                disabled={
                  !userEntity ||
                  !hasUpdateSettingsPermission ||
                  !entityUsers ||
                  isLoading ||
                  !form.formState.isValid
                }
                isLoading={isLoading}
                form="approvalsForm"
              >
                Submit
              </Button>
            ) : (
              <Button
                mt
                disabled={
                  !userEntity ||
                  !hasUpdateSettingsPermission ||
                  !entityUsers ||
                  isLoading
                }
                isLoading={isLoading}
                onClick={onSwitchApprovalFlowForEntity}
              >
                Submit
              </Button>
            )}
          </Row>
        </Col>
      </WhiteContentContainer>
    </>
  );
};

export default ApprovalSettings;
