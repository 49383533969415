import { IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';

export const hideContactBasedInvoices = ({
  invoiceId,
}: {
  invoiceId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<
    IResponse<{
      invoiceContactIdToRemove: string;
      sourceSystemContactIdToRemove?: string;
    }>
  >(`/bff/invoices/${invoiceId}/hide-contact-based-invoices`);
