import { useCallback, useEffect, useState } from 'react';
import { getFundingsToCollect } from 'services/funding';
import { useStoreState } from 'state';
import { IFundingToCollect } from 'types/funding';
import { errorHandler } from 'utils/errors';
import { callExternalApiWithLoading } from 'utils/fetchers';

const useFundingsToCollect = () => {
  const { entityId } = useStoreState((state) => state.UserState);
  const [fundings, setFundings] = useState<IFundingToCollect[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getFundingsToCollectHandler = useCallback(async () => {
    if (!entityId) {
      return;
    }

    await callExternalApiWithLoading({
      externalApiCall: getFundingsToCollect,
      responseHandler: (response) => {
        if (response.data.data) {
          setFundings(response.data.data);
        }
      },
      loadingHandler: setIsLoading,
      errorHandler,
    });
  }, [entityId]);

  useEffect(() => {
    getFundingsToCollectHandler();
  }, [getFundingsToCollectHandler]);

  return {
    fundings,
    isLoading,
    getFundingsToCollectHandler,
  };
};

export default useFundingsToCollect;
