import {
  FC,
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
} from 'react';
import { IContact, IRecipient } from 'types';
import { useStoreActions } from 'state';
import {
  useDeleteRecipientsPermissionsCheck,
  useUpdateRecipientsPermissionsCheck,
  usePauseHideExcludeRecipientsPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';
import { ContextMenu, Row } from 'components';
import { errorHandler } from 'utils/errors';
import { Notify } from 'utils';

interface IOwnProps {
  record: IContact;
  setRecipientForEdit: Dispatch<SetStateAction<IContact>>;
  setIsAddRecipient: Dispatch<SetStateAction<boolean>>;
  setShowDeleteRecipient: Dispatch<SetStateAction<boolean>>;
  setRecipientIdForDelete: Dispatch<SetStateAction<string>>;
}

const ContextMenuCell: FC<IOwnProps> = ({
  record,
  setRecipientForEdit,
  setIsAddRecipient,
  setShowDeleteRecipient,
  setRecipientIdForDelete,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { updateRecipient } = useStoreActions(
    ({ RecipientsState }) => RecipientsState
  );

  const hasUpdateRecipientsPermission = useUpdateRecipientsPermissionsCheck();
  const hasDeleteRecipientsPermission = useDeleteRecipientsPermissionsCheck();
  const hasPauseHideExcludeRecipientsPermissions = usePauseHideExcludeRecipientsPermissionsCheck();

  const onUpdateRecipient = useCallback(
    async (
      data: Partial<
        Pick<
          IRecipient,
          | 'shouldPausePayments'
          | 'shouldHideInvoices'
          | 'shouldExcludeInvoicesFromRisk'
        >
      >
    ) => {
      try {
        setIsLoading(true);

        const response = await updateRecipient({
          recipientData: {
            ...data,
            recipientName: record.recipientName,
          },
          recipientId: record.id,
          // temporary hack in order to skip validation of required fields because we do not need it
          isDraft: true,
        });

        if (response?.success) {
          Notify.success(`Successfully updated contact`);
        } else {
          console.warn(response.message);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [record, updateRecipient]
  );

  const dropdownItems = useMemo(() => {
    const dropdownItemsToReturn: any[] = [];

    if (hasUpdateRecipientsPermission) {
      dropdownItemsToReturn.push({
        id: 'edit',
        title: 'Edit',
        onClick: () => {
          setIsAddRecipient(true);
          setRecipientForEdit(record);
        },
      });
    }

    if (hasDeleteRecipientsPermission) {
      dropdownItemsToReturn.push({
        id: 'delete',
        title: 'Delete',
        onClick: () => {
          setShowDeleteRecipient(true);
          setRecipientIdForDelete(record.id);
        },
      });
    }

    if (hasPauseHideExcludeRecipientsPermissions) {
      if (!record.shouldPausePayments) {
        dropdownItemsToReturn.push({
          id: 'pause',
          title: 'Pause payments',
          onClick: () =>
            onUpdateRecipient({
              shouldPausePayments: true,
            }),
        });
      }

      if (record.shouldPausePayments) {
        dropdownItemsToReturn.push({
          id: 'unpause',
          title: 'Unpause payments',
          onClick: () =>
            onUpdateRecipient({
              shouldPausePayments: false,
            }),
        });
      }

      if (!record.shouldHideInvoices) {
        dropdownItemsToReturn.push({
          id: 'hide',
          title: 'Hide invoices',
          onClick: () =>
            onUpdateRecipient({
              shouldHideInvoices: true,
            }),
        });
      }

      if (record.shouldHideInvoices) {
        dropdownItemsToReturn.push({
          id: 'show',
          title: 'Show invoices',
          onClick: () =>
            onUpdateRecipient({
              shouldHideInvoices: false,
            }),
        });
      }

      if (!record.shouldExcludeInvoicesFromRisk) {
        dropdownItemsToReturn.push({
          id: 'exclude',
          title: 'Exclude from risk',
          onClick: () =>
            onUpdateRecipient({
              shouldExcludeInvoicesFromRisk: true,
            }),
        });
      }

      if (record.shouldExcludeInvoicesFromRisk) {
        dropdownItemsToReturn.push({
          id: 'include',
          title: 'Include in risk',
          onClick: () =>
            onUpdateRecipient({
              shouldExcludeInvoicesFromRisk: false,
            }),
        });
      }
    }

    return dropdownItemsToReturn;
  }, [
    record,
    hasUpdateRecipientsPermission,
    hasDeleteRecipientsPermission,
    hasPauseHideExcludeRecipientsPermissions,
    setIsAddRecipient,
    setRecipientForEdit,
    setShowDeleteRecipient,
    setRecipientIdForDelete,
    onUpdateRecipient,
  ]);

  if (!dropdownItems.length) {
    return null;
  }

  return (
    <Row justifyContent="flex-end" flex={1}>
      <ContextMenu disabled={isLoading} list={dropdownItems} />
    </Row>
  );
};

export default ContextMenuCell;
