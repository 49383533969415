import { FC } from 'react';
import { InlineLoader, Paragraph } from 'components';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { ICurrency } from 'types';
import { AccountsRow } from 'components/shared/AccountsRow/AccountsRow.styles';
import { getInvoicesPageLink } from 'utils/links';
import { DirectionRowWrapper } from '../../Accounts.styles';
import { useStoreState } from 'state';

interface OwnProps {
  currencyCode: ICurrency['code'];
  currencySymbol?: ICurrency['symbol'];
  amount?: number;
}

const ExpectedRow: FC<OwnProps> = ({
  currencyCode,
  currencySymbol,
  amount,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { isLoadingInvoicesAggregations, invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );

  const withInvoicesButton = !!invoicesAggregations?.perCurrency[currencyCode];

  return (
    <AccountsRow gap={theme.spacing.m}>
      <Paragraph>Bills to pay:</Paragraph>

      {isLoadingInvoicesAggregations && <InlineLoader />}

      {!isLoadingInvoicesAggregations && !!amount && (
        <>
          <DirectionRowWrapper>
            <Paragraph>
              {parseIntoCurrencyStringWithSymbol(amount, currencySymbol, 0)}
            </Paragraph>
          </DirectionRowWrapper>

          {withInvoicesButton && (
            <ActionButton
              maxWidth="78px"
              onClick={(event) => {
                event.stopPropagation();
                history.push(
                  getInvoicesPageLink({
                    currency: currencyCode,
                  })
                );
              }}
            >
              Invoices
            </ActionButton>
          )}
        </>
      )}

      {!isLoadingInvoicesAggregations && !amount && (
        <>
          <DirectionRowWrapper>
            <Paragraph>Nil</Paragraph>
          </DirectionRowWrapper>
        </>
      )}
    </AccountsRow>
  );
};

export default ExpectedRow;
