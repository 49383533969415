import { useStoreState } from 'state';
import { useHistory, useRouteMatch } from 'react-router';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';
import Table from 'components/shared/Table/Table';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import {
  generatePaymentRunTableTileColumns,
  paymentRunsTableTileFilterTypes,
} from './tableColumnsGenerator';
import { IBulkPayment } from 'types/bulkPayments';
import { useTheme } from 'styled-components';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import useTableFiltering from 'hooks/useTableFiltering';
import { getPaymentRunsPageLink } from 'utils/links';
import { FC, useState } from 'react';
import Button from '../Button/Button';
import { OverflowXWrapper } from '../OverflowXWrapper/OverflowXWrapper.styles';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { cancelBulkPayment } from 'services/firebase';
import { Notify } from 'utils';
import CancelBulkPayment from './components/CancelBulkPayment/CancelBulkPayment';
import CancelBulkPaymentError from './components/CancelBulkPaymentError/CancelBulkPaymentError';
import MobilePaymentRun from './components/MobilePaymentRun/MobilePaymentRun';
import StaleInfo from '../StaleInfo/StaleInfo';
import Icon from '../Icon/Icon';
import { Paragraph } from '../Typography/Typography';
import PaymentRunsPlaceholder from './components/PaymentRunsPlaceholder/PaymentRunsPlaceholder';

type TFilters = 'recent' | 'scheduled' | 'failed' | '';

interface OwnProps {
  showLimit?: number;
  showMore?: boolean;
}

const PaymentsRunTableTile: FC<OwnProps> = ({ showLimit, showMore }) => {
  const theme = useTheme();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { isMobile } = useDeviceWidth();
  const { bulkPayments } = useStoreState((state) => state.BulkPaymentsState);

  const [bulkPaymentToCancelId, setBulkPaymentToCancelId] = useState<
    false | string
  >(false);
  const [bulkPaymentToCancelErrorId, setBulkPaymentToCancelErrorId] = useState<
    false | string
  >(false);

  const onCancelBulkPaymentClick = (bulkPaymentId: string) =>
    setBulkPaymentToCancelId(bulkPaymentId);

  const paymentRunTransfersTableColumns = generatePaymentRunTableTileColumns({
    onCancelBulkPaymentClick,
  });

  const onRowClick = (bulkPayment: IBulkPayment) =>
    history.push(`${url}?bulkPaymentId=${bulkPayment.id}`);

  const { filter, setFilter, tableRef } = useTableFiltering<
    TFilters,
    IBulkPayment
  >({
    filterTypeName: 'buyAmountsBreakdown',
    initialFilterValue: '',
  });

  const onFilterClick = (filterName: TFilters) => {
    setFilter(filterName === filter ? '' : filterName);
  };

  const cancelHandler = async (bulkPaymentId: string) => {
    try {
      setBulkPaymentToCancelId(false);
      await cancelBulkPayment(bulkPaymentId);
      Notify.success(
        `Transaction ${bulkPaymentId} was successfully cancelled.`
      );
    } catch (error: any) {
      setBulkPaymentToCancelErrorId(bulkPaymentId);
    }
  };

  const bulkPaymentsToUse = bulkPayments.slice(0, showLimit);

  if (!bulkPaymentsToUse.length) {
    return <PaymentRunsPlaceholder />;
  }

  return (
    <>
      <Row mb justifyContent={showMore ? 'flex-start' : 'space-between'}>
        <CounterTitle
          title={
            showLimit
              ? `Last ${bulkPaymentsToUse.length} payment runs`
              : 'Payment runs'
          }
          count={showLimit ? undefined : bulkPaymentsToUse.length}
        />
        {!isMobile && (
          <Row ml gap={theme.spacing.xs}>
            {!showMore && (
              <>
                <FilterButton
                  onClick={() => onFilterClick('recent')}
                  active={filter === 'recent'}
                >
                  Recent
                </FilterButton>
                <FilterButton
                  onClick={() => onFilterClick('scheduled')}
                  active={filter === 'scheduled'}
                >
                  Scheduled
                </FilterButton>
                <FilterButton
                  onClick={() => onFilterClick('failed')}
                  active={filter === 'failed'}
                >
                  Failed
                </FilterButton>
              </>
            )}

            {showMore && (
              <StaleInfo
                infoSize={theme.iconSizes.m}
                mode="hover"
                strategy="fixed"
                portal
                placement="top"
                trigger={
                  <Button
                    variant="link"
                    onClick={() => history.push(getPaymentRunsPageLink())}
                  >
                    <Icon icon="round-plus-ico" />
                  </Button>
                }
              >
                <Paragraph color="white">Show more</Paragraph>
              </StaleInfo>
            )}
          </Row>
        )}
      </Row>

      {isMobile && (
        <Col gap={theme.spacing.xxs}>
          {bulkPaymentsToUse.map((bulkPayment) => (
            <MobilePaymentRun
              key={bulkPayment.id}
              bulkPayment={bulkPayment}
              onRowClick={onRowClick}
            />
          ))}
        </Col>
      )}

      {!isMobile && (
        <OverflowXWrapper>
          <Table<IBulkPayment>
            ref={tableRef}
            data={bulkPaymentsToUse}
            columns={paymentRunTransfersTableColumns}
            filterTypes={paymentRunsTableTileFilterTypes}
            autoResetExpanded={false}
            sortable
            onRowClick={onRowClick}
          />
        </OverflowXWrapper>
      )}

      {!!bulkPaymentToCancelId && (
        <CancelBulkPayment
          onClose={() => setBulkPaymentToCancelId(false)}
          onContinue={() => cancelHandler(bulkPaymentToCancelId)}
        />
      )}

      {!!bulkPaymentToCancelErrorId && (
        <CancelBulkPaymentError
          bulkPaymentId={bulkPaymentToCancelErrorId}
          onClose={() => setBulkPaymentToCancelErrorId(false)}
        />
      )}
    </>
  );
};

export default PaymentsRunTableTile;
