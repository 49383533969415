import {
  IInvoiceFromSearch,
  IRecipient,
  Nullable,
  PartialContact,
  RECIPIENT_STATUS,
} from 'types';
import {
  isInvoiceFromSearchApprovableByUser,
  isInvoiceFromSearchSelectable,
} from 'utils/invoices';

export const extractUnsavedRecipientFromIInvoiceFromSearch = (
  invoice: IInvoiceFromSearch
): Partial<IRecipient> => ({
  recipientName:
    invoice.contactRecipientName || invoice.externalRefsSourceSystemContactName,
  currency: invoice.currency,
  externalRefs: {
    sourceSystemId: invoice.externalRefsSourceSystemContactId,
  },
  status: RECIPIENT_STATUS.draft,
});

export const getPartialRecipientFromInvoiceFromSearch = ({
  invoice,
  recipientById,
}: {
  recipientById: (id?: string | null) => IRecipient | undefined;
  invoice: IInvoiceFromSearch;
}) => {
  /**
   * recipientById returns recipientById | undefined,
   * and extractUnsavedRecipientFromIInvoiceFromSearch returns a small subset,
   * So we use PartialContact which we need to use as ContactCellNew requires checks on `enabled` and `id` properties,
   * and other components like AddContact require different fields.
   */
  const existingRecipient:
    | IRecipient
    | PartialContact
    | undefined = recipientById(invoice.contactId);
  let recipientToUse = existingRecipient;

  if (
    !recipientToUse &&
    (invoice.contactRecipientName ||
      invoice.externalRefsSourceSystemContactName)
  ) {
    recipientToUse = extractUnsavedRecipientFromIInvoiceFromSearch(invoice);
  }

  return recipientToUse;
};

export const isInvoicesTableRowSelectable = ({
  tab,
  record,
  isUserApprover,
  isUserSubmitter,
  userId,
  hasApprovalFlow,
}: {
  tab: string | null;
  record: IInvoiceFromSearch;
  isUserApprover: boolean;
  isUserSubmitter: boolean;
  userId: Nullable<string>;
  hasApprovalFlow: boolean;
}) => {
  if (tab === 'received') {
    return true;
  }

  if (tab === 'submitted') {
    return (
      isUserApprover &&
      isInvoiceFromSearchApprovableByUser(record, userId) &&
      isInvoiceFromSearchSelectable(record)
    );
  }

  // Disable checkbox for Submitter if invoice is submitted
  if (hasApprovalFlow && isUserSubmitter && !isUserApprover) {
    return (
      isInvoiceFromSearchSelectable(record) &&
      record.approvalStatus !== 'submitted'
    );
  }

  // Select invoices for payment run only from approved tab
  if (hasApprovalFlow && tab !== 'approved') {
    return (
      isInvoiceFromSearchSelectable(record) &&
      record.approvalStatus !== 'approved'
    );
  }

  return isInvoiceFromSearchSelectable(record);
};
