import { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Col, FundingAccountCredentials, Loader, Title } from 'components';
import Popup from 'components/shared/Popup/Popup';
import useUrlValues from 'hooks/useUrlValues';
import { subscribeToConversionById } from 'services/firebase/conversions';
import { IConversion, CONVERSION_STATUS } from 'types';
import ExchangeDetails from './components/ExchangeDetails/ExchangeDetails';
import useFundingAccountCredentialsActions from '../FundingAccountCredentials/useFundingAccountCredentialsActions';
import FundingAccountCredentialsActions from '../FundingAccountCredentialsActions/FundingAccountCredentialsActions';
import useBalance from 'hooks/useBalance';

const ConversionInner: FC = () => {
  const theme = useTheme();
  const { conversionId, setUrlValue } = useUrlValues('conversionId');
  const [isLoading, setIsLoading] = useState(true);
  const [
    selectedConversion,
    setSelectedConversion,
  ] = useState<IConversion | null>(null);
  const fundingAccountCredentialsActions = useFundingAccountCredentialsActions();
  const {
    isLoading: isLoadingPayWithBalance,
    isEnoughBalance,
    onPayWithBalance,
  } = useBalance({
    sellCurrency: selectedConversion?.sellCurrency,
    sellAmount: selectedConversion?.sellAmount,
  });

  const isAwaitingPayment =
    selectedConversion?.status === CONVERSION_STATUS.awaitingPayment;

  const canBePaidWithBalance = isEnoughBalance && isAwaitingPayment;

  useEffect(() => {
    let unsubscribe: () => void;

    if (conversionId) {
      unsubscribe = subscribeToConversionById({
        conversionId: conversionId,
        callback: (data) => {
          setSelectedConversion(data);
          setIsLoading(false);
        },
      });
    }

    return () => unsubscribe?.();
  }, [conversionId]);

  const onClose = () => setUrlValue({ conversionId: '' });

  if (!selectedConversion) {
    return null;
  }

  const onPayWithBalanceHandler = () =>
    onPayWithBalance({
      payload: {
        currency: selectedConversion.sellCurrency,
        amount: selectedConversion.sellAmount,
      },
    });

  return (
    <Popup
      HeaderContent={<Title variant="h3">FX Exchange</Title>}
      FooterContent={
        <FundingAccountCredentialsActions
          isFundingInitiated={!isAwaitingPayment}
          isLoading={isLoading || isLoadingPayWithBalance}
          onPayWithBalanceHandler={onPayWithBalanceHandler}
          fundingAccountCredentialsActions={fundingAccountCredentialsActions}
          onSecondaryButtonClick={onClose}
          secondaryButtonText="Close"
        />
      }
      width="900px"
      height="800px"
      onClose={onClose}
    >
      {isLoading && <Loader size="large" />}
      {!isLoading && (
        <Col rowGap={theme.spacing.xl}>
          <ExchangeDetails selectedConversion={selectedConversion} />

          {isAwaitingPayment && (
            <FundingAccountCredentials
              assetId={selectedConversion.id}
              canBePaidWithBalance={canBePaidWithBalance}
              sellCurrency={selectedConversion.sellCurrency}
              fundingAccountCredentialsActions={
                fundingAccountCredentialsActions
              }
            />
          )}
        </Col>
      )}
    </Popup>
  );
};

export default ConversionInner;
