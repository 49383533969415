import React from 'react';
import { PdfViewerContainer, PdfViewerObject } from './PdfViewer.styles';

interface IOwnProps {
  url?: string;
  base64?: string;
}

const PdfViewer: React.FC<IOwnProps> = ({ url, base64 }) => {
  const dataUrl = base64 ?? url;

  return (
    <PdfViewerContainer>
      <PdfViewerObject data={dataUrl} type="application/pdf">
        <p>It appears your browser doesn't support embedded PDFs.</p>
      </PdfViewerObject>
    </PdfViewerContainer>
  );
};

export default PdfViewer;
