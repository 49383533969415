import { FC, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import { Col, Row } from 'components';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import Placeholder from 'components/shared/Placeholder/Placeholder';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import { getSettingsPageLink } from 'utils/links';
import Button from 'components/shared/Button/Button';
import useDeviceWidth from 'hooks/useDeviceWidth';
import AccountsTable from './components/AccountsTable/AccountsTable';
import AccountsTiles from './components/AccountsTiles/AccountsTiles';
import { getAccountsTableData } from 'services/bff';
import { IAccountsTableDataItem } from 'types/bff';
import { errorHandler } from 'utils/errors';
import { usePrevious } from 'hooks';

const Accounts: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile } = useDeviceWidth();
  const { entityId, entityCurrencyCode } = useStoreState(
    (state) => state.UserState
  );
  const { balances } = useStoreState((state) => state.BalancesState);
  const { isIntegrated } = useIntegrationEngine();
  const [data, setData] = useState<IAccountsTableDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const hasCashflows = !!data.length;
  const prevBalances = usePrevious(balances);

  const getAccountsTableDataHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getAccountsTableData();
      setData(response.data.data || []);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  /** If balances are not the same as before, refetch accounts table data */
  useEffect(() => {
    if (balances && prevBalances) {
      const areBalancesChanged = balances.some((balance) => {
        const prevBalance = prevBalances.find(
          (prevBalance) => prevBalance.id === balance.id
        );

        if (prevBalance && prevBalance.amount !== balance.amount) {
          return true;
        }

        return false;
      });

      if (areBalancesChanged) {
        const getAccountsTableDataInBackground = async () => {
          try {
            const response = await getAccountsTableData();
            setData(response.data.data || []);
          } catch (error) {
            errorHandler(error);
          }
        };

        getAccountsTableDataInBackground();
      }
    }
  }, [balances, prevBalances]);

  useEffect(() => {
    getAccountsTableDataHandler();
  }, [getAccountsTableDataHandler]);

  const onRecalculate = async () => {
    if (!entityId || !entityCurrencyCode) {
      return;
    }

    await getAccountsTableDataHandler();
  };

  return (
    <Col>
      <Row mb mbValue={theme.spacing.l}>
        <CounterTitle title="Bills to pay" count={data.length} />

        {hasCashflows && (
          <Button
            variant="link"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onRecalculate}
          >
            Recalculate
          </Button>
        )}
      </Row>

      {hasCashflows && !isMobile && <AccountsTable data={data} />}

      {hasCashflows && isMobile && <AccountsTiles data={data} />}

      {!hasCashflows && !isIntegrated && (
        <Placeholder
          actionText="+ Integrate your accounting system"
          onAction={() =>
            history.push(getSettingsPageLink({ tab: 'integrations' }))
          }
          description="to see your future currency cashflows"
        />
      )}
    </Col>
  );
};

export default Accounts;
