import { useEffect, useState } from 'react';
import { getAccounts } from 'services/firebase';
import { useStoreState } from 'state';
import { IIntegrationSettingsAccount } from 'types';
import { IAccountData } from 'types/integrations';
import { Notify } from 'utils';
import { generateIntegrationEngineBankFeesAccountData } from 'utils/integrations';

interface IFilteredAccounts {
  xeroAccounts: IAccountData[];
  bankFeesAccounts: IAccountData[];
  bankTransferAccounts: Record<string, IAccountData[]>;
}

const useXeroAccounts = (fetchAccounts = false) => {
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [hasEntityCurrencyAccount, setHasEntityCurrencyAccount] = useState(
    false
  );
  const [
    defaultBankFeesAccount404,
    setDefaultBankFeesAccount404,
  ] = useState<IIntegrationSettingsAccount | null>(null);
  const [xeroAccounts, setXeroAccounts] = useState<IAccountData[]>([]);
  const [bankFeesAccounts, setBankFeesAccounts] = useState<IAccountData[]>([]);
  const [bankTransferAccounts, setBankTransferAccounts] = useState<
    Record<string, IAccountData[]>
  >({});
  const { entityCurrencyCode, entityId } = useStoreState(
    (state) => state.UserState
  );

  useEffect(() => {
    const fetchXeroAccounts = async () => {
      try {
        setIsLoadingAccounts(true);
        const response = await getAccounts(entityId as string);
        if (response.data?.success) {
          const accounts = response.data.data;
          const foundEntityCurrencyAccount = accounts?.some(
            (account) => account.name === `HedgeFlows ${entityCurrencyCode}`
          );

          if (foundEntityCurrencyAccount) {
            setHasEntityCurrencyAccount(foundEntityCurrencyAccount);
          }

          const findBankFeesAccount = accounts?.find(
            (account) => account.code === '404'
          );

          if (findBankFeesAccount) {
            setDefaultBankFeesAccount404(findBankFeesAccount);
          }

          const mappedAccounts = accounts?.reduce<IFilteredAccounts>(
            (total, el) => {
              const currencyCode = el.currency;
              if (
                (el.type === 'bankTransferAccount' || el.isBankAccount) &&
                currencyCode === entityCurrencyCode &&
                el.name.includes('HedgeFlows')
              ) {
                total.xeroAccounts = [
                  ...total?.xeroAccounts,
                  generateIntegrationEngineBankFeesAccountData(el),
                ];
              }
              if (
                currencyCode !== undefined &&
                (el.isBankAccount === true ||
                  el.category === 'asset' ||
                  el.type === 'bankTransferAccount') &&
                !el.name.includes('HedgeFlows')
              ) {
                if (Array.isArray(total.bankTransferAccounts[currencyCode])) {
                  total.bankTransferAccounts[currencyCode].push(
                    generateIntegrationEngineBankFeesAccountData(el)
                  );
                } else {
                  total.bankTransferAccounts[currencyCode] = [
                    generateIntegrationEngineBankFeesAccountData(el),
                  ];
                }
              }

              if (el.category === 'expense') {
                total.bankFeesAccounts = [
                  ...total?.bankFeesAccounts,
                  generateIntegrationEngineBankFeesAccountData(el),
                ];
              }
              return total;
            },
            { xeroAccounts: [], bankFeesAccounts: [], bankTransferAccounts: {} }
          );

          if (mappedAccounts?.xeroAccounts?.length) {
            setXeroAccounts(mappedAccounts.xeroAccounts);
          }
          if (mappedAccounts?.bankFeesAccounts?.length) {
            setBankFeesAccounts(mappedAccounts.bankFeesAccounts);
          }

          if (Object.keys(mappedAccounts?.bankTransferAccounts ?? {}).length) {
            setBankTransferAccounts(mappedAccounts?.bankTransferAccounts ?? {});
          }
        }
      } catch (error: any) {
        Notify.error(error.message);
      } finally {
        setIsLoadingAccounts(false);
      }
    };

    if (fetchAccounts) {
      fetchXeroAccounts();
    }
  }, [entityId, entityCurrencyCode, fetchAccounts]);

  return {
    bankFeesAccounts,
    bankTransferAccounts,
    defaultBankFeesAccount404,
    hasEntityCurrencyAccount,
    isLoadingAccounts,
    xeroAccounts,
  };
};

export default useXeroAccounts;
