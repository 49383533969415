import { IIntegrationSettingsAccount } from 'types';
import { IFormBankTransferXeroAccount } from './StepAccountsForm';

export const populateBankTransferAccountsForDropdown = (
  accounts: IIntegrationSettingsAccount[],
  formBankTransferAccountsNames: string[]
) =>
  accounts.reduce<Record<string, IFormBankTransferXeroAccount>>(
    (accumulator, account) => {
      if (account.type !== 'bankTransferAccount') {
        return accumulator;
      }

      const accountRowId = `bankAccount_${account.id || account.name}`;
      formBankTransferAccountsNames.push(accountRowId);

      accumulator[accountRowId] = {
        currency: account.currency,
        account,
      };

      return accumulator;
    },
    {}
  );
