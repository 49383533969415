export type TLocalStorageKey = 'addBackupMethodPopupHasBeenClosed';

type LocalStorageValue = string | boolean | number | object | null;

class LocalStorage {
  private static instance: LocalStorage;

  private constructor() {}

  public static getInstance(): LocalStorage {
    if (!LocalStorage.instance) {
      LocalStorage.instance = new LocalStorage();
    }
    return LocalStorage.instance;
  }

  public setItem(key: TLocalStorageKey, value: LocalStorageValue): void {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error('Error setting localStorage item:', error);
    }
  }

  public getItem<T extends LocalStorageValue>(key: TLocalStorageKey): T | null {
    try {
      const serializedValue = localStorage.getItem(key);

      if (serializedValue === null) {
        return null;
      }

      return JSON.parse(serializedValue) as T;
    } catch (error) {
      console.error('Error getting localStorage item:', error);
      return null;
    }
  }

  public removeItem(key: TLocalStorageKey): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing localStorage item:', error);
    }
  }
}

export const localStorageInstance = LocalStorage.getInstance();
