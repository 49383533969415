import { IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';

/**
 * Sends a refresh_token to the external service so it can call gateway endpoints.
 */
export const saveUserAuth = (data: {
  entityId: string;
  refreshToken: string;
  sessionId: string;
}) => AxiosPrivateFirebaseInstance.post<IResponse>('/bff/auth/token', data);
