import { getInvoicesFilters } from 'pages/Invoices/utils';
import { useState, useEffect, useMemo } from 'react';
import { getInvoicesPaginated } from 'services/firebase/invoices';
import { useStoreState } from 'state';
import { IInvoiceFromSearch, IRateContract } from 'types';
import { errorHandler } from 'utils/errors';
import { callExternalApiWithLoading } from 'utils/fetchers';
import { generateInvoicesSearchQuery } from 'utils/search';

type ContentType = 'transfers' | 'invoices' | null;

interface UseRelatedTransfersAndInvoicesValues {
  selectedRateContract: IRateContract | null;
}

const usePrebookInner = ({
  selectedRateContract,
}: UseRelatedTransfersAndInvoicesValues) => {
  const { transfersByRateContract } = useStoreState(
    (state) => state.TransfersState
  );
  const [contentType, setContentType] = useState<ContentType>(null);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [relatedInvoices, setRelatedInvoices] = useState<IInvoiceFromSearch[]>(
    []
  );

  const relatedTransfers = useMemo(
    () => transfersByRateContract(selectedRateContract?.id),
    [transfersByRateContract, selectedRateContract]
  );

  useEffect(() => {
    if (selectedRateContract) {
      const fetchInvoices = async () => {
        const query = generateInvoicesSearchQuery({
          filters: getInvoicesFilters({
            contractId: selectedRateContract.id,
          }),
        });

        return getInvoicesPaginated(query);
      };

      callExternalApiWithLoading({
        externalApiCall: fetchInvoices,
        loadingHandler: setIsLoadingInvoices,
        responseHandler: (response) =>
          setRelatedInvoices(
            (response.data.data?.results as IInvoiceFromSearch[]) ?? []
          ),
        errorHandler,
      });
    }
  }, [selectedRateContract]);

  const tabs = useMemo(() => {
    if (!selectedRateContract) {
      return [];
    }

    const tabsArray: Array<{ id: ContentType; title: string }> = [];

    if (relatedTransfers.length > 0) {
      tabsArray.push({
        id: 'transfers',
        title: 'Transfers',
      });
    }

    if (relatedInvoices.length > 0) {
      tabsArray.push({
        id: 'invoices',
        title: 'Allocated Invoices',
      });
    }

    return tabsArray;
  }, [selectedRateContract, relatedTransfers, relatedInvoices]);

  useEffect(() => {
    if (!isLoadingInvoices && !contentType && tabs.length) {
      setContentType(tabs[0].id);
    }
  }, [contentType, tabs, isLoadingInvoices]);

  return {
    contentType,
    setContentType,
    tabs,
    relatedInvoices,
    relatedTransfers,
    isLoadingInvoices,
  };
};

export default usePrebookInner;
