import usePermissionsCheck from './usePermissionsCheck';

export const useCreateTransfersPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'create',
    resource: 'transfers',
  });

export const useCreatePrebooksPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'create',
    resource: 'prebooks',
  });

export const useAllocatePrebooksPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'allocate',
    resource: 'prebooks',
  });

export const useUpdateBalancesPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'update',
    resource: 'balances',
  });

export const useUpdateSettingsPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'update',
    resource: 'settings',
  });

export const useUpdateSettingsRiskPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'update',
    resource: 'settings_risk',
  });

export const useCreateRecipientsPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'create',
    resource: 'recipients',
  });

export const useUpdateRecipientsPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'update',
    resource: 'recipients',
  });

export const useDeleteRecipientsPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'delete',
    resource: 'recipients',
  });

export const usePauseHideExcludeRecipientsPermissionsCheck = () =>
  usePermissionsCheck({
    action: 'invoice_actions',
    resource: 'recipients',
  });
