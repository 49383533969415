import { Paragraph } from 'components';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import { useHistory } from 'react-router';
import { Cell, Column } from 'react-table';
import { useStoreState } from 'state';
import {
  getOpenBankingSettingsPageLink,
  parseIntoCurrencyStringWithSymbol,
} from 'utils';
import { IOpenBankingTableAccount } from '../../useOpenBankingSettings';
import IncludeInTotalsCell from './components/IncludeInTotalsCell/IncludeInTotalsCell';

interface OwnProps {
  isAccountsConnected: boolean;
  onDisconnectAccountIntegration: (integrationId: string) => Promise<void>;
}

export const generateTableColumns = ({
  isAccountsConnected,
  onDisconnectAccountIntegration,
}: OwnProps): Column<IOpenBankingTableAccount>[] => {
  const additionalCells: Column<IOpenBankingTableAccount>[] = isAccountsConnected
    ? [
        {
          accessor: 'institution',
          Header: 'Institution',
          Cell: ({ value }) => <Paragraph variant="bold">{value}</Paragraph>,
          width: 100,
        },
        {
          accessor: 'accountNumber',
          Header: 'Acc #',
          Cell: ({ value }) => <Paragraph>{value ?? '-'}</Paragraph>,
          width: 100,
        },
      ]
    : [];

  return [
    ...additionalCells,
    {
      accessor: 'currency',
      Header: 'Currency',
      Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
      width: 100,
    },
    {
      accessor: 'erpAccount',
      Header: 'ERP account',
      Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
      width: 100,
    },
    {
      accessor: 'openBankingBalance',
      Header: 'Balance',
      Cell: ({
        row: {
          original: { currency, openBankingBalance },
        },
      }) => {
        const { currencyByCode } = useStoreState(
          (state) => state.CurrenciesState
        );

        const { symbol, precision } = currencyByCode(currency) ?? {};

        return (
          <Paragraph>
            {openBankingBalance !== undefined
              ? parseIntoCurrencyStringWithSymbol(
                  openBankingBalance,
                  symbol,
                  precision
                )
              : '-'}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      accessor: 'balance',
      Header: 'ERP balance',
      Cell: ({
        row: {
          original: { currency, balance },
        },
      }) => {
        const { currencyByCode } = useStoreState(
          (state) => state.CurrenciesState
        );

        const { symbol, precision } = currencyByCode(currency) ?? {};

        return (
          <Paragraph>
            {parseIntoCurrencyStringWithSymbol(balance, symbol, precision)}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      id: 'inc',
      Header: 'Inc. in totals',
      Cell: ({ row }: Cell<IOpenBankingTableAccount>) => (
        <IncludeInTotalsCell record={row.original} />
      ),
      width: 100,
    },
    {
      id: 'actionCell',
      Header: '',
      Cell: ({
        row: {
          original: { id, isConnected, refuseDisconnect },
        },
      }: Cell<IOpenBankingTableAccount>) => {
        const history = useHistory();

        if (refuseDisconnect) {
          return null;
        }

        return (
          <>
            <ActionButton
              onClick={(event) => {
                event.stopPropagation();
                isConnected
                  ? onDisconnectAccountIntegration(id)
                  : history.push(getOpenBankingSettingsPageLink());
              }}
            >
              {isConnected ? 'Disconnect' : 'Connect'}
            </ActionButton>
          </>
        );
      },
      width: 100,
    },
  ];
};
