import { Paragraph, Row } from 'components';
import { FC, useState } from 'react';
import { useStoreState } from 'state';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import FxCostBreakdown from './components/FxCostBreakdown/FxCostBreakdown';
import { IPastPerformanceItem } from 'types';
import FxImpactBreakdown from './components/FxImpactBreakdown/FxImpactBreakdown';
import InceptionPnlBreakdown from './components/InceptionPnlBreakdown/InceptionPnlBreakdown';

interface IOwnProps {
  value: number;
  valuePercentage?: number;
  currencyCode: string;
  textColor?: string;
  record: IPastPerformanceItem;
  breakdownType: 'fxImpact' | 'inceptionPnl' | 'fxCost';
}

const AmountWithPercentageCell: FC<IOwnProps> = ({
  value,
  valuePercentage,
  currencyCode,
  textColor = 'black',
  record,
  breakdownType,
}) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(currencyCode);
  const [shouldShowBreakdown, setShouldShowBreakdown] = useState(false);

  return (
    <>
      <Row onClick={() => setShouldShowBreakdown(true)}>
        <Paragraph style={{ color: textColor }}>
          {parseIntoCurrencyStringWithSymbol(
            value,
            currency?.symbol,
            currency?.precision
          )}
        </Paragraph>
        {!!valuePercentage && (
          <Paragraph style={{ color: textColor, whiteSpace: 'break-spaces' }}>
            {` (${(valuePercentage * 100).toPrecision(2)}%)`}
          </Paragraph>
        )}
      </Row>
      {shouldShowBreakdown && breakdownType === 'fxCost' && (
        <FxCostBreakdown
          record={record}
          onClose={() => setShouldShowBreakdown(false)}
        />
      )}
      {shouldShowBreakdown && breakdownType === 'fxImpact' && (
        <FxImpactBreakdown
          record={record}
          onClose={() => setShouldShowBreakdown(false)}
        />
      )}
      {shouldShowBreakdown && breakdownType === 'inceptionPnl' && (
        <InceptionPnlBreakdown
          record={record}
          onClose={() => setShouldShowBreakdown(false)}
        />
      )}
    </>
  );
};

export default AmountWithPercentageCell;
