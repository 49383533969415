import { Paragraph } from 'components';
import { FC } from 'react';
import { ICurrency, IInvoice } from 'types';
import { parseIntoCurrencyString } from 'utils';
import Attachments from './components/Attachments/Attachments';
import {
  StyledTable,
  StyledTh,
  StyledTd,
  CellWrapper,
} from './LineItemsTable.styles';

interface IOwnProps {
  invoice: IInvoice;
  currency: ICurrency | null;
}
const LineItemsTable: FC<IOwnProps> = ({ invoice, currency }) => {
  const { lineItems, subTotalAmount, totalAmount, totalTaxAmount } = invoice;

  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledTh>
            <Paragraph>#</Paragraph>
          </StyledTh>
          <StyledTh>
            <Paragraph>Description</Paragraph>
          </StyledTh>
          <StyledTh>
            <Paragraph>Quantity</Paragraph>
          </StyledTh>
          <StyledTh>
            <Paragraph textAlign="right">Rate</Paragraph>
          </StyledTh>
          <StyledTh>
            <Paragraph textAlign="right">Amount</Paragraph>
          </StyledTh>
        </tr>
      </thead>

      <tbody>
        {lineItems?.map((item, index) => {
          return (
            <tr key={item.itemCode}>
              <StyledTd>
                <CellWrapper>
                  <Paragraph>{index + 1}</Paragraph>
                </CellWrapper>
              </StyledTd>
              <StyledTd>
                <CellWrapper>
                  <Paragraph>{item.description || item.name}</Paragraph>
                </CellWrapper>
              </StyledTd>
              <StyledTd>
                <CellWrapper>
                  <Paragraph>{item.quantity}</Paragraph>
                </CellWrapper>
              </StyledTd>
              <StyledTd>
                <CellWrapper>
                  <Paragraph textAlign="right">
                    {parseIntoCurrencyString(
                      item.unitAmount,
                      currency?.precision
                    )}
                  </Paragraph>
                </CellWrapper>
              </StyledTd>
              <StyledTd>
                <Paragraph textAlign="right">{`${
                  currency?.symbol
                }${parseIntoCurrencyString(
                  item.lineAmount,
                  currency?.precision
                )}`}</Paragraph>
              </StyledTd>
            </tr>
          );
        })}

        <tr>
          <StyledTd colSpan={1} rowSpan={2}>
            <Attachments invoice={invoice} />
          </StyledTd>
          <StyledTd colSpan={3}>
            <Paragraph mr textAlign="right">
              Subtotal:
            </Paragraph>
          </StyledTd>
          <StyledTd>
            <Paragraph textAlign="right">
              {currency?.symbol}
              {parseIntoCurrencyString(subTotalAmount ?? totalAmount)}
            </Paragraph>
          </StyledTd>
        </tr>

        <tr>
          <StyledTd colSpan={3}>
            <Paragraph mr textAlign="right">
              Taxes:
            </Paragraph>
          </StyledTd>
          <StyledTd>
            <Paragraph textAlign="right">
              {currency?.symbol}
              {parseIntoCurrencyString(totalTaxAmount ?? 0)}
            </Paragraph>
          </StyledTd>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default LineItemsTable;
