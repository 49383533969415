import styled, { css } from 'styled-components';
import { Row } from '../Row/Row';
import { Paragraph } from '../Typography/Typography';

export const InputBaseWrapper = styled(Row)<{
  focused: boolean;
  error: boolean;
  disabled?: boolean;
}>`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.color.greyLight_3};
  flex: 1;
  align-self: stretch;
  max-height: 52px;
  box-shadow: ${({ focused, error, theme }) =>
    focused && !error && `0 0 0 1px ${theme.color.emeraldDark} inset`};
  box-shadow: ${({ error, theme }) =>
    error && `0 0 0 1px ${theme.color.red} inset`};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const Input = styled.input<{
  disabled?: boolean;
  withLabel?: boolean;
  showSpinButtons?: boolean;
}>`
  font-weight: 500;
  font-family: 'gilroy', Verdana, sans-serif;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding-left: ${({ theme }) => theme.spacing.m};
  padding-top: ${({ theme, withLabel }) => (withLabel ? theme.spacing.m : 0)};
  z-index: 1;
  flex: 1;
  min-height: 52px;
  color: ${({ disabled, theme }) => disabled && theme.color.grey};

  ${({ showSpinButtons }) =>
    showSpinButtons &&
    css`
      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: auto;
        margin: 0 4px 0 0;
      }
    `}
`;

export const MovingLabel = styled(Paragraph)<{
  moved: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  transition: all 0.3s ease;
  top: ${({ theme }) => theme.spacing.m};
  left: ${({ theme }) => theme.spacing.m};
  font-size: ${({ theme }) => theme.fontSize.s};
  top: ${({ theme, moved }) => moved && theme.spacing.xs};
  font-size: ${({ theme, moved }) => moved && theme.fontSize.xs};
  font-weight: 400;
  color: ${({ disabled, theme }) => disabled && theme.color.grey};
`;

export const InputRightButton = styled.button`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.m};

  &:hover,
  &:focus {
    svg {
      fill: ${({ theme }) => theme.color.emeraldDark};
    }
  }
`;
