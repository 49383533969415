import { FC, useCallback, useState, useRef, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { VariableSizeList } from 'react-window';
import { IInvoiceFromSearch } from 'types';
import { Col } from 'components/shared/Col/Col';
import InfiniteLoader from 'components/shared/Table/components/InfiniteLoader';
import MobileInvoicesTile from './components/MobileInvoicesTile/MobileInvoicesTile';
import InlineLoader from 'components/shared/InlineLoader/InlineLoader';
import { TableProps } from 'components/shared/Table/types';
import { InfiniteLoadingIndicatorWrapper } from 'components/shared/Table/Table.styles';
import MobileInvoicesFooter from './components/MobileInvoicesFooter/MobileInvoicesFooter';
import { IPaymentRunCurrencyTotal } from 'types/paymentRuns';

type TTablePropsToPick =
  | 'data'
  | 'withInfiniteLoading'
  | 'onLoadMoreItems'
  | 'itemsCount'
  | 'loadingThreshold'
  | 'isLoadingMoreItems'
  | 'hasMoreToLoad'
  | 'selectable';

interface IOwnProps
  extends Pick<TableProps<IInvoiceFromSearch>, TTablePropsToPick> {
  isPaid: boolean;
  isSubmitted: boolean;
  onSelectInvoices?: (invoices: IInvoiceFromSearch[]) => void;
  currencyTotals: Pick<
    IPaymentRunCurrencyTotal,
    'currency' | 'amount' | 'amountInLocalCurrency'
  >[];
  showFooter?: boolean;
  hasPaymentRun?: boolean;
  removeInvoiceFromPaymentRun?: (paymentRunInvoiceId: string) => Promise<void>;
  isUpdatingPaymentRun?: boolean;
}

const MobileInvoicesList: FC<IOwnProps> = ({
  data,
  withInfiniteLoading,
  onLoadMoreItems,
  loadingThreshold,
  isLoadingMoreItems,
  itemsCount = 0,
  hasMoreToLoad,
  onSelectInvoices: onSelectInvoicesCallback,
  isPaid,
  isSubmitted,
  selectable,
  currencyTotals,
  showFooter,
  hasPaymentRun,
  removeInvoiceFromPaymentRun,
  isUpdatingPaymentRun,
}) => {
  const theme = useTheme();
  const isItemLoaded = useCallback(
    (index) => !hasMoreToLoad || index < data.length,
    [hasMoreToLoad, data.length]
  );
  const [
    mobileInvoicesWrapperHeight,
    setMobileInvoicesWrapperHeight,
  ] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState<
    IInvoiceFromSearch[]
  >([]);
  const mobileInvoicesWrapperRef = useRef<HTMLTableSectionElement>(null);

  const onSelectInvoices = useCallback(
    (invoices: IInvoiceFromSearch[]) => {
      setSelectedInvoices(invoices);

      onSelectInvoicesCallback?.(invoices);
    },
    [onSelectInvoicesCallback]
  );

  useEffect(() => {
    if (mobileInvoicesWrapperRef.current) {
      setMobileInvoicesWrapperHeight(
        showFooter
          ? mobileInvoicesWrapperRef.current.scrollHeight
          : mobileInvoicesWrapperRef.current.getBoundingClientRect().height
      );
    }
  }, [showFooter]);

  return (
    <>
      <Col
        mt
        mtValue={theme.spacing.xxs}
        ref={mobileInvoicesWrapperRef}
        gap={theme.spacing.xxs}
        flex={1}
      >
        {!withInfiniteLoading &&
          data.map((invoice) => (
            <MobileInvoicesTile
              key={invoice.id}
              invoice={invoice}
              isPaid={isPaid}
              isSubmitted={isSubmitted}
              selectedInvoices={selectedInvoices}
              onSelectInvoices={onSelectInvoices}
              selectable={selectable}
              hasPaymentRun={hasPaymentRun}
              removeInvoiceFromPaymentRun={removeInvoiceFromPaymentRun}
              isUpdatingPaymentRun={isUpdatingPaymentRun}
            />
          ))}

        {withInfiniteLoading && (
          <InfiniteLoader
            enabled={!!withInfiniteLoading}
            isItemLoaded={isItemLoaded}
            itemCount={hasMoreToLoad ? itemsCount + 1 : itemsCount}
            loadMoreItems={onLoadMoreItems}
            threshold={loadingThreshold}
          >
            {(infiniteLoaderProps) => {
              const { onItemsRendered } = infiniteLoaderProps || {};

              return (
                <VariableSizeList
                  height={mobileInvoicesWrapperHeight}
                  itemCount={itemsCount}
                  itemSize={() => 93}
                  width="100%"
                  style={{
                    maxHeight: mobileInvoicesWrapperHeight,
                    minWidth: '100%',
                    overflow: 'overlay',
                  }}
                  onItemsRendered={onItemsRendered}
                >
                  {(variableSizeListData) => {
                    const invoice = data[variableSizeListData.index];

                    return (
                      <MobileInvoicesTile
                        key={invoice.id}
                        invoice={invoice}
                        isPaid={isPaid}
                        isSubmitted={isSubmitted}
                        selectedInvoices={selectedInvoices}
                        onSelectInvoices={onSelectInvoices}
                        selectable={selectable}
                        hasPaymentRun={hasPaymentRun}
                        removeInvoiceFromPaymentRun={
                          removeInvoiceFromPaymentRun
                        }
                        isUpdatingPaymentRun={isUpdatingPaymentRun}
                        {...variableSizeListData}
                      />
                    );
                  }}
                </VariableSizeList>
              );
            }}
          </InfiniteLoader>
        )}

        {withInfiniteLoading && isLoadingMoreItems && (
          <InfiniteLoadingIndicatorWrapper>
            <InlineLoader />
          </InfiniteLoadingIndicatorWrapper>
        )}
      </Col>

      {!!showFooter && <MobileInvoicesFooter currencyTotals={currencyTotals} />}
    </>
  );
};

export default MobileInvoicesList;
