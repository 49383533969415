import { FC, ReactNode } from 'react';
import { ListOnItemsRenderedProps } from 'react-window';
import InfiniteLoaderReactWindow from 'react-window-infinite-loader';

interface OwnProps {
  enabled: boolean;
  isItemLoaded: (index: number) => boolean;
  loadMoreItems?: (startIndex: number, stopIndex: number) => Promise<void>;
  itemCount: number;
  threshold?: number;
  children: (props?: {
    onItemsRendered: (props: ListOnItemsRenderedProps) => unknown;
    ref: (ref: unknown) => void;
  }) => ReactNode;
}

const InfiniteLoader: FC<OwnProps> = ({
  children,
  enabled,
  isItemLoaded,
  loadMoreItems = async () => {},
  itemCount,
  threshold = 10,
}) => {
  return enabled ? (
    <InfiniteLoaderReactWindow
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={threshold}
    >
      {children}
    </InfiniteLoaderReactWindow>
  ) : (
    <>{children()}</>
  );
};

export default InfiniteLoader;
