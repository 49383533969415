import dayjs from 'dayjs';
import { Paragraph, Row, StaleInfo, Col } from 'components';
import { IInvoiceFromSearch, INVOICE_STATUSES } from 'types';
import { firstCharacterToUppercase } from 'utils';
import { useStoreState } from 'state';
import { isPayableInvoice } from 'utils/invoices';
import { DATE_FORMAT, TIME_FORMAT } from 'variables';

export const InvoiceFromSearchStatusCell: React.FC<{
  invoice: IInvoiceFromSearch;
}> = ({ invoice }) => {
  const { userEntity, hasApprovalFlow, entityUsers } = useStoreState(
    ({ UserState }) => UserState
  );

  const requiredNumberOfApprovalsOnUserEntity =
    userEntity?.approvalSettings?.requiredNumberOfApprovals || 1;

  const moreThenOneApprover = requiredNumberOfApprovalsOnUserEntity > 1;

  const approvedByName = entityUsers.find(
    (entityUser) => entityUser.id === invoice.approvedBy
  )?.name;
  const approvedBy2Name = entityUsers.find(
    (entityUser) => entityUser.id === invoice.approvedBy2
  )?.name;

  return (
    <StaleInfo
      disabled={!invoice.approvalStatus}
      mode="hover"
      strategy="fixed"
      portal
      placement="top"
      infoSize="auto"
      trigger={
        <Row>
          {hasApprovalFlow &&
          (invoice.status === INVOICE_STATUSES.authorised ||
            invoice.status === INVOICE_STATUSES.partiallyPaid) &&
          isPayableInvoice(invoice) ? (
            <Paragraph>
              {invoice.approvalStatus &&
                invoice.approvalStatus === 'submitted' &&
                `${
                  invoice.approvedBy
                    ? requiredNumberOfApprovalsOnUserEntity - 1
                    : requiredNumberOfApprovalsOnUserEntity
                } Approval(s) pending`}

              {invoice.approvalStatus &&
                invoice.approvalStatus !== 'submitted' &&
                firstCharacterToUppercase(invoice.approvalStatus)}

              {!invoice.approvalStatus && 'Needs approval'}
            </Paragraph>
          ) : (
            <Paragraph>
              {invoice.excludeFromRisk ? 'EXCLUDED' : invoice.status}
            </Paragraph>
          )}
        </Row>
      }
    >
      <Col>
        <Paragraph color="white">
          Approver {moreThenOneApprover ? '1' : ''}: {approvedByName || '-'}
        </Paragraph>
        <Paragraph color="white">
          Approved at:{' '}
          {invoice.approvedAt
            ? dayjs(invoice.approvedAt).format(`${DATE_FORMAT} ${TIME_FORMAT}`)
            : '-'}
        </Paragraph>

        {moreThenOneApprover && (
          <>
            <Paragraph mt color="white">
              Approver 2: {approvedBy2Name || '-'}
            </Paragraph>
            <Paragraph color="white">
              Approved at:{' '}
              {invoice.approvedAt2
                ? dayjs(invoice.approvedAt2).format(
                    `${DATE_FORMAT} ${TIME_FORMAT}`
                  )
                : '-'}
            </Paragraph>
          </>
        )}
      </Col>
    </StaleInfo>
  );
};
