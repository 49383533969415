import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { Firebase } from 'services';
import {
  createTransfer,
  getTransferPurposes,
} from 'services/firebase/transfers';

import { IRateContract, ITransfer, ITransferInput, Nullable } from 'types';
import { Notify } from 'utils';

export interface TransfersStateModel {
  transfers: ITransfer[];
  transfersByRateContract: Computed<
    TransfersStateModel,
    (rateContractId: IRateContract['id'] | undefined) => ITransfer[]
  >;
  transferById: Computed<
    TransfersStateModel,
    (transferId?: Nullable<string>) => ITransfer | null
  >;
  setState: Action<TransfersStateModel, [string, any]>;
  subscribeToTransfers: Thunk<
    TransfersStateModel,
    Omit<Firebase.SubscribeToTransfersParams, 'callback'>,
    null,
    object,
    (() => void) | undefined
  >;
  createTransfer: Thunk<TransfersStateModel, ITransferInput>;
  getTransferPurposes: Thunk<
    TransfersStateModel,
    Firebase.GetTransferPurposesParams
  >;
  transfersByBulkId: Computed<
    TransfersStateModel,
    (bulkId: Nullable<string>) => ITransfer[]
  >;
}

export const TransfersState: TransfersStateModel = {
  transfers: [],
  transfersByRateContract: computed(
    [(state) => state.transfers],
    (transfers) => (rateContractId) =>
      transfers.filter((item) => item.contractId === rateContractId)
  ),
  transferById: computed(
    [(state) => state.transfers],
    (transfers) => (transferId) => {
      if (!transferId) {
        return null;
      }

      return transfers.find((item) => item.id === transferId) || null;
    }
  ),
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  subscribeToTransfers: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToTransfers({
      ...payload,
      callback: (rateContracts) => setState(['transfers', rateContracts]),
    });

    return subscriber;
  }),
  createTransfer: thunk(async (_, payload) => {
    const data = await createTransfer(payload);

    return data;
  }),
  getTransferPurposes: thunk(async (_, payload) => {
    try {
      const { data } = await getTransferPurposes(payload);

      if (data.success) {
        return data;
      } else {
        Notify.error(data.message ?? '');
      }
    } catch (error: any) {
      console.warn(error);
    }
  }),
  transfersByBulkId: computed(
    [(state) => state.transfers],
    (transfers) => (bulkId) =>
      transfers.filter((item) => item.bulkPaymentId === bulkId)
  ),
};
