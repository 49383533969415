import { Row } from 'components';
import styled from 'styled-components';

export const InvoiceDetailsFooterWrapper = styled(Row)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.m};
  flex-wrap: wrap;
  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex-wrap: nowrap;
  }
`;
