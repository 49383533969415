import { Popup, Col, Title, Row } from 'components';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import CreatableSelectMenu from 'components/shared/CreatableSelectMenu/CreatableSelectMenu';
import { CreatableSelectMenuOption } from 'components/shared/CreatableSelectMenu/types';
import {
  createCreatableSelectMenuOption,
  onValidateEmails,
} from 'components/shared/CreatableSelectMenu/utils';
import { StyledForm } from 'components/shared/Form/Form.styles';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import { transferSendRemittance } from 'services/firebase';
import { useTheme } from 'styled-components';
import { IContact, ITransfer } from 'types';
import { Notify } from 'utils';
import { errorHandler } from 'utils/errors';

interface IOwnProps {
  onClose: () => void;
  transferRecipient: IContact;
  transferId: ITransfer['id'];
}

interface IInputs {
  emails: CreatableSelectMenuOption[];
  shouldSendRemittance: boolean;
}

const PopupSendRemittance: FC<IOwnProps> = ({
  onClose,
  transferRecipient,
  transferId,
}) => {
  const theme = useTheme();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { control, handleSubmit, watch, setValue } = useForm<IInputs>({
    mode: 'all',
    defaultValues: {
      shouldSendRemittance: transferRecipient?.shouldSendRemittance,
      emails: transferRecipient?.contactEmail
        ? transferRecipient.contactEmail
            .split(',')
            .map(createCreatableSelectMenuOption)
        : [],
    },
  });

  const emails = watch('emails');

  const onSubmit = async (values: IInputs) => {
    try {
      setIsSubmiting(true);
      const { data } = await transferSendRemittance({
        transferId,
        emails: values.emails.map((email) => email.value).join(','),
        shouldSendRemittance: values.shouldSendRemittance,
      });

      if (data.success) {
        Notify.success('Remittance sent successfully');
        setIsSubmiting(false);
        onClose();
      } else {
        setIsSubmiting(false);
        errorHandler(data?.message);
      }
    } catch (error) {
      setIsSubmiting(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!emails.length) {
      setValue('shouldSendRemittance', false);
    }
  }, [emails, setValue]);

  return (
    <Popup
      width="450px"
      onClose={onClose}
      HeaderContent={<Title variant="h3">Send remittance</Title>}
      FooterContent={
        <Row flex={1} gap={theme.spacing.m}>
          <Button disabled={isSubmiting} variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            form="send-remittance-form"
            disabled={isSubmiting}
            type="submit"
          >
            {!emails.length ? 'Save' : 'Send'}
          </Button>
        </Row>
      }
    >
      <StyledForm
        id="send-remittance-form"
        gap={theme.spacing.xs}
        alignItems="stretch"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Col gap={theme.spacing.m}>
          <Controller
            name="emails"
            control={control}
            rules={{
              validate: onValidateEmails,
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <CreatableSelectMenu
                name={name}
                label="Email"
                data={value}
                onChange={onChange}
                error={error?.message}
                disabled={isSubmiting}
              />
            )}
          />

          <Controller
            name="shouldSendRemittance"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="Send automatic remittance emails to this address for this recipient"
                checked={value}
                onChange={onChange}
                disabled={!emails.length || isSubmiting}
              />
            )}
          />
        </Col>
      </StyledForm>
    </Popup>
  );
};

export default PopupSendRemittance;
