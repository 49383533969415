import HexagonRating from 'components/shared/HexagonRating/HexagonRating';
import { getHexagonsStatus } from 'components/shared/HexagonRating/utils';
import useRiskRating from 'hooks/useRiskRating';
import { FC } from 'react';
import { getRiskLevelRatingText } from 'utils/analysis';
import { getColorKey } from 'components/shared/HexagonRating/utils';

interface OwnProps {
  cashflowAtRisk: number;
}

const RiskRatingCell: FC<OwnProps> = ({ cashflowAtRisk }) => {
  const { riskRating } = useRiskRating(cashflowAtRisk);
  const title = getRiskLevelRatingText(riskRating);
  const status = getHexagonsStatus(true, 'asc', riskRating);
  const color = getColorKey(status);

  return (
    <HexagonRating
      title={title}
      titleColor={color}
      rating={riskRating}
      status={status}
    />
  );
};

export default RiskRatingCell;
