import { DefaultThemeColorKey } from 'styled-components';
import { RatingStatus } from './HexagonRating';

export const getHexagonsStatus = (
  dataAvailable: boolean,
  direction: 'asc' | 'desc',
  rating?: number
): RatingStatus => {
  if (!dataAvailable || !rating) {
    return 'neutral';
  }

  if (direction === 'asc') {
    if (rating <= 2) {
      return 'good';
    }

    if (rating <= 4) {
      return 'neutral';
    }

    return 'bad';
  }

  if (rating === 1) {
    return 'bad';
  }

  if (rating <= 3) {
    return 'neutral';
  }

  return 'good';
};

export const getColorKey = (status?: RatingStatus): DefaultThemeColorKey => {
  if (status === 'good') {
    return 'green';
  }

  if (status === 'bad') {
    return 'red';
  }

  if (status === 'neutral') {
    return 'coral';
  }

  return 'black';
};
