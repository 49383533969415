import { FC } from 'react';
import {
  getCompanyRegistrationPageLink,
  getSettingsPageLink,
} from 'utils/links';
import CTACard from '../CTACard/CTACard';
import { AwaitingRegistrationDashboardSectionWrapper } from '../../AwaitingRegistrationDashboard.styles';
import { useStoreState } from 'state';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import useIsAwaitingRegistration from 'hooks/useIsAwaitingRegistration';

const CTACards: FC = () => {
  const {
    isFxManagementPackageEnabled,
    isAutomationPackageEnabled,
  } = useStoreState((state) => state.UserState);
  const { isIntegrated } = useIntegrationEngine();
  const isAwaitingRegistration = useIsAwaitingRegistration();

  const CTA_CARDS = [
    {
      id: 1,
      title: '1. Connect your system',
      text:
        'HedgeFlows turns your accounting or ERP system into a modular treasury platfrom',
      buttonText: 'Connect',
      link: getSettingsPageLink({ tab: 'integrations' }),
      isDone: isIntegrated,
    },
    {
      id: 2,
      title: '2. Select your package',
      text:
        'Choose modules that are right for your business an start your free trial',
      buttonText: 'Start free trial',
      link: getSettingsPageLink({ tab: 'subscriptions' }),
      isDone: isFxManagementPackageEnabled || isAutomationPackageEnabled,
    },
    {
      id: 3,
      title: '3. Register your business',
      text:
        'Tell us about your business to start paying and collecting in foreign currencies.',
      buttonText: 'Register',
      link: getCompanyRegistrationPageLink(),
      isDone: !isAwaitingRegistration,
    },
  ];

  return (
    <AwaitingRegistrationDashboardSectionWrapper>
      {CTA_CARDS.map((card) => (
        <CTACard key={card.id} {...card} />
      ))}
    </AwaitingRegistrationDashboardSectionWrapper>
  );
};

export default CTACards;
