import styled from 'styled-components';
import { Col } from '../shared/Col/Col';

export const AppLayout = styled(Col)`
  height: 100vh;
`;

export const ResponsiveCenteredContainer = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.smallMin}px) {
    width: ${({ theme }) => theme.breakpoint.smallMin}px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.mediumMin}px) {
    padding: 0 ${({ theme }) => theme.spacing.l};
    width: ${({ theme }) => theme.breakpoint.mediumMin}px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    width: ${({ theme }) => theme.breakpoint.largeMin}px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.extraLargeMin}px) {
    width: ${({ theme }) => theme.breakpoint.extraLargeMin}px;
  }
`;
