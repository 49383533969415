import styled, { DefaultThemeColorKey } from 'styled-components';
import { Paragraph } from '../Typography/Typography';

export const ContactReviewStatusWrapper = styled(Paragraph)<{
  color: DefaultThemeColorKey;
}>`
  border: 1px solid ${({ theme, color }) => theme.color[color]};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
`;
