import { FC } from 'react';
import { useStoreState } from 'state';
import { Col, Paragraph, Row, Loader } from 'components';
import DirectionIcon from 'components/shared/DirectionIcon/DirectionIcon';
import { parseIntoShortNumberWithSymbol } from 'utils';
import NetWorthInfoRow from '../NetWorthInfoRow/NetWorthInfoRow';
import { NetWrapper } from './NetWorth.styles';
import NetWorthSection from '../NetWorthSection/NetWorthSection';
import InflowsIcon from 'components/shared/InflowsIcon/InflowsIcon';
import OutflowsIcon from 'components/shared/OutflowsIcon/OutflowsIcon';
import HedgesIcon from '../../../HedgesIcon/HedgesIcon';
import { IRiskGroupedByEntityData } from 'services/firebase/analysis';

interface IOwnProps {
  values?: IRiskGroupedByEntityData['netWorthValues'];
  currencyCode: string | null;
}

const NetWorth: FC<IOwnProps> = ({ currencyCode, values }) => {
  const { userEntity } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const entityCurrencySymbol = currencyByCode(userEntity?.entityCurrency)
    ?.symbol;
  const currency =
    currencyByCode(currencyCode) || currencyByCode(userEntity?.entityCurrency);
  const isEntityCurrency = currency?.code === userEntity?.entityCurrency;

  return (
    <Col alignSelf="stretch" justifyContent="flex-start" flex={0.4}>
      {!values ? (
        <Loader size="large" />
      ) : (
        <>
          <NetWorthSection
            zIndex={4}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetWorthInfoRow
                title="Cash balance"
                amount={values?.balance + values?.externalBalance}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
              />
            )}
            expansionContent={
              <>
                <NetWorthInfoRow
                  title="with HedgeFlows"
                  amount={values?.balance}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetWorthInfoRow
                  title="with 3d parties"
                  amount={values?.externalBalance}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
              </>
            }
          />
          <NetWorthSection
            zIndex={3}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetWorthInfoRow
                title="Cash Inflows"
                amount={values?.receivables}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
                icon={<InflowsIcon />}
              />
            )}
            expansionContent={values?.entityCashflowInflows.map((cashflow) => (
              <NetWorthInfoRow
                title={cashflow.entityName}
                amount={cashflow.value}
                variant="grey"
                currencySymbol={currency?.symbol}
              />
            ))}
          />
          <NetWorthSection
            zIndex={2}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetWorthInfoRow
                title="Cash Outflows"
                amount={values?.payables}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
                icon={<OutflowsIcon />}
              />
            )}
            expansionContent={values?.entityCashflowOutflows.map((cashflow) => (
              <NetWorthInfoRow
                title={cashflow.entityName}
                amount={cashflow.value}
                variant="grey"
                currencySymbol={currency?.symbol}
              />
            ))}
          />
          <NetWorthSection
            zIndex={1}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetWorthInfoRow
                title="Hedges"
                amount={values?.prebooked + values?.externalHedges}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
                icon={<HedgesIcon />}
              />
            )}
            expansionContent={values?.entityHedges.map((hedge) => (
              <NetWorthInfoRow
                title={hedge.entityName}
                amount={hedge.value}
                variant="grey"
                currencySymbol={currency?.symbol}
              />
            ))}
          />
          <NetWorthSection
            zIndex={0}
            renderContent={() => (
              <NetWrapper>
                <Row>
                  <Paragraph variant="bold">Net balance:</Paragraph>

                  <Row>
                    <DirectionIcon
                      direction={values?.netExposure >= 0 ? 'in' : 'out'}
                    />
                    <Paragraph variant="bold">
                      {parseIntoShortNumberWithSymbol(
                        values?.netExposure,
                        currency?.symbol
                      )}
                    </Paragraph>
                  </Row>
                </Row>
                {!isEntityCurrency && (
                  <Row>
                    <Paragraph>{userEntity.entityCurrency} value</Paragraph>
                    <Paragraph>
                      {parseIntoShortNumberWithSymbol(
                        values?.netExposureInSellCurrency,
                        entityCurrencySymbol
                      )}
                    </Paragraph>
                  </Row>
                )}
              </NetWrapper>
            )}
          />
        </>
      )}
    </Col>
  );
};

export default NetWorth;
