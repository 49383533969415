import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Title } from 'components';
import Button from 'components/shared/Button/Button';
import { useStoreActions } from 'state';
import { useTheme } from 'styled-components';
import ErrorPageTemplate from 'components/pageTemplates/ErrorPageTemplate/ErrorPageTemplate';

const AccessError: FC<RouteComponentProps> = ({ history }) => {
  const theme = useTheme();
  const { signOut } = useStoreActions((actions) => actions.UserState);

  const logOutHandler = async () => {
    await signOut();
    history.replace('/login');
  };

  return (
    <ErrorPageTemplate>
      <Title>You don’t have access to this page.</Title>
      <Title variant="h2">
        You believe this is incorrect, please contact support.
      </Title>

      <Button
        mt
        mtValue={theme.spacing.xxl}
        icon="logout-ico"
        onClick={logOutHandler}
      >
        Log out
      </Button>
    </ErrorPageTemplate>
  );
};

export default AccessError;
