import { FC } from 'react';
import { useStoreState } from 'state';
import { Row, Icon, Title, StaleInfo, Paragraph } from 'components';
import { useTheme } from 'styled-components';

interface OwnProps {
  currencyCode: string;
  valid: boolean;
}

const AuthoriseCurrencyCell: FC<OwnProps> = ({ currencyCode, valid }) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const currency = currencyByCode(currencyCode);

  if (!currency) {
    return null;
  }

  return (
    <Row columnGap={theme.spacing.s} alignItems="center">
      <Icon
        width={theme.iconSizes.l}
        height={theme.iconSizes.l}
        icon={currency.countryCode}
      />
      <Title variant="h3">{currencyCode}</Title>

      {!valid && (
        <StaleInfo strategy="fixed" mode="hover" placement="top" portal>
          <Paragraph color="white">Something is not valid.</Paragraph>
        </StaleInfo>
      )}
    </Row>
  );
};

export default AuthoriseCurrencyCell;
