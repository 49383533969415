import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generateTableColumns } from './tableColumnsGenerator';
import { IInvoiceFromSearch } from 'types';
import { SortingRule } from 'react-table';
import { getInvoicesFilters } from 'pages/Invoices/utils';
import { getSortQueryStringFromTableSort } from 'utils/search';
import { isEqual } from 'lodash';
import useInvoices from 'hooks/useInvoices';
import { ExposedUseTableProps } from 'components/shared/Table/types';
import Controls from './components/Controls/Controls';
import SidebarPageTemplate from 'components/pageTemplates/SidebarPageTemplate/SidebarPageTemplate';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import { Table } from 'components';

const HedgeInvoices: FC = () => {
  const tableColumns = useMemo(() => generateTableColumns(), []);
  const [currency, setCurrency] = useState('all');
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('nextMonth');
  const [isLoadingFirstInvoicesPage, setIsLoadingFirstInvoicesPage] = useState(
    true
  );
  const [sortState, setSortState] = useState<SortingRule<IInvoiceFromSearch>[]>(
    []
  );
  const tableRef = useRef<ExposedUseTableProps<IInvoiceFromSearch>>(null);

  const {
    invoices,
    currentPage,
    hasMoreToLoad,
    isLoadingMoreInvoices,
    fetchInvoicesWithSideEffects,
  } = useInvoices();

  const fetchFirstInvoicesPage = useCallback(async () => {
    const filters = getInvoicesFilters({
      filterName: filter,
      currency,
    });
    const sortFields = getSortQueryStringFromTableSort(sortState);

    tableRef.current?.toggleAllRowsSelected(false);

    fetchInvoicesWithSideEffects({
      page: 1,
      searchQuery: search ?? '',
      filters: filters + ',filter=outstandingAll',
      sortFields,
      reset: true,
      withRiskContributions: true,
    }).finally(() => {
      setIsLoadingFirstInvoicesPage(false);
    });
  }, [filter, currency, sortState, fetchInvoicesWithSideEffects, search]);

  useEffect(() => {
    fetchFirstInvoicesPage();
  }, [fetchFirstInvoicesPage]);

  const handleSortChange = useCallback(
    (tableSortState: SortingRule<IInvoiceFromSearch>[]) => {
      if (isEqual(tableSortState, sortState)) {
        return;
      }

      setSortState(tableSortState);
    },
    [sortState]
  );

  const onLoadMoreItems = useCallback(async () => {
    if (isLoadingMoreInvoices) {
      return;
    }

    const filters =
      getInvoicesFilters({
        filterName: filter,
        currency,
      }) + ',filter=outstandingAll';

    return fetchInvoicesWithSideEffects({
      page: currentPage + 1,
      searchQuery: search ?? '',
      filters,
      sortFields: getSortQueryStringFromTableSort(sortState),
      withRiskContributions: true,
    });
  }, [
    currency,
    currentPage,
    fetchInvoicesWithSideEffects,
    filter,
    isLoadingMoreInvoices,
    search,
    sortState,
  ]);

  return (
    <SidebarPageTemplate>
      <TableHeader>
        <Controls
          currency={currency}
          setCurrency={setCurrency}
          filter={filter}
          setFilter={setFilter}
          search={search}
          setSearch={setSearch}
        />
      </TableHeader>

      <Table
        ref={tableRef}
        data={invoices as IInvoiceFromSearch[]}
        columns={tableColumns}
        onLoadMoreItems={onLoadMoreItems}
        hasMoreToLoad={hasMoreToLoad}
        isLoadingMoreItems={isLoadingMoreInvoices}
        itemsCount={invoices.length}
        isLoading={isLoadingFirstInvoicesPage}
        withInfiniteLoading
        onSort={handleSortChange}
        isVirtualized
        sortable
        manualSortBy
      />
    </SidebarPageTemplate>
  );
};

export default HedgeInvoices;
