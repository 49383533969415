import {
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { Row } from 'react-table';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { Col } from '../../Col/Col';

import { TableProps } from '../types';
import { TableDataCell, TableRow } from '../Table.styles';
import { ROW_BOTTOM_BORDER_HEIGHT } from '../consts';

interface OwnProps<T extends object>
  extends ListChildComponentProps,
    Pick<TableProps<T>, 'isRowDisabled' | 'onRowClick' | 'expansionRender'> {
  rowHeight: number;
  rows: Row<T>[];
  prepareRow: (row: Row<T>) => void;
  setExpandedRowSizes: Dispatch<SetStateAction<Record<string, number>>>;
  expandedRowSizes: Record<string, number>;
  listRef: MutableRefObject<VariableSizeList<any> | null>;
}

const VirtualizedRow = <T extends object>({
  index,
  style,
  rows,
  listRef,
  expansionRender,
  prepareRow,
  isRowDisabled,
  onRowClick,
  setExpandedRowSizes,
  expandedRowSizes,
  rowHeight,
}: PropsWithChildren<OwnProps<T>>) => {
  const row = rows[index];

  prepareRow(row);

  const isDisabled = isRowDisabled?.(row.original);
  const expansionWrapperRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (
      row.isExpanded &&
      !expandedRowSizes[index] &&
      expansionWrapperRef.current
    ) {
      const expansionHeight = expansionWrapperRef.current.getBoundingClientRect()
        .height;

      setExpandedRowSizes((prevState) => ({
        ...prevState,
        [index]: expansionHeight,
      }));
    } else if (!row.isExpanded && expandedRowSizes[index]) {
      setExpandedRowSizes((prevState) => ({ ...prevState, [index]: 0 }));
    }
  }, [index, row, expandedRowSizes, setExpandedRowSizes, listRef]);

  useEffect(() => {
    if (!expansionWrapperRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (
        row.isExpanded &&
        expandedRowSizes[index] === ROW_BOTTOM_BORDER_HEIGHT &&
        expansionWrapperRef.current
      ) {
        const expansionHeight = expansionWrapperRef.current.getBoundingClientRect()
          .height;

        if (expansionHeight !== expandedRowSizes[index]) {
          setExpandedRowSizes((prevState) => ({
            ...prevState,
            [index]: expansionHeight,
          }));
        }
      }
    });

    resizeObserver.observe(expansionWrapperRef.current);

    return () => resizeObserver.disconnect();
  }, [expandedRowSizes, index, listRef, row.isExpanded, setExpandedRowSizes]);

  useEffect(() => {
    if (
      listRef.current &&
      expandedRowSizes[index] &&
      expandedRowSizes[index] !== ROW_BOTTOM_BORDER_HEIGHT
    ) {
      listRef.current.resetAfterIndex(index);
    }
  }, [expandedRowSizes, index, listRef]);

  return (
    <Col
      {...row.getRowProps({
        style,
      })}
    >
      <TableRow
        as="tr"
        onClick={() => onRowClick?.(row.original)}
        disabled={isDisabled}
        isClickable={!!onRowClick}
        height={rowHeight}
      >
        {row.cells.map((cell) => {
          const isExpanderCell = cell.column.id === 'expander';
          const isSelectableCell = cell.column.id === 'selection';

          return (
            <TableDataCell
              as="td"
              isExpanderCell={isExpanderCell}
              isSelectableCell={isSelectableCell}
              {...cell.getCellProps()}
            >
              {cell.render('Cell')}
            </TableDataCell>
          );
        })}
      </TableRow>

      {row.isExpanded && (
        <TableRow as="tr" ref={expansionWrapperRef}>
          {row.isExpanded && expansionRender?.(row.original)}
        </TableRow>
      )}
    </Col>
  );
};

export default VirtualizedRow;
