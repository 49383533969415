import { FC } from 'react';
import { useTheme } from 'styled-components';
import Icon from '../Icon/Icon';

interface DirectionCellProps {
  direction?: 'in' | 'out';
}

const DirectionIcon: FC<DirectionCellProps> = ({ direction = 'in' }) => {
  const theme = useTheme();

  return (
    <Icon
      width="24px"
      height="24px"
      icon="pay-ico"
      color={direction === 'in' ? theme.color.green : theme.color.red}
      style={{
        fill: direction === 'in' ? theme.color.green : theme.color.red,
        transform: direction === 'in' ? 'rotate(180deg)' : 'none',
      }}
    />
  );
};

export default DirectionIcon;
