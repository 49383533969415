import styled, { css } from 'styled-components';
import { StepsNav, StepContent } from 'components';
import { StyledButton } from 'components/shared/Button/Button.styles';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: ${theme.color.white};
    display: flex;
    flex-direction: column;

    ${StepsNav} {
      position: static;
      flex: 1 0 auto;
    }

    ${StepContent} {
      flex: 0 1 auto;
      height: 100%;
      overflow: auto;
    }

    .field {
      margin-top: 16px;

      .resend {
        color: ${theme.color.greyDark};
        margin-top: 7px;
      }
    }

    .btns-group {
      margin-top: auto;
      padding: 16px;
      text-align: center;
      color: ${theme.color.greyDark};
      background: ${theme.color.white};
      box-shadow: ${theme.shadow};
    }

    .or {
      display: flex;
      justify-content: center;
      position: relative;
      margin: 24px 0;

      span {
        font-weight: 500;
        padding: 0 10px;
        background: ${theme.color.white};
        position: relative;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: ${theme.color.greyLight_2};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
      }
    }

    form {
      & > ${StyledButton} {
        margin: 24px 0;
      }
    }

    hr {
      background: ${theme.color.greyLight_2};
      height: 1px;
      width: 100%;
      margin: 18px 0;
    }

    .company-info {
      width: 100%;
      display: block;
      border-radius: 6px;
      padding: 8px 14px;
      background: ${theme.color.greyLight_4};
      border: 1px solid ${theme.color.greyLight_2};
      transition: ${theme.transition};
    }

    .additional {
      .row {
        justify-content: flex-start;

        &:not(:first-child) {
          margin-top: 22px;
        }
      }
    }

    .quiz {
      padding-bottom: 4px;
    }

    .reviewed {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: auto;
    }

    .onfido-sdk-ui-Modal-inner {
      border: none;
      background-color: #fff;
    }
  `
);
