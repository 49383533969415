import { useState, useMemo, Dispatch, SetStateAction } from 'react';
import sortBy from 'lodash.sortby';

import { useStoreState } from 'state';
import { canUseRateContract, getFlexCurrentBestRateUnparsed } from 'utils';
import { IRateContract, Nullable } from 'types';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';
import { useAllocatePrebooksPermissionsCheck } from './useSpecificPermissionsCheck';

interface UseRateContractsParams {
  buyCurrencyCode: string;
  sellCurrencyCode: string;
  rate: UseCurrencyRateReturnValues['rate'];
  predefinedRateContractId?: Nullable<string>;
  invoiceId?: string | null;
}

export interface UseRateContractsReturnValues {
  rateContracts: IRateContract[];
  selectedRateContract?: IRateContract;
  setSelectedRateContract: Dispatch<
    SetStateAction<IRateContract | null | undefined>
  >;
}

const useRateContracts = ({
  buyCurrencyCode,
  sellCurrencyCode,
  rate,
  predefinedRateContractId,
  invoiceId,
}: UseRateContractsParams): UseRateContractsReturnValues => {
  const { rateContractsByCurrencyPair, rateContractById } = useStoreState(
    ({ RateContractsState }) => RateContractsState
  );

  const [selectedRateContract, setSelectedRateContract] = useState(
    predefinedRateContractId
      ? rateContractById(predefinedRateContractId)
      : undefined
  );

  const hasAllocatePrebooksPermission = useAllocatePrebooksPermissionsCheck();

  const rateContracts = useMemo(() => {
    const filteredRateContracts =
      sellCurrencyCode && buyCurrencyCode
        ? rateContractsByCurrencyPair(sellCurrencyCode, buyCurrencyCode).filter(
            (item) =>
              canUseRateContract({
                rateContract: item,
                invoiceId,
                hasAllocatePrebooksPermission,
              })
          )
        : [];

    return sortBy(filteredRateContracts, (item) =>
      getFlexCurrentBestRateUnparsed(item, rate)
    ).reverse();
  }, [
    buyCurrencyCode,
    invoiceId,
    rate,
    rateContractsByCurrencyPair,
    sellCurrencyCode,
    hasAllocatePrebooksPermission,
  ]);

  return {
    rateContracts,
    selectedRateContract: selectedRateContract || undefined,
    setSelectedRateContract,
  };
};

export default useRateContracts;
