import { FC } from 'react';
import { StaleNotification } from 'components';
import useIsAwaitingRegistration from 'hooks/useIsAwaitingRegistration';
import { Paragraph } from '../Typography/Typography';
import Link from '../Link/Link';
import { getCompanyRegistrationPageLink } from 'utils/links';

type Props = {
  title?: string;
  bgColor?: string;
  style?: object;
};

const StaleRegisterCompanyNotification: FC<Props> = ({
  title = 'Full access required to continue',
  bgColor = '#121213',
  style,
}) => {
  const isAwaitingRegistration = useIsAwaitingRegistration();

  return isAwaitingRegistration ? (
    <StaleNotification
      icon="look-smile"
      bgColor={bgColor}
      title={title}
      cross={false}
      style={style}
      titleColor="white"
    >
      <Paragraph color="white">
        To get full access to HedgeFlows please{' '}
        <Link display="inline-flex" href={getCompanyRegistrationPageLink()}>
          register your company
        </Link>
      </Paragraph>
    </StaleNotification>
  ) : null;
};

export default StaleRegisterCompanyNotification;
