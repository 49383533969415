import { FC, useMemo, useCallback, useState, ReactNode, Ref } from 'react';
import Table from '../Table/Table';
import { useStoreState } from 'state';
import { isInvoiceDisabled } from 'utils/invoices';
import InvoicesTableFooterContent from './components/InvoicesTableFooterContent/InvoicesTableFooterContent';
import { generateTableColumns } from './tableColumnsGenerator';
import Popups from './components/Popups/Popups';
import useInvoicePopups from './hooks/useInvoicePopups';
import useUrlValues from 'hooks/useUrlValues';
import { IInvoiceFromSearch } from 'types';
import { ExposedUseTableProps, TableProps } from '../Table/types';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import { isInvoicesTableRowSelectable } from './utils';
import DisabledCheckboxHint from './components/DisabledCheckboxHint/DisabledCheckboxHint';

type TTablePropsToPick =
  | 'isVirtualized'
  | 'data'
  | 'onRenderFooterContent'
  | 'selectable'
  | 'autoResetSelectedRows'
  | 'withInfiniteLoading'
  | 'onLoadMoreItems'
  | 'itemsCount'
  | 'loadingThreshold'
  | 'isLoadingMoreItems'
  | 'hasMoreToLoad'
  | 'manualSortBy'
  | 'onSort';

interface OwnProps
  extends Pick<TableProps<IInvoiceFromSearch>, TTablePropsToPick> {
  showFooter?: boolean;
  renderActionCell?: (invoice: IInvoiceFromSearch) => ReactNode;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
  tableRef?: Ref<ExposedUseTableProps<IInvoiceFromSearch>>;
  onSelectInvoices?: (invoices: IInvoiceFromSearch[]) => void;
  unselectInvoice?: (invoiceId: string) => void;
  paymentRunId?: string;
  removeInvoiceFromPaymentRun?: (paymentRunInvoiceId: string) => Promise<void>;
  isUpdatingPaymentRun?: boolean;
}

const InvoicesTable: FC<OwnProps> = ({
  data,
  isVirtualized = true,
  autoResetSelectedRows = false,
  onRenderFooterContent,
  showFooter,
  selectable,
  renderActionCell,
  withInfiniteLoading,
  onLoadMoreItems,
  loadingThreshold,
  isLoadingMoreItems,
  itemsCount = 0,
  hasMoreToLoad,
  updateInMemoryInvoices,
  manualSortBy,
  onSort,
  tableRef,
  onSelectInvoices,
  unselectInvoice,
  paymentRunId,
  removeInvoiceFromPaymentRun,
  isUpdatingPaymentRun,
}) => {
  const invoicePopupsHandlers = useInvoicePopups();
  const { tab } = useUrlValues('currency', 'tab');
  const { accountingIntegrationSystem } = useIntegrationEngine();

  const {
    setExistingInvoiceTracking,
    setInvoiceDecide,
    setCancelPrebookInvoice,
    setInvoicesForAllocateFx,
    setDeleteManualInvoice,
    setTransferIdToShowPaymentDetails,
    setRemoveExistingPrebookInvoice,
    setShowAllDecideFields,
    setContactForEdit,
    setInvoiceForAddContact,
  } = invoicePopupsHandlers;

  const { recipientById } = useStoreState((state) => state.RecipientsState);
  const {
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
    entityCurrencyCode,
    userId,
    isUserApprover,
    isUserSubmitter,
    hasApprovalFlow,
  } = useStoreState(({ UserState }) => UserState);
  const [selectedInvoices, setSelectedInvoices] = useState<
    IInvoiceFromSearch[]
  >([]);
  const isPaymentRunsEnabled =
    isAutomationPackageEnabled || isFxManagementPackageEnabled;
  const onSelectInvoice = useCallback(
    (_, rows) => {
      const invoicesToSelect: IInvoiceFromSearch[] = rows.map(
        (row: any) => row.original
      );
      setSelectedInvoices(invoicesToSelect);
      onSelectInvoices?.(invoicesToSelect);
    },
    [setSelectedInvoices, onSelectInvoices]
  );

  const showFooterInternal = showFooter ?? selectedInvoices.length > 0;

  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        setExistingInvoiceTracking,
        setInvoiceDecide,
        setShowAllDecideFields,
        setCancelPrebookInvoice,
        setInvoicesForAllocateFx,
        setDeleteManualInvoice,
        setTransferIdToShowPaymentDetails,
        setRemoveExistingPrebookInvoice,
        setContactForEdit,
        recipientById,
        setInvoiceForAddContact,
        renderActionCell,
        tab,
        accountingIntegrationSystem,
        entityCurrencyCode,
        updateInMemoryInvoices,
        paymentRunId,
        removeInvoiceFromPaymentRun,
        isUpdatingPaymentRun,
      }),
    [
      setExistingInvoiceTracking,
      setInvoiceDecide,
      setShowAllDecideFields,
      setCancelPrebookInvoice,
      setInvoicesForAllocateFx,
      setDeleteManualInvoice,
      setTransferIdToShowPaymentDetails,
      setRemoveExistingPrebookInvoice,
      setContactForEdit,
      recipientById,
      setInvoiceForAddContact,
      renderActionCell,
      tab,
      accountingIntegrationSystem,
      entityCurrencyCode,
      updateInMemoryInvoices,
      paymentRunId,
      removeInvoiceFromPaymentRun,
      isUpdatingPaymentRun,
    ]
  );

  return (
    <>
      <Table<IInvoiceFromSearch>
        ref={tableRef}
        autoResetGlobalFilter={false}
        autoResetSortBy={false}
        autoResetFilters={false}
        autoResetSelectedRows={autoResetSelectedRows}
        data={data}
        sortable
        isRowDisabled={isInvoiceDisabled}
        withSelectAll={isPaymentRunsEnabled}
        selectable={selectable ?? isPaymentRunsEnabled}
        isRowSelectable={({ row: { original: record } }) =>
          isInvoicesTableRowSelectable({
            tab,
            record,
            isUserApprover,
            isUserSubmitter,
            userId,
            hasApprovalFlow,
          })
        }
        onSelect={onSelectInvoice}
        disabledCheckboxHint={<DisabledCheckboxHint />}
        columns={tableColumns}
        renderFooterContent={
          showFooterInternal
            ? onRenderFooterContent?.(selectedInvoices) ?? (
                <InvoicesTableFooterContent
                  selectedInvoices={selectedInvoices}
                  updateInMemoryInvoices={updateInMemoryInvoices}
                  unselectInvoice={unselectInvoice}
                  setInvoicesForAllocateFx={setInvoicesForAllocateFx}
                />
              )
            : null
        }
        isVirtualized={isVirtualized}
        withInfiniteLoading={withInfiniteLoading}
        onLoadMoreItems={onLoadMoreItems}
        loadingThreshold={loadingThreshold}
        isLoadingMoreItems={isLoadingMoreItems}
        itemsCount={itemsCount}
        hasMoreToLoad={hasMoreToLoad}
        manualSortBy={manualSortBy}
        onSort={onSort}
      />

      <Popups
        {...invoicePopupsHandlers}
        updateInMemoryInvoices={updateInMemoryInvoices}
      />
    </>
  );
};

export default InvoicesTable;
