import { FC, AllHTMLAttributes } from 'react';
import { Icon } from 'components';

const ButtonClose: FC<
  Pick<AllHTMLAttributes<HTMLButtonElement>, 'onClick' | 'disabled'>
> = (props) => (
  <button {...props}>
    <Icon icon="cross-ico" />
  </button>
);

export default ButtonClose;
