import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { getInvoiceManualUploadLink } from 'utils/links';
import useUrlValues from 'hooks/useUrlValues';
import {
  Icon,
  Paragraph,
  PermissionsChecker,
  Row,
  StaleInfo,
} from 'components';
import Button from 'components/shared/Button/Button';
import TabsShared from 'components/shared/Tabs/Tabs';
import { createAndOpenDownloadLinkToInMemoryFileData } from 'utils';
import { errorHandler } from 'utils/errors';
import { AxiosResponse } from 'axios';
import useTabs from 'pages/Invoices/hooks/useTabs';
import { IInvoicesTableActions } from '../InvoicesTable/types';
import { useStoreActions, useStoreState } from 'state';
import { IGroupedInvoicesTableActions } from '../GroupedInvoicesTable/types';
import { TInvoicePageTabs } from 'types';

interface OwnProps {
  getInvoicesAsCSV: (
    tab: 'outstanding' | 'paid'
  ) => Promise<AxiosResponse<string>>;
  getPaymentRunInvoicesAsCsv: () => void;
  isLoadingPaymentRunInvoicesCSV: boolean;
  paymentRunId: string;
  invoicesTableActionsRef?: React.MutableRefObject<
    IInvoicesTableActions | undefined
  >;
  groupedInvoicesTableActionsRef?: React.MutableRefObject<
    IGroupedInvoicesTableActions | undefined
  >;
  paymentRunInvoicesQuantity: number;
}

const Tabs: FC<OwnProps> = ({
  getInvoicesAsCSV,
  getPaymentRunInvoicesAsCsv,
  isLoadingPaymentRunInvoicesCSV,
  paymentRunId,
  invoicesTableActionsRef,
  groupedInvoicesTableActionsRef,
  paymentRunInvoicesQuantity,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { setUrlValue, tab } = useUrlValues('tab');
  const [isDownloadingCSVFile, setIsDownloadingCSVFile] = useState(false);
  const { setState: setInvoicesState } = useStoreActions(
    ({ InvoicesState }) => InvoicesState
  );
  const { hasInvoicesDataChanged } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );

  const onRefreshWhenInvoicesDataHasChanged = () => {
    invoicesTableActionsRef?.current?.fetchFirstInvoicesPage();
    groupedInvoicesTableActionsRef?.current?.fetchFirstInvoicesPage();
    setInvoicesState(['hasInvoicesDataChanged', false]);
  };

  const downloadInvoicesAsCSV = async (
    tab: Parameters<typeof getInvoicesAsCSV>[0]
  ) => {
    try {
      setIsDownloadingCSVFile(true);
      const response = await getInvoicesAsCSV(tab);
      createAndOpenDownloadLinkToInMemoryFileData(
        response.data,
        `invoices_${tab}.csv`,
        'text/csv'
      );
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsDownloadingCSVFile(false);
    }
  };

  const { tabs } = useTabs({
    paymentRunInvoicesLength: paymentRunInvoicesQuantity,
  });

  return (
    <Row alignSelf="stretch">
      <Row>
        <TabsShared<TInvoicePageTabs>
          data={tabs}
          activeTab={(tab as TInvoicePageTabs) ?? tabs[0].id}
          setActiveTab={({ id }) =>
            setUrlValue({
              tab: id,
              currency: 'all',
              filter: '',
              dueDateFrom: '',
              dueDateTo: '',
              fullyPaidOnDateFrom: '',
              fullyPaidOnDateTo: '',
            })
          }
          whiteSpace="nowrap"
        />
      </Row>
      <Row gap={theme.spacing.m}>
        {hasInvoicesDataChanged && (
          <button onClick={onRefreshWhenInvoicesDataHasChanged}>
            <Paragraph color="red">
              Data has changed. Click to refresh
            </Paragraph>
          </button>
        )}

        <PermissionsChecker action="create" resource="cashflows_manual">
          <StaleInfo
            infoSize={theme.iconSizes.m}
            mode="hover"
            strategy="fixed"
            portal
            placement="top"
            trigger={
              <Button
                variant="link"
                onClick={() => history.push(getInvoiceManualUploadLink())}
              >
                <Icon icon="upload-ico" />
              </Button>
            }
          >
            <Paragraph color="white">Manual upload</Paragraph>
          </StaleInfo>
        </PermissionsChecker>

        {tab === 'paymentRun' && !!paymentRunId && (
          <StaleInfo
            infoSize={theme.iconSizes.m}
            mode="hover"
            strategy="fixed"
            portal
            placement="top"
            trigger={
              <Button
                variant="link"
                onClick={getPaymentRunInvoicesAsCsv}
                isLoading={isLoadingPaymentRunInvoicesCSV}
                disabled={isLoadingPaymentRunInvoicesCSV}
              >
                <Icon icon="download-ico" />
              </Button>
            }
          >
            <Paragraph color="white">Download CSV</Paragraph>
          </StaleInfo>
        )}
        {(tab === 'outstanding' || tab === 'paid') && (
          <StaleInfo
            infoSize={theme.iconSizes.m}
            mode="hover"
            strategy="fixed"
            portal
            placement="top"
            trigger={
              <Button
                variant="link"
                onClick={() => downloadInvoicesAsCSV(tab)}
                isLoading={isDownloadingCSVFile}
                disabled={isDownloadingCSVFile}
              >
                <Icon icon="download-ico" />
              </Button>
            }
          >
            <Paragraph color="white">Download CSV</Paragraph>
          </StaleInfo>
        )}
      </Row>
    </Row>
  );
};

export default Tabs;
