import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { TIME_FORMAT, DATE_FORMAT, DB_DATE_FORMAT } from 'variables';
import 'dayjs/locale/en';

dayjs.extend(weekday);
dayjs.extend(quarterOfYear);

export const getFirstValidDay = (
  date = new Date(),
  daysBuffer = 0,
  nonTradingDays: string[] = [],
  disabledPastDates = false
) => {
  let isDayCorrect = false;
  let addDays = daysBuffer;

  let dateToUse = date;

  if (disabledPastDates) {
    dateToUse = dayjs(date).isBefore(new Date(), 'day') ? new Date() : date;
  }

  while (!isDayCorrect) {
    const currentDay = dayjs(dateToUse).add(addDays, 'days');

    const holidayDate = nonTradingDays.find(
      (item) => item === currentDay.format(DB_DATE_FORMAT)
    );

    const isWeekend = currentDay.weekday() === 0 || currentDay.weekday() === 6;

    if (!holidayDate && !isWeekend) {
      isDayCorrect = true;
    } else {
      addDays++;
    }
  }

  return dayjs(dateToUse).add(addDays, 'days').toDate();
};

export const getNumberOfDaysBetweenTwoDates = (
  startDate: dayjs.ConfigType,
  endDate: dayjs.ConfigType,
  dateFormat: dayjs.OptionType = DB_DATE_FORMAT
) =>
  Math.abs(
    dayjs(startDate, dateFormat).diff(dayjs(endDate, dateFormat), 'days')
  );

export const isDateOverdue = (dateString: string) =>
  dayjs(dateString).endOf('day').isBefore(dayjs());

export const formatTimeFormat = (value: string) =>
  dayjs(value).format(TIME_FORMAT);

export const formatDateFormat = (value: string) =>
  dayjs(value).format(DATE_FORMAT);

/**
 * @param {string} dateString - Date string in format '[Q]Q YYYY', e.g. 'Q1 2020'
 */
export const getQuarterFromQuarterDateString = (dateString: string) => {
  const quarter = dateString.split(' ')[0].split('Q')[1];

  return quarter;
};

/**
 * @param {string} dateString - Date string in format '[Q]Q YYYY', e.g. 'Q1 2020'
 */
export const getYearFromQuarterDateString = (dateString: string) => {
  const year = dateString.split(' ')[1];

  return year;
};

/**
 * @param {number} month - Month number, e.g. 0 for January, 1 for February, etc.
 * @param {number} year - Year number, e.g. 2020
 * @returns {string} - Quarter string, e.g. 'Q1 2020'
 */
export const getQuarterStringFromMonthAndYear = (
  month: number,
  year: number
) => {
  const adjustedYear = year + Math.floor(month / 12);
  const adjustedMonth = month % 12;
  const quarter = Math.ceil((adjustedMonth + 1) / 3);
  return `Q${quarter} ${adjustedYear}`;
};

const isDateAWeekendDay = (date: Dayjs | string) => {
  const dateToCheck = dayjs.isDayjs(date) ? date : dateToDayjsDate(date);

  return dateToCheck.day() === 0 || dateToCheck.day() === 6;
};

/**
 *
 * @param date
 * @param daysAfterOrBefore working days to find after or before the date passed in. If negative it will search in the past.
 * @returns
 */
export const getDateNWeekDaysFromDate = ({
  date,
  daysAfterOrBefore,
}: {
  date: Dayjs | string;
  daysAfterOrBefore: number;
}) => {
  let currentDay = dateToDayjsDate(date).startOf('day');
  let daysSkipped = 0;
  while (Math.abs(daysSkipped) < Math.abs(daysAfterOrBefore)) {
    currentDay = currentDay.add(daysAfterOrBefore > 0 ? 1 : -1, 'day');
    if (!isDateAWeekendDay(currentDay)) {
      daysSkipped += 1;
    }
  }

  return currentDay;
};

const dateToDayjsDate = (date: Dayjs | string) => {
  let dateToCheck: Dayjs;

  if (dayjs.isDayjs(date)) {
    dateToCheck = date;
  } else {
    dateToCheck = dayjs(date);
  }

  return dateToCheck;
};

export const toEnDateString = (date: string) =>
  dayjs(date).locale('en').format('HH.mm [GMT] [on] DD/MM/YYYY');
