import { useState } from 'react';
import { ITableFilter, ITableFilterOption } from '../types';

import { map, some, reject, find, filter as _filter } from 'lodash';

const updateFilterValue = (
  filter: ITableFilter,
  filterValue: ITableFilterOption
) => {
  const isValueSetAlready = some(filter.value, { id: filterValue.id });
  const newValue = map(filter.value, (existingFilterValue) =>
    existingFilterValue.id === filterValue.id
      ? filterValue
      : existingFilterValue
  );

  return {
    ...filter,
    value: isValueSetAlready ? newValue : [...filter.value, filterValue],
  };
};

const updateRadioFilterValue = (
  currentFilter: ITableFilter,
  newFilterValue: ITableFilterOption,
  isValueSetAlready: boolean
) => {
  const newValue = isValueSetAlready
    ? reject(currentFilter.value, (existingFilterValue) =>
        some(currentFilter.options, {
          id: existingFilterValue.id,
          type: 'radio',
        })
      )
    : _filter(
        [...currentFilter.value, newFilterValue],
        (existingFilterValue) =>
          existingFilterValue.type !== 'radio' ||
          existingFilterValue.id === newFilterValue.id
      );
  return { ...currentFilter, value: newValue };
};

const updateCheckboxFilterValue = (
  currentFilter: ITableFilter,
  newFilterValue: ITableFilterOption,
  isValueSetAlready: boolean
) => {
  const { id: newFilterValueId } = newFilterValue;
  const isAllCheckbox = newFilterValueId === 'all';
  const nonCheckboxFilterCurrentValues = reject(currentFilter.value, {
    type: 'checkbox',
  });

  if (isValueSetAlready) {
    const newValue = isAllCheckbox
      ? nonCheckboxFilterCurrentValues
      : reject(
          currentFilter.value,
          (existingFilterValue) =>
            existingFilterValue.id === newFilterValueId ||
            existingFilterValue.id === 'all'
        );

    return { ...currentFilter, value: newValue };
  }

  if (isAllCheckbox) {
    const newValue = [
      ...nonCheckboxFilterCurrentValues,
      ..._filter(currentFilter.options, { type: 'checkbox' }),
    ];
    return { ...currentFilter, value: newValue };
  }

  const updatedFilterValue = [...currentFilter.value, newFilterValue];
  const allCheckboxOption = find(currentFilter.options, {
    id: 'all',
    type: 'checkbox',
  });
  const shouldSelectAllCheckbox =
    updatedFilterValue.length === currentFilter.options.length - 1 &&
    !some(updatedFilterValue, { id: 'all' }) &&
    allCheckboxOption;

  if (shouldSelectAllCheckbox) {
    return {
      ...currentFilter,
      value: [...updatedFilterValue, allCheckboxOption],
    };
  }

  return { ...currentFilter, value: updatedFilterValue };
};

const useFilters = (initialFilters: ITableFilter[]) => {
  const [filters, setFilters] = useState<ITableFilter[]>(initialFilters);

  const setFilterValue = (
    filterId: string,
    filterValue: ITableFilterOption
  ) => {
    const updatedFilters = map(filters, (currentFilter) => {
      const { type: filterValueType } = filterValue;

      if (currentFilter.id !== filterId) {
        return currentFilter;
      }

      const isValueSetAlready = some(currentFilter.value, {
        id: filterValue.id,
      });

      if (filterValueType === 'radio') {
        updateRadioFilterValue(currentFilter, filterValue, isValueSetAlready);
      }

      if (filterValueType === 'checkbox') {
        updateCheckboxFilterValue(
          currentFilter,
          filterValue,
          isValueSetAlready
        );
      }

      if (filterValueType === 'date' || filterValueType === 'number') {
        return updateFilterValue(currentFilter, filterValue);
      }

      return currentFilter;
    });

    setFilters(updatedFilters);
  };

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  return {
    filters,
    setFilter: setFilterValue,
    resetFilters,
  };
};

export default useFilters;
