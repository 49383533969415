import { Row } from 'components';
import styled from 'styled-components';

export interface NetWorthInfoRowWrapperProps {
  variant?: 'white' | 'grey';
}

export const NetWorthInfoRowWrapper = styled(Row)<NetWorthInfoRowWrapperProps>`
  background: ${({ theme, variant }) =>
    variant === 'grey' ? theme.color.greyLight_2 : theme.color.white};
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.m}`};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;
