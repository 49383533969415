import dayjs from 'dayjs';
import { CellProps, Column, HeaderProps } from 'react-table';
import { Paragraph, Row, StaleInfo } from 'components';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import { TCashflowAtRiskNew } from 'types';
import { notUndefinedTypeGuard } from 'utils';
import { DB_DATE_FORMAT } from 'variables';
import { getDateFormatFromPeriodType } from './utils';
import HedgesIcon from 'components/shared/HedgesIcon/HedgesIcon';
import InflowsIcon from 'components/shared/InflowsIcon/InflowsIcon';
import OutflowsIcon from 'components/shared/OutflowsIcon/OutflowsIcon';
import { TRisksDataPeriod } from 'pages/Risks/types';
import { PerPeriodData } from 'services/firebase/analysis';
import HeaderWithHideControl from 'components/shared/HeaderWithHideControl/HeaderWithHideControl';

export const generateTableColumns = ({
  currencyCode,
  entityIds,
  entityCurrency,
  period,
}: {
  currencyCode: string;
  entityIds: string[];
  entityCurrency: string;
  period: TRisksDataPeriod;
}): Column<PerPeriodData>[] => {
  const isAllCurrencies = currencyCode === 'all';
  const currencyCodeToUse = isAllCurrencies ? entityCurrency : currencyCode;

  const inflowsEntitiesColumns: Column<PerPeriodData>[] = entityIds.map(
    (entityId) => ({
      id: `inflows-${entityId}`,
      accessor: 'receivablesBreakdown',
      Header: (headerProps) => {
        // entities are the same for all rows
        return headerProps.rows[0].original.receivablesBreakdown.find(
          (entityBreakdown) => entityBreakdown.entityId === entityId
        )?.entityName;
      },
      Cell: ({ row }) => (
        <AmountCell
          amount={
            row.original.receivablesBreakdown.find(
              (entityBreakdown) => entityBreakdown.entityId === entityId
            )?.value ?? 0
          }
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    })
  );
  const outflowsEntitiesColumns: Column<PerPeriodData>[] = entityIds.map(
    (entityId) => ({
      id: `outflows-${entityId}`,
      accessor: 'payablesBreakdown',
      Header: (headerProps) => {
        // entities are the same for all rows
        return headerProps.rows[0].original.payablesBreakdown.find(
          (entityBreakdown) => entityBreakdown.entityId === entityId
        )?.entityName;
      },
      Cell: ({ row }) => (
        <AmountCell
          amount={
            row.original.payablesBreakdown.find(
              (entityBreakdown) => entityBreakdown.entityId === entityId
            )?.value ?? 0
          }
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    })
  );
  const hedgesEntitiesColumns: Column<PerPeriodData>[] = entityIds.map(
    (entityId) => ({
      id: `hedges-${entityId}`,
      accessor: 'hedges',
      Header: (headerProps) => {
        // entities are the same for all rows
        return headerProps.rows[0].original.hedgesBreakdown.find(
          (entityBreakdown) => entityBreakdown.entityId === entityId
        )?.entityName;
      },
      Cell: ({ row }) => (
        <AmountCell
          amount={
            row.original.hedgesBreakdown.find(
              (entityBreakdown) => entityBreakdown.entityId === entityId
            )?.value ?? 0
          }
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    })
  );

  return [
    {
      accessor: 'date',
      Cell: ({ value, row }) => {
        const monthlyDate = dayjs(value, DB_DATE_FORMAT);
        const dateToUse = monthlyDate.format(
          getDateFormatFromPeriodType(period)
        );

        return (
          <>
            <Row justifyContent="space-between" flex={1}>
              <Row>
                <Paragraph mr={row.index === 0}>{dateToUse}</Paragraph>
                {row.index === 0 && (
                  <StaleInfo strategy="fixed" infoSize="16px" mode="hover">
                    <Paragraph color="white">
                      First period included overdue but unpaid invoices and
                      orders
                    </Paragraph>
                  </StaleInfo>
                )}
              </Row>
            </Row>
          </>
        );
      },
      minWidth: 80,
    },
    {
      accessor: 'startingBalance',
      Header: 'Starting Balance',
      Cell: ({ value }: { value: any }) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'receivables',
      Header: (headerProps: HeaderProps<TCashflowAtRiskNew>) => {
        const { setHiddenColumns, state } = headerProps;

        return (
          <HeaderWithHideControl
            title="Cash Inflows"
            setHiddenColumns={setHiddenColumns}
            columnsToHide={[
              ...inflowsEntitiesColumns
                .map(({ id }) => id)
                .filter(notUndefinedTypeGuard),
            ]}
            state={state}
            icon={<InflowsIcon />}
          />
        );
      },
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    ...inflowsEntitiesColumns,
    {
      accessor: 'payables',
      Header: (headerProps: HeaderProps<TCashflowAtRiskNew>) => {
        const { setHiddenColumns, state } = headerProps;

        return (
          <HeaderWithHideControl
            title="Cash Outflows"
            setHiddenColumns={setHiddenColumns}
            columnsToHide={[
              ...outflowsEntitiesColumns
                .map(({ id }) => id)
                .filter(notUndefinedTypeGuard),
            ]}
            state={state}
            icon={<OutflowsIcon />}
          />
        );
      },
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          withMinusSign={!value}
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    ...outflowsEntitiesColumns,
    {
      accessor: 'hedges',
      Header: (headerProps: HeaderProps<TCashflowAtRiskNew>) => {
        const { setHiddenColumns, state } = headerProps;

        return (
          <HeaderWithHideControl
            title="Hedges"
            setHiddenColumns={setHiddenColumns}
            columnsToHide={[
              ...hedgesEntitiesColumns
                .map(({ id }) => id)
                .filter(notUndefinedTypeGuard),
            ]}
            state={state}
            icon={<HedgesIcon />}
          />
        );
      },
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    ...hedgesEntitiesColumns,
    {
      accessor: 'closingBalance',
      Header: 'Closing balance',
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
  ];
};
