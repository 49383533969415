import { Column } from 'react-table';
import { IInvoiceFromSearch } from 'types';
import DateCell from 'components/shared/DateCell/DateCell';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import { Paragraph } from 'components';
import { InvoiceFromSearchStatusCell } from 'components/shared/InvoicesTable/components/Cells/Cells';
import AmountSellCurrencyCell from 'components/shared/AmountSellCurrencyCell/AmountSellCurrencyCell';
import HexagonRating from 'components/shared/HexagonRating/HexagonRating';
import ActionCell from './components/ActionCell/ActionCell';
import DirectionCell from 'components/shared/DirectionCell/DirectionCell';
import { isReceivableInvoice } from 'utils/invoices';
import { FlagCell } from 'components/shared/FlagCell/FlagCell';

export const generateTableColumns = (): Column<IInvoiceFromSearch>[] => {
  return [
    {
      accessor: 'invoiceNumber',
      Header: 'Ref',
      Cell: ({ row }) => <Paragraph>{row.original.invoiceNumber}</Paragraph>,
      width: 110,
    },
    {
      accessor: 'contactRecipientName',
      Header: 'Name',
      Cell: ({ row }) => (
        <Paragraph>{row.original.contactRecipientName}</Paragraph>
      ),
      width: 180,
      minWidth: 150,
    },
    {
      accessor: 'dueDate',
      Header: 'Due Date',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ row }) => <InvoiceFromSearchStatusCell invoice={row.original} />,
      width: 150,
      minWidth: 150,
    },
    {
      accessor: 'currency',
      disableSortBy: true,
      Cell: ({ value }) => <FlagCell currencyCode={value} />,
      width: 50,
      minWidth: 50,
    },
    {
      accessor: 'amountDue',
      Header: 'Due Amount',
      Cell: ({ row }) => (
        <>
          <DirectionCell
            withTitle={false}
            isReceivable={isReceivableInvoice(row.original)}
            currencyCode={row.original.currency}
          />
          <AmountCell
            size="large"
            amount={row.original.amountDue}
            currencyCode={row.original.currency}
          />
        </>
      ),
      width: 160,
      minWidth: 160,
    },
    {
      disableSortBy: true,
      accessor: 'profitAndLoss',
      Header: 'GBP Value',
      Cell: ({ row }) => <AmountSellCurrencyCell record={row.original} />,
      width: 160,
      minWidth: 160,
    },
    {
      accessor: 'riskRating',
      Header: 'Risk Impact',
      Cell: ({ value }) => <HexagonRating rating={value} status="bad" />,
      width: 150,
      minWidth: 150,
      disableSortBy: true,
    },
    {
      id: 'prebookFx',
      accessor: 'riskContribution',
      Header: '',
      Cell: ({ row }) => <ActionCell record={row.original} />,
      width: 150,
      minWidth: 150,
      disableSortBy: true,
    },
  ];
};
