import { Icon, Row } from 'components';
import { StyledButton } from 'components/shared/Button/Button.styles';
import { FC, ReactNode } from 'react';
import { HeaderProps } from 'react-table';
import { useTheme } from 'styled-components';
import { TCashflowAtRiskNew } from 'types';

interface IOwnProps
  extends Pick<HeaderProps<TCashflowAtRiskNew>, 'setHiddenColumns' | 'state'> {
  columnsToHide: string[];
  icon: ReactNode;
  title: string;
}

const HeaderWithHideControl: FC<IOwnProps> = ({
  setHiddenColumns,
  columnsToHide,
  state,
  icon,
  title,
}) => {
  const theme = useTheme();
  const hiddenColumns = state.hiddenColumns ?? [];
  const isHidden = columnsToHide.every((columnId) =>
    hiddenColumns.includes(columnId)
  );

  return (
    <Row>
      <Row gap={theme.spacing.xxs}>
        {icon}
        {title}
      </Row>

      <StyledButton
        variant="link"
        onClick={() => {
          if (isHidden) {
            setHiddenColumns(
              hiddenColumns.filter(
                (columnId) => !columnsToHide.includes(columnId)
              )
            );
          } else {
            setHiddenColumns([...hiddenColumns, ...columnsToHide]);
          }
        }}
      >
        <Icon
          icon="arrow-down"
          style={{
            height: 16,
            transform: isHidden ? 'scale(1.5)' : 'scale(1.5)rotate(180deg)',
          }}
        />
      </StyledButton>
    </Row>
  );
};

export default HeaderWithHideControl;
