import { Paragraph, Row, StaleInfo } from 'components';
import { useHistory } from 'react-router';
import { Column } from 'react-table';
import { getRisksGroupedPageLink, getRisksPageLink } from 'utils/links';
import { ISummaryItem } from './types';

import RateCell from './components/RateCell/RateCell';
import RiskRatingCell from './components/RiskRatingCell/RiskRatingCell';
import CurrencyCell from './components/CurrencyCell/CurrencyCell';
import TwoAmountsCell from './components/TwoAmountsCell/TwoAmountsCell';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import ContextMenuCell from './components/ContextMenuCell/ContextMenuCell';

export const generateTableColumns = ({
  detectedCurrencies,
  entityCurrency,
  isForAllEntities,
}: {
  detectedCurrencies: string[];
  entityCurrency: string;
  isForAllEntities: boolean;
}): Column<ISummaryItem>[] => {
  return [
    {
      Header: 'Currency',
      accessor: 'currency',
      Cell: ({ value }) => <CurrencyCell currencyCode={value} />,
      width: 100,
      minWidth: 100,
    },
    {
      Header: 'Current FX Rate',
      id: 'currentFxRate',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <RateCell isRateLoading={false} rate={row.original.rate} />
      ),
      width: 100,
      minWidth: 100,
    },
    {
      Header: 'Risk',
      accessor: 'cfar',
      Cell: ({ row }) => {
        const { cfar } = row.original;

        if (cfar) {
          return <RiskRatingCell cashflowAtRisk={cfar} />;
        }
        return null;
      },
      width: 100,
      minWidth: 100,
    },
    {
      Header: (
        <Row justifyContent="flex-start">
          <Paragraph mr>Cash</Paragraph>
          <StaleInfo infoSize="20px" mode="hover" portal>
            <Paragraph color="white">
              Consists of your balances on HedgeFlows account and estimated
              balances based on the data from Xero.
            </Paragraph>
          </StaleInfo>
        </Row>
      ),
      accessor: 'cash',
      disableSortBy: true,
      Cell: ({
        value,
        row: {
          original: { currency, cashInEntityCurrency },
        },
      }) => (
        <TwoAmountsCell
          currencyCode={currency}
          entityCurrencyCode={entityCurrency}
          value={value}
          valueInEntityCurrency={cashInEntityCurrency}
        />
      ),
      width: 100,
      minWidth: 100,
    },
    {
      Header: (
        <Row justifyContent="flex-start">
          <Paragraph mr>Net APs/ARs</Paragraph>
          <StaleInfo infoSize="20px" mode="hover" portal>
            <Paragraph color="white">
              Net accounts payable and receivable as well as forecasted foreign
              cashflows.
            </Paragraph>
          </StaleInfo>
        </Row>
      ),
      accessor: 'netAccountsPayableReceivable',
      Cell: ({
        value,
        row: {
          original: { currency, netAccountsPayableReceivableInEntityCurrency },
        },
      }) => (
        <TwoAmountsCell
          currencyCode={currency}
          entityCurrencyCode={entityCurrency}
          value={value}
          valueInEntityCurrency={netAccountsPayableReceivableInEntityCurrency}
        />
      ),
      disableSortBy: true,
      width: 100,
      minWidth: 100,
    },
    {
      Header: 'Hedges',
      accessor: 'prebookedAndHedged',
      disableSortBy: true,
      Cell: ({
        value,
        row: {
          original: { currency, prebookedAndHedgedInEntityCurrency },
        },
      }) => (
        <TwoAmountsCell
          currencyCode={currency}
          entityCurrencyCode={entityCurrency}
          value={value}
          valueInEntityCurrency={prebookedAndHedgedInEntityCurrency}
        />
      ),
      width: 100,
      minWidth: 100,
    },
    {
      Header: (
        <Row justifyContent="flex-start">
          <Paragraph mr>CFaR</Paragraph>
          <StaleInfo infoSize="20px" mode="hover" portal>
            <Paragraph color="white">
              CashFlows at Risk, estimated value of potential loss from
              outstanding foreign currency cashflows with 20% probability.
            </Paragraph>
          </StaleInfo>
        </Row>
      ),
      id: 'estimated-risk',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const { cfar } = row.original;

        if (cfar) {
          return (
            <AmountCell
              amount={cfar}
              currencyCode={entityCurrency}
              withCurrencyPrecision={false}
            />
          );
        }
        return null;
      },
      width: 100,
      minWidth: 100,
    },
    {
      id: 'toDo',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const history = useHistory();
        const showAction = row.original.currency !== entityCurrency;

        if (!showAction) {
          return null;
        }

        return (
          <ActionButton
            onClick={(event) => {
              event.stopPropagation();

              if (isForAllEntities) {
                history.push(
                  getRisksGroupedPageLink({ currency: row.original.currency }),
                  {
                    detectedCurrencies,
                  }
                );
              } else {
                history.push(
                  getRisksPageLink({ currency: row.original.currency }),
                  {
                    detectedCurrencies,
                  }
                );
              }
            }}
          >
            Details
          </ActionButton>
        );
      },
      width: 60,
      minWidth: 70,
    },
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row: { original } }: any) => {
        const showAction = original.currency !== entityCurrency;

        if (!showAction) {
          return null;
        }

        return (
          <ContextMenuCell
            record={original}
            detectedCurrencies={detectedCurrencies}
          />
        );
      },
      width: 48,
      minWidth: 40,
    },
  ];
};
