import { FC, useMemo, useContext } from 'react';
import {
  isRateContractCanBeUsedNow,
  parseIntoCurrencyString,
  parseRateWithPrecision,
} from 'utils';
import { Row, Paragraph } from 'components';
import { NotificationWrapper } from './StaleNotificationWrapper';
import { useStoreState } from 'state';
import _orderBy from 'lodash.orderby';
import { ThemeContext } from 'styled-components';
import Button from '../Button/Button';

interface OwnProps {
  bgColor?: string;
  position?: 'right' | 'left';
  amount: number;
  buyCurrency: any;
  sellCurrency: any;
  onSubmit: any;
}

const StalePrebookRateNotification: FC<OwnProps> = ({
  position = 'left',
  buyCurrency,
  amount,
  sellCurrency,
  onSubmit,
}) => {
  const { rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );

  const themeContext = useContext(ThemeContext);

  const availablePrebookedRate = useMemo(
    () =>
      _orderBy(
        rateContractsByCurrencyPair(sellCurrency.code, buyCurrency.code).filter(
          (item) =>
            item.remainingBuyAmount >= amount &&
            isRateContractCanBeUsedNow(item)
        ),
        'rate',
        'desc'
      )[0],
    [buyCurrency, sellCurrency, amount, rateContractsByCurrencyPair]
  );

  return availablePrebookedRate ? (
    <NotificationWrapper
      bgColor={themeContext.color.emeraldLight}
      position={position}
    >
      <Row>
        <Paragraph>You have a Prebooked rate:</Paragraph>
        <Paragraph variant="bold">{`${sellCurrency.symbol}1.00 = ${
          buyCurrency.symbol
        } ${parseRateWithPrecision(availablePrebookedRate.rate)}`}</Paragraph>
      </Row>

      <Row
        style={{
          marginTop: 6,
        }}
      >
        <Paragraph>If you use this rate then your total will be: </Paragraph>
        <Paragraph variant="bold">{`${
          sellCurrency.symbol
        }${parseIntoCurrencyString(
          amount / availablePrebookedRate.rate
        )}`}</Paragraph>
      </Row>

      <Row
        style={{
          marginTop: 6,
        }}
      >
        <Button onClick={onSubmit} variant="link" mr>
          Use prebooked rate
        </Button>
      </Row>
    </NotificationWrapper>
  ) : null;
};

export default StalePrebookRateNotification;
