import { PropsWithChildren } from 'react';
import { useTable } from 'react-table';
import {
  StyledTableHorizontal,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
} from './TableHorizontal.styles';
import { ITableHorizontalProps } from './types';
import NormalRow from './components/NormalRow/NormalRow';

const TableHorizontal = <T extends object>({
  columns,
  data,
  isVirtualized = false,
  initialState,
  getTdCellTheme = () => 'default',
  getThCellTheme = () => 'dark',
}: PropsWithChildren<ITableHorizontalProps<T>>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState,
    autoResetHiddenColumns: true,
  });

  return (
    <StyledTableHorizontal
      isVirtualized={isVirtualized}
      {...getTableProps()}
      as="table"
    >
      <TableHead as="thead">
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()} as="tr">
            {headerGroup.headers.map((column) => (
              <TableHeaderCell
                {...column.getHeaderProps()}
                as="th"
                colorTheme={getThCellTheme(column.id)}
              >
                {column.render('Header')}
              </TableHeaderCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()} as="tbody">
        {rows.map((row) => (
          <NormalRow
            row={row}
            prepareRow={prepareRow}
            getTdCellTheme={getTdCellTheme}
          />
        ))}
      </TableBody>
    </StyledTableHorizontal>
  );
};

export default TableHorizontal;
