import {
  ITableFilter,
  ITableFilterOption,
} from 'components/shared/Filters/types';

const invoiceDateFilterOptions: ITableFilterOption[] = [
  {
    id: 'dueDateFrom',
    value: '',
    title: 'Date from',
    type: 'date',
  },
  {
    id: 'dueDateTo',
    value: '',
    title: 'Date to',
    type: 'date',
  },
];

const invoicePaymentDateFilterOptions: ITableFilterOption[] = [
  {
    id: 'fullyPaidOnDateFrom',
    value: '',
    title: 'Date from',
    type: 'date',
  },
  {
    id: 'fullyPaidOnDateTo',
    value: '',
    title: 'Date to',
    type: 'date',
  },
];

export const invoiceDateFilters: ITableFilter[] = [
  {
    id: 'date',
    value: [],
    title: 'Due dates',
    options: invoiceDateFilterOptions,
  },
];

export const invoicePaymentDateFilters: ITableFilter[] = [
  {
    id: 'date',
    value: [],
    title: 'Payment dates',
    options: invoicePaymentDateFilterOptions,
  },
];
