import { FC, Fragment } from 'react';
import { InvoiceInfo } from './components';
import { ResponsiveCenteredContainer, Loader } from 'components';
import useGetDetailedInvoice from 'hooks/useGetDetailedInvoice';
import useUrlValues from 'hooks/useUrlValues';
import useDeviceWidth from 'hooks/useDeviceWidth';

const InvoiceInner: FC = () => {
  const { invoiceId } = useUrlValues('invoiceId');
  const { isMobile } = useDeviceWidth();
  const {
    isLoading,
    detailedInvoice: invoice,
    getDetailedInvoice,
  } = useGetDetailedInvoice(invoiceId);
  const InvoiceInfoWrapper = isMobile ? Fragment : ResponsiveCenteredContainer;

  if (isLoading) {
    return <Loader size="large" />;
  }

  if (!invoice) {
    return null;
  }

  return (
    <InvoiceInfoWrapper>
      <InvoiceInfo invoice={invoice} getDetailedInvoice={getDetailedInvoice} />
    </InvoiceInfoWrapper>
  );
};

export default InvoiceInner;
