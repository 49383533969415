import { FC, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import Tabs from './components/Tabs/Tabs';
import Controls from './components/Controls/Controls';
import Tables from './components/Tables/Tables';
import { IntegrationWrapper } from './Invoices.styles';
import { Loader, IntegrationSettings } from 'components';
import useDraftPaymentRun from './hooks/useDraftPaymentRun';
import { getInvoicesAsCsvFile } from 'services/firebase/invoices';
import TransferPrebookPopups from 'components/shared/TransferPrebookPopups/TransferPrebookPopups';
import { useStoreState, useStoreActions } from 'state';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import useDeviceWidth from 'hooks/useDeviceWidth';
import MobileTabs from './components/MobileTabs/MobileTabs';
import MobileControls from './components/MobileControls/MobileControls';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import { IInvoicesTableActions } from './components/InvoicesTable/types';
import { IGroupedInvoicesTableActions } from './components/GroupedInvoicesTable/types';

const Invoices: FC = () => {
  const theme = useTheme();
  const { hasInvoices, isLoadingHasInvoicesFlag } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const { checkForInvoices } = useStoreActions(
    ({ InvoicesState }) => InvoicesState
  );
  const { isIntegrated } = useIntegrationEngine();
  const { isMobile } = useDeviceWidth();
  const invoicesTableActionsRef = useRef<IInvoicesTableActions>();
  const groupedInvoicesTableActionsRef = useRef<IGroupedInvoicesTableActions>();

  const {
    paymentRun,
    getPaymentRunInvoicesAsCsv,
    isLoadingPaymentRunInvoicesCSV,
    paymentRunInvoicesQuantity,
  } = useDraftPaymentRun();

  useEffect(() => {
    if (!hasInvoices) {
      // TODO: check if this is necessary, it is called twice for some reason
      checkForInvoices();
    }
  }, [isIntegrated, hasInvoices, checkForInvoices]);

  if (isLoadingHasInvoicesFlag) {
    return <Loader size="large" />;
  }

  if (isIntegrated || hasInvoices) {
    return (
      <>
        {isMobile ? (
          <>
            <MobileTabs
              paymentRunInvoicesQuantity={paymentRunInvoicesQuantity}
            />
            <MobileControls />
          </>
        ) : (
          <TableHeader flexDirection="column" gap={theme.spacing.m}>
            <Tabs
              paymentRunId={paymentRun?.id ?? ''}
              getPaymentRunInvoicesAsCsv={getPaymentRunInvoicesAsCsv}
              getInvoicesAsCSV={getInvoicesAsCsvFile}
              isLoadingPaymentRunInvoicesCSV={isLoadingPaymentRunInvoicesCSV}
              invoicesTableActionsRef={invoicesTableActionsRef}
              groupedInvoicesTableActionsRef={groupedInvoicesTableActionsRef}
              paymentRunInvoicesQuantity={paymentRunInvoicesQuantity}
            />
            <Controls />
          </TableHeader>
        )}
        <Tables
          invoicesTableActionsRef={invoicesTableActionsRef}
          groupedInvoicesTableActionsRef={groupedInvoicesTableActionsRef}
          paymentRun={paymentRun}
        />
        <TransferPrebookPopups />
      </>
    );
  }

  return (
    <IntegrationWrapper>
      <IntegrationSettings />
    </IntegrationWrapper>
  );
};

export default Invoices;
