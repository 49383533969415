import { FC, useState } from 'react';
import { Paragraph, Col, Row, Title, Loader, InputSearch } from 'components';
import { useTheme } from 'styled-components';
import Button from 'components/shared/Button/Button';
import { IOpenBankingInstitution } from 'types';
import { StyledInstitutionImage } from 'components/shared/StyledInstitutionImage/StyledInstitutionImage.styles';

interface IOwnProps {
  onContinue: () => void;
  institutions: IOpenBankingInstitution[];
  isLoadingInstitutions: boolean;
  onAccountAuthorisationInstitutionSelect: (
    institution: IOpenBankingInstitution
  ) => Promise<void>;
}

const AccountProvider: FC<IOwnProps> = ({
  onContinue,
  institutions,
  isLoadingInstitutions,
  onAccountAuthorisationInstitutionSelect,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');

  const filteredInstitutions = institutions.filter((item) =>
    item.name.toLowerCase().trim().includes(searchValue.toLowerCase().trim())
  );

  const isList = !!filteredInstitutions.length;

  if (isLoadingInstitutions) {
    return <Loader size="large" />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Select the account provider to give access to
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        If your bank can be accessed via Open Banking, we will transfer you to
        their banking app.
      </Paragraph>

      <InputSearch
        isGray={true}
        type="text"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <Col mt gap={theme.spacing.xs}>
        {!isList && (
          <Paragraph style={{ textAlign: 'center' }} variant="bold">
            No results found
          </Paragraph>
        )}

        {isList &&
          filteredInstitutions.map((institution) => (
            <Row key={institution.id}>
              <Row gap={theme.spacing.m}>
                <StyledInstitutionImage
                  src={
                    institution.media?.find((item) => item.type === 'icon')
                      ?.source
                  }
                  alt={institution.name}
                />
                <Paragraph>{institution.name}</Paragraph>
              </Row>

              <Button
                variant="secondary"
                onClick={() =>
                  onAccountAuthorisationInstitutionSelect(institution).then(
                    onContinue
                  )
                }
              >
                Select
              </Button>
            </Row>
          ))}
      </Col>
    </>
  );
};

export default AccountProvider;
