import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import {
  IConversion,
  IConversionDates,
  IConversionInput,
} from 'types/conversions';
import { openDoc } from 'utils';
import db from 'services/firestore';

export const exchangeCurrency = async (payload: IConversionInput) => {
  return AxiosPrivateFirebaseInstance.post<IResponse<IConversion>>(
    '/conversions',
    payload
  );
};

interface GetConversionDatesParams {
  currencyPair: string;
}

export const getConversionDates = async ({
  currencyPair,
}: GetConversionDatesParams) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IConversionDates>>(
    `/conversions/dates/${currencyPair}`
  );

interface GetConversionByIdParams {
  conversionId: string;
}

export const getConversionById = async ({
  conversionId,
}: GetConversionByIdParams) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IConversion>>(
    `/conversions/${conversionId}`
  );

export interface SubscribeToConversionByIdParams {
  conversionId: string;
  callback: (conversion: IConversion | null) => void;
}

export const subscribeToConversionById = ({
  conversionId,
  callback,
}: SubscribeToConversionByIdParams) =>
  db
    .collection('conversions')
    .doc(conversionId)
    .onSnapshot((doc) => callback(openDoc(doc)));
