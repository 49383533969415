import { FC, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import {
  Title,
  TransferTable,
  Col,
  Row,
  FundingAccountCredentials,
  Button,
} from 'components';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import Popup from 'components/shared/Popup/Popup';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import BulkPaymentDetails from './components/BulkPaymentDetails/BulkPaymentDetails';
import { OverflowXWrapper } from '../OverflowXWrapper/OverflowXWrapper.styles';
import useTransferOrBulkPaymentInner from 'hooks/useTransferOrBulkPaymentInner';
import TransferOrBulkPaymentInnerInvoices from '../TransferOrBulkPaymentInnerInvoices/TransferOrBulkPaymentInnerInvoices';
import useBalance from 'hooks/useBalance';
import { BULK_PAYMENT_STATUS } from 'types';
import useFundingAccountCredentialsActions from '../FundingAccountCredentials/useFundingAccountCredentialsActions';
import FundingAccountCredentialsActions from '../FundingAccountCredentialsActions/FundingAccountCredentialsActions';
import FundingBulkPaymentActions from '../FundingBulkPaymentActions/FundingBulkPaymentActions';
import FundingBulkPayment from '../FundingBulkPayment/FundingBulkPayment';
import useOpenBankingPayments from 'hooks/useOpenBankingPayments';

const BulkPaymentInner: FC = () => {
  const theme = useTheme();
  const { bulkPaymentId, setUrlValue } = useUrlValues('bulkPaymentId');
  const { bulkPaymentById } = useStoreState((state) => state.BulkPaymentsState);
  const selectedBulkPayment = bulkPaymentById(bulkPaymentId);
  const [isRedirected, setIsRedirected] = useState(false);

  const {
    tabs,
    contentType,
    setContentType,
    relatedInvoices,
    relatedTransfers,
    isLoadingRelatedInvoices,
  } = useTransferOrBulkPaymentInner({
    selectedTransferOrBulkPayment: selectedBulkPayment,
  });
  const { isLoading, isEnoughBalance, onPayWithBalance } = useBalance({
    sellCurrency: selectedBulkPayment?.sellCurrency,
    sellAmount: selectedBulkPayment?.payAmount,
  });
  const fundingAccountCredentialsActions = useFundingAccountCredentialsActions();

  const isFundingInitiated =
    selectedBulkPayment?.status !== BULK_PAYMENT_STATUS.awaitingPayment;
  const canBePaidWithBalance = isEnoughBalance && !isFundingInitiated;
  const isOpenBankingFlow = !!selectedBulkPayment?.notPaidViaHedgeFlows;
  const showPaymentTab =
    contentType === 'payment' &&
    selectedBulkPayment?.status === BULK_PAYMENT_STATUS.awaitingPayment;
  const onClose = () => setUrlValue({ bulkPaymentId: '' });

  const {
    selectedInstitution,
    paymentAuthResponse,
    isLoadingPaymentAuthUrl,
    isLoadingOpenBankingSettings,
    onPaymentAuthorisationInstitutionSelect,
  } = useOpenBankingPayments({
    fetchInstitutions: false,
    assetId: bulkPaymentId || undefined,
  });

  useEffect(() => {
    if (isFundingInitiated && contentType === 'payment') {
      setContentType('transfers');
    }
  }, [isFundingInitiated, contentType, setContentType]);

  if (!selectedBulkPayment) {
    return null;
  }

  const onPayWithBalanceHandler = () =>
    onPayWithBalance({
      payload: {
        currency: selectedBulkPayment.sellCurrency,
        amount: selectedBulkPayment.payAmount,
      },
    });

  return (
    <Popup
      HeaderContent={<Title variant="h3">Bulk payment</Title>}
      FooterContent={
        <>
          {isOpenBankingFlow && isFundingInitiated && (
            <Row>
              <Button disabled={isLoading} onClick={onClose}>
                Close
              </Button>
            </Row>
          )}
          {isOpenBankingFlow && !isFundingInitiated && (
            <FundingBulkPaymentActions
              paymentAuthResponse={paymentAuthResponse}
              isLoadingPaymentAuthUrl={isLoadingPaymentAuthUrl}
              isRedirected={isRedirected}
              setIsRedirected={setIsRedirected}
              onClose={onClose}
              isDisabled={!paymentAuthResponse}
            />
          )}
          {!isOpenBankingFlow && (
            <FundingAccountCredentialsActions
              isFundingInitiated={isFundingInitiated}
              isLoading={isLoading}
              fundingAccountCredentialsActions={
                fundingAccountCredentialsActions
              }
              onPayWithBalanceHandler={onPayWithBalanceHandler}
              onSecondaryButtonClick={onClose}
              secondaryButtonText="Close"
            />
          )}
        </>
      }
      width="900px"
      height="800px"
      onClose={onClose}
    >
      <Col rowGap={theme.spacing.xl}>
        <BulkPaymentDetails transfer={selectedBulkPayment} />

        <Row justifyContent="flex-start">
          {tabs.map(({ title, id }) => (
            <StepperItem
              onClick={() => setContentType(id)}
              key={id}
              current={id === contentType}
            >
              {title}
            </StepperItem>
          ))}
        </Row>

        {contentType === 'transfers' && (
          <Col>
            <Title mb variant="h5">
              Transfers <span>{relatedTransfers?.length}</span>
            </Title>
            <OverflowXWrapper>
              <TransferTable data={relatedTransfers} />
            </OverflowXWrapper>
          </Col>
        )}

        {contentType === 'invoices' && (
          <TransferOrBulkPaymentInnerInvoices
            isLoading={isLoadingRelatedInvoices}
            selectedTransfer={selectedBulkPayment}
            relatedInvoices={relatedInvoices}
          />
        )}

        {isOpenBankingFlow && showPaymentTab && (
          <FundingBulkPayment
            isRedirected={isRedirected}
            institution={selectedInstitution}
            paymentAuthResponse={paymentAuthResponse}
            setIsRedirected={setIsRedirected}
            onSelectInstitution={onPaymentAuthorisationInstitutionSelect}
            QRCodeMaxWidth="150px"
            showQRCodeText
            isLoading={isLoadingOpenBankingSettings || isLoadingPaymentAuthUrl}
          />
        )}

        {!isOpenBankingFlow && showPaymentTab && (
          <FundingAccountCredentials
            assetId={selectedBulkPayment.id}
            sellCurrency={selectedBulkPayment.sellCurrency}
            canBePaidWithBalance={canBePaidWithBalance}
            fundingAccountCredentialsActions={fundingAccountCredentialsActions}
          />
        )}
      </Col>
    </Popup>
  );
};

export default BulkPaymentInner;
