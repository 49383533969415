import 'settings/providers/firebase';
import 'settings/analytics/googleAnalytics';
import 'settings/analytics/logRocket';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { StoreProvider } from 'easy-peasy';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from 'state';
import './index.css';
import Logger, { setLoggerRef } from 'utils/Logger';

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <StoreProvider store={store}>
        <Router history={history}>
          <App />
          <Logger ref={(ref) => setLoggerRef(ref)} />
        </Router>
      </StoreProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
