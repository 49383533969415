import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Col, Icon, Row, Title, Paragraph } from 'components';
import HexagonRating from 'components/shared/HexagonRating/HexagonRating';
import Button from 'components/shared/Button/Button';
import { getHexagonsStatus } from 'components/shared/HexagonRating/utils';
import { getRiskLevelRatingText } from 'utils/analysis';
import { CardWrapper } from './ReduceRiskCard.styles';

interface OwnProps {
  icon: string;
  title: string;
  textOne: string | JSX.Element;
  textTwo?: string | JSX.Element;
  buttonText: string;
  onClick?: () => void;
  showRisk: boolean;
  risk?: number;
  disabled?: boolean;
}

const ReduceRiskCard: FC<OwnProps> = ({
  icon,
  title,
  textOne,
  textTwo,
  buttonText,
  onClick,
  showRisk,
  risk,
  disabled = false,
}) => {
  const theme = useTheme();

  return (
    <CardWrapper key={icon}>
      <Col gap={theme.spacing.m} mb mbValue={theme.spacing.xl}>
        <Col rowGap={theme.spacing.xs}>
          <Icon icon={icon} />
          <Title variant="h5">{title}</Title>
        </Col>

        {showRisk && (
          <Row
            columnGap={theme.spacing.m}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <HexagonRating
              rating={risk}
              status={getHexagonsStatus(true, 'asc', risk)}
              title="Risk"
              statusText={risk ? getRiskLevelRatingText(risk) : 'Not available'}
            />
            <HexagonRating
              rating={0}
              title="Cost"
              statusText="Coming soon"
              disabled
            />
          </Row>
        )}

        <Col rowGap={theme.spacing.s}>
          <Paragraph>{textOne}</Paragraph>
          <Paragraph>{textTwo}</Paragraph>
        </Col>
      </Col>

      <Row>
        <Button disabled={disabled} onClick={onClick}>
          {buttonText}
        </Button>
      </Row>
    </CardWrapper>
  );
};

export default ReduceRiskCard;
