import { FC, useMemo } from 'react';
import { useStoreState } from 'state';
import { Table } from 'components';
import { generateTableColumns } from './tableColumnsGenerator';
import { IAccountsTableDataItem } from 'types/bff';

interface IOwnProps {
  data: IAccountsTableDataItem[];
}

const AccountsTable: FC<IOwnProps> = ({ data }) => {
  const { hasApprovalFlow, isAutomationPackageEnabled } = useStoreState(
    (state) => state.UserState
  );

  const columns = useMemo(
    () =>
      generateTableColumns({
        isAutomationPackageEnabled,
        hasApprovalFlow,
      }),
    [isAutomationPackageEnabled, hasApprovalFlow]
  );

  return (
    <Table<IAccountsTableDataItem>
      autoResetSortBy={false}
      sortable
      columns={columns}
      data={data}
      defaultRowHeight={70}
    />
  );
};

export default AccountsTable;
