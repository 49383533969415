import { FC, useCallback, useEffect } from 'react';
import { useQuery } from 'hooks';
import { Loader, StaleRegistrationNav } from 'components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import TwoSidesTemplate from 'components/pageTemplates/TwoSidesTemplate/TwoSidesTemplate';
import { getGoToStepQuery, getSettingsPageLink } from 'utils';
import GoBack from 'components/shared/GoBack/GoBack';
import { useTheme } from 'styled-components';
import PickAccountProvider from './components/PickAccountProvider/PickAccountProvider';
import RequestAccountProviderAccess from './components/RequestAccountProviderAccess/RequestAccountProviderAccess';
import MapAccountProviderAccounts from './components/MapAccountProviderAccounts/MapAccountProviderAccounts';
import useOpenBankingAccounts from 'hooks/useOpenBankingAccounts';

const OpenBankingSettings: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const URLQuery = useQuery();
  const { url } = useRouteMatch();
  const activeStep = URLQuery.get('step');
  const activeStepAsNumber = activeStep && parseInt(activeStep);

  const {
    institutions,
    selectedInstitution,
    accountAuthResponse,
    isRedirected,
    isLoadingAccountAuthUrl,
    isLoadingInstitutions,
    opTransaction,
    setIsRedirected,
    onAccountAuthorisationInstitutionSelect,
    onUnselectInstitution,
  } = useOpenBankingAccounts();

  const goToStep = useCallback(
    (stepNumber: number) =>
      history.push(getGoToStepQuery(url, URLQuery, stepNumber)),
    [url, URLQuery, history]
  );

  useEffect(() => {
    if (!activeStep) {
      goToStep(1);
    }
  }, [activeStep, goToStep]);

  const renderContent = (step: string) => {
    switch (parseInt(step)) {
      case 1:
        return (
          <PickAccountProvider
            onContinue={() => goToStep(2)}
            institutions={institutions}
            isLoadingInstitutions={isLoadingInstitutions}
            onAccountAuthorisationInstitutionSelect={
              onAccountAuthorisationInstitutionSelect
            }
          />
        );
      case 2:
        return (
          <RequestAccountProviderAccess
            onGoBack={() => goToStep(1)}
            onDismiss={() =>
              history.push(getSettingsPageLink({ tab: 'bankAccounts' }))
            }
            onContinue={() => goToStep(3)}
            opTransaction={opTransaction}
            onUnselectInstitution={onUnselectInstitution}
            isRedirected={isRedirected}
            accountAuthResponse={accountAuthResponse}
            isLoadingAccountAuthUrl={isLoadingAccountAuthUrl}
            selectedInstitution={selectedInstitution}
            setIsRedirected={setIsRedirected}
          />
        );
      case 3:
        return (
          <MapAccountProviderAccounts
            onContinue={() =>
              history.push(getSettingsPageLink({ tab: 'bankAccounts' }))
            }
            transactionID={accountAuthResponse?.id}
            selectedInstitution={selectedInstitution}
          />
        );
      default:
        return null;
    }
  };

  if (!activeStepAsNumber) {
    return <Loader size="large" />;
  }

  return (
    <TwoSidesTemplate
      onClose={() => history.push(getSettingsPageLink({ tab: 'bankAccounts' }))}
      leftColumn={
        <StaleRegistrationNav
          wrapperStyle={{
            justifyContent: 'flex-start',
            padding: 0,
            flex: '1 0 auto',
            maxWidth: '100%',
          }}
          title="Connect your bank accounts"
          description="Configure your Open Banking integration so it works as you want it to:"
          navMenu={[
            {
              id: 1,
              name: 'Connect',
              description: 'Connect via Open Banking',
              step: '01',
              onClick: () => goToStep(1),
            },
            {
              id: 2,
              name: 'Consent',
              description: 'Which currencies to ignore?',
              step: '02',
              onClick: () => goToStep(2),
            },
            {
              id: 3,
              name: 'Map accounts',
              description:
                'Link accounts to those in your ERP/Accounting system',
              step: '03',
              onClick: () => goToStep(3),
            },
          ]}
          activeStep={activeStepAsNumber}
        />
      }
      rightColumn={
        activeStep && (
          <>
            <GoBack
              mb
              mbValue={theme.spacing.xxxl}
              onClick={
                activeStepAsNumber === 1
                  ? () =>
                      history.push(getSettingsPageLink({ tab: 'bankAccounts' }))
                  : undefined
              }
            />
            {renderContent(activeStep)}
          </>
        )
      }
    />
  );
};

export default OpenBankingSettings;
