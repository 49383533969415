import { FC, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import MonthlyBreakdown from './components/MonthlyBreakdown/MonthlyBreakdown';
import DataTable from './components/DataTable/DataTable';
import { BottomContentWrapper, TopContentWrapper } from './RisksGrouped.styles';
import {
  getCashflowsRisksGroupedByEntityPerCurrency,
  IRiskGroupedByEntityData,
} from 'services/firebase/analysis';
import useUrlValues from 'hooks/useUrlValues';
import { errorHandler } from 'utils/errors';
import { InlineLoader, IntegrationSettings, Loader, Row } from 'components';
import { TRisksDataPeriod } from './types';
import { isEntityHasCashflows } from 'services/cashflows';
import { IntegrationWrapper } from 'pages/Invoices/Invoices.styles';
import SidebarPageTemplate from 'components/pageTemplates/SidebarPageTemplate/SidebarPageTemplate';
import CashflowAtRisk from 'components/shared/CashflowAtRisk/CashflowAtRisk';

const RisksGrouped: FC = () => {
  const theme = useTheme();
  const { currency, period } = useUrlValues('currency', 'period', 'popupType');
  const [isCheckingCashflows, setIsCheckingCashflows] = useState(true);
  const [hasCashflows, setHasCashflows] = useState(false);

  useEffect(() => {
    isEntityHasCashflows()
      .then((result) => {
        setHasCashflows(!!result.data.data);
        setIsCheckingCashflows(false);
      })
      .catch(errorHandler);
  }, []);

  const [
    cashflowsRisksData,
    setCashflowsRisksData,
  ] = useState<IRiskGroupedByEntityData>();
  const [
    isLoadingCashflowsRisksData,
    setIsLoadingCashflowsRisksData,
  ] = useState(true);
  const isLoadingAndHaveData =
    isLoadingCashflowsRisksData && !!cashflowsRisksData;

  const getCashflowsRisksPerCurrencyHandler = useCallback(
    async (currencyCode: string, period: TRisksDataPeriod) => {
      try {
        setIsLoadingCashflowsRisksData(true);

        const result = await getCashflowsRisksGroupedByEntityPerCurrency({
          currencyCode,
          period,
        });

        setCashflowsRisksData(result.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoadingCashflowsRisksData(false);
      }
    },
    []
  );

  useEffect(() => {
    if (currency && period) {
      getCashflowsRisksPerCurrencyHandler(currency, period as TRisksDataPeriod);
    }
  }, [currency, getCashflowsRisksPerCurrencyHandler, period]);

  if (isCheckingCashflows) {
    return <Loader size="large" />;
  }

  if (!hasCashflows) {
    return (
      <IntegrationWrapper>
        <IntegrationSettings />
      </IntegrationWrapper>
    );
  }

  return (
    <SidebarPageTemplate>
      <TopContentWrapper
        columnGap={theme.spacing.xl}
        mb
        mbValue={theme.spacing.xl}
      >
        <CashflowAtRisk
          cashflowsRisksData={cashflowsRisksData}
          getCashflowsRisksPerCurrencyHandler={
            getCashflowsRisksPerCurrencyHandler
          }
        />
        <MonthlyBreakdown
          cashflowsRisksData={cashflowsRisksData?.perPeriod ?? []}
          isLoadingCashflowsRisksData={isLoadingCashflowsRisksData}
        />
      </TopContentWrapper>

      <BottomContentWrapper>
        <DataTable
          cashflowsRisksData={cashflowsRisksData?.perPeriod ?? []}
          isLoadingCashflowsRisksData={isLoadingCashflowsRisksData}
          isLoadingAndHaveData={isLoadingAndHaveData}
          entityIds={cashflowsRisksData?.entityIds ?? []}
        />

        {isLoadingAndHaveData && (
          <Row
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
            }}
          >
            <InlineLoader />
          </Row>
        )}
      </BottomContentWrapper>
    </SidebarPageTemplate>
  );
};

export default RisksGrouped;
