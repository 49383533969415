import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import {
  AUTOMATION_PACKAGE_ADVANCES,
  CASH_VISIBILITY_ADVANCES,
  RISK_PACKAGE_ADVANCES,
} from './variables';
import { Icon, Paragraph, Row, Title } from 'components';
import Card from 'components/shared/Card/Card.styles';
import Pill from 'components/shared/Pill/Pill';
import { useHistory } from 'react-router';
import Button from 'components/shared/Button/Button';
import Link from 'components/shared/Link/Link';
import { PAYMENT_RUNS_DEMO_URL, SCHEDULE_ACCOUNTANT_MEET_URL } from 'variables';
import { getSettingsPageLink } from 'utils';

interface IOwnProps {
  withCashVisibilityItem?: boolean;
}

const SuggestedPlans: FC<IOwnProps> = ({ withCashVisibilityItem }) => {
  const theme = useTheme();
  const history = useHistory();

  const {
    isAccountant,
    isAutomationPackageFreeTrialEnabled,
    isFxManagementPackageFreeTrialEnabled,
  } = useStoreState((state) => state.UserState);

  return (
    <>
      {!isAutomationPackageFreeTrialEnabled && (
        <Card padding={theme.spacing.m} flex={1} alignSelf="stretch">
          <Row mb justifyContent="flex-start" gap={theme.spacing.xs}>
            <Icon
              width={theme.iconSizes.l}
              height={theme.iconSizes.l}
              icon="track"
            />
            <Title variant="h4">Payments automation</Title>
          </Row>

          <Paragraph mb>
            Save time with multi-currency payment runs. Work in a team with
            approval process and notes.
          </Paragraph>

          <Row
            mb
            justifyContent="flex-start"
            flexWrap="wrap"
            gap={theme.spacing.xs}
          >
            {AUTOMATION_PACKAGE_ADVANCES.map((text) => (
              <Pill key={text} variant="blue" text={text} />
            ))}
          </Row>

          <Row justifyContent="center" mt mtValue="auto">
            {!isAccountant && (
              <Button
                variant="link"
                onClick={() =>
                  history.push(getSettingsPageLink({ tab: 'subscriptions' }))
                }
              >
                Start free trial
              </Button>
            )}

            {isAccountant && (
              <Link
                href={PAYMENT_RUNS_DEMO_URL}
                target="_blank"
                rel="noreferrer"
                display="inline"
              >
                See demo video
              </Link>
            )}
          </Row>
        </Card>
      )}

      {withCashVisibilityItem && (
        <Card padding={theme.spacing.m} flex={1} alignSelf="stretch">
          <Row mb justifyContent="flex-start" gap={theme.spacing.xs}>
            <Icon
              width={theme.iconSizes.l}
              height={theme.iconSizes.l}
              icon="currency"
            />
            <Title variant="h4">Cash visibility</Title>
          </Row>

          <Paragraph mb>
            See you cash balances and needs in real-time in any currency
          </Paragraph>

          <Row
            mb
            justifyContent="flex-start"
            flexWrap="wrap"
            gap={theme.spacing.xs}
          >
            {CASH_VISIBILITY_ADVANCES.map((text) => (
              <Pill key={text} variant="purple2" text={text} />
            ))}
          </Row>

          <Row justifyContent="center" mt mtValue="auto">
            <Button
              variant="link"
              onClick={() =>
                history.push(getSettingsPageLink({ tab: 'subscriptions' }))
              }
            >
              Start free trial
            </Button>
          </Row>
        </Card>
      )}

      {!isFxManagementPackageFreeTrialEnabled && (
        <Card padding={theme.spacing.m} flex={1} alignSelf="stretch">
          <Row mb justifyContent="flex-start" gap={theme.spacing.xs}>
            <Icon
              width={theme.iconSizes.l}
              height={theme.iconSizes.l}
              icon="hf-guru-past-performance"
            />
            <Title variant="h4">Financial planning</Title>
          </Row>

          <Paragraph mb>
            Take control of your costs in foreign currencies. Plan & manage
            foreign cashflows with confidence.
          </Paragraph>

          <Row
            mb
            justifyContent="flex-start"
            flexWrap="wrap"
            gap={theme.spacing.xs}
          >
            {RISK_PACKAGE_ADVANCES.map((text) => (
              <Pill key={text} variant="purple" text={text} />
            ))}
          </Row>

          <Row justifyContent="center" mt mtValue="auto">
            {!isAccountant && (
              <Button
                variant="link"
                onClick={() =>
                  history.push(getSettingsPageLink({ tab: 'subscriptions' }))
                }
              >
                Start free trial
              </Button>
            )}

            {isAccountant && (
              <Link
                href={SCHEDULE_ACCOUNTANT_MEET_URL}
                target="_blank"
                rel="noreferrer"
                display="inline"
              >
                Schedule a demo
              </Link>
            )}
          </Row>
        </Card>
      )}
    </>
  );
};

export default SuggestedPlans;
