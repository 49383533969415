import { FC } from 'react';
import { Paragraph, Col, Title } from 'components';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import Button from 'components/shared/Button/Button';
import { getDashboardPageLink, getSettingsPageLink } from 'utils/links';

const StepFive: FC = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Thanks! We've saved your preferences
      </Title>

      <Paragraph mb mbValue={theme.spacing.xxxl}>
        We will use them to guide you on our platform. You can review or change
        your risk preferences in Settings.
      </Paragraph>

      <Col gap={theme.spacing.xl} justifyContent="center">
        <Button onClick={() => history.push(getDashboardPageLink())}>
          Back to Dashboard
        </Button>

        <Button
          variant="secondary"
          onClick={() => history.push(getSettingsPageLink())}
        >
          Settings
        </Button>
      </Col>
    </>
  );
};

export default StepFive;
