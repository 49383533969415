import { FC, useState } from 'react';
import { Wrapper } from './UseExistingPrebook.styles';

import {
  ResponsiveCenteredContainer,
  StalePopup,
  StaleBtnGroup,
  TextHint,
  StaleInputRadio,
  Paragraph,
} from 'components';
import Button from 'components/shared/Button/Button';
import { useStoreActions, useStoreState } from 'state';
import { IInvoice, IRateContract } from 'types';
import {
  isRateContractCanBeUsedNow,
  parseIntoCurrencyString,
  parseRateWithPrecision,
} from 'utils';
import dayjs from 'dayjs';
import _orderBy from 'lodash.orderby';

interface OwnProps {
  invoice: IInvoice;
  sellCurrency: string;
  onClose: () => void;
}

const UseExistingPrebook: FC<OwnProps> = ({
  invoice,
  sellCurrency,
  onClose,
}) => {
  const { rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );
  const { bindContractRateToInvoice } = useStoreActions(
    (actions) => actions.InvoicesState
  );

  const rateContractsToUse = _orderBy(
    rateContractsByCurrencyPair(sellCurrency, invoice.currency).filter(
      (item) =>
        item.remainingBuyAmount >= (invoice.amountDue ?? 0) &&
        isRateContractCanBeUsedNow(item)
    ),
    'rate',
    'desc'
  );

  const [rateContract, setRateContract] = useState<IRateContract | null>(
    rateContractsToUse[0] || null
  );

  const onBindContractRateToInvoice = async () => {
    if (!rateContract) {
      return;
    }

    await bindContractRateToInvoice({
      contractId: rateContract.id,
      payload: {
        invoiceIds: [invoice.id],
      },
    });

    onClose();
  };

  return (
    <StalePopup
      title="Use prebooked amount?"
      theme="grey"
      width="439px"
      onClose={onClose}
      contentStyle={{
        minHeight: 'unset',
      }}
    >
      <Wrapper>
        <ResponsiveCenteredContainer
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 0 0',
          }}
        >
          <Paragraph
            style={{
              padding: '0 24px',
            }}
          >
            You have previously prebooked the rate for this currency pair. Would
            you like to use one of them for this invoice amount of{' '}
            {`${parseIntoCurrencyString(invoice.amountDue)} ${
              invoice.currency
            }`}
            ?
          </Paragraph>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              padding: '0 24px',
              flex: 1,
            }}
          >
            {rateContractsToUse.map((item) => (
              <StaleInputRadio
                id={item.id}
                key={item.id}
                label={
                  <div className="row">
                    <Paragraph>{`1 ${
                      item.buyCurrency
                    } = ${parseRateWithPrecision(item.rate)} ${
                      item.sellCurrency
                    }`}</Paragraph>
                    <div>
                      <Paragraph>{`${parseIntoCurrencyString(
                        item.remainingBuyAmount
                      )} ${item.buyCurrency}`}</Paragraph>
                      <TextHint>{`pay by ${dayjs(item.expiryDate).format(
                        'DD MMM YY'
                      )}`}</TextHint>
                    </div>
                  </div>
                }
                cardTransparent
                checked={item.id === rateContract?.id}
                onChange={() => {
                  setRateContract(item);
                }}
              />
            ))}
          </div>
        </ResponsiveCenteredContainer>

        <StaleBtnGroup
          horizontal
          container={false}
          style={{
            paddingBottom: 32,
          }}
        >
          <Button variant="secondary" onClick={onClose}>
            Not this time
          </Button>
          <Button
            disabled={!rateContract}
            onClick={onBindContractRateToInvoice}
          >
            Use prebooking
          </Button>
        </StaleBtnGroup>
      </Wrapper>
    </StalePopup>
  );
};

export default UseExistingPrebook;
