import {
  FC,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import {
  Button,
  Paragraph,
  Popup,
  Row,
  Title,
  Icon,
  Loader,
  Col,
  Subtitle,
  StaleInfo,
} from 'components';
import { IFundingToCollect } from 'types/funding';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { MatchedInvoicesTableWrapper } from './MatchedPopup.styles';
import { getInvoicesFilters } from 'pages/Invoices/utils';
import { IInvoiceFromSearch } from 'types';
import { ExposedUseTableProps } from 'components/shared/Table/types';
import { IInvoicesTableActions } from 'pages/Invoices/components/InvoicesTable/types';
import useInvoices from 'hooks/useInvoices';
import InvoicesTableShared from 'components/shared/InvoicesTable/InvoicesTable';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'variables';
import { callExternalApiWithLoading } from 'utils/fetchers';
import { errorHandler } from 'utils/errors';
import { matchFundingToInvoices } from 'services/funding';
import { getUpdatedApprovedInvoices } from './utils';
import Card from 'components/shared/Card/Card.styles';

interface OwnProps {
  onClose: () => void;
  fundingToMatch: IFundingToCollect;
  getFundingsToCollectHandler: () => Promise<void>;
}

const MatchedPopup: FC<OwnProps> = ({
  onClose,
  fundingToMatch,
  getFundingsToCollectHandler,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(fundingToMatch.currency);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFirstInvoicesPage, setIsLoadingFirstInvoicesPage] = useState(
    true
  );
  const [selectedInvoices, setSelectedInvoices] = useState<
    IInvoiceFromSearch[]
  >([]);

  const tableRef = useRef<ExposedUseTableProps<IInvoiceFromSearch>>(null);
  const invoicesTableActionsRef = useRef<IInvoicesTableActions>();
  const {
    invoices,
    currentPage,
    hasMoreToLoad,
    isLoadingMoreInvoices,
    fetchInvoicesWithSideEffects,
    updateInMemoryInvoices,
  } = useInvoices();

  const selectedInvoicesTotalAmount = selectedInvoices.reduce(
    (acc, invoice) => acc + invoice.amountDue,
    0
  );
  const differenceAmount = fundingToMatch.amount - selectedInvoicesTotalAmount;

  const fetchFirstInvoicesPage = useCallback(async () => {
    const filters = getInvoicesFilters({
      filterName: 'receivables',
      currency: fundingToMatch.currency,
    });

    tableRef.current?.toggleAllRowsSelected(false);

    fetchInvoicesWithSideEffects({
      page: 1,
      searchQuery: '',
      filters,
      reset: true,
    }).finally(() => {
      setIsLoadingFirstInvoicesPage(false);
    });
  }, [fetchInvoicesWithSideEffects, fundingToMatch]);

  useEffect(() => {
    fetchFirstInvoicesPage();
  }, [fetchFirstInvoicesPage]);

  const onLoadMoreItems = useCallback(async () => {
    if (isLoadingMoreInvoices) {
      return;
    }

    return fetchInvoicesWithSideEffects({
      page: currentPage + 1,
      searchQuery: '',
      filters: getInvoicesFilters({
        filterName: 'receivables',
        currency: fundingToMatch.currency,
      }),
    });
  }, [
    currentPage,
    fetchInvoicesWithSideEffects,
    fundingToMatch,
    isLoadingMoreInvoices,
  ]);

  useImperativeHandle(
    invoicesTableActionsRef,
    () => ({
      updateInMemoryInvoices,
      fetchFirstInvoicesPage,
    }),
    [fetchFirstInvoicesPage, updateInMemoryInvoices]
  );

  const onSubmitMatchHandler = async () => {
    if (differenceAmount !== 0) {
      return;
    }

    callExternalApiWithLoading({
      externalApiCall: () =>
        matchFundingToInvoices({
          fundingId: fundingToMatch.id,
          invoiceIds: selectedInvoices.map((invoice) => invoice.id),
        }),
      loadingHandler: setIsLoading,
      responseHandler: ({ data }) => {
        if (data.success) {
          updateInMemoryInvoices((invoices) =>
            getUpdatedApprovedInvoices({
              invoices,
              invoicesForMatch: selectedInvoices,
              fundingId: fundingToMatch.id,
            })
          );
          getFundingsToCollectHandler();
        } else {
          errorHandler(data);
        }
      },
      errorHandler,
    });
  };

  return (
    <Popup
      HeaderContent={<Title variant="h3">Invoices to match</Title>}
      width="954px"
      height="800px"
      onClose={onClose}
      FooterContent={
        <Row gap={theme.spacing.m} flex={1}>
          <Row gap={theme.spacing.m}>
            <Button
              disabled={isLoading || differenceAmount !== 0}
              isLoading={isLoading}
              onClick={onSubmitMatchHandler}
            >
              Confirm
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </Row>
          {!!selectedInvoices.length && (
            <Col alignItems="flex-end">
              <Row alignItems="flex-start" alignSelf="stretch">
                <Subtitle mr variant="bold">
                  Total received:
                </Subtitle>

                <Subtitle variant="bold">
                  {parseIntoCurrencyStringWithSymbol(
                    fundingToMatch.amount,
                    currency?.symbol,
                    currency?.precision
                  )}
                </Subtitle>
              </Row>

              <Row alignItems="flex-start" alignSelf="stretch">
                <Subtitle mr variant="bold">
                  Total selected:
                </Subtitle>

                <Subtitle variant="bold">
                  {parseIntoCurrencyStringWithSymbol(
                    selectedInvoicesTotalAmount,
                    currency?.symbol,
                    currency?.precision
                  )}
                </Subtitle>
              </Row>

              <Row alignItems="flex-start" alignSelf="stretch">
                <Row mr gap={theme.spacing.xxs}>
                  <Subtitle variant="bold">Diff:</Subtitle>
                  {differenceAmount !== 0 && (
                    <StaleInfo mode="hover" strategy="fixed" placement="top">
                      <Paragraph color="white">
                        Selected amount must be equal to received amount to
                        confirm
                      </Paragraph>
                    </StaleInfo>
                  )}
                </Row>

                <Subtitle
                  variant="bold"
                  color={differenceAmount === 0 ? 'green' : 'red'}
                >
                  {parseIntoCurrencyStringWithSymbol(
                    differenceAmount,
                    currency?.symbol,
                    currency?.precision
                  )}
                </Subtitle>
              </Row>
            </Col>
          )}
        </Row>
      }
    >
      <Card gap={theme.spacing.m} padding={theme.spacing.m} flexDirection="row">
        <Col gap={theme.spacing.s}>
          <Row gap={theme.spacing.s}>
            <Paragraph>Currency:</Paragraph>

            <Row gap={theme.spacing.xs} justifyContent="flex-end">
              <Paragraph variant="bold">{fundingToMatch.currency}</Paragraph>
              {currency && <Icon icon={currency.countryCode} />}
            </Row>
          </Row>

          <Row gap={theme.spacing.s}>
            <Paragraph>Amount:</Paragraph>

            <Paragraph variant="bold">
              {parseIntoCurrencyStringWithSymbol(
                fundingToMatch.amount,
                currency?.symbol
              )}
            </Paragraph>
          </Row>
        </Col>

        <Col gap={theme.spacing.s}>
          <Row gap={theme.spacing.s}>
            <Paragraph>Sender Name:</Paragraph>

            <Row gap={theme.spacing.xs} justifyContent="flex-end">
              <Paragraph variant="bold">{fundingToMatch.senderName}</Paragraph>
            </Row>
          </Row>

          <Row gap={theme.spacing.s}>
            <Paragraph>Payment Date:</Paragraph>

            <Row gap={theme.spacing.xs} justifyContent="flex-end">
              <Paragraph variant="bold">
                {dayjs(fundingToMatch.paymentDate).format(DATE_FORMAT)}
              </Paragraph>
            </Row>
          </Row>
        </Col>

        {fundingToMatch.paymentRef && (
          <Col gap={theme.spacing.s}>
            <Row gap={theme.spacing.s}>
              <Paragraph>Reference:</Paragraph>

              <Row gap={theme.spacing.xs} justifyContent="flex-end">
                <Paragraph variant="bold">
                  {fundingToMatch.paymentRef}
                </Paragraph>
              </Row>
            </Row>
          </Col>
        )}
      </Card>

      <MatchedInvoicesTableWrapper>
        {isLoadingFirstInvoicesPage && <Loader size="large" />}

        {!isLoadingFirstInvoicesPage && (
          <InvoicesTableShared
            isVirtualized
            tableRef={tableRef}
            data={invoices as IInvoiceFromSearch[]}
            itemsCount={invoices.length}
            withInfiniteLoading
            loadingThreshold={10}
            hasMoreToLoad={hasMoreToLoad}
            onLoadMoreItems={onLoadMoreItems}
            isLoadingMoreItems={isLoadingMoreInvoices}
            updateInMemoryInvoices={updateInMemoryInvoices}
            onSelectInvoices={setSelectedInvoices}
            manualSortBy
            autoResetSelectedRows={false}
            selectable={true}
            showFooter={false}
          />
        )}
      </MatchedInvoicesTableWrapper>
    </Popup>
  );
};

export default MatchedPopup;
