import {
  Paragraph,
  Row,
  Col,
  StaleNotification,
  Popup,
  Title,
  InlineLoader,
  StaleInfo,
} from 'components';
import Button from 'components/shared/Button/Button';
import InstitutionsList from 'components/shared/FundingAccountCredentials/components/FundingBankTransfer/FundingOpenBankingPopup/components/InstitutionsList/InstitutionsList';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import { PaymentRunFooterTotalsWrapper } from 'components/shared/PaymentRunFooterWrappers/PaymentRunFooterWrappers.styles';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form7';
import { postDefaultInstitutionIdToOpenBankingSettings } from 'services/firebase';
import { updatePaymentRun } from 'services/paymentRuns';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IOpenBankingInstitution, IOpenBankingSettings } from 'types';
import { IPaymentRun, IPaymentRunCurrencyTotal } from 'types/paymentRuns';
import { errorHandler } from 'utils/errors';
import { ReviewStepFormWrapper } from './ReviewStepForm.styles';

interface OwnProps {
  paymentRunId: string;
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  isCombineSameContacts: boolean;
  setPaymentRun: Dispatch<SetStateAction<IPaymentRun | undefined>>;
  setIsUpdatingPaymentRun: Dispatch<SetStateAction<boolean>>;
  onSubmit: (openBanking: boolean) => void;
  paymentRunError?: string;
  openBankingPaymentEligibility?: IPaymentRun['openBankingPaymentEligibility'];
  openBankingSettings?: IOpenBankingSettings;
  setOpenBankingSettings: Dispatch<
    SetStateAction<IOpenBankingSettings | undefined>
  >;
  getPaymentRunHandler: (openBanking?: boolean) => Promise<void>;
  institutions: IOpenBankingInstitution[];
  isLoadingInstitutions: boolean;
  handleSubmit: UseFormReturn['handleSubmit'];
  isCombine: boolean;
}

const ReviewStepForm: FC<OwnProps> = ({
  paymentRunId,
  paymentRunTotals,
  isCombineSameContacts,
  setPaymentRun,
  setIsUpdatingPaymentRun,
  onSubmit,
  paymentRunError,
  openBankingPaymentEligibility,
  openBankingSettings,
  setOpenBankingSettings,
  getPaymentRunHandler,
  institutions,
  isLoadingInstitutions,
  handleSubmit,
  isCombine,
}) => {
  const theme = useTheme();
  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);
  const [showOpenBankingPopup, setShowOpenBankingPopup] = useState(false);
  const [
    isLoadingOpenBankingSettings,
    setIsLoadingOpenBankingSettings,
  ] = useState(false);
  const { isMobile } = useDeviceWidth();

  useEffect(() => {
    if (isCombine !== isCombineSameContacts) {
      const updatePaymentRunIsCombine = async () => {
        try {
          setIsUpdatingPaymentRun(true);

          const { data: response } = await updatePaymentRun({
            paymentRunId,
            combine: isCombine,
          });

          if (response.data) {
            setPaymentRun(response.data);
          }
          setIsUpdatingPaymentRun(false);
        } catch (error: any) {
          errorHandler(error);
          setIsUpdatingPaymentRun(false);
        }
      };

      updatePaymentRunIsCombine();
    }
  }, [
    isCombine,
    isCombineSameContacts,
    paymentRunId,
    setIsUpdatingPaymentRun,
    setPaymentRun,
  ]);

  const onInstitutionSelect = async (institution: IOpenBankingInstitution) => {
    try {
      setIsLoadingOpenBankingSettings(true);
      setShowOpenBankingPopup(false);

      const defaultInstitutionId = institution.id;
      const { data } = await postDefaultInstitutionIdToOpenBankingSettings({
        defaultInstitutionId,
      });
      if (data.success) {
        setOpenBankingSettings(data.data);
        await getPaymentRunHandler(true);
      } else {
        errorHandler(data);
      }

      setIsLoadingOpenBankingSettings(false);
    } catch (error: any) {
      setIsLoadingOpenBankingSettings(false);
      errorHandler(error);
    }
  };

  return (
    <>
      <form
        id="payment-run-review-form"
        onSubmit={handleSubmit(() => onSubmit(false))}
      >
        <Col>
          {!isMobile && paymentRunError && (
            <Row>
              <StaleNotification
                title="Issue with the transfers"
                cross={false}
                bgColor={theme.color.red}
                style={{ maxWidth: 'unset' }}
              >
                {paymentRunError}
              </StaleNotification>
            </Row>
          )}

          <ReviewStepFormWrapper>
            <Col gap={theme.spacing.m}>
              <Row gap={theme.spacing.m} justifyContent="flex-start">
                <Button form="payment-run-review-form">
                  Pay via HedgeFlows
                </Button>
                {!isMobile &&
                  featureFlagById('openBankingPaymentsForBulkPayments') && (
                    <>
                      <StaleInfo
                        mode="hover"
                        strategy="fixed"
                        portal
                        placement="top"
                        infoSize="auto"
                        trigger={
                          <Button
                            type="button"
                            variant="secondary"
                            disabled={
                              !openBankingPaymentEligibility?.valid ||
                              isLoadingOpenBankingSettings
                            }
                            onClick={() => onSubmit(true)}
                          >
                            Pay directly from bank
                          </Button>
                        }
                      >
                        {!!openBankingPaymentEligibility?.errors?.length && (
                          <Paragraph color="white" whiteSpace="pre-line">
                            {openBankingPaymentEligibility.errors.length > 1
                              ? openBankingPaymentEligibility.errors.map(
                                  (error, i) => `${i + 1}. ${error} \n`
                                )
                              : `${openBankingPaymentEligibility.errors[0]}`}
                          </Paragraph>
                        )}
                      </StaleInfo>

                      <Col alignItems="flex-start">
                        {!!openBankingSettings && (
                          <Row gap={theme.spacing.xs}>
                            <Paragraph variant="bold">Chosen bank:</Paragraph>
                            <Paragraph>
                              {isLoadingOpenBankingSettings ? (
                                <InlineLoader />
                              ) : (
                                openBankingSettings.defaultInstitutionName
                              )}
                            </Paragraph>
                          </Row>
                        )}

                        <Button
                          type="button"
                          variant="link"
                          disabled={isLoadingOpenBankingSettings}
                          onClick={() => setShowOpenBankingPopup(true)}
                        >
                          {!!openBankingSettings ? 'Change' : 'Choose bank'}
                        </Button>
                      </Col>
                    </>
                  )}
              </Row>
            </Col>

            <PaymentRunFooterTotalsWrapper>
              <InvoicesCurrencyTotals
                title="Totals:"
                currencyTotals={paymentRunTotals}
              />
            </PaymentRunFooterTotalsWrapper>

            {!!isMobile && paymentRunError && (
              <Row>
                <StaleNotification
                  title="Issue with the transfers"
                  cross={false}
                  bgColor={theme.color.red}
                  style={{ maxWidth: 'unset' }}
                >
                  {paymentRunError}
                </StaleNotification>
              </Row>
            )}
          </ReviewStepFormWrapper>
        </Col>
      </form>

      {showOpenBankingPopup && (
        <Popup
          HeaderContent={<Title variant="h3">Select your bank</Title>}
          width="439px"
          height="788px"
          onClose={() => setShowOpenBankingPopup(false)}
        >
          <InstitutionsList
            institutions={institutions}
            onInstitutionSelect={onInstitutionSelect}
            isLoadingInstitutions={isLoadingInstitutions}
          />
        </Popup>
      )}
    </>
  );
};

export default ReviewStepForm;
