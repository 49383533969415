import { FC, useMemo } from 'react';
import { Title, Row, Col, Subtitle, Paragraph, Icon } from 'components';
import {
  parseIntoCurrencyString,
  getCountryCodeByCurrency,
  parseRateWithPrecision,
} from 'utils';
import dayjs from 'dayjs';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import Popup from 'components/shared/Popup/Popup';
import { useTheme } from 'styled-components';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { DATE_FORMAT } from 'variables';
import Button from '../Button/Button';

const HedgeInner: FC = () => {
  const theme = useTheme();
  const { hedgeId, setUrlValue } = useUrlValues('hedgeId');

  const { externalHedgeById } = useStoreState(
    (state) => state.ExternalHedgesState
  );
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );

  const selectedHedge = useMemo(() => externalHedgeById(hedgeId), [
    externalHedgeById,
    hedgeId,
  ]);

  const onClose = () => setUrlValue({ hedgeId: '' });

  if (!selectedHedge) {
    return null;
  }

  return (
    <Popup
      HeaderContent={<Title variant="h3">External hedge</Title>}
      FooterContent={
        <Row columnGap={theme.spacing.m}>
          <Button onClick={onClose}>Close</Button>
        </Row>
      }
      width="900px"
      height="800px"
      onClose={onClose}
    >
      <Col rowGap={theme.spacing.xl}>
        <Row columnGap={theme.spacing.xl} alignItems="flex-start">
          <Col flex={1}>
            <Subtitle mbValue={theme.spacing.xs} mb variant="bold">
              Hedge Details
            </Subtitle>

            <RowInfo>
              <Paragraph>Reference</Paragraph>
              <Paragraph>{selectedHedge.reference}</Paragraph>
            </RowInfo>

            <RowInfo>
              <Paragraph>Counterparty name</Paragraph>
              <Paragraph>{selectedHedge.counterpartyName}</Paragraph>
            </RowInfo>

            <RowInfo>
              <Paragraph>Currency rate</Paragraph>

              <Row>
                <Icon
                  width="16px"
                  height="16px"
                  icon={getCountryCodeByCurrency(
                    selectedHedge.buyCurrency,
                    currencies
                  )}
                />

                <Paragraph mlValue={theme.spacing.s} ml>
                  {`1 ${selectedHedge.sellCurrency} = ${parseRateWithPrecision(
                    selectedHedge.rate
                  )} ${selectedHedge.buyCurrency}`}
                </Paragraph>
              </Row>
            </RowInfo>

            <RowInfo alignItems="flex-start">
              <Paragraph>Amount</Paragraph>

              <Col style={{ textAlign: 'right' }}>
                <Paragraph>
                  Sold
                  {` ${parseIntoCurrencyString(
                    selectedHedge.sellAmount,
                    currencyByCode(selectedHedge.sellCurrency)?.precision
                  )} ${selectedHedge.sellCurrency}`}
                </Paragraph>
                <Paragraph>
                  Purchased
                  {` ${parseIntoCurrencyString(
                    selectedHedge.buyAmount,
                    currencyByCode(selectedHedge.buyCurrency)?.precision
                  )} ${selectedHedge.buyCurrency}`}
                </Paragraph>
              </Col>
            </RowInfo>

            <RowInfo>
              <Paragraph>Created on</Paragraph>
              <Paragraph>
                {dayjs(selectedHedge._created).format(DATE_FORMAT)}
              </Paragraph>
            </RowInfo>

            <RowInfo>
              <Paragraph>Use by</Paragraph>
              <Paragraph>
                {dayjs(selectedHedge.expiryDate).format(DATE_FORMAT)}
              </Paragraph>
            </RowInfo>
          </Col>
        </Row>
      </Col>
    </Popup>
  );
};

export default HedgeInner;
