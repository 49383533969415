import { Firebase } from 'services';
import { useState } from 'react';
import { errorHandler } from 'utils/errors';

/**
 * Simple wrapper around a click handler that retrieves an attachment
 */
export const useGetInvoiceAttachment = () => {
  const [isLoadingAttachment, setIsLoadingAttachment] = useState<string>();

  /**
   * Change this so in only takes the invoice id and attachment id
   */
  const onFileClickHandlerNew = async (args: {
    invoiceId: string;
    attachmentId: string;
    fileName: string;
  }) => {
    setIsLoadingAttachment(args.fileName);
    try {
      const {
        data: { data: base64EncodedString },
      } = await Firebase.Invoices.getInvoiceAttachmentContent(args);
      if (base64EncodedString) {
        // convert buffer to file data
        const link = document.createElement('a');

        link.setAttribute('href', base64EncodedString);
        link.setAttribute('download', args.fileName);
        link.setAttribute('id', 'id-download-attachment');

        // download
        link.click();

        // clean up
        const element = document.getElementById('id-download-attachment');
        element?.remove();
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingAttachment(undefined);
    }
  };

  return {
    isLoadingAttachment,
    onFileClickHandlerNew,
  };
};
