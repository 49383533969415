import styled from 'styled-components';
import { Overflow } from 'components';

export const MainContent = styled.div`
  flex: 0 1 auto;
  height: 100%;
  overflow: auto;

  ${Overflow} {
    padding: 8px 0 16px;
  }
`;
