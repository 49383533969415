import { FC } from 'react';
import Button from 'components/shared/Button/Button';
import { Paragraph, Title } from 'components/shared/Typography/Typography';
import Popup from 'components/shared/Popup/Popup';

interface OwnProps {
  onClose: () => void;
  bulkPaymentId: string;
}

const CancelBulkPaymentError: FC<OwnProps> = ({ onClose, bulkPaymentId }) => (
  <Popup
    width="450px"
    onClose={onClose}
    HeaderContent={<Title variant="h3">Transaction cancellation error</Title>}
    FooterContent={<Button onClick={onClose}>Close</Button>}
  >
    <Paragraph>We couldn't cancel your transaction {bulkPaymentId}.</Paragraph>

    <Paragraph mt>
      To cancel this transaction you need to contact our Support Team and they
      will be happy to arrange this for you. You can either do this via email
      <b> (support@hedgeflows.com)</b> or via the online chat during business
      hours.
    </Paragraph>
  </Popup>
);

export default CancelBulkPaymentError;
