import { FC, useMemo, useState } from 'react';
import { Table } from 'components';
import { generateTableColumns } from './utils';
import { IFundingToCollect } from 'types/funding';
import MatchedPopup from './components/MatchedPopup/MatchedPopup';

interface IOwnProps {
  data: IFundingToCollect[];
  getFundingsToCollectHandler: () => Promise<void>;
}

const ReceivedFundingsTable: FC<IOwnProps> = ({
  data,
  getFundingsToCollectHandler,
}) => {
  const [
    fundingToMatch,
    setFundingToMatch,
  ] = useState<IFundingToCollect | null>(null);

  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        setFundingToMatch,
      }),
    []
  );

  return (
    <>
      <Table<IFundingToCollect>
        autoResetGlobalFilter={false}
        autoResetSortBy={false}
        autoResetFilters={false}
        data={data}
        globalFilter="text"
        sortable
        columns={tableColumns}
        isVirtualized
        minVisibleRows={6}
        defaultRowHeight={58}
      />

      {fundingToMatch && (
        <MatchedPopup
          fundingToMatch={fundingToMatch}
          onClose={() => setFundingToMatch(null)}
          getFundingsToCollectHandler={getFundingsToCollectHandler}
        />
      )}
    </>
  );
};

export default ReceivedFundingsTable;
