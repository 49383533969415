import { useTheme } from 'styled-components';
import { Col, Paragraph, Subtitle } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { parseIntoShortNumberWithSymbol } from 'utils';

interface OwnProps {
  value: number;
  valueInEntityCurrency?: number;
  currencyCode: string;
  entityCurrencyCode: string;
}

const TwoAmountsCell: FC<OwnProps> = ({
  value,
  valueInEntityCurrency,
  currencyCode,
  entityCurrencyCode,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(currencyCode);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  if (currencyCode === entityCurrencyCode) {
    return (
      <Subtitle variant="bold">
        {parseIntoShortNumberWithSymbol(
          value,
          currency?.symbol,
          currency?.precision
        )}
      </Subtitle>
    );
  }

  return (
    <Col gap={theme.spacing.xxs}>
      <Subtitle variant="bold">
        {parseIntoShortNumberWithSymbol(
          value,
          currency?.symbol,
          currency?.precision
        )}
      </Subtitle>
      {valueInEntityCurrency && (
        <Paragraph>
          {parseIntoShortNumberWithSymbol(
            valueInEntityCurrency,
            entityCurrency?.symbol,
            entityCurrency?.precision
          )}
        </Paragraph>
      )}
    </Col>
  );
};

export default TwoAmountsCell;
