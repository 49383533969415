import styled from 'styled-components';
import { Col } from '../Col/Col';

export const TransactionWrapper = styled(Col)`
  width: 100%;
  max-width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoint.mediumMin}px) {
    max-width: 49.4%;
  }
`;
