import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import { IFundingToCollect } from 'types/funding';

export const getFundingsToCollect = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IFundingToCollect[]>>(
    '/senders/to-collect'
  );

export const getFundingsToCollectCurrencies = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<string[]>>(
    `/senders/to-collect/currencies`
  );

interface IMatchFundingToInvoices {
  fundingId: string;
  invoiceIds: string[];
}

export const matchFundingToInvoices = async ({
  fundingId,
  invoiceIds,
}: IMatchFundingToInvoices) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `/senders/funding/${fundingId}/match`,
    invoiceIds
  );
