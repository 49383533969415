import dayjs from 'dayjs';
import { ActionCreator } from 'easy-peasy';
import { TRisksDataPeriod } from 'pages/Risks/types';
import { createCustomCashflow, updateCustomCashflow } from 'services/cashflows';
import { updateEntitySettings } from 'services/firebase';
import { ICustomCashflowDefinition } from 'state/user';
import { TCustomCashflowAggregation } from 'types';
import { errorHandler } from 'utils/errors';
import {
  DB_DATE_FORMAT,
  DB_MONTH_FORMAT,
  MONTH_DATE_FORMAT,
  QUARTER_DATE_FORMAT,
} from 'variables';

export const onEditCustomCashflowName = ({
  newValueId,
  customCashflowDefinitions,
  setShowSpinnerInRecalculateButton,
}: {
  newValueId: string;
  customCashflowDefinitions: ICustomCashflowDefinition[];
  setShowSpinnerInRecalculateButton: ActionCreator<boolean>;
}) => async (newValue: string) => {
  try {
    const updatedCustomCashflowDefinitions = customCashflowDefinitions.map(
      (customCashflowDefinition) => {
        if (customCashflowDefinition.id === newValueId) {
          return {
            ...customCashflowDefinition,
            name: newValue,
          };
        }
        return customCashflowDefinition;
      }
    );

    await updateEntitySettings({
      customCashflowDefinitions: updatedCustomCashflowDefinitions,
    });
  } catch (error) {
    errorHandler(error);
  } finally {
    setShowSpinnerInRecalculateButton(true);
  }
};

export const onEditCustomCashflowAmount = ({
  date,
  currentCustomCashflows,
  currency,
  cashflowDefinitionId,
  setShowSpinnerInRecalculateButton,
}: {
  /** YYYY-MM */
  date: string;
  currency: string;
  currentCustomCashflows: TCustomCashflowAggregation['details'];
  cashflowDefinitionId: string;
  setShowSpinnerInRecalculateButton: ActionCreator<boolean>;
}) => async (newValue: number) => {
  try {
    let dateToUse = dayjs(date, DB_MONTH_FORMAT);

    const existingCustomCashflowFromManualEntry = currentCustomCashflows.find(
      (item) => item.externalRef === 'manualEntry'
    );

    if (existingCustomCashflowFromManualEntry) {
      return updateCustomCashflow({
        id: existingCustomCashflowFromManualEntry.id,
        data: {
          amount: newValue,
        },
      });
    }

    return createCustomCashflow({
      date: dateToUse.format(DB_DATE_FORMAT),
      currency,
      cashflowDefinitionId,
      amount: newValue,
    });
  } catch (error) {
    errorHandler(error);
  } finally {
    setShowSpinnerInRecalculateButton(true);
  }
};

export const getDateFormatFromPeriodType = (periodType: TRisksDataPeriod) => {
  switch (periodType) {
    case 'month':
      return MONTH_DATE_FORMAT;
    case 'quarter':
      return QUARTER_DATE_FORMAT;
    case 'week':
      return 'DD MMM';
    default:
      return DB_DATE_FORMAT;
  }
};
