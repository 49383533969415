import { IInvoice } from 'types';

export const useInvoiceLastSyncDetails = (invoice: IInvoice) => {
  const { lastSyncFromExternalSource, lastSyncToExternalSource } = invoice;

  return {
    lastSyncFromExternalSource,
    lastSyncToExternalSource,
  };
};
