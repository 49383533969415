export const TABS = [
  {
    id: 'volumes',
    title: 'Volumes',
  },
  {
    id: 'realised',
    title: 'Realised',
  },
  {
    id: 'marketImpact',
    title: 'Market Impact',
  },
] as const;

export const TABS_FOR_ALL_CURRENCIES = [
  {
    id: 'pnl',
    title: 'FX Profit and Loss',
  },
] as const;

export const PERIOD_VALUES = [
  {
    id: 3,
    name: 'Last 3 months',
    value: 3,
  },
  {
    id: 6,
    name: 'Last 6 months',
    value: 6,
  },
  {
    id: 9,
    name: 'Last 9 months',
    value: 9,
  },
  {
    id: 12,
    name: 'Last 12 months',
    value: 12,
  },
  {
    id: 18,
    name: 'Last 18 months',
    value: 18,
  },
  {
    id: 24,
    name: 'Last 24 months',
    value: 24,
  },
];
