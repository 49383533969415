import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { TableExpandButton } from '../Table.styles';

const ExpanderCell = <T extends object>({
  row,
  toggleAllRowsExpanded,
  expandOneRowAtATime,
}: PropsWithChildren<
  CellProps<T> & {
    expandOneRowAtATime?: boolean;
  }
>) => {
  // @ts-expect-error TS(2339) FIXME: Property 'onClick' does not exist on type 'TableEx... Remove this comment to see the full error message
  const { onClick, ...rest } = row.getToggleRowExpandedProps();

  return (
    <TableExpandButton
      {...rest}
      onClick={(e) => {
        e.stopPropagation();

        if (expandOneRowAtATime) {
          if (row.isExpanded) {
            toggleAllRowsExpanded(false);
          } else {
            toggleAllRowsExpanded(false);
            onClick();
          }
          return;
        }

        onClick();
      }}
    >
      <svg width="24px" height="24px">
        <use xlinkHref={row.isExpanded ? '#show-less-ico' : '#show-more-ico'} />
      </svg>
    </TableExpandButton>
  );
};

export default ExpanderCell;
