import styled from 'styled-components';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';

export const WhiteContentContainer = styled(Col)`
  flex: 1;
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xxxl}`};
  }
`;

export const WhiteHeaderContainer = styled(WhiteContentContainer)`
  padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing.m} 0`};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xxxl} 0`};
  }
`;

export const WhiteFooterContainer = styled(Row)`
  padding: ${({ theme }) => theme.spacing.m};
  border-top: 1px solid ${({ theme }) => theme.color.greyLight_2};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xxxl}`};
  }
`;
