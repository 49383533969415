import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Paragraph, Title } from 'components';
import StyledInternalLink from 'components/shared/InternalLink/InternalLink.styles';
import Card from 'components/shared/Card/Card.styles';

interface IOwnProps {
  title: string;
  text: string;
  buttonText: string;
  link: string;
  isDone: boolean;
}

const CTACard: FC<IOwnProps> = ({ title, text, buttonText, link, isDone }) => {
  const theme = useTheme();
  return (
    <Card
      flex={1}
      alignSelf="stretch"
      padding={theme.spacing.m}
      gap={theme.spacing.xs}
      color={isDone ? 'grey' : 'emeraldDark'}
    >
      <Title variant="h4" color="white">
        {title}
      </Title>
      <Paragraph color="white">{text}</Paragraph>

      {!isDone && (
        <StyledInternalLink to={link} color="white" mt mtValue="auto">
          {buttonText}
        </StyledInternalLink>
      )}

      {isDone && <Paragraph color="white">Finished</Paragraph>}
    </Card>
  );
};

export default CTACard;
