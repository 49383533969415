import styled from 'styled-components';
import { Col } from '../Col/Col';

export const StalePasswordField = styled.div`
  flex: 3;
  margin: 0 24px 0 0;
  min-width: 180px;
  max-width: 250px;
`;

export const ReAuthenticateWrapper = styled(Col)`
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    justify-content: flex-start;
  }
`;

export const ReAuthenticateButtonsWrapper = styled(Col)`
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;
