import styled from 'styled-components';
import { Row } from 'components';

interface OwnProps {
  greyed?: boolean;
}

export const InvoiceInfoRow = styled(Row)<OwnProps>`
  flex: 1;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.m};
  min-height: 50px;
  background: ${({ theme, greyed }) =>
    greyed ? theme.color.greyLight_1 : theme.color.white};
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  border: 1px solid ${({ theme }) => theme.color.greyLight_2};
`;

export const CellRow = styled(Row)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xs};
  justify-content: flex-start;
`;
