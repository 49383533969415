import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import {
  Row,
  Paragraph,
  ResponsiveCenteredContainer,
  Title,
  Icon,
} from 'components';
import {
  IdVerificationThanksPageWrapper,
  IdVerificationThanksPageContent,
} from './IdVerificationThanksPage.styles';
import Button from 'components/shared/Button/Button';
import { goToProductionLanding } from 'utils';

const IdVerificationThanksPage: FC = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <IdVerificationThanksPageWrapper>
      <ResponsiveCenteredContainer>
        <IdVerificationThanksPageContent>
          <Icon
            icon="complete"
            width="210px"
            height="185px"
            mb
            mbValue={theme.spacing.xxl}
          />

          <Title mb mbValue={theme.spacing.xxl}>
            Thank You for ID verification
          </Title>

          <Paragraph mb mbValue={theme.spacing.xxxl}>
            We will be in touch once we have reviewed the company registration.
            If you are not yet registered as a user, please ask the person who
            submitted the company registration to add you to the account.
          </Paragraph>

          <Row gap={theme.spacing.xl} alignSelf="stretch">
            <Button flex={1} onClick={() => history.push('/')}>
              Login
            </Button>

            <Button
              flex={1}
              variant="secondary"
              onClick={() => goToProductionLanding()}
            >
              About HedgeFlows
            </Button>
          </Row>
        </IdVerificationThanksPageContent>
      </ResponsiveCenteredContainer>
    </IdVerificationThanksPageWrapper>
  );
};

export default IdVerificationThanksPage;
