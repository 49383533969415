import { FC } from 'react';
import { Icon, Paragraph, Row, Subtitle, StaleInfo } from 'components';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { ICurrency } from 'types';
import { getCurrencyExchangePageLink } from 'utils/links';
import { AccountsRow } from '../AccountsRow/AccountsRow.styles';
import { useUpdateBalancesPermissionsCheck } from 'hooks/useSpecificPermissionsCheck';

interface BalanceRowProps {
  currencyCode: ICurrency['code'];
  currencySymbol?: ICurrency['symbol'];
  currencyCountryCode?: ICurrency['countryCode'];
  currencyPrecision: ICurrency['precision'];
  balance: number;
  hasBalance: boolean;
}

const BalanceRow: FC<BalanceRowProps> = ({
  currencyCode,
  currencySymbol,
  currencyCountryCode,
  currencyPrecision,
  balance,
  hasBalance,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const hasUpdateBalancesPermission = useUpdateBalancesPermissionsCheck();

  return (
    <AccountsRow>
      {!!currencyCountryCode && <Icon icon={currencyCountryCode} />}
      <Subtitle variant="bold" ml mlValue={theme.spacing.xs}>
        {currencyCode}
      </Subtitle>

      <Row flex={1} ml mlValue={theme.spacing.s} justifyContent="center">
        <Paragraph mr mrValue={theme.spacing.s}>
          Balance:
        </Paragraph>
        <Subtitle variant="bold">
          {parseIntoCurrencyStringWithSymbol(
            balance,
            currencySymbol,
            currencyPrecision
          )}
        </Subtitle>
      </Row>

      <Row
        ml
        mlValue={theme.spacing.xxxl}
        columnGap={theme.spacing.s}
        justifyContent="flex-end"
      >
        <StaleInfo
          infoSize="24px"
          mode="hover"
          strategy="fixed"
          portal
          placement="bottom"
          disabled={!hasBalance || !hasUpdateBalancesPermission}
          trigger={
            <button
              disabled={!hasBalance}
              onClick={() =>
                history.push(
                  getCurrencyExchangePageLink({
                    predefinedSellCurrency: currencyCode,
                    step: '1',
                  })
                )
              }
            >
              <Icon
                icon="prebook-filled"
                fill={
                  hasBalance && hasUpdateBalancesPermission ? 'black' : 'grey'
                }
              />
            </button>
          }
        >
          <Paragraph color="white">Exchange</Paragraph>
        </StaleInfo>
      </Row>
    </AccountsRow>
  );
};

export default BalanceRow;
