import { FC } from 'react';
import { useTheme } from 'styled-components';
import useHfGuru from 'hooks/useHfGuru';
import HFGuru from 'components/shared/HFGuru/HFGuru';
import { HF_GURU_TASKS } from 'types/hfGuru';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import { Col } from 'components';

const HFGuruTile: FC = () => {
  const theme = useTheme();
  const {
    isHfGuruLoading,
    hfGuruData,
    updateHfGuruStatus,
    showRemainingTasksLink,
    setShowRemainingTasksLink,
    hfGuruActiveTasks,
  } = useHfGuru();
  const { isIntegrated } = useIntegrationEngine();

  const checkToShowTaskPopup = (id: HF_GURU_TASKS) =>
    id === HF_GURU_TASKS.healthCheck && !isIntegrated;

  const onSeeRemainingTasks = async () => {
    setShowRemainingTasksLink(false);
    await updateHfGuruStatus(HF_GURU_TASKS.removeDeferrals);
  };

  if (
    isHfGuruLoading ||
    hfGuruData?.completed ||
    (hfGuruData?.basic && hfGuruData.progress >= 2)
  ) {
    return null;
  }

  return (
    <Col mb mbValue={theme.spacing.xxl}>
      <HFGuru<HF_GURU_TASKS>
        isLoading={isHfGuruLoading}
        data={hfGuruData}
        onSeeRemainingTasks={onSeeRemainingTasks}
        activeTasks={hfGuruActiveTasks}
        updateStatus={updateHfGuruStatus}
        showRemainingTasksLink={showRemainingTasksLink}
        checkToShowTaskPopup={checkToShowTaskPopup}
        basic={hfGuruData?.basic}
      />
    </Col>
  );
};

export default HFGuruTile;
