import { FC, useState } from 'react';
import { Subtitle, Title } from 'components';
import ExchangeSummary from '../../../../components/shared/ExchangeSummary/ExchangeSummary';
import { Notify } from 'utils';
import { exchangeCurrency } from 'services/firebase/conversions';
import { IConversion } from 'types/conversions';
import StaleInfoBox from 'components/shared/StaleInfoBox/StaleInfoBox';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import { UseExchangeDetailsReturnValues } from 'pages/CurrencyExchange/hooks/useExchangeDetails';
import Button from 'components/shared/Button/Button';
import { errorHandler } from 'utils/errors';

interface OwnProps {
  onContinue: (conversionData: IConversion) => void;
  savedValues: UseExchangeDetailsReturnValues;
}

const Review: FC<OwnProps> = ({ onContinue, savedValues }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const {
        sellAmountAsNumber: sellAmount,
        buyAmountAsNumber: buyAmount,
        sellCurrency,
        buyCurrency,
        rateToUse,
        rateType,
        selectedRateContract,
        conversionFeeRate,
        invoiceId,
      } = savedValues;

      if (!rateToUse) {
        return;
      }

      const { data } = await exchangeCurrency({
        sellAmount,
        sellCurrency: sellCurrency.code,
        buyCurrency: buyCurrency.code,
        buyAmount,
        rate: rateToUse,
        rateType,
        contractId: selectedRateContract?.id,
        conversionFeeRate,
        invoiceId: invoiceId || undefined,
      });

      if (data.success && data.data) {
        onContinue(data.data);
      } else {
        Notify.error(data.message ?? '');
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    sellAmountAsNumber: sellAmount,
    buyAmountAsNumber: buyAmount,
    sellCurrency,
    buyCurrency,
    rateToUse,
    conversionFeeRate,
  } = savedValues;

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          <Title mb variant="h3">
            Review your FX conversion details:
          </Title>

          <StaleInfoBox text="Check details & confirm" icon="alert-ico" />
        </FlowContentLeft>

        <FlowContentRight>
          <Subtitle variant="bold">Summary</Subtitle>
          <ExchangeSummary
            sellCurrency={sellCurrency}
            buyCurrency={buyCurrency}
            buyAmountAsNumber={buyAmount}
            sellAmountAsNumber={sellAmount}
            rate={rateToUse}
            conversionFeeRate={conversionFeeRate}
          />
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <Button onClick={onSubmit} isLoading={isLoading} disabled={isLoading}>
          Confirm
        </Button>
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default Review;
