import { ChartProps, Chart as ChartJSReact } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartTypeRegistry,
  BarElement,
  Filler,
  LineController,
  BarController,
} from 'chart.js';
// this is for chart to time scale using dayjs
import 'chartjs-adapter-dayjs';
import { ChartJSOrUndefined, ForwardedRef } from 'react-chartjs-2/dist/types';
import { Row } from '../Row/Row';
import { forwardRef } from 'react';

// Register elements you want to use
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);

interface IOwnProps<T extends keyof ChartTypeRegistry> extends ChartProps<T> {
  ref?: ForwardedRef<ChartJSOrUndefined<T>>;
}

/**
 * @description Can be any chart, it depends on the "type" prop.
 * If you want to mix charts in one, you can pass type of the first chart.
 * The rest of types specified per dataset.
 */
const Chart = forwardRef(
  <T extends keyof ChartTypeRegistry>(
    props: IOwnProps<T>,
    ref: ForwardedRef<ChartJSOrUndefined<T>>
  ) => {
    return (
      <Row flex={1} position="relative">
        <ChartJSReact {...props} ref={ref} />
      </Row>
    );
  }
);

export default Chart;
