import { FC } from 'react';

import { Wrapper } from './NotificationMessage.styles';
import { NotificationType } from '../Notifications';
import { Col } from 'components/shared/Col/Col';

const NotificationMessage: FC<Omit<NotificationType, 'id'>> = ({
  message = '',
  icon = '',
  color = '#121213',
  duration = 3000,
}) => {
  const isArray = Array.isArray(message);
  return (
    <Wrapper duration={duration} color={color}>
      <svg>
        <use xlinkHref={`#${icon}`} />
      </svg>

      <Col>
        {isArray ? (
          message.map((item: string) => <p>{item}</p>)
        ) : (
          <p>{message}</p>
        )}
      </Col>
    </Wrapper>
  );
};

export default NotificationMessage;
