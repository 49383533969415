import { Icon } from 'components';
import { FC } from 'react';
import { useTheme } from 'styled-components';

const OutflowsIcon: FC = () => {
  const theme = useTheme();

  return (
    <Icon
      icon="pay-ico"
      style={{
        fill: theme.color.red,
        transform: 'rotate(45deg)scale(1.5)',
      }}
      height={16}
    />
  );
};

export default OutflowsIcon;
