import { FC, useEffect } from 'react';
import Card from 'components/shared/Card/Card.styles';
import { Row, Loader, StaleInputSelect } from 'components';
import useUrlValues from 'hooks/useUrlValues';
import dayjs from 'dayjs';
import {
  DB_DATE_FORMAT,
  DB_MONTH_FORMAT,
  QUARTER_DATE_FORMAT,
} from 'variables';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import { TRisksDataPeriod } from 'pages/Risks/types';
import { IRiskGroupedByEntityData } from 'services/firebase/analysis';
import ChartCashflows from 'components/shared/ChartCashflows/ChartCashflows';

dayjs.extend(advancedFormat);

interface IOwnProps {
  cashflowsRisksData: IRiskGroupedByEntityData['perPeriod'];
  isLoadingCashflowsRisksData: boolean;
}

const MonthlyBreakdown: FC<IOwnProps> = ({
  cashflowsRisksData,
  isLoadingCashflowsRisksData,
}) => {
  const { setUrlValue, period, dateRange, currency } = useUrlValues(
    'currency',
    'period',
    'dateRange'
  );
  useEffect(() => {
    if (!period) {
      setUrlValue({ period: 'month' });
    }
  }, [period, setUrlValue]);

  const onChartPeriodClick = (periodFromChart: string, periodIndex: number) => {
    const date = dayjs(periodFromChart, DB_DATE_FORMAT);
    const isFirstPeriod = periodIndex === 0 ? 'true' : '';

    if (period === 'month') {
      const formattedMonth = date.format(DB_MONTH_FORMAT);

      setUrlValue({
        dateRange: formattedMonth !== dateRange ? formattedMonth : '',
        isFirstPeriod,
      });
    }

    if (period === 'quarter') {
      const formattedQuarter = date.format(QUARTER_DATE_FORMAT);

      setUrlValue({
        dateRange: formattedQuarter !== dateRange ? formattedQuarter : '',
        isFirstPeriod,
      });
    }
  };

  const onSelectPeriod = ({ value }: { value: TRisksDataPeriod }) => {
    setUrlValue({ period: value, dateRange: '' });
  };

  return (
    <>
      <Card
        alignSelf="stretch"
        justifyContent="space-between"
        flex={1}
        position="relative"
      >
        <Row alignItems="flex-start">
          <Row justifyContent="flex-start">
            <StepperItem current>Cashflows</StepperItem>
          </Row>
          <StaleInputSelect
            selected={period}
            data={[
              {
                id: 'week',
                name: 'Weekly',
                value: 'week',
              },
              {
                id: 'month',
                name: 'Monthly',
                value: 'month',
              },
              {
                id: 'quarter',
                name: 'Quarterly',
                value: 'quarter',
              },
            ]}
            onSelect={onSelectPeriod}
            id="risks-data-duration"
          />
        </Row>

        <Row flex={1} alignItems="unset">
          {isLoadingCashflowsRisksData && <Loader size="large" />}
          {!isLoadingCashflowsRisksData && (
            <ChartCashflows
              data={cashflowsRisksData}
              currencyCode={currency}
              period={period as TRisksDataPeriod}
              onChartClick={onChartPeriodClick}
            />
          )}
        </Row>
      </Card>
    </>
  );
};

export default MonthlyBreakdown;
