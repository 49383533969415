import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Paragraph, Row, StaleInputSelect, TableSearchInput } from 'components';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import { useStoreState } from 'state';
import Filters from 'components/shared/Filters/Filters';
import { getCountFromInvoicesAggregationPerFilter } from 'utils/invoices';
import useControls from '../../hooks/useControls';
import { invoiceDateFilters } from 'variables/invoicesFilters';

const Controls: FC = () => {
  const theme = useTheme();
  const { invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const {
    currenciesSelectData,
    searchInputValue,
    setSearchInputValue,
    onFilterChange,
    urlValues: { setUrlValue, tab, currency, filter },
  } = useControls();

  return (
    <Row alignSelf="stretch">
      <Row justifyContent="flex-start" gap={theme.spacing.xl} flex={1}>
        <StaleInputSelect
          selected={currency}
          data={currenciesSelectData}
          onSelect={(item) => setUrlValue({ currency: item.value })}
          id="invoices-currency"
          inputHeight="32px"
          style={{ minWidth: '132px' }}
        />

        {tab === 'receivables' && (
          <>
            <TableSearchInput
              placeholder="Search"
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.target.value)}
            />

            <Row gap={theme.spacing.xs} flex={1} justifyContent="flex-start">
              <FilterButton
                onClick={() => setUrlValue({ filter: 'receivables' })}
                active={filter === 'receivables'}
              >
                <Row gap={theme.spacing.xs}>
                  <Paragraph>All</Paragraph>
                  <NumberCircle
                    value={getCountFromInvoicesAggregationPerFilter(
                      invoicesAggregations,
                      'receivables'
                    )}
                  />
                </Row>
              </FilterButton>

              <FilterButton
                onClick={() =>
                  setUrlValue({
                    filter:
                      filter !== 'receivablesOverdue'
                        ? 'receivablesOverdue'
                        : 'receivables',
                  })
                }
                active={filter === 'receivablesOverdue'}
              >
                <Row gap={theme.spacing.xs}>
                  <Paragraph>Overdue</Paragraph>
                  <NumberCircle
                    value={getCountFromInvoicesAggregationPerFilter(
                      invoicesAggregations,
                      'receivablesOverdue'
                    )}
                  />
                </Row>
              </FilterButton>

              <FilterButton
                onClick={() =>
                  setUrlValue({
                    filter:
                      filter !== 'receivablesDueIn14Days'
                        ? 'receivablesDueIn14Days'
                        : 'receivables',
                  })
                }
                active={filter === 'receivablesDueIn14Days'}
              >
                <Row gap={theme.spacing.xs}>
                  <Paragraph whiteSpace="nowrap">Due soon</Paragraph>
                  <NumberCircle
                    value={getCountFromInvoicesAggregationPerFilter(
                      invoicesAggregations,
                      'receivablesDueIn14Days'
                    )}
                  />
                </Row>
              </FilterButton>
            </Row>
            <Row flex={1} gap={theme.spacing.m} justifyContent="flex-end">
              <Filters
                initialFilters={invoiceDateFilters}
                onFilterChange={onFilterChange}
              />
            </Row>
          </>
        )}
      </Row>
    </Row>
  );
};

export default Controls;
