import styled from 'styled-components';
import { Col } from 'components';

export const AuthoriseStepConfirmPopupWrapper = styled(Col)`
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.m};
  flex: 1;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
