import { useEffect, useState } from 'react';
import { Firebase } from 'services';
import { useStoreState } from 'state';
import { RECIPIENT_STATUS, IRecipient, IInvoiceFromSearch } from 'types';
import { errorHandler } from 'utils/errors';

const useExternalInvoiceContactFromSearchContact = (
  invoice?: IInvoiceFromSearch | null
) => {
  const [
    recipientFromInvoiceExternalSystem,
    setRecipientFromInvoiceExternalSystem,
  ] = useState<IRecipient>();
  const { entityId } = useStoreState(({ UserState }) => UserState);

  const defaultIsLoading = !!(invoice?.id && !invoice?.contactId);

  const [isLoadingInvoiceOrContact, setIsLoadingInvoiceOrContact] = useState(
    defaultIsLoading
  );

  useEffect(() => {
    const getInvoiceContact = async () => {
      try {
        if (invoice?.id && !invoice?.contactId) {
          setIsLoadingInvoiceOrContact(true);
          const invoiceContactResponse = await Firebase.Invoices.getInvoiceExternalContact(
            invoice.id
          );
          const invoiceContact = invoiceContactResponse.data.data;

          if (invoiceContact) {
            const contact: IRecipient = {
              ...invoiceContact,
              status: RECIPIENT_STATUS.draft,
              externalRefs: {
                sourceSystemId: invoice?.externalRefsSourceSystemContactId,
              },
            };

            // TODO: check types
            setRecipientFromInvoiceExternalSystem(contact);
          }

          setIsLoadingInvoiceOrContact(false);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingInvoiceOrContact(false);
      }
    };

    void getInvoiceContact();
  }, [entityId, invoice]);

  return {
    isLoadingContact: isLoadingInvoiceOrContact,
    recipientFromInvoiceExternalSystem,
    setRecipientFromInvoiceExternalSystem,
  };
};

export default useExternalInvoiceContactFromSearchContact;
