import styled, { css } from 'styled-components';
import { ROW_BORDER_WIDTH } from './consts';
import { TTableHorizontalCellTheme } from './types';

export const StyledTableHorizontal = styled.div<{
  isVirtualized: boolean;
}>`
  border-spacing: 0;
  display: flex;
  flex: 1;
`;

export const TableHead = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableDataCell = styled.div<{
  isExpanderCell?: boolean;
  isSelectableCell?: boolean;
  colorTheme?: TTableHorizontalCellTheme;
}>(
  ({
    theme,
    isExpanderCell = false,
    isSelectableCell = false,
    colorTheme = 'default',
  }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    background-color: ${colorTheme === 'default'
      ? theme.color.white
      : theme.color.greyLight_1};
    white-space: nowrap;

    ${isExpanderCell &&
    css`
      padding-right: 0px;
    `}

    ${isSelectableCell &&
    css`
      padding-left: 24px;
    `}
  `
);

export const TableBody = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
`;

export const TableRow = styled.div<{
  disabled?: boolean;
  isClickable?: boolean;
  height?: number;
  minRowHeight?: number;
}>(
  ({ theme, height, minRowHeight }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    justify-content: flex-start;
    border: ${`${ROW_BORDER_WIDTH}px solid ${theme.color.greyLight_2}`};
    border-top: 0;
    height: ${height && !minRowHeight ? `${height}px` : 'unset'};
    min-height: ${minRowHeight ? `${minRowHeight}px` : 'unset'};
  `
);

export const TableHeaderCell = styled.div<{
  isSelectableCell?: boolean;
  colorTheme?: TTableHorizontalCellTheme;
}>(
  ({ theme, isSelectableCell, colorTheme = 'dark' }) => css`
    padding: 12px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: ${theme.color.greyDark};
    text-align: left;
    white-space: nowrap;
    background-color: ${colorTheme === 'dark'
      ? theme.color.greyLight_1
      : theme.color.white};
    width: 180px;

    &:first-child {
      border-top-left-radius: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
    }

    ${isSelectableCell &&
    css`
      padding-left: 24px;
    `}
  `
);
