import { FC } from 'react';
import { Row, Paragraph, StaleInfo, Icon } from 'components';
import { useTheme } from 'styled-components';
import { IPaymentRunItemSummary } from 'types/paymentRuns';
import { getTransactionStatusDetails } from 'utils/transactions';
import { TRANSFER_STATUS } from 'types';

interface OwnProps {
  summary: IPaymentRunItemSummary;
}

const PayWithBalanceStatusCell: FC<OwnProps> = ({ summary }) => {
  const theme = useTheme();

  const {
    isPayWithBalanceSuccess,
    payWithBalanceError,
    succeededBulkPaymentStatus,
  } = summary;

  const awaitingFundsStatusInfo = getTransactionStatusDetails(
    TRANSFER_STATUS.awaitingPayment
  );
  const fundedStatusInfo = getTransactionStatusDetails(
    succeededBulkPaymentStatus || TRANSFER_STATUS.funded
  );

  if (awaitingFundsStatusInfo && isPayWithBalanceSuccess === undefined) {
    return (
      <Paragraph color={awaitingFundsStatusInfo.color}>
        {awaitingFundsStatusInfo.text}
      </Paragraph>
    );
  }

  if (!isPayWithBalanceSuccess) {
    return (
      <Row gap={theme.spacing.s}>
        <Paragraph color="red">Funding failed</Paragraph>

        <StaleInfo mode="hover" strategy="fixed" portal>
          <Row gap={theme.spacing.s}>
            <Icon icon="failed" />

            <Paragraph color="white">
              Failed to pay with balance. Error: {payWithBalanceError}
            </Paragraph>
          </Row>
        </StaleInfo>
      </Row>
    );
  }

  if (fundedStatusInfo && isPayWithBalanceSuccess) {
    return (
      <Row>
        <Paragraph color={fundedStatusInfo.color}>
          {fundedStatusInfo.text}
        </Paragraph>
      </Row>
    );
  }

  return null;
};

export default PayWithBalanceStatusCell;
