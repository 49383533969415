import { FC, useState } from 'react';
import { Col, Popup, Row, Title } from 'components';
import { copyTextToClipboard, getAccountDetailsForCopy, Notify } from 'utils';
import Button from '../Button/Button';
import { useTheme } from 'styled-components';
import AccountDetailsTile from './components/AccountDetailsTile/AccountDetailsTile';
import { IEntityAccountDetailsType } from 'types';
import useEntityAccountDetails from './useEntityAccountDetails';

interface IOwnProps {
  onClose: () => void;
}

const AllAccountDetails: FC<IOwnProps> = ({ onClose }) => {
  const theme = useTheme();
  const [detailsForCopyTypes, setDetailsForCopyTypes] = useState<
    IEntityAccountDetailsType[]
  >([]);

  const entityAccountDetailsData = useEntityAccountDetails();

  const selectDetailsForCopy = (detailsType: IEntityAccountDetailsType) => {
    if (detailsForCopyTypes.includes(detailsType)) {
      setDetailsForCopyTypes((prevState) =>
        prevState.filter((type) => type !== detailsType)
      );

      return;
    }

    setDetailsForCopyTypes((prevState) => [...prevState, detailsType]);
  };

  const onCopyText = (text: string) => {
    copyTextToClipboard(text);
    Notify.success('Account details copied!');
  };

  const onCopySelected = (viaMail = false) => {
    let textToCopy = '';

    detailsForCopyTypes.forEach((detailsType) => {
      const accountDetails = entityAccountDetailsData.find(
        (item) => item?.accountDetailsType === detailsType
      );

      if (!accountDetails) {
        return;
      }

      const detailsText = getAccountDetailsForCopy(
        Object.keys(accountDetails.data).map((key) => [
          key,
          accountDetails.data[key],
        ]),
        accountDetails.title,
        viaMail
      );

      textToCopy = textToCopy + detailsText;
    });

    if (!viaMail) {
      onCopyText(textToCopy);
      return;
    }

    window.open(`mailto:?subject=My account details&body=${textToCopy}`);
  };

  return (
    <Popup
      width="908px"
      onClose={onClose}
      HeaderContent={<Title variant="h4">My account details</Title>}
      FooterContent={
        <Row>
          <Button
            onClick={() => onCopySelected()}
            disabled={!detailsForCopyTypes.length}
            mr
          >
            Copy selected
          </Button>

          <Button
            onClick={() => onCopySelected(true)}
            variant="link"
            disabled={!detailsForCopyTypes.length}
            icon="mail"
          >
            Send via email
          </Button>
        </Row>
      }
    >
      <Col gap={theme.spacing.xl}>
        {entityAccountDetailsData.map((item) => (
          <AccountDetailsTile
            accountDetailsType={item.accountDetailsType}
            detailsForCopyTypes={detailsForCopyTypes}
            selectDetailsForCopy={selectDetailsForCopy}
            onCopyHandler={() =>
              onCopyText(
                Object.keys(item.data)
                  .map((key) => `${key}: ${item.data[key]}`)
                  .join('\n')
              )
            }
            title={item.title}
            data={item.data}
          />
        ))}
      </Col>
    </Popup>
  );
};

export default AllAccountDetails;
