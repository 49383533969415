import { CASHFLOW_TYPE_NEW } from 'services/cashflows';

export const CASHFLOW_TYPES_FOR_FILTER_BUTTONS: CASHFLOW_TYPE_NEW[] = [
  CASHFLOW_TYPE_NEW.externalHedge,
  CASHFLOW_TYPE_NEW.hedge,
  CASHFLOW_TYPE_NEW.payableInvoice,
  CASHFLOW_TYPE_NEW.receivableInvoice,
  CASHFLOW_TYPE_NEW.purchaseOrder,
  CASHFLOW_TYPE_NEW.salesOrder,
];
