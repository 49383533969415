import { CellProps, Column } from 'react-table';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import CurrencyCell from 'components/shared/CurrencyCell/CurrencyCell';
import NumberCircleCell from '../NumberCircleCell/NumberCircleCell';
import ContextMenuCell from '../ContextMenuCell/ContextMenuCell';
import { IAccountsTableDataItem } from 'types/bff';

export const generateTableColumns = ({
  isAutomationPackageEnabled,
  hasApprovalFlow,
}: {
  isAutomationPackageEnabled: boolean;
  hasApprovalFlow: boolean;
}): Column<IAccountsTableDataItem>[] => {
  const prebookedCells: Column<IAccountsTableDataItem>[] =
    isAutomationPackageEnabled && hasApprovalFlow
      ? []
      : [
          {
            Header: 'Prebooked FX',
            accessor: 'hedges',
            disableSortBy: true,
            Cell: ({
              row: {
                original: { currency, hedges, externalHedges },
              },
            }) => (
              <AmountCell
                amount={hedges + externalHedges}
                currencyCode={currency}
              />
            ),
            width: 100,
            minWidth: 100,
          },
        ];

  const invoicesCells: Column<IAccountsTableDataItem>[] = isAutomationPackageEnabled
    ? [
        {
          id: 'overdue',
          Header: 'Overdue',
          Cell: ({ row }: CellProps<IAccountsTableDataItem>) => (
            <NumberCircleCell
              currencyCode={row.original.currency}
              amount={row.original.overdue?.value}
              count={row.original.overdue?.count}
            />
          ),
          width: 100,
          minWidth: 100,
        },
        {
          id: 'due',
          Header: 'Due soon',
          Cell: ({ row }: CellProps<IAccountsTableDataItem>) => (
            <NumberCircleCell
              currencyCode={row.original.currency}
              amount={row.original?.dueSoon?.value}
              count={row.original?.dueSoon?.count}
            />
          ),
          width: 100,
          minWidth: 100,
        },
      ]
    : [];

  const approvalCells: Column<IAccountsTableDataItem>[] =
    isAutomationPackageEnabled && hasApprovalFlow
      ? [
          {
            id: 'toApprove',
            Header: 'To approve',
            Cell: ({ row }: CellProps<IAccountsTableDataItem>) => (
              <NumberCircleCell
                currencyCode={row.original.currency}
                amount={row.original.toApprove?.value}
                count={row.original.toApprove?.count}
              />
            ),
            width: 100,
            minWidth: 100,
          },
          {
            id: 'toPay',
            Header: 'To pay',
            Cell: ({ row }: CellProps<IAccountsTableDataItem>) => {
              return (
                <NumberCircleCell
                  currencyCode={row.original.currency}
                  amount={row.original.toPay?.value}
                  count={row.original.toPay?.count}
                />
              );
            },
            width: 100,
            minWidth: 100,
          },
        ]
      : [];

  return [
    {
      Header: 'Currency',
      accessor: 'currency',
      Cell: ({ value }) => <CurrencyCell size="large" currencyCode={value} />,
      width: 100,
      minWidth: 100,
    },
    {
      Header: 'Account Balance',
      accessor: 'balance',
      Cell: ({
        value,
        row: {
          original: { currency },
        },
      }) => <AmountCell size="large" amount={value} currencyCode={currency} />,
      width: 140,
      minWidth: 140,
    },
    {
      Header: 'Bills to pay',
      accessor: 'outstanding',
      Cell: ({
        value,
        row: {
          original: { currency },
        },
      }) => (
        <AmountCell
          size="large"
          amount={value?.value}
          currencyCode={currency}
        />
      ),
      width: 140,
      minWidth: 140,
    },
    ...prebookedCells,
    ...invoicesCells,
    ...approvalCells,
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: CellProps<IAccountsTableDataItem>) => (
        <ContextMenuCell data={row.original} />
      ),
      width: 48,
      minWidth: 40,
    },
  ];
};
