import styled from 'styled-components';
import { Col } from 'components';

export const AwaitingRegistrationDashboardSectionWrapper = styled(Col)`
  gap: ${({ theme }) => theme.spacing.s};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing.m};
  }
`;
