import { parseIntoCurrencyString } from 'utils';
import InflowsIcon from '../InflowsIcon/InflowsIcon';
import OutflowsIcon from '../OutflowsIcon/OutflowsIcon';
import { Paragraph } from '../Typography/Typography';
import { FC } from 'react';

interface IOwnProps {
  value: number;
  direction: 'in' | 'out';
  currencyCode: string;
}

const CellAmountWithDirection: FC<IOwnProps> = ({
  value,
  direction,
  currencyCode,
}) => (
  <>
    {direction === 'in' ? <InflowsIcon /> : <OutflowsIcon />}
    <Paragraph variant="bold">{`${parseIntoCurrencyString(
      value
    )} ${currencyCode}`}</Paragraph>
  </>
);

export default CellAmountWithDirection;
