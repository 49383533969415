import { FC } from 'react';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import { Row, Col, Subtitle, Paragraph, Icon } from 'components';
import {
  parseIntoCurrencyString,
  getCountryCodeByCurrency,
  parseRateWithPrecision,
  getPrebookStatusDetails,
} from 'utils';
import { useStoreState } from 'state';
import { IRateContract } from 'types';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { DATE_FORMAT } from 'variables';
import { InnerDetailsWrapper } from '../../../InnerDetails/InnerDetails.styles';
import Pill from 'components/shared/Pill/Pill';

interface IPrebookDetails {
  selectedRateContract: IRateContract;
  isAwaitingPrepayment: boolean;
}

const PrebookDetails: FC<IPrebookDetails> = ({
  selectedRateContract,
  isAwaitingPrepayment,
}) => {
  const theme = useTheme();
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const statusInfo = getPrebookStatusDetails(selectedRateContract);

  const getExpiryText = (contract: IRateContract) => {
    const expiryDate = dayjs(selectedRateContract.expiryDate).format(
      DATE_FORMAT
    );

    const daysLeft = dayjs(contract.expiryDate).isBefore(dayjs())
      ? '0 days'
      : dayjs().to(dayjs(contract.expiryDate), true);

    return selectedRateContract.useableFromDate
      ? `Use on ${expiryDate} (after ${daysLeft})`
      : `Use until ${expiryDate} (${daysLeft} left)`;
  };

  return (
    <InnerDetailsWrapper>
      <Col flex={1} alignSelf="stretch">
        <Row mbValue={theme.spacing.xs} mb>
          <Subtitle variant="bold">Prebook Details</Subtitle>

          <Pill
            variant="emerald"
            text={
              selectedRateContract.bookingName
                ? `${selectedRateContract.bookingName}(${selectedRateContract._shortId})`
                : selectedRateContract._shortId
            }
          />
        </Row>

        <RowInfo>
          <Paragraph>Currency rate</Paragraph>

          <Row>
            <Icon
              width="16px"
              height="16px"
              icon={getCountryCodeByCurrency(
                selectedRateContract.buyCurrency,
                currencies
              )}
            />

            <Paragraph mlValue={theme.spacing.s} ml>
              {`1 ${
                selectedRateContract.sellCurrency
              } = ${parseRateWithPrecision(selectedRateContract.rate)} ${
                selectedRateContract.buyCurrency
              }`}
            </Paragraph>
          </Row>
        </RowInfo>

        <RowInfo alignItems="flex-start">
          <Paragraph>Prebooked amount</Paragraph>

          <Col style={{ textAlign: 'right' }}>
            <Paragraph>
              Booked{' '}
              {`${parseIntoCurrencyString(
                selectedRateContract.buyAmount,
                currencyByCode(selectedRateContract.buyCurrency)?.precision
              )} ${selectedRateContract.buyCurrency}`}
            </Paragraph>

            <Paragraph>
              Remaining{' '}
              {`${parseIntoCurrencyString(
                selectedRateContract.remainingBuyAmount,
                currencyByCode(selectedRateContract.buyCurrency)?.precision
              )} ${selectedRateContract.buyCurrency}`}
            </Paragraph>
          </Col>
        </RowInfo>

        <RowInfo>
          <Paragraph>Created on</Paragraph>
          <Paragraph>
            {dayjs(selectedRateContract._created).format(DATE_FORMAT)}
          </Paragraph>
        </RowInfo>

        <RowInfo>
          <Paragraph>
            Use {selectedRateContract?.useableFromDate ? 'on' : 'by'}
          </Paragraph>
          <Paragraph>
            {dayjs(selectedRateContract.expiryDate).format(DATE_FORMAT)}
          </Paragraph>
        </RowInfo>
      </Col>

      {selectedRateContract && (
        <Col flex={1} alignSelf="stretch">
          <Subtitle variant="bold" mb>
            Additional Information
          </Subtitle>

          <RowInfo>
            <Paragraph>Fee amount</Paragraph>
            <Paragraph>{`${parseIntoCurrencyString(
              selectedRateContract.feeAmount,
              currencyByCode(selectedRateContract.sellCurrency)?.precision
            )} ${selectedRateContract.sellCurrency}`}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph variant="bold">Prepayment amount</Paragraph>
            <Paragraph variant="bold">{`${parseIntoCurrencyString(
              selectedRateContract.prepaymentAmount ?? 0,
              currencyByCode(selectedRateContract.sellCurrency)?.precision
            )} ${selectedRateContract.sellCurrency}`}</Paragraph>
          </RowInfo>

          <RowInfo alignItems="flex-start">
            <Paragraph variant="bold">Status</Paragraph>

            <Col style={{ textAlign: 'right' }}>
              <Paragraph
                style={{
                  color: statusInfo?.color,
                }}
              >
                {` ${statusInfo?.text}`}
              </Paragraph>

              {isAwaitingPrepayment && (
                <Paragraph>{`(Pay by ${dayjs(
                  selectedRateContract.prepaymentPayByDate
                ).format(DATE_FORMAT)})`}</Paragraph>
              )}
              {!isAwaitingPrepayment && (
                <Paragraph>{getExpiryText(selectedRateContract)}</Paragraph>
              )}
            </Col>
          </RowInfo>
        </Col>
      )}
    </InnerDetailsWrapper>
  );
};

export default PrebookDetails;
