import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';
import Loader from 'components/shared/Loader/Loader';
import { Paragraph } from 'components/shared/Typography/Typography';
import { TPartialRecipientWithId } from 'types';
import Button from 'components/shared/Button/Button';
import ImportConfirmationPopup from '../ImportConfirmationPopup/ImportConfirmationPopup';
import BankRecipientItem from './components/BankRecipientItem';
import { RecipientsListWrapper } from './ImportfromBank.styles';
import { InputSearch } from 'components/shared';

interface IOwnProps {
  existingRecipientName?: string;
  existingCurrency?: string;
  disableRecipientName: boolean;
  disableCurrency: boolean;
  onApplyImportedValues: (values: Partial<TPartialRecipientWithId>) => void;
  isLoadingPartialRecipients: boolean;
  partialRecipients: TPartialRecipientWithId[];
}

const ImportFromBank: FC<IOwnProps> = ({
  existingRecipientName,
  existingCurrency,
  disableRecipientName,
  disableCurrency,
  onApplyImportedValues,
  isLoadingPartialRecipients,
  partialRecipients,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const [
    selectedRecipient,
    setSelectedRecipient,
  ] = useState<TPartialRecipientWithId>();
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);

  const importDetailsFromSelectedBeneficiary = ({
    preferredRecipientName,
    checkForChanges = true,
  }: {
    // user can select which name to use inside confirmation popup
    preferredRecipientName?: string;
    checkForChanges?: boolean;
  } = {}) => {
    if (selectedRecipient) {
      const areRecipientNamesDifferent =
        !disableRecipientName &&
        !!selectedRecipient.recipientName &&
        !!existingRecipientName &&
        existingRecipientName !== selectedRecipient.recipientName;

      if (checkForChanges && areRecipientNamesDifferent) {
        setIsShowConfirmationPopup(true);
        return;
      }

      const changesToApply = {
        ...selectedRecipient,
      };

      if (preferredRecipientName) {
        changesToApply.recipientName = preferredRecipientName;
      }

      onApplyImportedValues(changesToApply);
    }
  };

  const filteredRecipients = partialRecipients.filter((recipient) => {
    if (!searchValue) {
      return true;
    }

    return recipient.recipientName
      ?.toLowerCase()
      .includes(searchValue.toLowerCase());
  });

  return (
    <Col rowGap={theme.spacing.s}>
      <Row
        flex={1}
        gap={theme.spacing.s}
        justifyContent="flex-start"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        {isLoadingPartialRecipients && <Loader size="small" />}
        {!isLoadingPartialRecipients && partialRecipients.length === 0 ? (
          <Paragraph>No contacts from bank</Paragraph>
        ) : null}

        {!isLoadingPartialRecipients && !!partialRecipients.length && (
          <>
            <InputSearch
              isGray
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <RecipientsListWrapper>
              {filteredRecipients.map((recipient) => (
                <BankRecipientItem
                  key={recipient.id}
                  isSelected={selectedRecipient?.id === recipient.id}
                  accountNumber={
                    recipient.accountNumber ?? 'Missing account number'
                  }
                  sortCode={recipient.routingNumber ?? 'Missing sort code'}
                  recipientName={
                    recipient.recipientName ?? 'Missing recipient name'
                  }
                  onClick={() => setSelectedRecipient(recipient)}
                />
              ))}
              {filteredRecipients.length === 0 && (
                <Paragraph>No contacts found</Paragraph>
              )}
            </RecipientsListWrapper>
          </>
        )}
      </Row>

      <Row flex={1} justifyContent="flex-end">
        <Button
          type="button"
          onClick={() => importDetailsFromSelectedBeneficiary()}
        >
          Extract data
        </Button>
      </Row>

      {isShowConfirmationPopup && (
        <ImportConfirmationPopup
          onClose={() => setIsShowConfirmationPopup(false)}
          onSubmit={(values) =>
            importDetailsFromSelectedBeneficiary({
              checkForChanges: false,
              preferredRecipientName: values.recipientName,
            })
          }
          existingRecipientName={existingRecipientName ?? ''}
          existingCurrency={existingCurrency ?? ''}
          newRecipientName={selectedRecipient?.recipientName ?? ''}
          disableRecipientName={disableRecipientName}
          disableCurrency={disableCurrency}
        />
      )}
    </Col>
  );
};

export default ImportFromBank;
