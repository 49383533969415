import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { Button, Col, Row, Paragraph, InputSearch, Loader } from 'components';
import { IOpenBankingInstitution } from 'types';
import { StyledInstitutionImage } from 'components/shared/StyledInstitutionImage/StyledInstitutionImage.styles';

interface IOwnProps {
  institutions: IOpenBankingInstitution[];
  onInstitutionSelect: (institution: IOpenBankingInstitution) => Promise<void>;
  isLoadingInstitutions: boolean;
  isLoadingPaymentAuthUrl?: boolean;
}

const InstitutionsList: FC<IOwnProps> = ({
  institutions,
  onInstitutionSelect,
  isLoadingInstitutions,
  isLoadingPaymentAuthUrl,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');

  const filteredInstitutions = institutions.filter((item) =>
    item.name.toLowerCase().trim().includes(searchValue.toLowerCase().trim())
  );
  const isList = !!filteredInstitutions.length;

  if (isLoadingInstitutions || isLoadingPaymentAuthUrl) {
    return <Loader size="large" />;
  }

  return (
    <>
      <Paragraph mb>
        Funds must come from your company's bank account and not from private
        account.
      </Paragraph>

      <InputSearch
        isGray={true}
        type="text"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <Col mt gap={theme.spacing.xs} alignItems="flex-start">
        {!isList && (
          <Paragraph style={{ textAlign: 'center' }} variant="bold">
            No results found
          </Paragraph>
        )}

        {isList &&
          filteredInstitutions.map((institution) => (
            <Button
              variant="link"
              key={institution.id}
              onClick={() => onInstitutionSelect(institution)}
            >
              <Row gap={theme.spacing.m}>
                <StyledInstitutionImage
                  src={
                    institution.media?.find((item) => item.type === 'icon')
                      ?.source
                  }
                  alt={institution.name}
                />
                <Paragraph variant="bold">{institution.name}</Paragraph>
              </Row>
            </Button>
          ))}
      </Col>
    </>
  );
};

export default InstitutionsList;
