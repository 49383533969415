import { FC, AnchorHTMLAttributes, CSSProperties } from 'react';
import { DefaultThemeColorKey } from 'styled-components';
import { StyledLink } from './Link.styles';
import { SpacingCssProps } from '../Spacing/Spacing.styles';
import Icon, { IconProps } from '../Icon/Icon';
import { MakeOptional } from 'types';

interface LinkProps
  extends Pick<
      AnchorHTMLAttributes<HTMLAnchorElement>,
      'href' | 'target' | 'rel' | 'type' | 'download'
    >,
    MakeOptional<Pick<IconProps, 'icon'>>,
    SpacingCssProps,
    Pick<CSSProperties, 'display'> {
  color?: DefaultThemeColorKey;
}

const Link: FC<LinkProps> = ({
  children,
  icon,
  href,
  target,
  rel,
  type,
  download,
  display,
  color,
  ...spacingCssProps
}) => (
  <StyledLink
    {...spacingCssProps}
    href={href}
    target={target}
    rel={rel}
    type={type}
    download={download}
    display={display}
    color={color}
  >
    {children}

    {!!icon && <Icon icon={icon} height="16px" width="16px" ml />}
  </StyledLink>
);

export default Link;
