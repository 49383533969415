import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'components';
import { IPastPerformanceItem } from 'types';
import { generateSearchQuery } from 'utils';
import { searchReports } from 'services/firebase/analysis';
import { generateReportsTableColumns } from 'pages/Reports/components/ContributorsTable/tableColumnsGenerator';
import { useStoreState } from 'state';
import { SortingRule } from 'react-table';
import { isEqual } from 'lodash';
import { getSortQueryStringFromTableSort } from 'utils/search';
import { ExposedUseTableProps } from 'components/shared/Table/types';

interface IOwnProps {
  currencyCode: string;
  dateFrom: string;
  dateTo: string;
}

const ContributorsTable: FC<IOwnProps> = ({
  currencyCode,
  dateFrom,
  dateTo,
}) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const [isLoadingFirstPage, setIsLoadingFirstPage] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true);
  const [data, setData] = useState<IPastPerformanceItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = useMemo(
    () =>
      generateReportsTableColumns({
        entityCurrency: entityCurrencyCode,
      }),
    [entityCurrencyCode]
  );
  const tableRef = useRef<ExposedUseTableProps<IPastPerformanceItem>>(null);
  const [sortState, setSortState] = useState<
    SortingRule<IPastPerformanceItem>[]
  >([]);
  const handleSortChange = useCallback(
    (tableSortState: SortingRule<IPastPerformanceItem>[]) => {
      if (isEqual(tableSortState, sortState)) {
        return;
      }

      setSortState(tableSortState);
    },
    [sortState]
  );

  const fetchFirstReportsPage = useCallback(async () => {
    setHasMoreToLoad(true);
    setCurrentPage(1);
    setIsLoadingFirstPage(true);

    let filtersStrings = [];

    if (currencyCode && currencyCode !== 'all') {
      filtersStrings.push(`currency=${currencyCode}`);
    }

    if (dateFrom) {
      filtersStrings.push(`dateFrom=${dateFrom}`);
    }

    if (dateTo) {
      filtersStrings.push(`dateTo=${dateTo}`);
    }

    const sortFields = getSortQueryStringFromTableSort(sortState);

    const searchQuery = generateSearchQuery({
      page: 1,
      filters: filtersStrings.join(','),
      sortFields,
    });

    const response = await searchReports(searchQuery);

    if (!response.data.data?.results) {
      return;
    }

    setData(response.data.data.results);
    setIsLoadingFirstPage(false);

    const currentPageFromResponse = response.data.data.meta.page.current;
    const totalPagesFromResponse = response.data.data.meta.page.total_pages;

    if (currentPageFromResponse === totalPagesFromResponse) {
      setHasMoreToLoad(false);
    }
  }, [currencyCode, dateFrom, dateTo, sortState]);

  useEffect(() => {
    fetchFirstReportsPage();
  }, [fetchFirstReportsPage]);

  const onLoadMoreItems = useCallback(async () => {
    if (isLoadingMore) {
      return;
    }

    setIsLoadingMore(true);
    let filtersStrings = [];

    if (currencyCode && currencyCode !== 'all') {
      filtersStrings.push(`currency=${currencyCode}`);
    }

    if (dateFrom) {
      filtersStrings.push(`dateFrom=${dateFrom}`);
    }

    if (dateTo) {
      filtersStrings.push(`dateTo=${dateTo}`);
    }

    const sortFields = getSortQueryStringFromTableSort(sortState);

    const searchQuery = generateSearchQuery({
      page: currentPage + 1,
      filters: filtersStrings.join(','),
      sortFields,
    });

    const response = await searchReports(searchQuery);

    if (!response.data.data?.results) {
      return;
    }

    setData([...data, ...response.data.data.results]);

    setIsLoadingMore(false);
    setCurrentPage(currentPage + 1);

    const currentPageFromResponse = response.data.data.meta.page.current;
    const totalPagesFromResponse = response.data.data.meta.page.total_pages;

    if (currentPageFromResponse === totalPagesFromResponse) {
      setHasMoreToLoad(false);
    }
  }, [
    isLoadingMore,
    currencyCode,
    dateFrom,
    dateTo,
    sortState,
    currentPage,
    data,
  ]);

  return (
    <>
      <Table
        isVirtualized
        data={data}
        columns={columns}
        withInfiniteLoading
        onLoadMoreItems={onLoadMoreItems}
        loadingThreshold={10}
        isLoadingMoreItems={isLoadingMore}
        itemsCount={data.length}
        hasMoreToLoad={hasMoreToLoad}
        onSort={handleSortChange}
        manualSortBy
        sortable
        ref={tableRef}
        isLoading={isLoadingFirstPage}
      />
    </>
  );
};

export default ContributorsTable;
