import { FC, useEffect, useState } from 'react';
import { useQuery } from 'hooks';
import { StaleCarousel, Loader } from 'components';
import { useHistory } from 'react-router-dom';
import { SliderWrapper } from 'pages/RiskSettings/RiskSettings.styles';
import TwoSidesTemplate from 'components/pageTemplates/TwoSidesTemplate/TwoSidesTemplate';
import StepCreatingClient from './components/StepCreatingClient/StepCreatingClient';
import { GradientTitle } from 'components/shared/GradientTitle/GradientTitle.styles';
import useNewClientXero from './useNewClientXero';
import { getMyClientsLink } from 'utils/links';
import { useStoreActions } from 'state';

const NewClientXero: FC = () => {
  const history = useHistory();
  const URLQuery = useQuery();
  const activeStep = URLQuery.get('step');
  const queryError = URLQuery.get('error');
  const isCanceledFromXero = queryError === 'access_denied';
  const [isLoading, setIsLoading] = useState(true);
  const [hasRunCreatingEntity, setHasRunCreatingEntity] = useState(false);
  const { onSignUpFromExternalSystem } = useStoreActions(
    (state) => state.UserState
  );
  const activeStepAsNumber = activeStep && parseInt(activeStep);
  const {
    companyName,
    createNewClientEntity,
    isCompleted,
    isCreatingEntity,
    isError,
  } = useNewClientXero();

  useEffect(() => {
    if (!activeStep) {
      onSignUpFromExternalSystem();
    } else if (activeStep && !hasRunCreatingEntity) {
      createNewClientEntity();
      setIsLoading(false);
      setHasRunCreatingEntity(true);
    }
  }, [
    activeStep,
    createNewClientEntity,
    onSignUpFromExternalSystem,
    hasRunCreatingEntity,
    setHasRunCreatingEntity,
  ]);

  if (isLoading || !activeStep || !activeStepAsNumber) {
    return <Loader size="large" />;
  }

  return (
    <TwoSidesTemplate
      onClose={() => history.push(getMyClientsLink())}
      leftColumn={
        <>
          {activeStepAsNumber < 2 && (
            <GradientTitle variant="h1">
              Let’s sign up a new client to HedgeFlows
            </GradientTitle>
          )}

          {activeStepAsNumber === 2 && (
            <GradientTitle variant="h1">
              Now you’re ready to start:
            </GradientTitle>
          )}

          <SliderWrapper>
            <StaleCarousel />
          </SliderWrapper>
        </>
      }
      rightColumn={
        activeStep && (
          <StepCreatingClient
            isCompleted={isCompleted}
            isError={isError}
            isCanceledFromXero={isCanceledFromXero}
            isCreatingEntity={isCreatingEntity}
            companyName={companyName}
          />
        )
      }
    />
  );
};

export default NewClientXero;
