import { FC, useState } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';

import { getGoToStepQuery } from 'utils';
import { Col, StaleStepsHorizontal } from 'components';
import { useQuery } from 'hooks';
import { withErrorBoundary } from 'hoc';
import ExchangeDetails from './components/ExchangeDetails/ExchangeDetails';
import Review from './components/Review/Review';
import Exchange from './components/Exchange/Exchange';
import { IConversion } from 'types/conversions';
import useExchangeDetails from './hooks/useExchangeDetails';
import useUrlValues from 'hooks/useUrlValues';

const CurrencyExchange: FC<RouteComponentProps> = ({ history }) => {
  const { url } = useRouteMatch();
  const URLQuery = useQuery();
  const { step } = useUrlValues('step');

  const [
    createdConversion,
    setCreatedConversion,
  ] = useState<IConversion | null>(null);

  const goToStep = (stepNumber: number) =>
    history.push(getGoToStepQuery(url, URLQuery, stepNumber));

  const exchangeDetailsValues = useExchangeDetails();

  const renderContent = () => {
    if (!step) {
      return null;
    }

    switch (parseInt(step)) {
      case 1:
        return (
          <ExchangeDetails
            onContinue={() => {
              goToStep(2);
            }}
            {...exchangeDetailsValues}
          />
        );
      case 2:
        return (
          exchangeDetailsValues && (
            <Review
              onContinue={(conversionData: IConversion) => {
                setCreatedConversion(conversionData);
                goToStep(3);
              }}
              savedValues={exchangeDetailsValues}
            />
          )
        );
      case 3:
        return (
          createdConversion && (
            <Exchange
              onContinue={() => history.push('/app/dashboard')}
              conversionId={createdConversion.id}
            />
          )
        );
      default:
        return null;
    }
  };

  const renderSteps = (activeStep: string | null) => {
    if (!activeStep) {
      return null;
    }

    const stepAsNumber = parseInt(activeStep);

    return (
      <StaleStepsHorizontal
        activeStep={stepAsNumber}
        title="Currency exchange"
        onClose={() => {}}
        data={[
          {
            title: 'Exchange details',
            onClick: () => goToStep(1),
            disabled: stepAsNumber === 3,
          },
          {
            title: 'Review',
            onClick: () => goToStep(2),
            disabled: stepAsNumber === 3,
          },
          {
            title: 'Confirmation',
            onClick: () => goToStep(3),
            disabled: stepAsNumber === 3,
          },
        ]}
      >
        {renderContent()}
      </StaleStepsHorizontal>
    );
  };

  return (
    <Col flex={1} justifyContent="center" alignItems="center">
      {renderSteps(step)}
    </Col>
  );
};

export default withErrorBoundary(CurrencyExchange);
