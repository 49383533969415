import {
  ICountry,
  ICurrency,
  IRecipient,
  IRecipientValidationResult,
  IResponse,
} from 'types';
import { openQuery } from 'utils';
import { AxiosPrivateFirebaseInstance } from 'settings';
import db from 'services/firestore';
import { errorHandler } from 'utils/errors';

export interface SubscribeToRecipientsParams {
  entityId: string;
  callback: (recipients: IRecipient[]) => void;
}

export const subscribeToRecipients = ({
  entityId,
  callback,
}: SubscribeToRecipientsParams) =>
  db
    .collection('recipients')
    .where('_owner', '==', entityId)
    .onSnapshot((query) => callback(openQuery(query)));

export interface CreateRecipientParams {
  recipient: IRecipient;
  isDraft?: boolean;
}

export const createRecipient = async ({
  recipient,
  isDraft,
}: CreateRecipientParams) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post(
      `/recipients?isDraft=${isDraft}`,
      recipient
    );

    return data;
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface UpdateRecipientParams {
  recipientId: IRecipient['id'];
  recipientData: Omit<Partial<IRecipient>, 'id'>;
  isDraft?: boolean;
}

export const updateRecipient = async ({
  recipientId,
  recipientData,
  isDraft,
}: UpdateRecipientParams) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.put(
      `/recipients/${recipientId}?isDraft=${isDraft}`,
      recipientData
    );

    return data;
  } catch (error: any) {
    errorHandler(error);
  }
};

export const deleteRecipient = async (recipientId: IRecipient['id']) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.delete(
      `/recipients/${recipientId}`
    );

    return data;
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface CheckRecipientBankDetailsPayload {
  type: 'iban' | 'bic_swift' | 'aba';
  value: string;
}

export const checkRecipientBankDetails = async (
  payload: CheckRecipientBankDetailsPayload
) => {
  return await AxiosPrivateFirebaseInstance.post<IResponse>(
    '/recipients/bank_details',
    payload
  );
};

export interface GetRecipientCreationMetadataParams {
  accountCountry?: ICountry['alpha2'];
  recipientCountry?: ICountry['alpha2'];
  currency: ICurrency['code'];
}

export const getRecipientCreationMetadata = async ({
  accountCountry,
  recipientCountry,
  currency,
}: GetRecipientCreationMetadataParams) => {
  const params = new URLSearchParams();

  if (accountCountry) {
    params.append('accountCountry', accountCountry);
  }

  if (recipientCountry) {
    params.append('recipientCountry', recipientCountry);
  }

  return await AxiosPrivateFirebaseInstance.get(
    `/recipients/meta/${currency}`,
    {
      params,
    }
  );
};

export const validateRecipient = async (payload: {
  /** used for validation of existing recipient on updates */
  recipientId?: string;
  recipientName: string;
  accountNumber: string;
  sortCode: string;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IRecipientValidationResult>>(
    `/recipients/validate`,
    payload
  );
