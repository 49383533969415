import { FC } from 'react';
import { useStoreState } from 'state';
import { Row, Icon, Paragraph, Title } from 'components';
import { useTheme } from 'styled-components';

interface OwnProps {
  currencyCode: string;
  size?: 'medium' | 'large';
}

const CurrencyCell: FC<OwnProps> = ({ currencyCode, size = 'medium' }) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const currency = currencyByCode(currencyCode);

  const generateCellContent = () => {
    if (!currency) {
      return null;
    }

    if (size === 'large') {
      return (
        <>
          <Icon
            width={theme.iconSizes.xl}
            height={theme.iconSizes.xl}
            icon={currency.countryCode}
          />
          <Title variant="h4">{currencyCode}</Title>
        </>
      );
    }

    return (
      <>
        <Icon icon={currency.countryCode} />
        <Paragraph variant="bold">{currencyCode}</Paragraph>
      </>
    );
  };

  return (
    <Row columnGap={theme.spacing.s} alignItems="center">
      {generateCellContent()}
    </Row>
  );
};

export default CurrencyCell;
