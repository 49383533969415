import {
  ChangeEvent,
  FC,
  FocusEvent,
  HTMLAttributes,
  InputHTMLAttributes,
  LegacyRef,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import cx from 'classnames';

import { Wrapper } from './StaleInputNew.styles';

export interface InputNewProps {
  id: string;
  type?: HTMLInputElement['type'];
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  maxLength?: InputHTMLAttributes<HTMLInputElement>['maxLength'];
  autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus'];
  disabled?: InputHTMLAttributes<HTMLInputElement>['disabled'];
  label?: string;
  view?: 'static' | 'moving';
  icon?: string;
  isLoading?: boolean;
  isDone?: boolean;
  onActionIcon?: () => any;
  onClick?: InputHTMLAttributes<HTMLInputElement>['onClick'];
  onKeyDown?: InputHTMLAttributes<HTMLInputElement>['onKeyDown'];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => any;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  inputRef?: LegacyRef<HTMLInputElement> | null;
  locked?: boolean;
  error?: string;
  renderIcon?: ReactNode;
  prefix?: string;
  value?: string;
  className?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

const StaleInputNew: FC<InputNewProps> = ({
  id,
  label = '',
  view = 'static',
  type = 'text',
  inputMode = 'text',
  autoFocus = false,
  disabled = false,
  icon = '',
  isLoading = false,
  isDone = false,
  onActionIcon = (e: any) => e,
  onChange = () => {},
  onClick,
  onKeyDown,
  locked = false,
  error = '',
  maxLength,
  renderIcon,
  onFocus,
  onBlur,
  prefix = '',
  value = '',
  className = '',
  inputProps = {},
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      setIsFocused(true);
    }
  }, [autoFocus]);

  return (
    <Wrapper
      className={cx(view, value && 'filled', isFocused && 'focused', className)}
      icon={!!icon}
      locked={locked}
      error={!!error}
      isFocused={isFocused}
      isPrefix={!!prefix}
      isDisabled={disabled}
    >
      {label && view === 'static' && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}

      <div className="wrap">
        {label && view === 'moving' && (
          <label className="label" htmlFor={id}>
            {label}
          </label>
        )}

        {!!prefix && <div className="prefix">{prefix}</div>}

        <input
          id={id}
          className="input"
          inputMode={inputMode}
          type={type === 'password' ? (isShowPassword ? 'text' : type) : type}
          placeholder={view === 'static' ? label : ''}
          autoComplete="off"
          maxLength={maxLength}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value}
          onChange={(event) => {
            onChange(event);
          }}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onFocus={(event) => {
            setIsFocused(true);
            onFocus && onFocus(event);
          }}
          onBlur={(event) => {
            setIsFocused(false);
            onBlur && onBlur(event);
          }}
          {...inputProps}
        />
      </div>

      <div className="icons-wrapper">
        {icon && onActionIcon && (
          <button type="button" className="icon" onClick={onActionIcon}>
            <svg width="24px" height="24px">
              <use xlinkHref={`#${icon}`} />
            </svg>
          </button>
        )}

        {icon && !onActionIcon && (
          <svg className="icon" width="24px" height="24px">
            <use xlinkHref={`#${icon}`} />
          </svg>
        )}

        {isLoading && (
          <svg className="icon loader" width="24px" height="24px">
            <use xlinkHref={`#loading-ico`} />
          </svg>
        )}

        {isDone && (
          <svg
            className="icon"
            width="24px"
            height="24px"
            style={{
              fill: '#3AA33A',
            }}
          >
            <use xlinkHref={`#tick-ico`} />
          </svg>
        )}

        {type === 'password' && (
          <button
            type="button"
            className="icon"
            onClick={() => setIsShowPassword(!isShowPassword)}
          >
            <svg width="24px" height="24px">
              <use
                xlinkHref={`#${
                  isShowPassword ? 'eye-closed-ico' : 'eye-open-ico'
                }`}
              />
            </svg>
          </button>
        )}

        {renderIcon && <div className="icon">{renderIcon}</div>}
      </div>

      {error && <span className="error">{error}</span>}
    </Wrapper>
  );
};

export default StaleInputNew;
