import { FC } from 'react';
import Button from 'components/shared/Button/Button';
import { useTheme } from 'styled-components';
import Popup from 'components/shared/Popup/Popup';
import { Row } from 'components/shared/Row/Row';
import { Paragraph } from 'components/shared/Typography/Typography';

interface OwnProps {
  onClose: () => void;
  onSubmit: () => void;
  disabled: boolean;
}

const DirtyPopup: FC<OwnProps> = ({ onClose, onSubmit, disabled }) => {
  const theme = useTheme();

  return (
    <Popup
      width="347px"
      HeaderContent={
        <Paragraph variant="bold">
          You have unsaved changes, do you want to:
        </Paragraph>
      }
      onClose={onClose}
    >
      <Row flex={1} gap={theme.spacing.m}>
        <Button disabled={disabled} onClick={onSubmit}>
          Save
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Ignore
        </Button>
      </Row>
    </Popup>
  );
};

export default DirtyPopup;
