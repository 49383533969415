import { useEffect, useState } from 'react';
import { Firebase } from 'services';
import { useStoreState } from 'state';
import { RECIPIENT_STATUS, IInvoice, IRecipient } from 'types';
import { errorHandler } from 'utils/errors';

/**
 * If the invoice does not have a contactId, this retrieves the contact from the external system.
 *
 * @param invoice
 * @returns
 */
const useExternalInvoiceContactOrContactOnInvoice = (invoice?: IInvoice) => {
  const { recipientById } = useStoreState((state) => state.RecipientsState);
  const [
    recipientFromInvoice,
    setRecipientFromInvoice,
  ] = useState<IRecipient>();

  const { entityId } = useStoreState(({ UserState }) => UserState);

  const [isLoadingInvoiceOrContact, setIsLoadingInvoiceOrContact] = useState(
    !!(
      !invoice?.contactId &&
      invoice?.externalRefs?.sourceSystemContactId &&
      invoice.externalRefs.sourceSystemId
    )
  );

  useEffect(() => {
    const getInvoiceContact = async () => {
      try {
        const contactFromInvoiceContactId = recipientById(invoice?.contactId);
        if (invoice?.id && !invoice?.contactId) {
          setIsLoadingInvoiceOrContact(true);
          const invoiceContactResponse = await Firebase.Invoices.getInvoiceExternalContact(
            invoice.id
          );
          const invoiceContact = invoiceContactResponse.data.data;

          if (invoiceContact) {
            const contact: IRecipient = {
              ...invoiceContact,
              status: RECIPIENT_STATUS.draft,
              externalRefs: {
                sourceSystem: invoice?.externalRefs?.sourceSystem,
                sourceSystemId: invoice?.externalRefs?.sourceSystemContactId,
              },
            };

            setRecipientFromInvoice(contact);
          }
          setIsLoadingInvoiceOrContact(false);
        } else if (contactFromInvoiceContactId) {
          setRecipientFromInvoice(contactFromInvoiceContactId);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingInvoiceOrContact(false);
      }
    };

    getInvoiceContact();
  }, [invoice, recipientById, entityId]);

  return {
    isLoadingInvoiceOrContact,
    recipientFromInvoice,
  };
};

export default useExternalInvoiceContactOrContactOnInvoice;
