import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Row } from 'components';
import { SUBSCRIPTION_CARDS } from './constants';
import SubscriptionCard from './components/SubscriptionCard/SubscriptionCard';
import { WhiteContentContainer } from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';

const Subscriptions: FC = () => {
  const theme = useTheme();

  return (
    <WhiteContentContainer>
      <Row gap={theme.spacing.m} alignItems="flex-start">
        {SUBSCRIPTION_CARDS.map((data) => (
          <SubscriptionCard key={data.id} data={data} />
        ))}
      </Row>
    </WhiteContentContainer>
  );
};

export default Subscriptions;
