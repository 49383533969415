import { action, Action, thunk, Thunk, computed, Computed } from 'easy-peasy';
import firebase from 'firebase/compat/app';
import { Firebase } from 'services';
import { reset, StateModel } from 'state';
import {
  CASHFLOW_TYPE,
  ENTITY_TYPE,
  ICountry,
  ICurrency,
  IIntegrationSettingsAll,
  IResponse,
  IUser,
  Nullable,
  TInvoicesAggregationPerFilter,
} from 'types';
import { Notify } from 'utils';
import auth from 'services/auth';
import LogRocket from 'logrocket';
import {
  IIntegrationPermissions,
  IIntegrationStatusSummary,
  TPlatformNames,
} from 'types/integrations';
import {
  IEntityLoadingState,
  IOneOfEntityAccountDetails,
} from 'types/entities';
import {
  getAuthUrlForSignUpFromExternalSystem,
  getEntityAccountDetails,
  GetEntityAccountDetailsParams,
  getEntityInvites,
  getEntityUsers,
  getSettings,
  saveIntegrationEnginePermissions,
} from 'services/firebase';
import { isPackageEnabled } from 'utils/packages';
import {
  IPermitRoleV2,
  TEntityPackageKeys,
  TEntityUserAttributes,
} from 'types/permissions';
import { MultiFactorError } from 'firebase/auth';
import { ISystemFields } from 'types';
import { errorHandler } from 'utils/errors';
import { localStorageInstance } from 'utils/localStorage';

interface User {
  id: IUser['id'];
  email: IUser['email'];
  name: IUser['name'];
  firstName?: IUser['firstName'];
  lastName?: IUser['lastName'];
  entityId: string;
  enabled: boolean;
  status: string;
  onboardingId?: string;
  debuggable?: boolean | undefined;
  isSuperAdmin?: boolean | undefined;
  isAccountant?: IUser['isAccountant'];
}

export interface ICustomCashflowDefinition {
  id: string;
  name: string;
  type: CASHFLOW_TYPE.receivable | CASHFLOW_TYPE.payable;
}

interface IApprovalSettingsRecipientOrApprover {
  id: string;
  name: string;
}
export interface IEntityApprovalSettings {
  hasApprovalFlow?: boolean;
  // level 1
  autoApproveEnabled?: boolean;
  autoApproveAmount?: number;
  autoApproveRecipientList?: IApprovalSettingsRecipientOrApprover[];
  // level 2
  approver1List?: IApprovalSettingsRecipientOrApprover[];
  approver2List?: IApprovalSettingsRecipientOrApprover[];
  requiredNumberOfApprovals?: number;
  // level 3
  exceptionalApprovalEnabled: boolean;
  exceptionalAmount?: number;
  exceptionalRecipientList?: IApprovalSettingsRecipientOrApprover[];
  exceptionalApproverList?: IApprovalSettingsRecipientOrApprover[];
}

type TCashflowsShareSystem = 'google' | 'microsoftFileUpload';

export interface IEntitySettings {
  email?: string;
  shouldSendTransactionConfirmations?: boolean;
  shouldSendApprovalAlerts?: boolean;
  shouldSendRemittanceCopiesToSelf?: boolean;
  shouldSendEmailIfRiskToleranceExceeded?: boolean;
  riskTolerance?: number;
  accountingSystem?: string;
  howDidYouFindUs?: string;
  riskSettingCommittedCashFlows?: string;
  riskSettingExpectedCashFlows?: string;
  HFGuru?: {
    integrations: {
      isDone: boolean;
    };
  };
  deferredSuggestedFollowedCurrencies?: Record<ICurrency['code'], string>; // "YYYY-MM-DD"
  riskSettingHedgeRatio?: TRiskSettingHedgeRatio;
  notificationSettingsRiskAlertsEnabled?: boolean;
  customCashflowDefinitions?: ICustomCashflowDefinition[];
  excludedAccountIds?: string[];
  cashflowsFileShareSystem?: TCashflowsShareSystem;
  cashflowsFileShareUrl?: string;
  approvalSettings?: IEntityApprovalSettings;
}

export type TRiskSettingHedgeRatioKeys = '3' | '6' | '9' | '12' | '24' | '36';

interface IRiskSettingHedgeRatioValues {
  min: number;
  max: number;
}

export type TRiskSettingHedgeRatio = Record<
  TRiskSettingHedgeRatioKeys,
  IRiskSettingHedgeRatioValues
>;

interface IEntityPackage {
  freeTrialUntil?: string | null;
  enabledUntil?: string | null;
}

export type TEntityPackages = Record<TEntityPackageKeys, IEntityPackage>;

export const packageKeysToNames: Record<TEntityPackageKeys, string> = {
  automation: 'Payment automation in 30 currencies',
  global: 'Global Business Account',
  fxManagement: 'FX Risk and cost management',
};

export interface IEntity extends IEntitySettings {
  expectedAnnualTurnover: number;
  expectedFxTurnoverUpperLimit: number;
  externalRefs: {
    ccId: string; // "9367e94f-7015-4a78-882c-67009c14e74c"
    ccOnBehalfOfId: string; //"91f2cfb7-2eb0-4ab9-9fef-9469fee1280"
    ccShortReference: string; // "210908-58552"
  };
  invoicesAndReceivesPayments: boolean;
  name: string;
  onboarded: boolean;
  status?: string;
  companyCountry?: string;
  canProvideInvoicesAndBankStatements: boolean;
  companyType: ENTITY_TYPE; // "limitedCompany"
  enabled: boolean;
  entityCurrency: string; // GBP for example
  remainingCredit?: number;
  hfGuru?: boolean;
  approverId?: string;
  shouldShowReports?: boolean;
  packages?: TEntityPackages;
  isUsingDeposits?: boolean; // true if entity wants to always use deposits during prebooking
  /** ISO 8601 string, e.g., 2024-07-01T09:51:16.748Z */
  deferAddAuthenticator?: string;
}

export interface IClient {
  entityId: string;
  name: IEntity['name'];
  entityCurrency: IEntity['entityCurrency'];
  worstRiskRatingStars: number;
  topCurrenciesByCashflowRisk: string[];
  outstandingPaymentRunsCount: number;
  billsOutstanding?: TInvoicesAggregationPerFilter;
  billsOverdue?: TInvoicesAggregationPerFilter;
}

export interface IClientWithLoadingState extends IClient {
  entityLoadingState: Omit<IEntityLoadingState, 'id'>;
}

export interface IEntityUser {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  roles: IPermitRoleV2[];
  enabled: boolean;
  attributes: TEntityUserAttributes[];
}

type TInviteStatus = 'pending' | 'accepted' | 'rejected' | 'expired';

export interface IEntityInvite extends ISystemFields {
  userId: string; // invitee
  firstName: string;
  lastName: string;
  email: string;
  entityId: string;
  roles: string[];
  status: TInviteStatus;
}

export interface UserStateModel {
  user: User | null;
  userAuth: firebase.User | null;
  isUserApprover: Computed<UserStateModel, boolean>;
  hasApproverRole: Computed<UserStateModel, boolean>;
  isUserSubmitter: Computed<UserStateModel, boolean>;
  // TODO: add correct interface for user entity
  userEntity: IEntity;
  entityOnboardingRecord: any;
  setState: Action<UserStateModel, [string, any]>;
  setUser: Action<UserStateModel, User | null>;
  setUserEntity: Action<UserStateModel, any>;
  setUserAuth: Action<UserStateModel, firebase.User | null>;
  integrationsSummary: IIntegrationStatusSummary | null;
  integrationEngineSettings: IIntegrationSettingsAll;
  updateIntegrationEngineSettingsPermissions: Thunk<
    UserStateModel,
    {
      permissions: Partial<IIntegrationPermissions>;
      platformName: TPlatformNames;
    }
  >;
  setIntegrationEngineSettingsPermissions: Action<
    UserStateModel,
    Partial<IIntegrationPermissions>
  >;
  getIntegrationEngineSettings: Thunk<UserStateModel>;
  isLoadingIntegrationEngineSettings: boolean;
  onSignUpFromExternalSystem: Thunk<UserStateModel>;
  setUserEntityIntegrations: Action<
    UserStateModel,
    IIntegrationStatusSummary | null
  >;
  setEntityOnboardingRecord: Action<UserStateModel, any>;
  signIn: Thunk<
    UserStateModel,
    {
      email: string;
      password: string;
      disableNotifyOnError?: boolean;
      resetAddBackupMethodPopup?: boolean;
    }
  >;
  signInWithGoogle: Thunk<
    UserStateModel,
    { resetAddBackupMethodPopup?: boolean } | undefined
  >;
  createUser: Thunk<
    UserStateModel,
    Omit<Firebase.CreateUserPayload, 'id'>,
    null,
    StateModel,
    Promise<IResponse | undefined>
  >;
  createAuthUser: Thunk<
    UserStateModel,
    {
      email: string;
      password: string;
    }
  >;
  getUser: Thunk<UserStateModel, Firebase.GetUserParams>;
  getUserPermissionsOnEntity: Thunk<UserStateModel>;
  signOut: Thunk<UserStateModel>;
  getUserEntity: Thunk<UserStateModel, Firebase.GetUserEntityParams>;
  getEntityOnboardingRecord: Thunk<UserStateModel>;
  getEntityAccountDetails: Thunk<UserStateModel, GetEntityAccountDetailsParams>;
  getEntityUsers: Thunk<UserStateModel, { entityId: string }>;
  getEntityInvites: Thunk<UserStateModel, { entityId: string }>;
  userId: Computed<UserStateModel, Nullable<User['id']>>;
  userFirstName: Computed<UserStateModel, Nullable<User['firstName']>>;
  userEmail: Computed<UserStateModel, Nullable<User['email']>>;
  isUserRegistrationDone: Computed<UserStateModel, Nullable<User['enabled']>>;
  entityId: Computed<UserStateModel, Nullable<User['entityId']>>;
  entityType: Computed<UserStateModel, ENTITY_TYPE>;
  entityCountry: Computed<UserStateModel, Nullable<ICountry['alpha2']>>;
  isEntityEnabled: Computed<UserStateModel, boolean>;
  isEntityOnboarded: Computed<UserStateModel, boolean>;
  showReports: Computed<UserStateModel, boolean>;
  isWithinCreditLimit: Computed<UserStateModel, (amount: number) => boolean>;
  hasApprovalFlow: Computed<UserStateModel, boolean>;
  entityCurrencyCode: Computed<UserStateModel, ICurrency['code']>;
  entityAccountDetails: IOneOfEntityAccountDetails[];
  entityUsers: IEntityUser[];
  entityInvites: IEntityInvite[];
  isLoadingEntityInvites: boolean;
  userRoles: Computed<UserStateModel, IPermitRoleV2[]>;
  isAuthLoading: boolean;
  isAccountant: Computed<UserStateModel, boolean>;
  permissions: string[];
  isAutomationPackageEnabled: Computed<UserStateModel, boolean>;
  isFxManagementPackageEnabled: Computed<UserStateModel, boolean>;
  isAutomationPackageFreeTrialEnabled: Computed<UserStateModel, boolean>;
  isFxManagementPackageFreeTrialEnabled: Computed<UserStateModel, boolean>;
  isMsDynamicsIntegration: Computed<UserStateModel, boolean>;
}

export const UserState: UserStateModel = {
  user: null,
  userAuth: null,
  setUserAuth: action((state, payload) => {
    state.userAuth = payload;
  }),
  isUserApprover: computed([(state) => state], ({ permissions }) =>
    permissions.includes('cashflows:approve')
  ),
  isUserSubmitter: computed([(state) => state], ({ permissions }) =>
    permissions.includes('cashflows:submit')
  ),
  hasApproverRole: computed([(state) => state], ({ userRoles }) => {
    let hasApproverRole = false;

    for (const role of userRoles) {
      if (role.role?.includes('approver')) {
        hasApproverRole = true;
      }
    }

    return hasApproverRole;
  }),
  entityAccountDetails: [],
  entityUsers: [],
  entityInvites: [],
  isLoadingEntityInvites: true,
  integrationsSummary: null,
  isLoadingIntegrationEngineSettings: true,
  integrationEngineSettings: {
    permissions: {
      importBankBalances: true,
      importBills: true,
      importInvoices: true,
      importPayments: true,
      importContactDetails: true,
      importPurchaseAndSalesOrders: true,
    },
  },
  userEntity: {} as IEntity,
  entityOnboardingRecord: null,
  isAuthLoading: true,
  permissions: [],
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  getIntegrationEngineSettings: thunk(async (actions, _, { getState }) => {
    try {
      actions.setState(['isLoadingIntegrationEngineSettings', true]);
      const entityId = getState().entityId;

      if (!entityId) {
        return;
      }

      const { data } = await getSettings(entityId);

      if (data.data) {
        actions.setState(['integrationEngineSettings', data.data]);
        actions.setState(['isLoadingIntegrationEngineSettings', false]);
      }
    } catch (error) {
      actions.setState(['isLoadingIntegrationEngineSettings', false]);
      errorHandler(error);
    }
  }),
  setIntegrationEngineSettingsPermissions: action((state, payload) => {
    state.integrationEngineSettings.permissions = {
      ...state.integrationEngineSettings.permissions,
      ...payload,
    };
  }),
  updateIntegrationEngineSettingsPermissions: thunk(
    async (actions, payload, { getState }) => {
      const { permissions } = payload;
      const entityId = getState().entityId;

      if (!entityId) {
        return;
      }

      let updatedPermissions: Partial<IIntegrationPermissions> = {};

      const res = await saveIntegrationEnginePermissions({
        entityId,
        permissions,
      });

      if (res?.data?.data) {
        updatedPermissions = res.data.data;
      }

      actions.setIntegrationEngineSettingsPermissions(updatedPermissions);
    }
  ),
  setEntityOnboardingRecord: action((state, payload) => {
    state.entityOnboardingRecord = payload;
  }),
  setUserEntity: action((state, payload) => {
    state.userEntity = { ...state.userEntity, ...payload };
  }),
  setUserEntityIntegrations: action((state, payload) => {
    state.integrationsSummary = payload;
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  getEntityAccountDetails: thunk(async ({ setState }, payload) => {
    try {
      const entityAccountDetails = await getEntityAccountDetails(payload);

      if (entityAccountDetails) {
        setState(['entityAccountDetails', entityAccountDetails]);
      }
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  getEntityUsers: thunk(async ({ setState }, payload) => {
    try {
      const { data } = await getEntityUsers(payload);

      if (data.data) {
        setState(['entityUsers', data.data]);
      }
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  getEntityInvites: thunk(async ({ setState }, payload) => {
    try {
      setState(['isLoadingEntityInvites', true]);

      const { data } = await getEntityInvites(payload);

      if (data.data) {
        setState(['entityInvites', data.data]);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setState(['isLoadingEntityInvites', false]);
    }
  }),
  getUserEntity: thunk(async (actions, payload) => {
    const entity = await Firebase.getUserEntity(payload);

    if (entity) {
      actions.setUserEntity(entity);
    }
  }),
  signOut: thunk(async (actions, _, { getState }) => {
    try {
      const userId = getState().userId;

      if (!userId) {
        return;
      }

      await Firebase.removeUserConnections({ userId });
      await auth.signOut();
      actions.setState(['userEntity', null]);
      actions.setState(['integrationsSummary', {}]);

      removeChatWindow();

      reset();
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  signIn: thunk(async (actions, payload) => {
    try {
      // Remove localStorage item to enable addBackupMethod popup after login if needed
      if (payload.resetAddBackupMethodPopup) {
        localStorageInstance.removeItem('addBackupMethodPopupHasBeenClosed');
      }

      const { user: authUser } = await auth.signInWithEmailAndPassword(
        payload.email,
        payload.password
      );

      // can't be here if user entered incorrect details
      if (!authUser) {
        throw new Error(
          'Internal authentication error. Please try again after a short pause, or try another browser.'
        );
      }

      const userFromDatabase = await Firebase.getUser({
        id: authUser.uid,
      });

      // this means user did not complete sign up step 3
      if (!userFromDatabase) {
        actions.setUser({
          id: authUser.uid,
          email: payload.email,
          name: authUser.displayName || '',
          firstName: '',
          lastName: '',
          enabled: false,
          status: '',
          entityId: '',
        });
      } else {
        actions.setUser(userFromDatabase);
      }

      return true;
    } catch (error: any) {
      if (error.code === 'auth/multi-factor-auth-required') {
        return error as MultiFactorError;
      } else if (error.code === 'auth/too-many-requests') {
        throw error;
      } else if (error.code === 'auth/user-disabled') {
        Notify.error(
          'There is an issue with signing you in. Please contact support.'
        );
        throw error;
      } else if (
        error.code === 'auth/wrong-password' ||
        error.code === 'auth/user-not-found' ||
        error.code === 'auth/invalid-login-credentials'
      ) {
        Notify.error('Incorrect login details. Please check and try again');
        throw error;
      } else {
        if (!payload.disableNotifyOnError) {
          Notify.error(error.message);
        }
        throw error;
      }
    }
  }),
  signInWithGoogle: thunk(async (actions, payload) => {
    try {
      // Remove localStorage item to enable addBackupMethod popup after login if needed
      if (payload?.resetAddBackupMethodPopup) {
        localStorageInstance.removeItem('addBackupMethodPopupHasBeenClosed');
      }

      const authProvider = new firebase.auth.GoogleAuthProvider();

      const response = await auth.signInWithPopup(authProvider);

      const { user } = response;

      if (user) {
        const { uid, email, displayName } = user;

        const userFromDatabase = await Firebase.getUser({
          id: uid,
        });

        if (userFromDatabase) {
          actions.setUser(userFromDatabase as IUser);
        } else {
          actions.setUser({
            id: uid,
            email: email || '',
            name: displayName || '',
            entityId: '',
            status: '',
            enabled: false,
          });
        }
      }
    } catch (error: any) {
      if (error.code === 'auth/multi-factor-auth-required') {
        return error;
      } else if (error.code === 'auth/user-disabled') {
        Notify.error(
          'There is an issue with signing you in. Please contact support.'
        );
        throw error;
      } else {
        Notify.error(error.message);
      }
    }
  }),
  createUser: thunk(async (actions, payload, { getState }) => {
    const { userId } = getState();

    if (!userId) {
      return undefined;
    }

    // TODO: discuss if we can get back newly created user
    const response = await Firebase.createUser({ ...payload, id: userId });

    if (response?.success) {
      actions.setUser({
        id: response.id,
        ...response.data,
      });
    } else {
      Notify.error(response?.message ?? '');
    }

    return response;
  }),
  createAuthUser: thunk(async (actions, payload) => {
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        payload.email,
        payload.password
      );

      if (user) {
        const { uid, email, displayName } = user;

        actions.setUser({
          id: uid,
          email: email || '',
          name: displayName || '',
          entityId: '',
          status: '',
          enabled: false,
        });
      }
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  getUser: thunk(async (actions, payload) => {
    const user = await Firebase.getUser(payload);

    if (user) {
      actions.setUser({
        ...user,
      });

      if (process.env.REACT_APP_LOGROCKET_APP_ID) {
        LogRocket.identify(user.id, {
          name: user.name,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user.email,
          entityId: user.entityId,
          isSuperAdmin: !!user.isSuperAdmin,
        });
      }
    }
  }),
  getUserPermissionsOnEntity: thunk(async (actions, _, { getState }) => {
    const { entityId, user } = getState();

    if (typeof entityId === 'string' && typeof user?.id === 'string') {
      const permissions = await Firebase.getUserPermissionsOnEntity({
        entityId,
        userId: user.id,
      });

      if (permissions) {
        actions.setState(['permissions', permissions]);
      }
    } else {
      console.log(
        `getUserPermissionsOnEntity: not retrieving user permissions due to entityId or user.id not being present`
      );
    }
  }),
  getEntityOnboardingRecord: thunk(async (actions, _, { getState }) => {
    const { entityId, user } = getState();

    const { onboardingId } = user || {};

    if (!entityId || !onboardingId) {
      console.warn(
        'Cannot get entity onboarding record, entity ID is not defined or user has no onboarding ID.'
      );
      return;
    }

    const onboardingRecord = await Firebase.getEntityOnboardingRecord({
      entityId,
      onboardingId,
    });

    if (onboardingRecord) {
      actions.setEntityOnboardingRecord(onboardingRecord);
    }
  }),
  userId: computed([(state) => state.user], (user) => user?.id || null),
  userFirstName: computed([(state) => state.user], (user) => {
    if (user?.firstName) {
      return user?.firstName;
    } else if (user?.name) {
      return user.name.substring(0, user.name.lastIndexOf(' '));
    } else {
      return null;
    }
  }),
  userEmail: computed([(state) => state.user], (user) => user?.email),
  isUserRegistrationDone: computed(
    [(state) => state.user],
    (user) => user?.enabled
  ),
  entityId: computed([(state) => state.user], (user) => user?.entityId || null),
  entityType: computed(
    [(state) => state.userEntity],
    (userEntity) => userEntity?.companyType || null
  ),
  entityCountry: computed(
    [(state) => state.userEntity],
    (userEntity) => userEntity?.companyCountry || null
  ),
  isEntityEnabled: computed(
    [(state) => state.userEntity],
    (userEntity) => userEntity?.enabled
  ),
  isEntityOnboarded: computed(
    [(state) => state.userEntity],
    (userEntity) =>
      userEntity?.status === 'onboardingStep4Completed' ||
      userEntity?.status === 'onboarded'
  ),
  hasApprovalFlow: computed(
    [(state) => state.isAutomationPackageEnabled, (state) => state.userEntity],
    (isAutomationPackageEnabled, userEntity) =>
      !!isAutomationPackageEnabled &&
      !!userEntity.approvalSettings?.hasApprovalFlow
  ),
  showReports: computed(
    [(state) => state.userEntity],
    (userEntity) => !!userEntity?.shouldShowReports
  ),
  entityCurrencyCode: computed(
    [(state) => state.userEntity],
    (userEntity) => userEntity?.entityCurrency || ''
  ),
  isWithinCreditLimit: computed(
    [(state) => state.userEntity],
    (userEntity) => (amount: number) => {
      const creditLimit = userEntity?.remainingCredit ?? 0;
      return amount <= creditLimit;
    }
  ),
  isAccountant: computed(
    [(state) => state.user],
    (user) => !!user?.isAccountant
  ),
  isAutomationPackageEnabled: computed(
    [(state) => state.userEntity],
    (userEntity) =>
      isPackageEnabled(userEntity?.packages?.automation?.enabledUntil)
  ),
  isAutomationPackageFreeTrialEnabled: computed(
    [(state) => state.userEntity],
    (userEntity) =>
      isPackageEnabled(userEntity?.packages?.automation?.freeTrialUntil)
  ),
  isFxManagementPackageEnabled: computed(
    [(state) => state.userEntity],
    (userEntity) =>
      isPackageEnabled(userEntity?.packages?.fxManagement?.enabledUntil)
  ),
  isFxManagementPackageFreeTrialEnabled: computed(
    [(state) => state.userEntity],
    (userEntity) =>
      isPackageEnabled(userEntity?.packages?.fxManagement?.freeTrialUntil)
  ),
  userRoles: computed(
    [(state) => state],
    ({ entityUsers, userId }) =>
      entityUsers?.find((user) => user.id === userId)?.roles || []
  ),
  isMsDynamicsIntegration: computed(
    [(state) => state],
    ({ integrationsSummary }) =>
      !!(
        integrationsSummary?.connected &&
        integrationsSummary?.platformName === 'Dynamics 365 Business Central'
      )
  ),
  onSignUpFromExternalSystem: thunk(async (actions) => {
    try {
      const nextUri = `${window.location.href}?step=1&firstIntegration=true`;

      const response = await getAuthUrlForSignUpFromExternalSystem({
        system: 'xero',
        nextUri,
      });
      const oAuthUrlFromResponse = response.data.data;
      if (oAuthUrlFromResponse) {
        window.location.href = oAuthUrlFromResponse;
      }
    } catch (error: any) {
      errorHandler(error);
    }
  }),
};

const removeChatWindow = () => {
  (window as any).HubSpotConversations.widget.remove();
};
