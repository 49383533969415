import { regex } from 'utils';
import { CreatableSelectMenuOption } from './types';

export const createCreatableSelectMenuOption = (label: string) => ({
  label,
  value: label,
});

export const onValidateEmails = (values: CreatableSelectMenuOption[]) => {
  const invalidEmails: string[] = [];

  values.forEach((value) => {
    if (!regex.email.test(value.value)) {
      invalidEmails.push(value.value);
    }
  });

  if (invalidEmails.length === 1) {
    return `Email ${invalidEmails[0]} is invalid`;
  }

  if (invalidEmails.length > 1) {
    return `Emails ${invalidEmails.join(', ')} are invalid`;
  }

  return true;
};
