import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import { Row, Paragraph, InlineLoader } from 'components';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import { parseIntoShortNumberString } from 'utils';

interface OwnProps {
  amount?: number;
  count?: number;
  currencyCode: string;
  isLoading?: boolean;
}

const NumberCircleCell: FC<OwnProps> = ({
  currencyCode,
  amount = 0,
  count,
  isLoading,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const currency = currencyByCode(currencyCode);

  if (isLoading) {
    return <InlineLoader />;
  }

  return (
    <Row columnGap={theme.spacing.xs} alignItems="center">
      <Paragraph variant="bold" style={{ minWidth: '80px' }}>
        {currency?.symbol}
        {parseIntoShortNumberString(amount)}
      </Paragraph>
      {!!count && <NumberCircle background="red" color="white" value={count} />}
    </Row>
  );
};

export default NumberCircleCell;
