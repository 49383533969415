import { Icon, Loader, Paragraph, Row } from 'components';
import InputSelect from 'components/shared/InputSelect/InputSelect';
import { SettingsField } from 'pages/Settings/Settings.styles';
import { useEffect, useState } from 'react';
import { getUserSettings, updateUserSettings } from 'services/firebase';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IUserSettings } from 'types';
import { errorHandler } from 'utils/errors';
import { callExternalApiWithLoading } from 'utils/fetchers';

const UserSettings = () => {
  const theme = useTheme();
  const { isAccountant } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingUserSettings, setIsUpdatingUserSettings] = useState(false);
  const [userSettings, setUserSettings] = useState<Partial<IUserSettings>>({});
  const consolidateCurrencyCodeFromUserSettings =
    userSettings.currencyCodeToConsolidateInto ?? '';

  const saveConsolidateCurrency = async (newConsolidateCurrency: string) => {
    try {
      setIsUpdatingUserSettings(true);
      const updatedUserSettings = await updateUserSettings({
        currencyCodeToConsolidateInto: newConsolidateCurrency,
      });

      if (updatedUserSettings.data.data) {
        setUserSettings(updatedUserSettings.data.data);
      }
      setIsUpdatingUserSettings(false);
    } catch (error: any) {
      errorHandler(error);
      setIsUpdatingUserSettings(false);
    }
  };

  useEffect(() => {
    callExternalApiWithLoading({
      externalApiCall: getUserSettings,
      loadingHandler: setIsLoading,
      responseHandler: (response) => {
        if (response.data.data) {
          setUserSettings(response.data.data);
        }
      },
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {isAccountant && (
        <SettingsField>
          <InputSelect<{
            id: string;
            name: string;
            value: string;
          }>
            inputProps={{
              id: 'contact',
              view: 'moving',
              label: 'Consolidate Currency',
              isLoading: isUpdatingUserSettings,
            }}
            data={[
              {
                id: 'GBP',
                name: 'GBP',
                value: 'GBP',
              },
              {
                id: 'USD',
                name: 'USD',
                value: 'USD',
              },
              {
                id: 'EUR',
                name: 'EUR',
                value: 'EUR',
              },
            ]}
            selected={{
              id: consolidateCurrencyCodeFromUserSettings,
              name: consolidateCurrencyCodeFromUserSettings,
              value: consolidateCurrencyCodeFromUserSettings,
            }}
            selectedValueLabel={consolidateCurrencyCodeFromUserSettings}
            labelKey="name"
            onSelect={(selectItem) => saveConsolidateCurrency(selectItem.value)}
            renderOption={(item) => {
              const currency = currencyByCode(item.value);
              return (
                <Row justifyContent="flex-start" columnGap={theme.spacing.m}>
                  {currency && <Icon icon={currency.countryCode} />}
                  <Paragraph>{item.name}</Paragraph>
                </Row>
              );
            }}
          />
        </SettingsField>
      )}
    </>
  );
};

export default UserSettings;
