import { FC } from 'react';
import { useStoreState } from 'state';
import { Section } from 'components/shared/Section/Section.styles';
import PaymentsRunTableTile from 'components/shared/PaymentsRunTableTile/PaymentsRunTableTile';
import Accounts from '../Accounts/Accounts';
import FollowedCurrencies from '../../../../components/shared/FollowedCurrencies/FollowedCurrencies';

const StandardDashboard: FC = () => {
  const { isAutomationPackageEnabled } = useStoreState(
    (state) => state.UserState
  );

  return (
    <>
      <Section>
        <FollowedCurrencies showTransferButton />
      </Section>

      <Section>
        <Accounts />
      </Section>

      {isAutomationPackageEnabled && (
        <Section>
          <PaymentsRunTableTile showLimit={5} showMore />
        </Section>
      )}
    </>
  );
};

export default StandardDashboard;
