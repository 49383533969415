import { FC, useState, useEffect, ReactNode } from 'react';
import Select, { ActionMeta, MenuPlacement, MenuPosition } from 'react-select';

import { Wrapper } from './StaleSelectMenu.styles';
import Option from './StaleOption';
import SingleValue from './StaleSingleValue';
import MultiValueLabel from './StaleMultiValueLabel';
import DropdownIndicator from './StaleDropdownIndicator';
import { CSSProperties } from 'styled-components';

// TODO. This (data) should be fixed with a generic.
export interface IMenuItem {
  value?: any;
  label: string | ReactNode;
  icon?: string;
}
interface SelectMenuProps {
  id: string;
  data: IMenuItem[];
  name: string;
  label: string;
  isMulti?: boolean;
  defaultIsOpen?: boolean;
  placeholder?: string;
  value?: any;
  inputValue?: string;
  onChange?: (value: any, actionMeta?: ActionMeta<any>) => void;
  onInputChange?: (value: any) => void;
  isSearchable?: boolean;
  filterOption?: any;
  tabSelectsValue?: boolean;
  autoFocus?: boolean;
  hideSelectedOptions?: boolean;
  disabled?: boolean;
  menuPlacement?: MenuPlacement;
  menuPosition?: MenuPosition;
  withBackdrop?: boolean;
  style?: CSSProperties;
}

const SelectMenu: FC<SelectMenuProps> = ({
  id,
  data,
  name,
  label,
  isMulti,
  defaultIsOpen = false,
  placeholder = '',
  value,
  inputValue = '',
  onChange,
  onInputChange,
  isSearchable = true,
  filterOption,
  tabSelectsValue = false,
  autoFocus = false,
  hideSelectedOptions = true,
  disabled = false,
  menuPlacement,
  menuPosition,
  withBackdrop = true,
  style,
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    if (value) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [value]);

  return (
    <Wrapper
      open={isOpen}
      filled={isFilled}
      multi={isMulti}
      disabled={disabled}
      menuPlacement={menuPlacement}
      withBackdrop={withBackdrop}
      style={style}
    >
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}

      <Select
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        components={{
          Option,
          SingleValue,
          MultiValueLabel,
          DropdownIndicator,
        }}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        placeholder={placeholder}
        isMulti={isMulti}
        autoFocus={autoFocus}
        name={name}
        menuIsOpen={isOpen}
        options={data}
        classNamePrefix="select"
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        closeMenuOnSelect={!isMulti}
        onChange={onChange}
        isSearchable={isSearchable}
        filterOption={filterOption}
        tabSelectsValue={tabSelectsValue}
        isClearable
        hideSelectedOptions={hideSelectedOptions}
        disabled={disabled}
      />
    </Wrapper>
  );
};

export default SelectMenu;
