import { FC, useCallback, useEffect, useState } from 'react';
import { Loader } from 'components';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import Tabs from './components/Tabs/Tabs';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utcPlugin from 'dayjs/plugin/utc';
import { DB_MONTH_FORMAT } from 'variables';
import {
  getQuarterFromQuarterDateString,
  getYearFromQuarterDateString,
} from 'utils/dates';
import TransferPrebookPopups from 'components/shared/TransferPrebookPopups/TransferPrebookPopups';
import {
  IGenerateSearchQueryParams,
  generateInvoicesSearchQuery,
} from 'utils/search';
import { get } from 'lodash';
import { errorHandler } from 'utils/errors';
import { ICashflowFromSearch, getCashflowsPaginated } from 'services/cashflows';
import { getElasticQueryFilters } from 'pages/Invoices/utils';
import ContributorsTable from './components/ContributorsTable/ContributorsTable';

dayjs.extend(quarterOfYear);
dayjs.extend(utcPlugin);

interface IOwnProps {
  dateRange: string;
}

const DateRangeInfo: FC<IOwnProps> = ({ dateRange }) => {
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const {
    currency,
    period,
    isFirstPeriod,
    filter: filterFromUrl,
  } = useUrlValues('currency', 'tab', 'period', 'filter', 'isFirstPeriod');
  const [cashflows, setCashflows] = useState<ICashflowFromSearch[]>([]);
  const [isLoadingMoreInvoices, setIsLoadingMoreInvoices] = useState(false);
  const [isLoadedFirstPage, setIsLoadedFirstPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true);

  const fetchCashflows = useCallback(
    async ({
      dateRange,
      page,
      period,
      currency,
      isFirstPeriod,
      sortFields,
      size = 30,
      reset = false,
      filter,
    }: {
      dateRange: string;
      page: number;
      entityCurrencyCode: string;
      period: string;
      currency: string;
      isFirstPeriod: boolean;
      sortFields?: IGenerateSearchQueryParams['sortFields'];
      size?: number;
      reset?: boolean;
      filter: string | null;
    }) => {
      try {
        setIsLoadingMoreInvoices(true);

        if (reset) {
          setCurrentPage(1);
          setHasMoreToLoad(true);
        }

        let dateFrom = dayjs(dateRange, DB_MONTH_FORMAT)
          .utc(true)
          .startOf('month');
        let dateTo = dayjs(dateRange, DB_MONTH_FORMAT).utc(true).endOf('month');

        if (period === 'quarter') {
          const quarter = getQuarterFromQuarterDateString(dateRange);
          const year = getYearFromQuarterDateString(dateRange);

          if (quarter && year) {
            dateFrom = dayjs()
              .year(Number(year))
              .quarter(Number(quarter))
              .startOf('quarter');

            dateTo = dayjs()
              .year(Number(year))
              .quarter(Number(quarter))
              .endOf('quarter');
          }
        }

        const searchQueryParams = {
          filters: getElasticQueryFilters({
            currency,
            dateFrom: isFirstPeriod ? '' : dateFrom.toISOString(),
            dateTo: dateTo.toISOString(),
            status: JSON.stringify(['live', 'excluded']),
            type: filter,
          }),
          sortFields,
          page,
          size,
        } as const;

        const query = generateInvoicesSearchQuery(searchQueryParams);
        const result = await getCashflowsPaginated(query);

        const cashflows = get(
          result,
          'data.data.results',
          []
        ) as ICashflowFromSearch[];

        setCashflows((prevInvoices) => {
          const prevStateToUse = reset ? [] : prevInvoices;
          return [...prevStateToUse, ...cashflows];
        });

        const currentPageFromResponse = result.data.data?.meta.page.current;
        const totalPagesFromResponse = result.data.data?.meta.page.total_pages;

        if (
          currentPageFromResponse &&
          currentPageFromResponse !== currentPage
        ) {
          setCurrentPage(currentPageFromResponse);
        }

        if (
          currentPageFromResponse &&
          totalPagesFromResponse &&
          currentPageFromResponse === totalPagesFromResponse
        ) {
          setHasMoreToLoad(false);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoadingMoreInvoices(false);
      }
    },
    [currentPage]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [dateRange]);

  useEffect(() => {
    if (currency && period && currentPage === 1) {
      fetchCashflows({
        dateRange,
        page: currentPage,
        entityCurrencyCode,
        period,
        currency,
        isFirstPeriod: isFirstPeriod === 'true',
        filter: filterFromUrl,
        reset: true,
      }).finally(() => setIsLoadedFirstPage(true));
    }
  }, [
    currency,
    currentPage,
    dateRange,
    entityCurrencyCode,
    period,
    isFirstPeriod,
    fetchCashflows,
    filterFromUrl,
  ]);

  const updateInMemoryCashflows = useCallback(
    (
      updateFunction: (
        cashflows: ICashflowFromSearch[]
      ) => ICashflowFromSearch[]
    ) => {
      setCashflows((prevState) => updateFunction(prevState));
    },
    []
  );

  return (
    <>
      <Tabs />
      {!isLoadedFirstPage ? (
        <Loader size="large" />
      ) : (
        <ContributorsTable
          isVirtualized
          data={cashflows}
          isLoadingMoreItems={isLoadingMoreInvoices}
          loadingThreshold={10}
          withInfiniteLoading
          itemsCount={cashflows.length}
          hasMoreToLoad={hasMoreToLoad}
          updateInMemoryCashflows={updateInMemoryCashflows}
          onLoadMoreItems={
            isLoadingMoreInvoices
              ? async () => {}
              : async () => {
                  if (period && currency) {
                    fetchCashflows({
                      dateRange,
                      page: currentPage + 1,
                      entityCurrencyCode,
                      period,
                      currency,
                      isFirstPeriod: isFirstPeriod === 'true',
                      filter: filterFromUrl,
                    });
                  }
                }
          }
        />
      )}
      <TransferPrebookPopups />
    </>
  );
};

export default DateRangeInfo;
