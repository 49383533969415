import { Col } from '../../../../Col/Col';
import styled from 'styled-components';

export const BankRecipientItemButtonWrapper = styled.button`
  border: none;
  background: none;
`;

export const BankRecipientItemWrapper = styled(Col)<{ isSelected?: boolean }>`
  padding: 16px;
  ${({ isSelected, theme }) =>
    isSelected && `background: ${theme.color.emeraldLight}`};
  border-radius: 8px;
  border: 1px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.color.emerald : theme.color.grey};
`;
