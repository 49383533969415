import { TRisksDataPeriod } from 'pages/Risks/types';
import {
  DB_DATE_FORMAT,
  MONTH_DATE_FORMAT,
  QUARTER_DATE_FORMAT,
} from 'variables';

export const getDateFormatFromPeriodType = (periodType: TRisksDataPeriod) => {
  switch (periodType) {
    case 'month':
      return MONTH_DATE_FORMAT;
    case 'quarter':
      return QUARTER_DATE_FORMAT;
    case 'week':
      return 'DD MMM';
    default:
      return DB_DATE_FORMAT;
  }
};
