import { SpacingCssProps } from '../Spacing/Spacing.styles';
import { StyledInlineLoader } from './InlineLoader.styles';

interface OwnProps extends SpacingCssProps {
  height?: string;
  width?: string;
  icon?: string;
  spin?: boolean;
}
const InlineLoader = ({
  height = '16px',
  width = '16px',
  icon = 'loader-ico',
  spin = true,
  ...spacingCssProps
}: OwnProps) => (
  <StyledInlineLoader
    width={width}
    height={height}
    spin={spin}
    {...spacingCssProps}
  >
    <use xlinkHref={`#${icon}`} />
  </StyledInlineLoader>
);

export default InlineLoader;
