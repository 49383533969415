import styled, { css } from 'styled-components';
import {
  ResponsiveCenteredContainer,
  StaleBtnGroup,
  Paragraph,
} from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    ${ResponsiveCenteredContainer} {
      flex: 0 1 100%;
      overflow: auto;
      padding: 20px 24px 0;
    }

    .field {
      margin-bottom: 14px;
    }

    .row {
      justify-content: space-between;
      align-items: flex-start;

      p {
        padding: 0;
        min-height: 18px;

        &:not(:last-child) {
          margin-bottom: 4px !important;
        }
        &:last-child {
          margin-bottom: 0 !important;
          margin-right: auto;
        }
      }
    }

    table.convert-info {
      text-align: left;
      width: 100%;
      margin: 16px 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }

      th {
        color: ${theme.color.greyDark};
      }

      td {
        padding: 8px 33px 0 0;
        vertical-align: middle;
        width: 0;

        .row {
          align-items: center;
          flex-wrap: nowrap;
        }

        &:last-child {
          text-align: right;
          padding-right: 8px;
          width: auto;
        }
      }
    }

    .tracked-block {
      display: flex;
      width: 100%;
      background: ${theme.color.greyLight_3};

      .col {
        padding: 15px 8px 16px;
        flex: 0 1 auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        &:first-of-type {
          padding-left: 16px;
        }
        &:last-of-type {
          align-items: flex-end;
          padding-right: 16px;
        }

        .cost-range {
          width: 100%;
        }

        > span {
          color: ${theme.color.dark};
          font-size: 14px;
          margin-bottom: 9px;
        }
      }

      p {
        font-weight: 500;
        color: ${theme.color.greyDark};
      }
    }

    table.tracked-table {
      width: 100%;
      background: ${theme.color.greyLight_3};
      tr {
        &:first-of-type {
          td {
            padding-top: 16px;
          }
        }
        &:last-of-type {
          td {
            padding-bottom: 15px;
          }
        }
      }
      td {
        width: 0;
        padding: 2px 8px;
        &:nth-of-type(2) {
          text-align: center;
        }
        &:first-of-type {
          padding-left: 16px;
        }
        &:last-of-type {
          text-align: right;
          padding-right: 16px;
        }
      }
      p {
        font-weight: 500;
        color: ${theme.color.greyDark};
      }
      span {
        color: ${theme.color.dark};
        font-size: 14px;
      }
    }

    ${StaleBtnGroup} {
      button {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      a {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  `
);

export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
`;

export const AmountHeading = styled(Paragraph)`
  margin-bottom: 6px;
`;
