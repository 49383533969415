import * as React from 'react';
import dayjs from 'dayjs';
import { IInvoice, ISourceSyncData } from 'types';
import { useInvoiceLastSyncDetails } from 'hooks/useInvoiceLastSyncDetails';
import { Icon, Row, TextHint } from 'components';
import { DATE_FORMAT, mapAccountingIntegrationToIcon } from 'variables';
import useIntegrationEngine from 'hooks/useIntegrationEngine';

interface IProps {
  invoice: IInvoice;
}

const createSyncToText = (prefix: 'Update' | 'Import') => (
  lastSyncToExternalSource?: ISourceSyncData
) => {
  const mapSyncStatusToText = {
    error: `${prefix} failed:`,
    queued: `${prefix} queued:`,
    success: `${prefix}ed:`.replace('ee', 'e'),
  } as const;

  if (lastSyncToExternalSource?.status !== undefined) {
    return mapSyncStatusToText[lastSyncToExternalSource.status];
  }

  if (lastSyncToExternalSource?.success) {
    return mapSyncStatusToText[
      lastSyncToExternalSource.success ? 'success' : 'error'
    ];
  }
};

const createLastSyncToText = createSyncToText('Update');
const createLastFromToText = createSyncToText('Import');

const InvoiceLastSyncDetails: React.FC<IProps> = ({ invoice }) => {
  const {
    lastSyncFromExternalSource,
    lastSyncToExternalSource,
  } = useInvoiceLastSyncDetails(invoice);
  const { accountingIntegrationSystem } = useIntegrationEngine();

  const lastSyncFromSuccess = createLastFromToText(lastSyncFromExternalSource);
  const lastSyncToSuccess = createLastSyncToText(lastSyncToExternalSource);

  return (
    <Row mb justifyContent="flex-start">
      {!!accountingIntegrationSystem && (
        <Icon
          icon={mapAccountingIntegrationToIcon[accountingIntegrationSystem]}
          width="16"
          height="16"
        />
      )}

      {!!lastSyncFromExternalSource && (
        <TextHint color="grey" ml>
          {lastSyncFromSuccess}{' '}
          {dayjs(lastSyncFromExternalSource.timestampIsoDate).format(
            DATE_FORMAT
          )}
        </TextHint>
      )}

      {!!lastSyncToExternalSource && (
        <TextHint color="grey" ml>
          {lastSyncToSuccess}{' '}
          {dayjs(lastSyncToExternalSource.timestampIsoDate).format(DATE_FORMAT)}
        </TextHint>
      )}
    </Row>
  );
};

export default InvoiceLastSyncDetails;
