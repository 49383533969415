import { Col } from '../../../../../Col/Col';
import { FC, useEffect, useState } from 'react';
import {
  getInvoiceAttachmentContent,
} from 'services/firebase/invoices';
import { IAttachment } from 'types';
import { errorHandler } from 'utils/errors';
import PdfViewer from '../../../PdfViewer/PdfViewer';
import useGetDetailedInvoice from 'hooks/useGetDetailedInvoice';
import { Row } from '../../../../../Row/Row';
import { ParagraphWithEllipsis } from '../../../../../Typography/Typography';
import Button from '../../../../../Button/Button';
import Icon from '../../../../../Icon/Icon';
import { useTheme } from 'styled-components';
import Loader from '../../../../../Loader/Loader';

interface IOwnProps {
  invoiceId: string;
  isFillingFromInvoice: boolean;
  fillFromSelectedInvoiceAttachment: (attachmentId: string) => Promise<void>;
}

const InvoiceAttachmentsViewer: FC<IOwnProps> = ({
  invoiceId,
  isFillingFromInvoice,
  fillFromSelectedInvoiceAttachment,
}) => {
  const theme = useTheme();
  const { detailedInvoice: invoice } = useGetDetailedInvoice(invoiceId);
  const [
    selectedAttachment,
    setSelectedAttachment,
  ] = useState<IAttachment | null>(null);
  const [loadedAttachments, setLoadedAttachments] = useState<
    Record<
      string,
      {
        invoiceId: string;
        base64EncodedString: string;
      }
    >
  >({});

  const getInvoiceAttachmentAsBase64 = async (args: {
    invoiceId: string;
    attachmentId: string;
    fileName: string;
  }) => {
    try {
      const {
        data: { data: base64EncodedString },
      } = await getInvoiceAttachmentContent(args);

      return base64EncodedString;
    } catch (error: any) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (
      invoice &&
      !invoice.attachments?.find(
        (attachment) => attachment.id === selectedAttachment?.id
      )
    ) {
      setSelectedAttachment(invoice.attachments?.[0] ?? null);
    }
  }, [invoice, selectedAttachment]);

  useEffect(() => {
    if (
      invoice &&
      selectedAttachment &&
      !loadedAttachments[selectedAttachment.id]
    ) {
      getInvoiceAttachmentAsBase64({
        invoiceId: invoice.id,
        attachmentId: selectedAttachment.id,
        fileName: selectedAttachment.fileName,
      })
        .then((base64EncodedString) => {
          if (base64EncodedString) {
            setLoadedAttachments((prev) => ({
              ...prev,
              [selectedAttachment.id]: {
                invoiceId: invoice.id,
                base64EncodedString,
              },
            }));
          }
        })
        .catch(errorHandler);
    }
  }, [selectedAttachment, loadedAttachments, invoice]);

  const selectedInvoiceAttachmentIndex = invoice?.attachments?.findIndex(
    (attachment) => attachment.id === selectedAttachment?.id
  );
  const isNextAttachmentAvailable =
    selectedInvoiceAttachmentIndex !== undefined &&
    selectedInvoiceAttachmentIndex < (invoice?.attachments?.length ?? 0) - 1;
  const isPreviousAttachmentAvailable =
    selectedInvoiceAttachmentIndex !== undefined &&
    selectedInvoiceAttachmentIndex > 0;

  const selectNextAttachment = () => {
    if (isNextAttachmentAvailable) {
      setSelectedAttachment(
        invoice?.attachments?.[selectedInvoiceAttachmentIndex + 1] ?? null
      );
    }
  };

  const selectPreviousAttachment = () => {
    if (isPreviousAttachmentAvailable) {
      setSelectedAttachment(
        invoice?.attachments?.[selectedInvoiceAttachmentIndex - 1] ?? null
      );
    }
  };

  return (
    <Col flex={1} rowGap={theme.spacing.xs}>
      <Row columnGap={theme.spacing.xs}>
        <Row columnGap={theme.spacing.xs}>
          {!!isPreviousAttachmentAvailable && (
            <Button
              variant="link"
              type="button"
              onClick={selectPreviousAttachment}
            >
              <Icon icon="arrow-left" fill={theme.color.black} />
            </Button>
          )}
          <ParagraphWithEllipsis maxWidth='300px'>
            {selectedAttachment?.fileName}
          </ParagraphWithEllipsis>

          {!!isNextAttachmentAvailable && (
            <Button variant="link" type="button" onClick={selectNextAttachment}>
              <Icon icon="arrow-right" fill={theme.color.black} />
            </Button>
          )}
        </Row>
        <Button
          type="button"
          isLoading={isFillingFromInvoice}
          disabled={!selectedAttachment || isFillingFromInvoice}
          onClick={() =>
            selectedAttachment &&
            fillFromSelectedInvoiceAttachment(selectedAttachment.id)
          }
        >
          Extract data
        </Button>
      </Row>
      {invoice &&
      !!selectedAttachment &&
      loadedAttachments[selectedAttachment.id]?.invoiceId === invoice?.id ? (
        <PdfViewer
          base64={loadedAttachments[selectedAttachment.id].base64EncodedString}
        />
      ) : (
        <Loader />
      )}
    </Col>
  );
};

export default InvoiceAttachmentsViewer;
