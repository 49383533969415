import { useEffect, useState } from 'react';
import { getInvoicesPdfAttachments } from 'services/firebase/invoices';
import { getOpenBankingPartialRecipientsForCurrency } from 'services/openBanking';
import { useStoreState } from 'state';
import { IContact, TPartialRecipientWithId } from 'types';
import { errorHandler } from 'utils/errors';
import { IInvoiceAttachmentsGrouped } from '../components/InvoicesWithAttachments/types';

interface IOwnProps {
  recipientForEdit?: Partial<IContact> | null;
  existingCurrency?: string;
}

const useImportDetails = ({
  recipientForEdit,
  existingCurrency,
}: IOwnProps) => {
  const { entityId } = useStoreState(({ UserState }) => UserState);

  const [groupedInvoiceAttachments, setGroupedInvoiceAttachments] = useState<
    IInvoiceAttachmentsGrouped[]
  >([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);

  useEffect(() => {
    if (entityId) {
      getInvoicesPdfAttachments(recipientForEdit?.externalRefs?.sourceSystemId)
        .then((invoiceAttachments) => {
          if (invoiceAttachments.data.data) {
            const groupedInvoiceAttachmentsToSave: IInvoiceAttachmentsGrouped[] = [];

            invoiceAttachments.data.data.forEach((invoiceAttachment) => {
              const existingGroup = groupedInvoiceAttachmentsToSave.find(
                (group) => group.invoiceId === invoiceAttachment.invoiceId
              );

              if (existingGroup) {
                existingGroup.attachments.push(invoiceAttachment);
              } else {
                groupedInvoiceAttachmentsToSave.push({
                  invoiceId: invoiceAttachment.invoiceId,
                  invoiceNumber: invoiceAttachment.invoiceNumber,
                  attachments: [invoiceAttachment],
                });
              }
            });

            setGroupedInvoiceAttachments(groupedInvoiceAttachmentsToSave);
            setIsLoadingInvoices(false);
          }
        })
        .catch(errorHandler);
    }
  }, [recipientForEdit, entityId]);

  const [isLoadingPartialRecipients, setIsLoadingPartialRecipients] = useState(
    true
  );
  const [partialRecipients, setPartialRecipients] = useState<
    TPartialRecipientWithId[]
  >([]);

  useEffect(() => {
    // entity id must be true because call requires authentication
    if (entityId && existingCurrency) {
      const getUpToDateBeneficiaries = async () => {
        try {
          setIsLoadingPartialRecipients(true);

          const { data } = await getOpenBankingPartialRecipientsForCurrency(
            existingCurrency
          );

          if (data.data) {
            setPartialRecipients(data.data);
            setIsLoadingPartialRecipients(false);
          }
        } catch (error) {
          errorHandler(error);
        }
      };
      getUpToDateBeneficiaries();
    }
  }, [entityId, existingCurrency]);

  return {
    groupedInvoiceAttachments,
    isLoadingInvoices,
    partialRecipients,
    isLoadingPartialRecipients,
  };
};

export default useImportDetails;
