import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  cardRow?: boolean;
  cardRowRevert?: boolean;
  cardCol?: boolean;
  cardTransparent?: boolean;
  disabled?: boolean;
}>(
  ({
    theme,
    cardRow,
    cardRowRevert,
    cardCol,
    cardTransparent,
    disabled,
  }) => css`
    background: ${(cardRow || cardCol) && theme.color.greyLight_4};
    transition: ${theme.transition};
    box-shadow: inset 0 0 40px 15px
      ${(cardRow || cardCol) && theme.color.transparent};
    border: 1px solid ${(cardRow || cardCol) && theme.color.greyLight_2};
    overflow: ${(cardRow || cardCol) && 'hidden'};

    &:hover {
      background: ${(cardRow || cardCol) && theme.color.white};
      box-shadow: inset 0 0 5px 1px
        ${(cardRow || cardCol) && theme.color.greyLight_2};
    }

    &:not(:last-of-type) {
      margin-bottom: 11px;
    }

    label {
      position: relative;
      padding-left: 28px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 3px;
        border-radius: ${theme.borderRadius.round};
        background: ${theme.color.emeraldDark};
        border: 2px solid ${theme.color.emeraldDark};
        box-shadow: inset 0 0 0 7px ${theme.color.white};
        transition: box-shadow 0.2s linear;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: ${theme.color.dark};
      }
    }

    input {
      position: absolute;
      opacity: 0;

      &:checked + label:before {
        box-shadow: inset 0 0 0 2px ${theme.color.white};
      }

      &:focus-visible {
        + label {
          background: ${(cardRow || cardCol) && theme.color.white};

          &:before {
            box-shadow: inset 0 0 0 7px ${theme.color.white},
              0 0 16px 1px ${theme.color.emeraldDark};
          }
        }
      }

      &:focus-visible:checked {
        + label:before {
          box-shadow: inset 0 0 0 2px ${theme.color.white},
            0 0 16px 1px ${theme.color.emeraldDark};
        }
      }
    }

    ${cardRow &&
    css`
      padding: 16px;
      border-radius: 8px;
      background: ${theme.color.greyLight_4};
    `}

    ${cardRowRevert &&
    css`
      padding: 0;

      label {
        padding: 14px 16px 16px;

        &:before {
          left: inherit;
          top: 16px;
          right: 20px;
        }

        .row {
          padding-right: 32px;
        }
      }
    `}

    ${cardCol &&
    css`
      border-radius: 8px;
      flex: 0 0 48%;
      max-width: 48%;
      margin-bottom: 12px;

      label {
        padding: 11px 16px 11px 44px;

        &:before {
          left: 16px;
          top: 14px;
        }
      }
    `}
    
    ${cardTransparent &&
    css`
      border: none;
      background: none;
      padding: 0;
      margin: 22px 0 0;
      overflow: visible;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    `}

    ${disabled &&
    css`
      opacity: 0.5;
      cursor: none;
    `}
  `
);
