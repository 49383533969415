import { FC, useEffect, useState } from 'react';
import { Row } from '../../../../../../../Row/Row';
import { ITableFilterOption } from '../../../../../../types';
import Field from '../../../../../../../Field/Field.styles';
import InputDateUncontrolled from '../../../../../../../InputDateUncontrolled/InputDateUncontrolled';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'variables';

interface OwnProps {
  filterId: string;
  filterOption: ITableFilterOption;
  filterOptionValue: string;
  setFilter: (filterId: string, filterValue: ITableFilterOption) => void;
}

const FilterOptionDate: FC<OwnProps> = ({
  filterId,
  filterOption,
  filterOptionValue,
  setFilter,
}) => {
  const { id, title } = filterOption;
  const [localValue, setLocalValue] = useState(filterOptionValue);

  useEffect(() => {
    setLocalValue(filterOptionValue);
  }, [filterOptionValue]);

  const onChange = (val: string) => {
    setLocalValue(val);

    if (dayjs(val, DATE_FORMAT).isValid()) {
      setFilter(filterId, { ...filterOption, value: val });
    }
  };

  return (
    <Row>
      <Field mb>
        <InputDateUncontrolled
          id={id}
          label={title}
          value={localValue}
          onChange={onChange}
        />
      </Field>
    </Row>
  );
};

export default FilterOptionDate;
