import { FC } from 'react';
import { useTheme } from 'styled-components';
import {
  Col,
  Icon,
  Paragraph,
  PermissionsChecker,
  Row,
  Title,
} from 'components';
import Button from 'components/shared/Button/Button';
import {
  CardWrapper,
  CardContent,
  CardFooter,
} from './SubscriptionCard.styles';
import UnorderedList from 'components/shared/UnorderedList/UnorderedList';
import { useStoreState } from 'state';
import useSubscriptionAction from './useSubscriptionAction';
import { ISubscriptionCard } from '../../constants';

interface OwnProps {
  data: ISubscriptionCard;
}

const SubscriptionCard: FC<OwnProps> = ({ data }) => {
  const theme = useTheme();
  const {
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
    isAutomationPackageFreeTrialEnabled,
    isFxManagementPackageFreeTrialEnabled,
  } = useStoreState((state) => state.UserState);
  const { id, icon, title, text, price, packageName, advantages } = data;

  const { entityId } = useStoreState(({ UserState }) => UserState);

  const {
    subscriptionAction: { actionText, onActionClickHandler, isActionDisabled },
    isLoading,
  } = useSubscriptionAction({
    key: id,
    packageName,
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
    isAutomationPackageFreeTrialEnabled,
    isFxManagementPackageFreeTrialEnabled,
  });

  return (
    <CardWrapper flex={1}>
      <CardContent>
        <Col gap={theme.spacing.xs} alignItems="center" flex={1}>
          <Icon
            width={theme.iconSizes.l}
            height={theme.iconSizes.l}
            icon={icon}
          />
          <Title textAlign="center" variant="h4">
            {title}
          </Title>
          <Paragraph textAlign="center">{text}</Paragraph>
        </Col>
        <Row
          mb
          mbValue={theme.spacing.xl}
          justifyContent="center"
          gap={theme.spacing.xs}
        >
          <Icon icon="price-tag" />
          <Paragraph variant="bold">{price}</Paragraph>
        </Row>

        <PermissionsChecker action="update" resource="subscriptions">
          {!packageName && (
            <Button disabled variant="secondary">
              Included
            </Button>
          )}
          {!!packageName && (
            <Button
              isLoading={isLoading}
              disabled={isLoading || isActionDisabled}
              onClick={() => !!entityId && onActionClickHandler?.(entityId)}
              variant="secondary"
            >
              {actionText}
            </Button>
          )}
        </PermissionsChecker>
      </CardContent>
      <CardFooter>
        <UnorderedList data={advantages} />
      </CardFooter>
    </CardWrapper>
  );
};

export default SubscriptionCard;
