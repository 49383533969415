import { FC } from 'react';
import { TextHint } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { useStoreState } from 'state';

interface OwnProps {
  profitAndLoss: number | null;
}

const ProfitAndLossCell: FC<OwnProps> = ({ profitAndLoss }) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(entityCurrencyCode);

  if (!profitAndLoss) {
    return null;
  }

  return (
    <TextHint color={profitAndLoss >= 0 ? 'green' : 'red'}>
      {`${parseIntoCurrencyStringWithSymbol(
        profitAndLoss,
        currency?.symbol,
        currency?.precision
      )}`}
    </TextHint>
  );
};

export default ProfitAndLossCell;
