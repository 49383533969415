import { FC } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { getInvoiceManualUploadLink } from 'utils/links';
import useUrlValues from 'hooks/useUrlValues';
import {
  Icon,
  Paragraph,
  PermissionsChecker,
  Row,
  StaleInfo,
} from 'components';
import Button from 'components/shared/Button/Button';
import TabsShared from 'components/shared/Tabs/Tabs';
import { TInvoicePageTabs } from 'types';
import useTabs, { IUseTabsTab } from '../../hooks/useTabs';
import { useStoreActions, useStoreState } from 'state';
import { IInvoicesTableActions } from 'pages/Invoices/components/InvoicesTable/types';

interface OwnProps {
  invoicesTableActionsRef?: React.MutableRefObject<
    IInvoicesTableActions | undefined
  >;
  fundingsCount: number;
}

const Tabs: FC<OwnProps> = ({ invoicesTableActionsRef, fundingsCount }) => {
  const theme = useTheme();
  const history = useHistory();
  const { setUrlValue, tab } = useUrlValues('tab');
  const { setState: setInvoicesState } = useStoreActions(
    ({ InvoicesState }) => InvoicesState
  );
  const { hasInvoicesToCollectDataChanged } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );

  const { tabs } = useTabs({ fundingsCount });

  const onRefreshWhenInvoicesDataHasChanged = () => {
    invoicesTableActionsRef?.current?.fetchFirstInvoicesPage();
    setInvoicesState(['hasInvoicesToCollectDataChanged', false]);
  };

  return (
    <Row alignSelf="stretch">
      <Row>
        <TabsShared<TInvoicePageTabs>
          data={tabs}
          activeTab={(tab as TInvoicePageTabs) ?? tabs[0].id}
          setActiveTab={({ id, filter }: IUseTabsTab) =>
            setUrlValue({
              tab: id,
              currency: 'all',
              filter: filter || '',
              dueDateFrom: '',
              dueDateTo: '',
              fullyPaidOnDateFrom: '',
              fullyPaidOnDateTo: '',
            })
          }
          whiteSpace="nowrap"
        />
      </Row>
      <Row gap={theme.spacing.m}>
        {hasInvoicesToCollectDataChanged && (
          <button onClick={onRefreshWhenInvoicesDataHasChanged}>
            <Paragraph color="red">
              Data has changed. Click to refresh
            </Paragraph>
          </button>
        )}

        <PermissionsChecker action="create" resource="cashflows_manual">
          <StaleInfo
            infoSize={theme.iconSizes.m}
            mode="hover"
            strategy="fixed"
            portal
            placement="top"
            trigger={
              <Button
                variant="link"
                onClick={() => history.push(getInvoiceManualUploadLink())}
              >
                <Icon icon="import-ico" />
              </Button>
            }
          >
            <Paragraph color="white">Manual upload</Paragraph>
          </StaleInfo>
        </PermissionsChecker>
      </Row>
    </Row>
  );
};

export default Tabs;
