import { FC, useEffect } from 'react';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import {
  IOpenBankingInstitution,
  IOpenBankingTransaction,
  IOpenBankingPaymentAuthResponseData,
} from 'types';
import { DATE_FORMAT } from 'variables';
import { Paragraph, Row, Title, Loader } from 'components';
import Link from 'components/shared/Link/Link';
import Button from 'components/shared/Button/Button';
import OpenBankingRedirectResponse from 'components/shared/OpenBankingRedirectResponse/OpenBankingRedirectResponse';

interface IOwnProps {
  onDismiss: () => void;
  onGoBack: () => void;
  onContinue: () => void;
  opTransaction: IOpenBankingTransaction | null;
  onUnselectInstitution: () => void;
  isRedirected: boolean;
  accountAuthResponse?: IOpenBankingPaymentAuthResponseData;
  isLoadingAccountAuthUrl: boolean;
  selectedInstitution?: IOpenBankingInstitution;
  setIsRedirected: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequestAccountProviderAccess: FC<IOwnProps> = ({
  onDismiss,
  onGoBack,
  onContinue,
  opTransaction,
  onUnselectInstitution,
  isRedirected,
  accountAuthResponse,
  isLoadingAccountAuthUrl,
  selectedInstitution,
  setIsRedirected,
}) => {
  const theme = useTheme();

  const onAuthoriseAccountAuth = () => {
    if (!accountAuthResponse) {
      return;
    }
    window.open(accountAuthResponse.authorisationUrl, '_blank');
    setIsRedirected(true);
  };

  useEffect(() => {
    if (opTransaction?.activityStatus === 'authorised') {
      onContinue();
    }
  }, [opTransaction, onContinue]);

  if (isLoadingAccountAuthUrl) {
    return <Loader size="large" />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Permission to request access
      </Title>

      {isRedirected && (
        <OpenBankingRedirectResponse
          onRetry={() => {
            onUnselectInstitution();
            onGoBack();
          }}
        />
      )}

      {!isRedirected && (
        <>
          <Paragraph mb>
            HedgeFlows is authorised and regulated by the FCA to provide Account
            Information Services (AIS). We use Yapily Connect to provide access
            to your bank data at {selectedInstitution?.name}.
          </Paragraph>
          <Paragraph mb mbValue={theme.spacing.xl}>
            You will now be securely redirected to {selectedInstitution?.name}{' '}
            to give access to the following information:
          </Paragraph>
          <Paragraph variant="bold" mb>
            Account(s) details
          </Paragraph>
          <Paragraph variant="bold" mb>
            Balances
          </Paragraph>
          <Paragraph variant="bold" mb>
            Beneficiaries
          </Paragraph>
          <Paragraph variant="bold" mb>
            Direct debits
          </Paragraph>
          <Paragraph variant="bold" mb mbValue={theme.spacing.xl}>
            Periodic payments
          </Paragraph>
          <Paragraph variant="bold" mb mbValue={theme.spacing.xl}>
            Scheduled payments
          </Paragraph>
          <Paragraph variant="bold" mb>
            Transaction history
          </Paragraph>
          <Paragraph mb mbValue={theme.spacing.xl}>
            By using HedgeFlows AlS, you agree to us accessing your bank data
            (via Yapily Connect), our{' '}
            <Link
              href="https://www.hedgeflows.com/terms-of-use"
              target="_blank"
              rel="noreferrer"
              display="inline"
            >
              Terms of Use
            </Link>{' '}
            (in particular Schedule C referencing AlS) and{' '}
            <Link
              href="https://www.hedgeflows.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
              display="inline"
            >
              Privacy Policy
            </Link>
            .
          </Paragraph>
          <Paragraph mb mbValue={theme.spacing.xl}>
            This consent will be valid until{' '}
            {dayjs().add(90, 'day').format(DATE_FORMAT)}.
          </Paragraph>
          <Row mt mtValue={theme.spacing.xl} gap={theme.spacing.m}>
            <Button
              flex={1}
              disabled={isLoadingAccountAuthUrl}
              onClick={onAuthoriseAccountAuth}
            >
              Confirm
            </Button>
            <Button
              flex={1}
              variant="secondary"
              disabled={isLoadingAccountAuthUrl}
              onClick={onDismiss}
            >
              Cancel
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

export default RequestAccountProviderAccess;
