import { FC } from 'react';
import { Paragraph } from 'components';
import { ICurrency } from 'types';
import { useStoreState } from 'state';
import { InvoiceInfoRow, CellRow } from './InvoicesRow.styles';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import { parseIntoShortNumberString } from 'utils';
import {
  getInvoiceAggregationCount,
  getInvoiceAggregationValue,
} from './utils';

interface OwnProps {
  currencyCode: ICurrency['code'];
  currencySymbol?: ICurrency['symbol'];
}

const InvoicesRow: FC<OwnProps> = ({ currencyCode, currencySymbol }) => {
  const { hasApprovalFlow } = useStoreState(({ UserState }) => UserState);
  const { invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const aggregationValues = invoicesAggregations?.perCurrency[currencyCode];

  return (
    <>
      <InvoiceInfoRow greyed>
        <CellRow>
          <Paragraph>Overdue</Paragraph>
        </CellRow>
        <CellRow>
          <Paragraph>Due soon</Paragraph>
        </CellRow>
        {hasApprovalFlow && (
          <>
            <CellRow>
              <Paragraph>To approve</Paragraph>
            </CellRow>
            <CellRow>
              <Paragraph>To pay</Paragraph>
            </CellRow>
          </>
        )}
      </InvoiceInfoRow>
      <InvoiceInfoRow>
        <CellRow>
          <Paragraph variant="bold">
            {currencySymbol}
            {parseIntoShortNumberString(
              getInvoiceAggregationValue(aggregationValues, 'overdue')
            )}
          </Paragraph>
          <NumberCircle
            background="red"
            color="white"
            value={getInvoiceAggregationCount(aggregationValues, 'overdue')}
          />
        </CellRow>
        <CellRow>
          <Paragraph variant="bold">
            {currencySymbol}
            {parseIntoShortNumberString(
              getInvoiceAggregationValue(aggregationValues, 'dueWithin14Days')
            )}
          </Paragraph>
          <NumberCircle
            value={getInvoiceAggregationCount(
              aggregationValues,
              'dueWithin14Days'
            )}
          />
        </CellRow>
        {hasApprovalFlow && (
          <>
            <CellRow>
              <Paragraph variant="bold">
                {currencySymbol}
                {parseIntoShortNumberString(
                  getInvoiceAggregationValue(aggregationValues, 'submitted')
                )}
              </Paragraph>
              <NumberCircle
                value={getInvoiceAggregationCount(
                  aggregationValues,
                  'submitted'
                )}
              />
            </CellRow>
            <CellRow>
              <Paragraph variant="bold">
                {currencySymbol}
                {parseIntoShortNumberString(
                  getInvoiceAggregationValue(aggregationValues, 'approved')
                )}
              </Paragraph>
              <NumberCircle
                value={getInvoiceAggregationCount(
                  aggregationValues,
                  'approved'
                )}
              />
            </CellRow>
          </>
        )}
      </InvoiceInfoRow>
    </>
  );
};

export default InvoicesRow;
