import styled from 'styled-components';
import { Col } from 'components';
import { cardBaseCss } from 'components/shared/CardBase/CardBase.styles';
import { Flex } from 'components/shared/Flex/Flex.styles';

export const GuruCardsWrapper = styled(Flex)`
  flex: 1;
  overflow-x: auto;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.s};
  padding: ${({ theme }) => `0 ${theme.spacing.m} ${theme.spacing.m}`};
  margin: ${({ theme }) => `0 -${theme.spacing.m} 0`};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    overflow-x: unset;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    gap: ${({ theme }) => theme.spacing.m};
  }
`;

export const GuruTitleWrapper = styled(Col)`
  justify-content: space-between;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.smallMin}px) {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.m};
  }
`;

export const GuruCard = styled(Flex)`
  flex: 1;
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing.m};
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  box-shadow: ${({ theme }) => theme.shadow};
  position: relative;
  ${cardBaseCss}
`;

export const GuruCardClose = styled.button`
  position: absolute;
  right: ${({ theme }) => theme.spacing.xxs};
  top: ${({ theme }) => theme.spacing.xxs};
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoint.smallMin}px) {
    right: ${({ theme }) => theme.spacing.s};
    top: ${({ theme }) => theme.spacing.m};
  }
`;
