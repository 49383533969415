import * as PopperJS from '@popperjs/core';
import { FC, useState, ReactNode } from 'react';
import { usePopper } from 'react-popper';

import { InfoPopup } from '../StaleInfo/StaleInfo.styles';
import { CSSProperties } from 'styled-components';

interface TooltipProps {
  content: ReactNode;
  disabled?: boolean;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  strategy?: PopperJS.PositioningStrategy;
  containerWidth?: CSSProperties['width'];
}

const StaleTooltip: FC<TooltipProps> = ({
  content,
  disabled = false,
  placement = 'right',
  children,
  strategy = 'fixed',
  containerWidth = 'fit-content',
}) => {
  const [show, setShow] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement,
    strategy,
  });

  const onMouseEnter = () => {
    if (disabled) {
      return;
    }

    setShow(true);
  };

  const onMouseLeave = () => {
    if (disabled) {
      return;
    }

    setShow(false);
  };

  return (
    <>
      <div
        style={{ width: containerWidth }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<null>>' is not assig... Remove this comment to see the full error message
        ref={setReferenceElement}
      >
        {children}
      </div>

      {show && (
        <InfoPopup
          // TODO: remove later, hack for clicks inside table
          className="tooltip"
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          ref={setPopperElement}
          style={{ ...styles.popper }}
          {...attributes.popper}
        >
          <div
            className="arrow"
            // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<null>>' is not assig... Remove this comment to see the full error message
            ref={setArrowElement}
            style={styles.arrow}
            {...attributes.arrow}
          />
          <div className="wrap">{content}</div>
        </InfoPopup>
      )}
    </>
  );
};

export default StaleTooltip;
