import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import {
  StaleSwitch,
  Subtitle,
  StaleInputSelect,
  Row,
  Col,
  Paragraph,
} from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useStoreActions, useStoreState } from 'state';
import {
  IAccountData,
  isDynamicsPlatform,
  isNonXeroPlatform,
  isXeroPlatform,
  TPlatformNames,
} from 'types/integrations';
import {
  IIntegrationSettingsPermissions,
  IIntegrationSettingsTaxRate,
  IIntegrationSettingsInput,
} from 'types/integrationEngine';
import { hasDuplicatesInArray, Notify } from 'utils';
import Field from '../../../Field/Field.styles';
import { ICurrency, IIntegrationSettingsAccount } from 'types';
import Button from 'components/shared/Button/Button';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import {
  generateIntegrationEngineBankFeesAccountData,
  generateIntegrationSettingsBankTransferAccountsToSave,
  generateIntegrationSettingsAccountDocIdsToDelete,
} from 'utils/integrations';
import BankTransferAccountsFields from 'components/shared/BankTransferAccountsFields/BankTransferAccountsFields';
import { ERROR_MESSAGES } from 'variables';
import { populateBankTransferAccountsForDropdown } from './utils';
import { saveIntegrationEngineSettings } from 'services/firebase/integrationEngine';
import InputBase from 'components/shared/InputBase/InputBase';

export interface IFormBankTransferXeroAccount {
  currency?: ICurrency['code'];
  account?: IIntegrationSettingsAccount;
}

interface Inputs {
  bankFeesAccount: IAccountData | null;
  taxRateForBankFees: ISelectItem<IIntegrationSettingsTaxRate> | null;
  bankTransfersAccount: IAccountData | null;
  formBankTransferAccounts: Record<string, IFormBankTransferXeroAccount> | null;
  bankAccountPostingGroupAccountNumber: string | null;
  profitAndLossAccountNumber: string | null;
}

export interface IOnSaveValuesArguments extends Inputs {
  createNewAccount?: boolean;
  invoicesAndBills: boolean;
  bankTransfers?: boolean;
  mainSellCurrencyAccount?: IAccountData | null;
  paymentFees: boolean;
  paymentsAndConversions: true;
  withBankFees?: boolean;
  withExistingAccount?: boolean;
}
export interface IOnSaveValues {
  (data: IOnSaveValuesArguments): void;
}

interface StepAccountsFormProps {
  areTaxRatesRequired?: boolean;
  defaultBankFeesAccount: IAccountData | null;
  feesAccounts: IAccountData[];
  isLoadingTaxRates?: boolean;
  onContinue: (onContinue: boolean) => void;
  onSaveValues: IOnSaveValues;
  platformName: TPlatformNames;
  savedTaxRateForBankFees?: ISelectItem<IIntegrationSettingsTaxRate>;
  taxRates?: Array<ISelectItem<IIntegrationSettingsTaxRate>>;
  /**
   * These are bank accounts used for transfers keyed by currency
   */
  bankTransferAccounts?: Record<string, IAccountData[]>;
  allAccounts?: IIntegrationSettingsAccount[];
}

const StepAccountsForm: FC<StepAccountsFormProps> = ({
  areTaxRatesRequired,
  defaultBankFeesAccount,
  feesAccounts,
  onContinue,
  onSaveValues,
  platformName,
  savedTaxRateForBankFees,
  taxRates,
  bankTransferAccounts,
  allAccounts,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const { integrationEngineSettings, entityId } = useStoreState(
    (state) => state.UserState
  );
  const { sellCurrencies } = useStoreState((state) => state.CurrenciesState);
  const isNonXeroIntegration = isNonXeroPlatform(platformName);
  const isXeroIntegration = isXeroPlatform(platformName);
  const isDynamics = isDynamicsPlatform(platformName);

  const { getIntegrationEngineSettings } = useStoreActions(
    (state) => state.UserState
  );
  const savedIntegrationEngineSettings = integrationEngineSettings.settings;
  const savedBankTransfersPermission =
    integrationEngineSettings.permissions?.bankTransfers;

  /**
   * Defaults to true if it's not set or present, otherwise use the existing saved value
   * */
  const initialBankTransfersPermission =
    savedBankTransfersPermission !== undefined
      ? savedBankTransfersPermission
      : true;

  const savedBankFeesAccount = integrationEngineSettings?.accounts?.find(
    (account) => account.type === 'bankFeesAccount'
  );

  const savedBankFeesAccountItem = savedBankFeesAccount
    ? generateIntegrationEngineBankFeesAccountData(savedBankFeesAccount)
    : undefined;

  const isRadioButtonInvoicesAndBillsEnabled = !isNonXeroIntegration;
  const isRadioButtonPaymentsAndConversionsEnabled = true;
  const isRadioButtonPaymentFeesEnabled = isXeroIntegration || isDynamics;

  const [isLoading, setIsLoading] = useState(false);
  const [invoicesAndBills, setInvoicesAndBills] = useState(
    isRadioButtonInvoicesAndBillsEnabled
  );
  const [paymentsAndConversions, setPaymentsAndConversions] = useState(
    isRadioButtonPaymentsAndConversionsEnabled
  );
  const [bankTransfers, setBankTransfers] = useState(
    initialBankTransfersPermission
  );
  const [paymentFees, setPaymentFees] = useState(
    isRadioButtonPaymentFeesEnabled
  );
  const [
    postingGroupAccountExisting,
    setIsPostingGroupAccountExisting,
  ] = useState(false);
  const [pnlAccountExisting, setIsPnLAccountExisting] = useState(false);

  const [formBankTransferAccounts, setFormBankTransferAccounts] = useState<
    string[]
  >([]);

  const calculateBankTransferAccounts = useCallback(
    (formBankTransferAccountsNames: string[]) => {
      const savedIntegrationAccounts: IIntegrationSettingsAccount[] =
        integrationEngineSettings.accounts || [];

      if (savedIntegrationAccounts.length > 0) {
        return populateBankTransferAccountsForDropdown(
          savedIntegrationAccounts,
          formBankTransferAccountsNames
        );
      }
    },
    [integrationEngineSettings]
  );

  const savedCurrencyTransferAccountsForDropdown = useMemo(() => {
    let formBankTransferAccountsNames: string[] = [];
    const savedAccounts = calculateBankTransferAccounts(
      formBankTransferAccountsNames
    );
    setFormBankTransferAccounts(formBankTransferAccountsNames);
    return savedAccounts;
  }, [calculateBankTransferAccounts]);

  const currenciesSelectData = useMemo(
    () =>
      sellCurrencies
        .filter(
          (item) => !!bankTransferAccounts && item.code in bankTransferAccounts
        )
        .map((item) => ({
          name: item.code,
          id: item.code,
          icon: item.countryCode,
          value: item.code,
        })),
    [sellCurrencies, bankTransferAccounts]
  );

  const isAddBankTransferAccountButtonEnabled =
    isRadioButtonPaymentFeesEnabled &&
    bankTransfers &&
    formBankTransferAccounts.length < currenciesSelectData.length;

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      bankFeesAccount: savedBankFeesAccountItem || defaultBankFeesAccount,
      taxRateForBankFees: savedTaxRateForBankFees,
      formBankTransferAccounts: savedCurrencyTransferAccountsForDropdown,
      bankAccountPostingGroupAccountNumber:
        integrationEngineSettings?.settings
          ?.bankAccountPostingGroupAccountNumber,
      profitAndLossAccountNumber:
        integrationEngineSettings?.settings?.profitAndLossAccountNumber,
    },
  });

  useEffect(() => {
    if (!paymentFees) {
      setValue('bankFeesAccount', null);
      setValue('taxRateForBankFees', null);
    }
  }, [paymentFees, setValue]);

  useEffect(() => {
    if (!bankTransfers) {
      setValue('formBankTransferAccounts', null);
      setFormBankTransferAccounts([]);
    }
  }, [bankTransfers, setValue]);

  const watchBankAccountPostingGroupAccountNumber = watch(
    'bankAccountPostingGroupAccountNumber'
  );
  const watchPnLAccount = watch('profitAndLossAccountNumber');

  useEffect(() => {
    if (watchBankAccountPostingGroupAccountNumber && allAccounts?.length) {
      const matchingAccount = allAccounts.find(
        (account) => account.code === watchBankAccountPostingGroupAccountNumber
      );

      setIsPostingGroupAccountExisting(!!matchingAccount);
    }
  }, [watchBankAccountPostingGroupAccountNumber, allAccounts]);

  useEffect(() => {
    if (watchPnLAccount && allAccounts?.length) {
      const matchingAccount = allAccounts.find(
        (account) => account.code === watchPnLAccount
      );

      setIsPnLAccountExisting(!!matchingAccount);
    }
  }, [watchPnLAccount, allAccounts]);

  const onSubmit = async (values: Inputs) => {
    if (!entityId) {
      Notify.error(ERROR_MESSAGES.noEntityId);
      return undefined;
    }

    try {
      if (!isNonXeroIntegration && !isXeroIntegration) {
        console.error('attempting to save accounts for unsupported platform');
        Notify.error(
          'Attempting to save accounts for unsupported platform. Please contact support'
        );
        return undefined;
      }

      if (values.formBankTransferAccounts) {
        const formBankTransferAccountsValues = Object.values(
          values.formBankTransferAccounts
        );
        const formBankTransferAccountsCurrencies = formBankTransferAccountsValues.map(
          (item) => item.currency
        );

        if (hasDuplicatesInArray(formBankTransferAccountsCurrencies)) {
          Notify.error(
            'Detected duplicate currencies in Bank transfer accounts. Please ensure all currencies are unique.'
          );
          return undefined;
        }
      }

      if (paymentsAndConversions) {
        onSaveValues({
          ...values,
          invoicesAndBills,
          paymentsAndConversions,
          paymentFees,
          bankTransfers,
        });

        // go to the next accounting step
        if (!isDynamics) {
          return onContinue(true);
        }
      }

      // otherwise submit now everything we have and go to the last step, congrats
      setIsLoading(true);

      const permissions: Partial<IIntegrationSettingsPermissions> = {
        invoices: invoicesAndBills,
        payments: !!paymentsAndConversions,
        purchaseOrders: invoicesAndBills,
        bills: invoicesAndBills,
        bankFees: !!values.bankFeesAccount,
        accounts: !!paymentsAndConversions,
        bankTransfers: !!bankTransfers,
      };

      const settingsInput: IIntegrationSettingsInput = {
        settings: savedIntegrationEngineSettings,
        permissions,
        taxRateForBankFees: values.taxRateForBankFees?.value || undefined,
      };

      if (!!values?.bankAccountPostingGroupAccountNumber) {
        settingsInput.settings = {
          ...settingsInput?.settings,
          bankAccountPostingGroupAccountNumber:
            values?.bankAccountPostingGroupAccountNumber,
        };
      }

      if (!!values?.profitAndLossAccountNumber) {
        settingsInput.settings = {
          ...settingsInput?.settings,
          profitAndLossAccountNumber: values?.profitAndLossAccountNumber,
        };
      }

      const settingsAccountsForUpdate: IIntegrationSettingsAccount[] = [];

      // Bank Fees Account
      if (values.bankFeesAccount?.value) {
        const {
          id,
          name,
          code,
          currency,
          category,
        } = values.bankFeesAccount.value;
        const bankFeesAccount: IIntegrationSettingsAccount = {
          id,
          name,
          code,
          currency,
          type: 'bankFeesAccount',
          category,
        };
        settingsAccountsForUpdate.push(bankFeesAccount);
      }

      // Bank Transfer Accounts
      if (values.formBankTransferAccounts) {
        const bankTransferAccountsToSave = generateIntegrationSettingsBankTransferAccountsToSave(
          Object.values(values.formBankTransferAccounts)
        );

        settingsAccountsForUpdate.push(...bankTransferAccountsToSave);
      }

      if (settingsAccountsForUpdate.length > 0) {
        settingsInput.accounts = settingsAccountsForUpdate;

        settingsInput.accountDocIdsToDelete = generateIntegrationSettingsAccountDocIdsToDelete(
          {
            savedAccounts: integrationEngineSettings.accounts,
            updatedAccounts: settingsAccountsForUpdate,
          }
        );
      }

      const settingsResponse = await saveIntegrationEngineSettings({
        entityId,
        settings: settingsInput,
      });

      if (settingsResponse?.data?.success) {
        await getIntegrationEngineSettings();
        return onContinue(false);
      } else {
        Notify.error(settingsResponse?.data?.message || '');
        setIsLoading(false);
      }
    } catch (error: any) {
      Notify.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <form id="step-accounts-form" onSubmit={handleSubmit(onSubmit)}>
      <Row mtValue={theme.spacing.xl} mt justifyContent="flex-start">
        <StaleSwitch
          id="invoicesAndBills"
          isOn={invoicesAndBills}
          disabled={!isRadioButtonInvoicesAndBillsEnabled}
          handleToggle={() => setInvoicesAndBills(!invoicesAndBills)}
        />
        <Subtitle ml>
          Update purchase orders, invoices and bills with prebooked exchange
          rates
        </Subtitle>
      </Row>

      <Row mtValue={theme.spacing.xl} mt justifyContent="flex-start">
        <StaleSwitch
          id="paymentsAndConversions"
          isOn={paymentsAndConversions}
          handleToggle={() =>
            setPaymentsAndConversions(!paymentsAndConversions)
          }
          disabled={!isRadioButtonPaymentsAndConversionsEnabled}
        />
        <Subtitle ml>
          Automatically record processed payments and conversions in{' '}
          {platformName}
        </Subtitle>
      </Row>

      {isDynamics && paymentsAndConversions && (
        <>
          <Col mtValue={theme.spacing.l} mt mb>
            <Subtitle variant="bold" mb>
              Enter a number from your Chart of Accounts in {platformName} for:
            </Subtitle>
            <Subtitle variant="bold">
              HedgeFlows Posting Group to record payments and FX transactions:
            </Subtitle>
          </Col>
          <Col flex={1}>
            <Field fluid flexDirection="column">
              <Controller
                id="bankAccountPostingGroupAccountNumber"
                name="bankAccountPostingGroupAccountNumber"
                control={control}
                defaultValue={null}
                disabled={!isRadioButtonPaymentFeesEnabled}
                rules={{
                  validate: (value: string) => {
                    if (
                      paymentsAndConversions &&
                      isRadioButtonPaymentFeesEnabled &&
                      !value
                    ) {
                      return 'Choose posting group account number';
                    }

                    if (value && watchPnLAccount && value === watchPnLAccount) {
                      return 'Must be a unique number';
                    }

                    return true;
                  },
                }}
                render={({ onChange, value, name }) => {
                  return (
                    <InputBase
                      id={name}
                      type="number"
                      label="Posting Group Account #"
                      value={value}
                      onChange={onChange}
                      disabled={
                        !paymentsAndConversions ||
                        !isRadioButtonPaymentsAndConversionsEnabled
                      }
                      error={
                        errors.bankAccountPostingGroupAccountNumber?.message
                      }
                    />
                  );
                }}
              />
            </Field>
            {!!watchBankAccountPostingGroupAccountNumber && (
              <Paragraph ml>
                {postingGroupAccountExisting
                  ? 'This is an existing G/L account number, are you sure you want us to use this account number when creating Bank Account Posting Group?'
                  : 'Are you happy for us to create a G/L account at his number?'}
              </Paragraph>
            )}
          </Col>

          <Row mtValue={theme.spacing.l} mt mb>
            <Subtitle variant="bold">
              FX PnL account to record Realised gains & losses from FX
              conversions and transfers:{' '}
            </Subtitle>
          </Row>
          <Col flex={1}>
            <Field fluid flexDirection="column">
              <Controller
                id="profitAndLossAccountNumber"
                name="profitAndLossAccountNumber"
                control={control}
                defaultValue={null}
                disabled={!isRadioButtonPaymentsAndConversionsEnabled}
                rules={{
                  validate: (value: string) => {
                    if (isRadioButtonPaymentsAndConversionsEnabled && !value) {
                      return 'Choose PnL account number';
                    }

                    if (
                      watchBankAccountPostingGroupAccountNumber &&
                      value &&
                      watchBankAccountPostingGroupAccountNumber === value
                    ) {
                      return 'Must be a unique number';
                    }

                    return true;
                  },
                }}
                render={({ onChange, value, name }) => {
                  return (
                    <InputBase
                      id={name}
                      type="number"
                      label="PnL Account #"
                      value={value}
                      onChange={onChange}
                      disabled={!isRadioButtonPaymentsAndConversionsEnabled}
                      error={errors.profitAndLossAccountNumber?.message}
                    />
                  );
                }}
              />
            </Field>
            {!!watchPnLAccount && (
              <Paragraph>
                {pnlAccountExisting
                  ? 'This is an existing G/L account number, are you sure you want us to use this account number for the FX GnL G/L account?'
                  : 'Are you happy for us to create a G/L account at his number?'}
              </Paragraph>
            )}
          </Col>
        </>
      )}

      <Row mtValue={theme.spacing.xl} mt mb justifyContent="flex-start">
        <StaleSwitch
          id="paymentFees"
          isOn={paymentFees}
          disabled={!isRadioButtonPaymentFeesEnabled}
          handleToggle={() => setPaymentFees(!paymentFees)}
        />
        <Subtitle ml>
          Record payment fees (e.g. SWIFT fees) as separate expenses to:
        </Subtitle>
      </Row>
      {paymentFees && (
        <Col flex={1}>
          <Field fluid>
            <Controller
              id="bankFeesAccount"
              name="bankFeesAccount"
              control={control}
              defaultValue={null}
              disabled={!isRadioButtonPaymentFeesEnabled}
              rules={{
                validate: (value: IAccountData | null) => {
                  if (
                    paymentFees &&
                    isRadioButtonPaymentFeesEnabled &&
                    !value
                  ) {
                    return 'Choose expense account';
                  }
                  return true;
                },
              }}
              render={({ onChange, value, name }) => {
                return (
                  <StaleInputSelect
                    id={name}
                    name={name}
                    label="Expense account"
                    view="moving"
                    data={feesAccounts.filter((item) =>
                      item.name
                        .toLocaleLowerCase()
                        .includes(searchValue.toLocaleLowerCase())
                    )}
                    selected={value}
                    onSelect={(item) => {
                      onChange(item);
                    }}
                    withSearch
                    searchValue={searchValue}
                    onSearch={(e) => setSearchValue(e.currentTarget.value)}
                    strategy="fixed"
                    disabled={!paymentFees || !isRadioButtonPaymentFeesEnabled}
                    error={errors.bankFeesAccount?.message}
                    style={{ width: '100%' }}
                  />
                );
              }}
            />
          </Field>
        </Col>
      )}
      {isXeroIntegration && (
        <>
          <Row mtValue={theme.spacing.xl} mt mb justifyContent="flex-start">
            <StaleSwitch
              id="bankTransfers"
              isOn={bankTransfers}
              handleToggle={() => {
                setBankTransfers(!bankTransfers);
                if (!bankTransfers) {
                  let formBankTransferAccountsNames: string[] = [];
                  calculateBankTransferAccounts(formBankTransferAccountsNames);
                  setFormBankTransferAccounts(formBankTransferAccountsNames);
                }
              }}
            />
            <Subtitle ml>
              Update my Xero with bank transfers to/from my main bank accounts:
            </Subtitle>
          </Row>

          <Col mt mb gap={theme.spacing.xs}>
            <Subtitle variant="bold">Bank transfer accounts</Subtitle>

            <Paragraph mbValue={theme.spacing.xl}>
              Save time by allowing HedgeFlows to automatically update your{' '}
              {platformName} with funding transfers and payouts.
            </Paragraph>
          </Col>

          <BankTransferAccountsFields
            setFormBankTransferAccounts={setFormBankTransferAccounts}
            formBankTransferAccounts={formBankTransferAccounts}
            bankTransferAccounts={bankTransferAccounts}
            currenciesSelectData={currenciesSelectData}
            control={control}
            watch={watch}
            errors={errors}
            bankTransfersPermission={bankTransfers}
            isRadioButton3Enabled={isRadioButtonPaymentFeesEnabled}
            isAddBankTransferAccountButtonEnabled={
              isAddBankTransferAccountButtonEnabled
            }
            platformName={platformName}
          />
        </>
      )}

      {paymentFees && areTaxRatesRequired && (
        <Col flex={1} mt>
          <Field fluid>
            <Controller
              id="taxRateForBankFees"
              name="taxRateForBankFees"
              control={control}
              defaultValue={null}
              disabled={!isRadioButtonPaymentFeesEnabled}
              rules={{
                validate: (value: IAccountData | null) => {
                  if (paymentFees && !value) {
                    return 'Choose tax rate';
                  }
                  return true;
                },
              }}
              render={({ onChange, value, name }) => {
                return (
                  <StaleInputSelect
                    id={name}
                    name={name}
                    label="Tax rate"
                    view="moving"
                    data={taxRates}
                    selected={value}
                    onSelect={(item) => {
                      onChange(item);
                    }}
                    strategy="fixed"
                    disabled={!paymentFees}
                    error={errors.taxRateForBankFees?.message}
                    style={{ width: '100%' }}
                  />
                );
              }}
            />
          </Field>
        </Col>
      )}
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        mt
        mtValue={theme.spacing.xl}
        type="submit"
      >
        Confirm
      </Button>
    </form>
  );
};

export default StepAccountsForm;
