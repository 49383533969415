import { IInvoiceFromSearch } from 'types';

export const updateInvoicesBasedOnBackendUpdate = (
  updatedInvoicesFromBackend: IInvoiceFromSearch[]
) => (invoices: IInvoiceFromSearch[]) => {
  return invoices.map((invoice) => {
    const updatedInvoice = updatedInvoicesFromBackend.find(
      (inv) => inv.id === invoice.id
    );

    if (updatedInvoice) {
      return updatedInvoice;
    }

    return invoice;
  });
};

export const removeInvoicesBasedOnSameContactId = ({
  invoiceContactIdToRemove,
  sourceSystemContactIdToRemove,
}: {
  invoiceContactIdToRemove: string;
  sourceSystemContactIdToRemove?: string;
}) => (invoices: IInvoiceFromSearch[]) => {
  return invoices.reduce<IInvoiceFromSearch[]>((acc, invoice) => {
    if (
      invoice.contactId === invoiceContactIdToRemove ||
      invoice.externalRefsSourceSystemContactId ===
        sourceSystemContactIdToRemove
    ) {
      return acc;
    }

    return [...acc, invoice];
  }, []);
};
