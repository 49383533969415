import { useState, useEffect, FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

import {
  StaleBtnGroup,
  Title,
  StaleLoadPlaceholder,
  Paragraph,
  StaleSelectMenu,
  StaleInput,
  StaleRadioButton,
  TextHint,
  StaleCheckboxControlled,
  StaleSelectLocation,
  Overflow,
  Row,
} from 'components';
import { useDebounce } from 'hooks';
import { Firebase } from 'services';
import { SignUpContent } from '..';
import { useTheme } from 'styled-components';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';
import { StyledButton } from 'components/shared/Button/Button.styles';

dayjs.extend(customParseFormat);

interface OwnProps {
  onSaveValues: (values: any) => void;
  onContinue: () => void;
  stepTwoValues: any;
}

const StepTwo: FC<OwnProps> = ({ onSaveValues, onContinue, stepTwoValues }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [companies, setCompanies] = useState([]);
  const [companyDetails, setCompanyDetails] = useState<Record<string, any>>({});

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<{
    companyName: {
      label: string;
      value: any;
    };
    correspondenceAddress: {
      label: string;
      value: {
        addressLine1: string;
        addressLine2?: string;
        city: string;
        country: string;
        postalCode: string;
        stateOrProvince: string;
      };
    };
    isAddressesMatch: boolean;
    isVatNumber: 'yes' | 'no';
    VAT?: string;
    socialMediaLink?: string;
  }>({
    mode: 'all',
    defaultValues: { ...stepTwoValues?.values },
  });

  const selectedCompany = watch('companyName');
  const isAddressMatch = watch('isAddressesMatch');
  const correspondenceAddress = watch('correspondenceAddress');

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const history = useHistory();

  useEffect(() => {
    if (debouncedSearchValue) {
      Firebase.searchCompanies({
        searchString: debouncedSearchValue,
      }).then((response) => {
        if (!response || !response?.data) {
          return;
        }

        const companiesToDisplay = response.data.map((item: any) => {
          return {
            value: item.name,
            label: (
              <div className="row">
                <Paragraph>{item.name}</Paragraph>
                <TextHint color="dark">{item.description}</TextHint>
                <TextHint>{`${item.address.addressLine1}, ${item.address.addressLine2}, ${item.address.postalCode}, ${item.address.city}, United Kingdom`}</TextHint>
              </div>
            ),
            data: item,
          };
        });

        setCompanies(companiesToDisplay);
      });
    } else {
      setCompanies([]);
    }
  }, [debouncedSearchValue]);

  // fetch data for selected company
  useEffect(() => {
    if (selectedCompany) {
      setIsLoading(true);

      Firebase.getCompanyDetails({
        companyNumber: selectedCompany.value.companyNumber,
      })
        .then((response) => {
          if (!response?.success) {
            return;
          }

          setCompanyDetails(response.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (isAddressMatch && companyDetails.address) {
      setShowAddressDetails(false);

      setValue(`correspondenceAddress.value.city`, '');
      setValue(`correspondenceAddress.value.country`, '');
      setValue(`correspondenceAddress.value.addressLine1`, '');
      setValue(`correspondenceAddress.value.addressLine2`, '');
      setValue(`correspondenceAddress.value.postalCode`, '');
      setValue(`correspondenceAddress.value.stateOrProvince`, '');

      Object.entries(companyDetails.address).forEach(([key, value]) => {
        setValue(`correspondenceAddress.value.${key}`, value);
        trigger(`correspondenceAddress.value.${key}`);
      });
    }
  }, [isAddressMatch, companyDetails, setValue, trigger]);

  const correspondenceLine1 = watch('correspondenceAddress.value.addressLine1');
  const correspondenceLine2 = watch('correspondenceAddress.value.addressLine2');
  const correspondenceCity = watch('correspondenceAddress.value.city');
  const correspondenceCountry = watch('correspondenceAddress.value.country');
  const correspondencePostalCode = watch(
    'correspondenceAddress.value.postalCode'
  );

  useEffect(() => {
    setValue('correspondenceAddress', {
      label: `${correspondenceLine1 && `${correspondenceLine1},`} ${
        correspondenceLine2 && `${correspondenceLine2},`
      } ${correspondencePostalCode && `${correspondencePostalCode},`} ${
        correspondenceCity && `${correspondenceCity},`
      } ${correspondenceCountry}`.trim(),
    });
  }, [
    correspondenceCity,
    correspondenceCountry,
    correspondenceLine1,
    correspondenceLine2,
    correspondencePostalCode,
    setValue,
  ]);

  const onSubmit = (values: any) => {
    onSaveValues({ values, companyDetails });
    onContinue();
  };

  const onError = () => {
    // TODO: discuss how to handle errors
  };

  return (
    <>
      <SignUpContent>
        {!isMobile && (
          <StyledButton
            variant="link"
            className="cross"
            onClick={() => history.push('/app/dashboard')}
          >
            <svg width="24" height="24">
              <use xlinkHref="#cross-ico" />
            </svg>
          </StyledButton>
        )}

        <Overflow>
          <div className="block">
            {!isMobile && (
              <StyledButton
                variant="link"
                onClick={history.goBack}
                className="back"
              >
                <svg width="24" height="24">
                  <use xlinkHref="#arrow-left" />
                </svg>
                Back
              </StyledButton>
            )}

            {isMobile ? (
              <Title variant="h5">Please fill your company information</Title>
            ) : (
              <Title>Please fill your company information</Title>
            )}

            <form
              id="company-registration-step-two-form"
              onSubmit={(event) => {
                event.preventDefault();

                handleSubmit(onSubmit, onError)();
              }}
            >
              <div className="field" style={{ marginBottom: '8px' }}>
                <Controller
                  control={control}
                  name="companyName"
                  rules={{ required: ERROR_MESSAGES.requiredField }}
                  render={({ value, name, onChange }) => {
                    return (
                      <StaleSelectMenu
                        id="companyName"
                        label="Company Name or Number"
                        name={name}
                        isMulti={false}
                        data={companies}
                        inputValue={searchValue}
                        value={value}
                        onChange={(item) => {
                          if (item && item.data) {
                            onChange({
                              label: item.data.name,
                              value: item.data,
                            });
                          }
                        }}
                        onInputChange={(value) => {
                          setSearchValue(value);
                        }}
                        filterOption={(candidate: any, input: any) => {
                          if (input) {
                            if (
                              candidate.data.data.name
                                .toLowerCase()
                                .trim()
                                .includes(input.toLowerCase().trim()) ||
                              candidate.data.data.companyNumber.includes(input)
                            ) {
                              return true;
                            }
                          }

                          return true;
                        }}
                        autoFocus
                      />
                    );
                  }}
                />
              </div>

              {selectedCompany && (
                <>
                  <div className="company-info">
                    <div className="row">
                      <TextHint color="dark" style={{ color: '#121213' }}>
                        {selectedCompany.value.description}
                      </TextHint>
                      <TextHint>{`${selectedCompany.value.address.addressLine1}, ${selectedCompany.value.address.addressLine2}, ${selectedCompany.value.address.postalCode}, ${selectedCompany.value.address.city}, United Kingdom`}</TextHint>
                    </div>
                  </div>
                  <StaleCheckboxControlled
                    id="isAddressesMatch"
                    name="isAddressesMatch"
                    defaultValue={false}
                    control={control}
                    Label={
                      <span>Use registration address for correspondence</span>
                    }
                  />
                </>
              )}

              <hr />

              <div className="col">
                <div className="field">
                  <Controller
                    control={control}
                    name="correspondenceAddress"
                    defaultValue={null}
                    rules={{ required: ERROR_MESSAGES.requiredField }}
                    render={({ onChange, value, name }) => {
                      return (
                        <StaleSelectLocation
                          id="correspondenceAddress"
                          label="Correspondence Address"
                          isDisabled={isAddressMatch}
                          name={name}
                          value={value}
                          onChange={(values: any) => {
                            onChange({
                              label: values.label,
                            });

                            if (values.dataForPaste) {
                              Object.entries(values.dataForPaste).forEach(
                                ([key, value]) => {
                                  setValue(
                                    `correspondenceAddress.value.${key}`,
                                    value
                                  );
                                  trigger(`correspondenceAddress.value.${key}`);
                                }
                              );
                            }
                          }}
                        />
                      );
                    }}
                  />
                </div>

                <div
                  className="col"
                  style={{
                    height: showAddressDetails ? 'auto' : 0,
                    overflow: 'hidden',
                  }}
                >
                  <div className="field">
                    <StaleInput
                      locked={!showAddressDetails}
                      control={control}
                      name="correspondenceAddress.value.addressLine1"
                      id="correspondenceAddress.value.addressLine1"
                      label="Address Line 1"
                      view="moving"
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                    />
                  </div>
                  <div className="field">
                    <StaleInput
                      locked={!showAddressDetails}
                      control={control}
                      name="correspondenceAddress.value.addressLine2"
                      id="correspondenceAddress.value.addressLine2"
                      label="Address Line 2 (optional)"
                      view="moving"
                    />
                  </div>

                  <div className="field">
                    <StaleInput
                      locked={!showAddressDetails}
                      control={control}
                      name="correspondenceAddress.value.city"
                      id="correspondenceAddress.value.city"
                      label="City"
                      view="moving"
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                    />
                  </div>

                  <div className="row">
                    <div
                      className="field"
                      style={{ width: 116, marginRight: 16 }}
                    >
                      <StaleInput
                        locked={!showAddressDetails}
                        control={control}
                        name="correspondenceAddress.value.postalCode"
                        id="correspondenceAddress.value.postalCode"
                        label="Post code"
                        view="moving"
                        rules={{
                          required: ERROR_MESSAGES.requiredField,
                        }}
                      />
                    </div>
                    <div className="field" style={{ flex: 1 }}>
                      <StaleInput
                        locked={!showAddressDetails}
                        control={control}
                        name="correspondenceAddress.value.stateOrProvince"
                        id="correspondenceAddress.value.stateOrProvince"
                        label="Region (optional)"
                        view="moving"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <StaleInput
                      locked={!showAddressDetails}
                      control={control}
                      name="correspondenceAddress.value.country"
                      id="correspondenceAddress.value.country"
                      label="Country"
                      view="moving"
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                    />
                  </div>
                </div>

                <StyledButton
                  variant="link"
                  disabled={isAddressMatch}
                  type="button"
                  onClick={() => setShowAddressDetails(!showAddressDetails)}
                  style={{
                    textDecoration: 'underline',
                    marginTop: 6,
                    marginBottom: 16,
                  }}
                >
                  <Paragraph>
                    {showAddressDetails
                      ? 'Apply'
                      : correspondenceAddress?.label
                      ? 'Edit'
                      : 'Enter manually'}
                  </Paragraph>
                </StyledButton>
              </div>

              {isLoading ? (
                <>
                  <StaleLoadPlaceholder height="48px" />
                  <StaleLoadPlaceholder height="26px" />
                  <StaleLoadPlaceholder height="26px" />
                </>
              ) : null}

              <hr />

              <div className="field">
                <StaleInput
                  control={control}
                  name="socialMediaLink"
                  id="socialMediaLink"
                  label="Website or social media link"
                  view="moving"
                />
              </div>
              <hr />
              <Paragraph>Are you VAT registered?</Paragraph>
              <Row mtValue={theme.spacing.xs} mt>
                <Controller
                  name="isVatNumber"
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  render={({ name, onChange }) => (
                    <StaleRadioButton
                      onChange={(item: any) => onChange(item.value)}
                      list={[
                        {
                          id: 'val-yes',
                          value: 'yes',
                          checked: watch('isVatNumber') === 'yes',
                          name: <p>Yes</p>,
                        },
                        {
                          id: 'val-no',
                          value: 'no',
                          checked: watch('isVatNumber') === 'no',
                          name: <p>No</p>,
                        },
                      ]}
                      name={name}
                      cardCol
                    />
                  )}
                />
              </Row>
              {watch('isVatNumber') === 'yes' && (
                <div className="field">
                  <StaleInput
                    id="VAT"
                    label="VAT Number"
                    view="moving"
                    type="text"
                    control={control}
                    name="VAT"
                    prefix="GB"
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                      validate: {
                        isValid: async (value) => {
                          const data = await Firebase.checkVATNumber({
                            number: `GB${value}`,
                          });

                          if (data?.success && data.data.valid) {
                            return true;
                          } else {
                            return 'VAT number is not valid.';
                          }
                        },
                      },
                    }}
                    error={errors.VAT?.message}
                  />
                </div>
              )}
            </form>

            {!isMobile && (
              <StaleBtnGroup>
                <Button
                  disabled={!isValid}
                  form="company-registration-step-two-form"
                >
                  Continue
                </Button>
              </StaleBtnGroup>
            )}
          </div>
        </Overflow>
      </SignUpContent>

      {isMobile && (
        <StaleBtnGroup>
          <Button disabled={!isValid} form="company-registration-step-two-form">
            Continue
          </Button>
        </StaleBtnGroup>
      )}
    </>
  );
};

export default StepTwo;
