import { InlineLoader, Paragraph, Title } from 'components';
import { FC } from 'react';
import { parseRateWithPrecision } from 'utils';

interface OwnProps {
  isRateLoading: boolean;
  rate?: number;
}

const RateCell: FC<OwnProps> = ({ isRateLoading, rate }) => {
  if (isRateLoading) {
    return <InlineLoader />;
  }

  if (!isRateLoading && !rate) {
    return <Paragraph color="red">Not available</Paragraph>;
  }

  return <Title variant="h3">{parseRateWithPrecision(rate)}</Title>;
};

export default RateCell;
