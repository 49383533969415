import styled, { css } from 'styled-components';
import {
  ResponsiveCenteredContainer,
  Title,
  Paragraph,
  Overflow,
} from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: ${theme.color.dark};

    @media (max-width: ${theme.breakpoint.largeMax}px) {
      padding-top: 56px;
    }

    .field {
      margin-top: 16px;
    }

    .bot {
      padding: 32px 16px 16px;
      text-align: center;
      color: ${theme.color.greyDark};

      @media (max-width: ${theme.breakpoint.largeMax}px) {
        margin-top: auto;
      }
    }

    .or {
      display: flex;
      justify-content: center;
      position: relative;
      margin: 24px 0;

      span {
        font-weight: 500;
        padding: 0 10px;
        background: ${theme.color.white};
        position: relative;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: ${theme.color.greyLight_2};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
      }
    }

    .block {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        justify-content: center;
      }
    }
  `
);

export const LoginWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-top: 0;
    height: 100%;
    box-shadow: inset 0 80px 0 0 ${theme.color.dark};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      flex-direction: row;
    }

    ${ResponsiveCenteredContainer} {
      padding: 16px 16px 12px;
    }

    .rounded {
      background: ${theme.color.white};

      @media (max-width: ${theme.breakpoint.largeMax}px) {
        border-radius: 12px 12px 0 0;
        padding: 14px 0 0;
        box-shadow: 0 -3px 9px -3px rgba(0, 0, 0, 0.1);
      }
    }
  `
);

export const LoginContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    overflow: auto;
    height: 100%;
    background: ${theme.color.white};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      flex: 0 0 50%;
      max-width: 50%;
      justify-content: center;
    }

    & > ${Overflow} {
      padding: 14px 0 16px;

      & > div:not(.notification):first-child {
        @media (max-width: ${theme.breakpoint.largeMax}px) {
          padding-top: 0;
        }
      }
    }

    .cross {
      position: absolute;
      right: 28px;
      top: 28px;
      z-index: 10;

      @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
        right: 48px;
        top: 48px;
      }
    }

    .block {
      padding: 10px 16px 0;
      position: relative;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        padding: 70px;
      }
      @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
        padding: 92px 154px;
      }

      & + .block {
        border-top: 1px solid ${theme.color.greyLight_2};
      }

      .row {
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        ${Paragraph} {
          display: flex;
          align-items: center;
        }

        .text${Paragraph}, .checkbox${Paragraph} {
          display: inline;
        }
      }

      ${Title} {
        margin-bottom: 8px;

        & + div > button {
          margin-bottom: 8px;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .checkbox {
      background: ${theme.color.greyLight_4};
      border: 1px solid ${theme.color.greyLight_2};
      border-radius: 8px;
      min-height: 44px;
      padding: 8px 8px 8px 35px;
      position: relative;
      flex: 0 0 32%;
      max-width: 32%;
      font-size: 12px;
      margin-bottom: 16px;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        left: 12px;
        top: 12px;
        border-radius: ${theme.borderRadius.round};
        background: ${theme.color.emeraldDark};
        border: 2px solid ${theme.color.emeraldDark};
        box-shadow: inset 0 0 0 7px ${theme.color.white};
        transition: box-shadow 0.2s linear;
      }

      &.checked {
        &:before {
          box-shadow: inset 0 0 0 2px ${theme.color.white};
        }

        span,
        ${Paragraph} {
          font-weight: 700;
        }
      }

      span {
        display: inline-block;
      }
    }

    .save {
      width: 100%;
      background: ${theme.color.greyLight_4};
      border-radius: 8px;
      min-height: 40px;
      padding: 8px 44px 8px 16px;
      color: ${theme.color.grey};
      margin-bottom: 16px;
      position: relative;
      line-height: 24px;

      &.active {
        color: ${theme.color.greyDark};
        background: ${theme.color.emeraldLight};

        span {
          color: ${theme.color.dark};
          padding-left: 6px;
        }

        svg {
          fill: ${theme.color.dark};
        }
      }

      svg {
        position: absolute;
        right: 16px;
        top: 8px;
        width: 24px;
        height: 24px;
        fill: ${theme.color.grey};
      }
    }

    .explained {
      padding-bottom: 40px;

      &-table {
        display: flex;
        background: ${theme.color.greyLight_4};
        border: 1px solid ${theme.color.greyLight_1};
        color: ${theme.color.greyDark};
        border-radius: 8px;

        ${Paragraph} {
          color: ${theme.color.dark};
        }

        .col {
          min-height: 64px;
          padding: 12px;
          flex: 0 1 100%;
          position: relative;

          &:not(:last-of-type) {
            border-right: 1px solid ${theme.color.greyLight_1};

            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: -8px;
              bottom: 0;
              margin: auto;
              width: 16px;
              height: 16px;
              border-radius: ${theme.borderRadius.round};
              background: ${theme.color.white};
              border: 1px solid ${theme.color.greyLight_1};
              text-align: center;
              line-height: 17px;
              color: ${theme.color.dark};
              font-weight: 500;
              font-size: 17px;
            }
          }

          &:nth-last-of-type(2) {
            padding-left: 15px;
            padding-right: 16px;

            &:before {
              content: '=';
            }
          }

          &:first-of-type {
            &:before {
              content: '+';
            }
          }

          &:last-of-type {
            text-align: right;
          }
        }
      }

      &-info {
        padding: 12px 0 0 20px;
        margin: 8px 0 0 15px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: -8px;
          left: 0;
          height: 100%;
          width: 1px;
          background: ${theme.color.greyLight_1};
        }

        .item {
          position: relative;

          &:not(:last-of-type) {
            padding-bottom: 15px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: -27px;
            width: 16px;
            height: 16px;
            border-radius: ${theme.borderRadius.round};
            background: ${theme.color.white};
            border: 1px solid ${theme.color.greyLight_1};
            text-align: center;
            line-height: 17px;
            color: ${theme.color.dark};
            font-weight: 500;
            font-size: 17px;
          }

          &:first-of-type:before {
            content: '+';
          }
          &:last-of-type:before {
            content: '=';
          }
        }

        .loader {
          animation: loading 1.2s linear infinite;
        }

        @keyframes loading {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }

    .notification {
      padding: 16px 12px 15px 48px;
      border-radius: 10px;
      color: ${theme.color.white};
      background: ${theme.color.emeraldDark};
      margin: 0 16px 14px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 18px;
        top: 20px;
        width: 18px;
        height: 8px;
        border-bottom: 2px solid ${theme.color.white};
        border-left: 2px solid ${theme.color.white};
        transform: rotate(-45deg);
      }
    }

    .download {
      margin: 12px 0;
      display: block;
      padding: 13px 0;
      display: flex;
      align-items: center;
    }

    .react-date-picker {
      width: 100vw;
      left: -16px;

      &__clear-button {
        display: none;
      }

      &__inputGroup {
        &__input {
          padding: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.002em;
          color: ${theme.color.dark};
        }

        &__month {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          width: 28px;
        }
      }

      &__wrapper {
        border: 0;
        position: absolute;
        right: 16px;
        top: -32px;
      }
    }

    .forgot-password-button {
      display: inline;
      border-bottom: 1px solid ${theme.color.dark};
      padding-bottom: 1px;
      margin: 16px 0 0 0;

      &:hover {
        border-color: ${theme.color.transparent};
      }
    }

    .sign-up {
      margin: 0;
    }

    .resend {
      color: ${theme.color.greyDark};
      margin-top: 10px;
      margin-bottom: 35px;

      button {
        display: inline;
        text-decoration: underline;
        color: ${theme.color.black};
        margin: 0 0 0 8px;
        font-weight: 500;
      }
    }
  `
);

export const LoginNav = styled.div(
  ({ theme }) => css`
    background: ${theme.color.dark};
    padding: 8px 16px;
    color: ${theme.color.white};
    min-height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      padding: 80px 70px;
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
      padding: 100px 154px;
    }

    @media (max-width: ${theme.breakpoint.largeMax}px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 12;
    }

    .icon {
      margin: 68px auto 65px;
      fill: ${theme.color.white};
    }

    .slick-slider {
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        display: none;
      }

      .wrap {
        display: flex;
        flex-direction: column;
      }
    }

    ${Title} {
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        text-align: center;
      }
      @media (min-width: ${theme.breakpoint.largeMin}px) {
        font-size: 36px;
        line-height: 42.2px;
        background: -webkit-linear-gradient(
          360deg,
          #cfeeea 1.31%,
          #e7edd0 27.56%,
          #facba0 70.76%,
          #ffb8bd 98.26%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .back {
      position: absolute;
      right: 16px;
      top: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      svg {
        fill: ${theme.color.white};
      }

      &.previous {
        display: flex;
        align-items: center;
        left: 12px;
        right: inherit;
        right: initial;

        svg {
          margin-right: 4px;
        }
      }
    }

    .item {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${theme.color.grey};
      position: relative;
      padding: 6px 22px 2px 0;

      &:not(:last-of-type):after {
        content: '';
      }

      &:after {
        width: 0;
        height: 0;
        border-top: 4.5px solid transparent;
        border-left: 6px solid ${theme.color.grey};
        border-bottom: 4.5px solid transparent;
        position: absolute;
        top: 3px;
        right: 8px;
        bottom: 1px;
        margin: auto;
      }

      &.passed {
        color: ${theme.color.white};

        &:after {
          border-left-color: ${theme.color.white};
        }
      }
      &.current {
        color: ${theme.color.emeraldDark};
      }
    }
  `
);
