import { FC } from 'react';
import { Paragraph, TextHint, Col } from 'components';
import InlineLoader from 'components/shared/InlineLoader/InlineLoader';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';

const ExchangeSummarySkeleton: FC = () => {
  return (
    <Col>
      <RowInfo>
        <Paragraph>You convert</Paragraph>

        <InlineLoader />
      </RowInfo>

      <TextHint>
        Includes our conversion fee <InlineLoader />
      </TextHint>

      <RowInfo>
        <Paragraph>You get</Paragraph>

        <InlineLoader />
      </RowInfo>

      <RowInfo>
        <Paragraph>Exchange rate</Paragraph>

        <InlineLoader />
      </RowInfo>
    </Col>
  );
};

export default ExchangeSummarySkeleton;
