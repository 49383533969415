import ButtonCopy from 'components/shared/ButtonCopy/ButtonCopy';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';
import { Paragraph, Title } from 'components/shared/Typography/Typography';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IEntityAccountDetailsType } from 'types';
import { AccountDetailWrapper } from './AccountDetailsTile.styles';

interface IOwnProps {
  detailsForCopyTypes: IEntityAccountDetailsType[];
  selectDetailsForCopy: (id: IEntityAccountDetailsType) => void;
  onCopyHandler: () => void;
  title: string;
  accountDetailsType: IEntityAccountDetailsType;
  data: Record<string, string>;
}

const AccountDetailsTile: FC<IOwnProps> = ({
  detailsForCopyTypes,
  selectDetailsForCopy,
  onCopyHandler,
  accountDetailsType,
  title,
  data,
}) => {
  const theme = useTheme();

  return (
    <Col gap={theme.spacing.xxs}>
      <Row>
        <Row justifyContent="flex-start" gap={theme.spacing.s}>
          <Checkbox
            checked={detailsForCopyTypes.includes(accountDetailsType)}
            onChange={() => selectDetailsForCopy(accountDetailsType)}
          />
          <Title variant="h5">{title}</Title>
        </Row>

        <ButtonCopy showIcon onClick={onCopyHandler}>
          <Paragraph>Copy</Paragraph>
        </ButtonCopy>
      </Row>

      {Object.keys(data).map((key) => (
        <AccountDetailWrapper key={key}>
          <Paragraph>{key}</Paragraph>
          <Paragraph variant="bold">{data[key]}</Paragraph>
        </AccountDetailWrapper>
      ))}
    </Col>
  );
};

export default AccountDetailsTile;
