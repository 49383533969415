import React from 'react';

interface IOwnProps {
  isDisabled: boolean;
  children: React.ReactNode;
}

const Disabled: React.FC<IOwnProps> = ({ children, isDisabled }) => (
  <div
    style={{
      opacity: isDisabled ? 0.5 : 1,
      pointerEvents: isDisabled ? 'none' : 'auto',
    }}
  >
    {children}
  </div>
);

export default Disabled;
