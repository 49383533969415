import { Col, Row } from 'components';
import styled from 'styled-components';

export const ConnectionTileCardContentWrapper = styled(Col)`
  gap: ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex-direction: row;
  }
`;

export const ConnectionTileTextWrapper = styled(Row)`
  flex: 1;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    justify-content: flex-start;
  }
`;

export const ConnectionTileCTAWrapper = styled(Row)`
  flex: 1;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    justify-content: flex-end;
  }
`;

export const SeparatorLine = styled.div`
  width: 1px;
  height: 28px;
  background: ${({ theme }) => theme.color.greyLight_1};
`;
