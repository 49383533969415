import { ResponsiveCenteredContainer } from 'components';
import { StyledButton } from 'components/shared/Button/Button.styles';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.color.dark};
    height: 100vh;
    width: 100%;
    align-items: center;
  `
);

export const Heading = styled.div(
  ({ theme }) => css`
    background: ${theme.color.dark};
    padding: 8px 16px;
    color: ${theme.color.white};
    min-height: 56px;
    display: flex;
    justify-self: start;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  `
);

export const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.color.white};
    flex: 1;
    padding: 16px 16px 12px;
    align-self: stretch;
    align-items: center;

    @media (max-width: ${theme.breakpoint.largeMax}px) {
      border-radius: 12px 12px 0 0;
      box-shadow: 0 -3px 9px -3px rgba(0, 0, 0, 0.1);
    }

    ${ResponsiveCenteredContainer} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      @media (max-width: ${theme.breakpoint.largeMax}px) {
        justify-content: flex-start;
      }

      .block {
        max-width: 576px;

        @media (max-width: ${theme.breakpoint.largeMax}px) {
          max-width: none;
        }
      }
    }
  `
);

export const LogOutButton = styled(StyledButton)(
  ({ theme }) => css`
    text-decoration: none;
    white-space: nowrap;

    ${!isMobile &&
    css`
      position: absolute;
      right: 48px;
      top: 48px;
      z-index: 10;
    `}

    ${isMobile &&
    css`
      color: ${theme.color.white};

      svg {
        fill: ${theme.color.white};
      }
    `}
  `
);
