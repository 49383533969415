import styled from 'styled-components';
import { Paragraph } from 'components/shared/Typography/Typography';
import { Col } from 'components';

export const RedHint = styled(Paragraph)`
  color: ${({ theme }) => theme.color.red};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    text-align: right;
  }
`;

export const AuthoriseStepFooterWrapper = styled(Col)`
  background-color: ${({ theme }) => theme.color.white};
  gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.xxs};
  padding: ${({ theme }) => theme.spacing.m};
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
`;

export const AuthoriseStepCurrencyTotalsWrapper = styled(Col)`
  justify-content: space-between;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacing.xs};
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    align-items: flex-end;
  }
`;
