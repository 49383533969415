import styled from 'styled-components';
import { Col } from 'components/shared/Col/Col';

export const CurrencyInfoWrapper = styled(Col)`
  flex: 0.4;
  align-self: stretch;
  min-height: 364px;
  min-width: 454px;
  column-gap: ${({ theme }) => theme.spacing.xxl};
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ theme }) => theme.color.white};
`;

export const CurrencyInfoTableWrapper = styled(Col)`
  flex: 1;
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacing.xxl};
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  min-height: 364px;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacing.xxl};
  align-items: stretch;
`;
