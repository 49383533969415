import { IInvoiceFromSearch } from 'types';

export const useInvoiceFromSearchLastSyncDetails = (
  invoice: IInvoiceFromSearch
) => {
  const {
    lastSyncFromExternalSourceDate,
    lastSyncFromExternalSourceStatus,
    lastSyncToExternalSourceDate,
    lastSyncToExternalSourceStatus,
  } = invoice;

  return {
    lastSyncFromExternalSourceDate,
    lastSyncFromExternalSourceStatus,
    lastSyncToExternalSourceDate,
    lastSyncToExternalSourceStatus,
  };
};
