import { FC } from 'react';
import { useTheme } from 'styled-components';
import { getCountryCodeByCurrency, parseRateWithPrecision } from 'utils';
import { useStoreState } from 'state';
import { Row } from 'components/shared/Row/Row';
import Icon from 'components/shared/Icon/Icon';
import { Col } from 'components/shared/Col/Col';
import { Paragraph, Title } from 'components/shared/Typography/Typography';

const CurrencyRateCell: FC<{ data: any }> = ({ data }) => {
  const theme = useTheme();
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { buyCurrency, sellCurrency, rate } = data;
  const countryCode = getCountryCodeByCurrency(buyCurrency, currencies);

  return (
    <Row justifyContent="start">
      <Icon
        mr
        width={theme.iconSizes.l}
        height={theme.iconSizes.l}
        icon={countryCode}
      />

      <Col>
        <Paragraph>1 {` ${sellCurrency} `} =</Paragraph>

        <Paragraph>
          <Title as="span" style={{ lineHeight: '24px' }} variant="h3">
            {parseRateWithPrecision(rate)}
          </Title>{' '}
          {buyCurrency}
        </Paragraph>
      </Col>
    </Row>
  );
};

export default CurrencyRateCell;
