import { PhoneMultiFactorGenerator } from 'firebase/auth';
import auth from 'services/auth';
import { useStoreState } from 'state';
import { isEmulated } from 'utils';

const useMFASetup = () => {
  const {
    isUserRegistrationDone,
    isEntityOnboarded,
    userId,
    isEntityEnabled,
  } = useStoreState((state) => state.UserState);

  const isPhoneVerified = auth.currentUser?.multiFactor.enrolledFactors.find(
    (item) => item.factorId === PhoneMultiFactorGenerator.FACTOR_ID
  );

  const isRegisteredAndReadyForMFASetup = !!(
    !!userId &&
    isEntityOnboarded &&
    isUserRegistrationDone &&
    isEntityEnabled &&
    !isEmulated()
  );

  return {
    isPhoneVerified,
    isRegisteredAndReadyForMFASetup,
    showMFASetup: isRegisteredAndReadyForMFASetup && !isPhoneVerified,
  };
};

export default useMFASetup;
