import { useRef } from 'react';
import { ChartData } from 'chart.js';
import Chart from 'components/shared/Chart/Chart';
import { Col } from 'components/shared/Col/Col';
import { useTheme } from 'styled-components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

export interface IChartDataRecord {
  date: string;
  sumOfBalances: number;
  balances: {
    date: string;
    amount: number;
    name: string;
    currencyCode: string;
  }[];
}

const ChartBalancesByAccount = ({
  data,
  labels,
}: {
  labels: Date[];
  data: {
    label: string;
    data: number[];
    backgroundColor: string[];
  }[];
}) => {
  const theme = useTheme();
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const chartData: ChartData<'bar', number[], Date> = {
    labels,
    datasets: data,
  };

  return (
    <Col flex={1} style={{ position: 'relative' }}>
      <Chart
        type="bar"
        data={chartData}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false, // Disable the default tooltip
              external: (context) => {
                const {
                  chart: { chartArea },
                  tooltip,
                } = context;

                // Hide tooltip if not hovering
                if (tooltipRef.current && tooltip.opacity === 0) {
                  return (tooltipRef.current.style.opacity = '0');
                }

                if (tooltipRef.current) {
                  // Calculate and set the position based on chart area and data point
                  tooltipRef.current.style.opacity = '1';
                  tooltipRef.current.style.left = `${
                    chartArea.left + tooltip.caretX
                  }px`;
                  tooltipRef.current.style.top = `${
                    chartArea.top + tooltip.caretY
                  }px`;

                  // Set tooltip content
                  const label = tooltip.title[0];

                  const notZeroDataPoints = tooltip.dataPoints.filter(
                    (el) => el.raw !== 0
                  );

                  const content = notZeroDataPoints.map((el) => {
                    const dataset = data[el.datasetIndex];
                    const value = dataset.data[el.dataIndex];

                    return `
                      <p style="white-space:nowrap; font-size: 12px;">
                        <span style="
                          background-color: ${dataset.backgroundColor};
                          width: 10px;
                          height: 10px; 
                          display: inline-block; 
                          margin-right: 5px; 
                          border-radius: 3px;
                        ">
                        </span>
                        ${dataset.label}: ${parseIntoCurrencyStringWithSymbol(
                      value
                    )}
                      </p>`;
                  });

                  tooltipRef.current.innerHTML = `
                    <strong style="white-space:nowrap; font-size: 14px;">${label}</strong><br>
                    ${content.join('')}
                  `;
                }
              },
            },
          },
          scales: {
            x: {
              type: 'time',
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                source: 'data',
              },
              time: {
                tooltipFormat: 'MMM DD',
                minUnit: 'day',
              },
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                display: false,
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
        }}
      />

      <div
        ref={tooltipRef}
        style={{
          opacity: 0, // Start hidden
          zIndex: 1,
          position: 'absolute',
          pointerEvents: 'none',
          background: 'rgba(0,0,0,0.8)',
          color: theme.color.white,
          borderRadius: theme.borderRadius.m,
          padding: theme.spacing.s,
          transition: theme.transition,
        }}
      />
    </Col>
  );
};

export default ChartBalancesByAccount;
