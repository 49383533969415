import { FC } from 'react';
import { Paragraph } from '../Typography/Typography';
import cx from 'classnames';
import { DirectionSellWrapper } from './DirectionCell.styles';
import Icon from '../Icon/Icon';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';

interface OwnProps {
  isReceivable?: boolean;
  withTitle?: boolean;
  currencyCode?: string;
  withIcon?: boolean;
}

const DirectionCell: FC<OwnProps> = ({
  isReceivable,
  currencyCode,
  withTitle = true,
  withIcon = false,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(currencyCode);

  return (
    <DirectionSellWrapper>
      {withIcon && currency && (
        <Icon
          icon={currency.countryCode}
          style={{ marginRight: theme.spacing.m }}
        />
      )}

      <Icon
        width="24px"
        height="24px"
        className={cx(isReceivable ? 'pay-ico get' : 'pay-ico')}
        icon="pay-ico"
      />

      {withTitle && <Paragraph>{isReceivable ? 'Get' : 'Pay'}</Paragraph>}
    </DirectionSellWrapper>
  );
};

export default DirectionCell;
