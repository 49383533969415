import useExternalInvoiceContactFromSearchContact from 'hooks/useExternalInvoiceContactFromSearchContact';
import { IInvoiceFromSearch } from 'types';
import { getSourceSystemContactFromInvoiceFromSearch } from 'utils/invoices';

const useInvoiceRecipientPrePopulatedValues = (
  invoice: IInvoiceFromSearch | null
) => {
  const {
    isLoadingContact,
    recipientFromInvoiceExternalSystem,
    setRecipientFromInvoiceExternalSystem,
  } = useExternalInvoiceContactFromSearchContact(invoice);

  const recipientFromInvoiceFromSearch = getSourceSystemContactFromInvoiceFromSearch(
    invoice
  );

  const resetPrePopulatedValues = () => {
    setRecipientFromInvoiceExternalSystem(undefined);
  };

  return {
    recipientPrePopulatedValues: {
      ...recipientFromInvoiceFromSearch,
      ...(recipientFromInvoiceExternalSystem ?? {}),
      currency: invoice?.currency,
    },
    recipientFromInvoiceExternalSystem,
    isLoadingContact,
    resetPrePopulatedValues,
  };
};

export default useInvoiceRecipientPrePopulatedValues;
