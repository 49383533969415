import { Loader, Popup, Title } from 'components';
import EventsHistory from 'components/shared/EventsHistory/EventsHistory';
import { FC, useEffect, useState } from 'react';
import { getAssetEvents } from 'services/bff';
import { IPlatformEventsSearchResult } from 'types/events';
import { errorHandler } from 'utils/errors';

interface IOwnProps {
  assetId: string;
  onClose: () => void;
}

const PopupAssetEvents: FC<IOwnProps> = ({ assetId, onClose }) => {
  const [events, setEvents] = useState<IPlatformEventsSearchResult[]>([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setIsLoadingEvents(true);
        const { data } = await getAssetEvents(assetId);

        if (data.data) {
          setEvents(data.data);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoadingEvents(false);
      }
    };

    fetchEvents();
  }, [assetId]);

  return (
    <Popup
      HeaderContent={<Title variant="h2">Events</Title>}
      width="600px"
      height="600px"
      onClose={onClose}
    >
      {isLoadingEvents && <Loader size="large" />}
      {!isLoadingEvents && <EventsHistory events={events} />}
    </Popup>
  );
};

export default PopupAssetEvents;
