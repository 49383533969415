import styled from 'styled-components';
import { Title } from 'components';

export const SignUpLeftSideTitle = styled(Title)`
  font-size: 36px;
  line-height: 42.2px;
  background: -webkit-linear-gradient(
    360deg,
    #cfeeea 1.31%,
    #e7edd0 27.56%,
    #facba0 70.76%,
    #ffb8bd 98.26%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: ${({ theme }) => theme.breakpoint.largeMax}px) {
    text-align: center;
  }
`;
