import { FC, InputHTMLAttributes, useState } from 'react';
import cx from 'classnames';
import { Wrapper } from './StaleInputPhone.styles';
import { StaleInputSelectList } from '../index';

interface Item {
  id: any;
  name: string;
  value: any;
  icon?: string;
}

interface OwnProps {
  id: any;
  title?: string;
  name?: string;
  label?: string;
  view?: string;
  type?: string;
  value?: string;
  data?: Item[];
  onSelect?: (item: Item) => any;
  selected?: any;
  theme?: 'theme-grey' | 'theme-dark';
  onChange: () => void;
  error?: string;
  autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus'];
}

const StaleInputPhone: FC<OwnProps> = ({
  id,
  title,
  name = '',
  label = '',
  view = 'static',
  type = 'text',
  value = '',
  data = [],
  selected,
  onSelect = () => {},
  theme = 'theme-grey',
  onChange = (e: any) => e,
  error = '',
  autoFocus = false,
}) => {
  const [open, setOpen] = useState(false);
  const [actionClasses, setActionClasses] = useState('');

  return (
    <Wrapper error={!!error} className={cx(actionClasses, view)}>
      <div className="wrap">
        <div className="select" onClick={() => setOpen(true)}>
          {selected && (
            <svg width="24px" height="24px" className="country">
              <use xlinkHref={`#${selected.alpha2}`} />
            </svg>
          )}
          {selected ? selected.phoneCode : ''}
          <svg className="i-arrow" width="24px" height="24px" fill="#8C9199">
            <use xlinkHref="#arrow-down" />
          </svg>
        </div>

        {label && (
          <label className="label" htmlFor={id}>
            {label}
          </label>
        )}

        <input
          id={id}
          className="input"
          type={type}
          name={name}
          placeholder={view === 'static' ? label : ''}
          autoComplete="new-password"
          defaultValue={value}
          onChange={onChange}
          onFocus={() => setActionClasses(label ? 'focused filled' : 'focused')}
          onBlur={(e) =>
            e.target.value.length && label
              ? setActionClasses('filled')
              : setActionClasses('')
          }
          autoFocus={autoFocus}
        />
        {open && (
          <StaleInputSelectList
            withSearch={false}
            withClose={true}
            onSelect={onSelect}
            selectedValue={selected}
            data={data}
            title={title}
            theme={theme}
            onClose={() => {
              setOpen(!open);
            }}
          />
        )}
      </div>

      {error && <span className="error">{error}</span>}
    </Wrapper>
  );
};

export default StaleInputPhone;
