import {
  InlineLoader,
  Paragraph,
  Row,
  StaleSwitch,
  PermissionsChecker,
} from 'components';
import Field from 'components/shared/Field/Field.styles';
import InputDateUncontrolled from 'components/shared/InputDateUncontrolled/InputDateUncontrolled';
import { FC } from 'react';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import { ERROR_MESSAGES } from 'variables';

export interface IFooterControlsProps {
  control: Control<{
    password: string;
    date: string;
  }>;
  errors: DeepMap<
    {
      password: string;
      date: string;
    },
    FieldError
  >;
  defaultActiveStartDate: Date;
  minDate: Date;
  nonTradingDaysToUse: Set<string>;
  isBuyFxOn: boolean;
  onBuyFxAll: () => void;
  isLoading: boolean;
  isLoadingBuyFxAll: boolean;
  canSelectBuyFx: boolean;
}

const FooterControls: FC<IFooterControlsProps> = ({
  control,
  errors,
  defaultActiveStartDate,
  minDate,
  nonTradingDaysToUse,
  isBuyFxOn,
  onBuyFxAll,
  isLoading,
  isLoadingBuyFxAll,
  canSelectBuyFx,
}) => (
  <>
    <Field mr flexDirection="column">
      <Controller
        id="date"
        name="date"
        control={control}
        rules={{
          required: ERROR_MESSAGES.requiredField,
        }}
        error={errors?.date?.message}
        render={({ onChange, value, name }) => {
          return (
            <InputDateUncontrolled
              id={name}
              view="moving"
              label="Date to schedule payment"
              error={errors?.date?.message}
              value={value}
              calendarProps={{
                defaultActiveStartDate,
                minDate,
                minDetail: 'month',
              }}
              onChange={onChange}
              disabledDates={Array.from(nonTradingDaysToUse)}
            />
          );
        }}
      />
    </Field>

    <PermissionsChecker action="create" resource="prebooks">
      {canSelectBuyFx && (
        <Row>
          {isLoading || isLoadingBuyFxAll ? (
            <Row style={{ width: 46 }}>
              <InlineLoader height="24px" />
            </Row>
          ) : (
            <StaleSwitch
              id="all-payment-run-summary-items"
              isOn={isBuyFxOn}
              handleToggle={onBuyFxAll}
            />
          )}
          <Paragraph ml>Book required currency conversions</Paragraph>
        </Row>
      )}
    </PermissionsChecker>
  </>
);

export default FooterControls;
