import { FC, useState } from 'react';
import { Paragraph } from 'components';
import Button from '../Button/Button';
import Popup from '../Popup/Popup';
import { Title } from '../Typography/Typography';
import useUrlValues from 'hooks/useUrlValues';
import { cancelContract } from 'services/firebase';
import { Notify } from 'utils';

const DeleteContract: FC = () => {
  const { deleteContractId, setUrlValue } = useUrlValues('deleteContractId');
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => setUrlValue({ deleteContractId: '' });

  const onCancelContract = async () => {
    try {
      if (!deleteContractId) {
        return;
      }
      setIsLoading(true);
      const { data } = await cancelContract(deleteContractId);
      if (data.success) {
        Notify.success('Prebook canceled successfully');
        onClose();
      } else {
        Notify.error(data?.message ?? '');
        setIsLoading(false);
      }
    } catch (error: any) {
      Notify.error(error.message);
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <Popup
      HeaderContent={<Title variant="h3">Cancel Prebook?</Title>}
      FooterContent={
        <>
          <Button variant="secondary" onClick={onClose}>
            No
          </Button>
          <Button
            isLoading={isLoading}
            variant="primary"
            onClick={onCancelContract}
          >
            Yes, cancel
          </Button>
        </>
      }
      width="439px"
      onClose={onClose}
    >
      <Paragraph>
        Please confirm that you want to cancel this prebook.
      </Paragraph>
    </Popup>
  );
};

export default DeleteContract;
