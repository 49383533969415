import { FC, useEffect } from 'react';
import { init as onfidoInit } from 'onfido-sdk-ui';
import { isMobile } from 'react-device-detect';
import { SignUpContent } from '../../../CompanyRegistration/components';
import { Firebase } from 'services';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'state';
import { Notify } from 'utils';
import { StyledButton } from 'components/shared/Button/Button.styles';

interface OwnProps {
  onContinue: () => void;
  onfidoApplicantId: string | null;
}

const StepThree: FC<OwnProps> = ({ onContinue, onfidoApplicantId }) => {
  const history = useHistory();

  const { entityOnboardingRecord } = useStoreState((state) => state.UserState);
  const { getEntityOnboardingRecord } = useStoreActions(
    (actions) => actions.UserState
  );

  useEffect(() => {
    getEntityOnboardingRecord();
  }, [getEntityOnboardingRecord]);

  useEffect(() => {
    const onfidoTrigger = async (applicantId: any) => {
      if (applicantId) {
        const SDKToken = await Firebase.onfidoGenerateToken({
          applicant_id: applicantId,
        });

        if (SDKToken) {
          onfidoInit({
            useModal: false,
            // @ts-expect-error TS(2339) FIXME: Property 'token' does not exist on type 'IResponse... Remove this comment to see the full error message
            token: SDKToken.token,
            containerId: 'onfido-test',
            onComplete: function () {
              Firebase.updateCompanyEidStatus()
                .then(() => {
                  onContinue();
                })
                .catch(() => {
                  Notify.error('Failed to update company registration status.');
                });
            },
            steps: [
              {
                type: 'welcome',
                options: {
                  title: 'Onboarding - Verification',
                  descriptions: [
                    'In order to prevent fraud and financial crime we need to check your identity through a simple online process.',
                  ],
                  nextButton: 'Verify Your Identity',
                },
              },
              'document',
              'face',
              'complete',
            ],
          });
        }
      }
    };

    if (entityOnboardingRecord || onfidoApplicantId) {
      onfidoTrigger(
        entityOnboardingRecord?.onfidoApplicantId || onfidoApplicantId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityOnboardingRecord, onfidoApplicantId]);

  return (
    <>
      <SignUpContent>
        {!isMobile && (
          <StyledButton
            variant="link"
            className="cross"
            onClick={() => history.push('/app/dashboard')}
          >
            <svg width="24" height="24">
              <use xlinkHref="#cross-ico" />
            </svg>
          </StyledButton>
        )}

        <div className="overflow">
          <div
            className="block"
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              id="onfido-test"
            />
          </div>
        </div>
      </SignUpContent>
    </>
  );
};

export default StepThree;
