import styled, { css } from 'styled-components';
import { Paragraph, TextHint } from 'components/shared/Typography/Typography';
import { Row } from 'components/shared/Row/Row';

export const IntegrationTileWrapper = styled(Row)`
  margin: 0 0 24px 0;
  border-radius: 8px;
  min-height: 48px;
`;

export const IntegrationSvg = styled.svg`
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  margin-right: 12px;
`;

export const IntegrationRow = styled(Row)`
  justify-content: flex-end;

  &:last-of-type {
    margin: 0;
  }
`;

export const IntegrationParagraph = styled(Paragraph)`
  white-space: nowrap;
`;

export const IntegrationTextHint = styled(TextHint)`
  white-space: nowrap;
`;

export const ComingSoon = styled.div(
  ({ theme }) => css`
    padding: 4px 20px;
    background: ${theme.color.greyLight_3};

    border: 1px solid ${theme.color.greyLight_1};
    box-sizing: border-box;
    border-radius: ${theme.borderRadius.xl};

    font-size: 14px;
    line-height: 157%;
    letter-spacing: 0.002em;

    color: ${theme.color.greyDark};
  `
);

export const CSVRow = styled(Row)`
  justify-content: center;
  width: 100%;
`;
