import usePermissionsCheck from 'hooks/usePermissionsCheck';
import { TPermissionActions, TPermissionResources } from 'types/permissions';

interface IOwnProps {
  action: TPermissionActions & {};
  resource: TPermissionResources & {};
}

const PermissionsChecker: React.FC<IOwnProps> = ({
  action,
  resource,
  children,
}) => {
  const hasPermissions = usePermissionsCheck({ action, resource });
  return hasPermissions ? <>{children}</> : null;
};

export default PermissionsChecker;
