import { Title } from 'components';
import ChartPie from 'components/shared/ChartPie/ChartPie';
import { FC } from 'react';
import { animated } from '@react-spring/web';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import {
  parseIntoShortNumberString,
  parseIntoShortNumberWithSymbol,
} from 'utils';
import { CashNeedsCard } from '../CashNeedsCard/CashNeedsCard.styles';
import { ICashByAccountOrEntity } from 'types';

interface IOwnProps {
  data: ICashByAccountOrEntity[];
  currencyCode: string;
}

const CashByEntity: FC<IOwnProps> = ({ data, currencyCode }) => {
  const theme = useTheme();
  const currencyByCode = useStoreState(
    (state) => state.CurrenciesState.currencyByCode
  );
  const entityCurrency = currencyByCode(currencyCode);

  return (
    <CashNeedsCard flex={0.75} alignSelf="stretch" padding={theme.spacing.m}>
      <Title variant="h5">Cash by Entity</Title>

      <ChartPie
        data={data}
        colors={{ datum: 'data.color' }}
        margin={{ right: 20, bottom: 50, left: 20, top: 20 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={theme.color.white}
        arcLabelsComponent={({ style, datum }) => (
          <animated.g
            // @ts-expect-error - TS complains, but it works
            transform={style.transform}
            style={{
              pointerEvents: 'none',
            }}
          >
            <text
              x="0"
              y="0"
              textAnchor="middle"
              dominantBaseline="central"
              fill={style.textColor}
              style={{
                fontSize: 11,
              }}
            >
              {parseIntoShortNumberString(datum.data.value)}
            </text>
            {datum.data.id !== currencyCode && (
              <text
                x="0"
                y="10"
                textAnchor="middle"
                dominantBaseline="central"
                fill={style.textColor}
                style={{
                  fontSize: 11,
                }}
              >
                {parseIntoShortNumberWithSymbol(
                  datum.data.value,
                  entityCurrency?.symbol
                )}
              </text>
            )}
          </animated.g>
        )}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 30,
            itemsSpacing: 0,
            itemWidth: 50,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 10,
            symbolShape: 'circle',
          },
        ]}
      />
    </CashNeedsCard>
  );
};

export default CashByEntity;
