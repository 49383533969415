// Use src/components/shared/HexagonRating/HexagonRating.tsx instead
import Icon from 'components/shared/Icon/Icon';
import { Row } from 'components/shared/Row/Row';
import { FC } from 'react';
import { useTheme } from 'styled-components';

interface OwnProps {
  rating: number;
  size?: string;
  hexagonsCount?: number;
}

const StaleRatingHexagons: FC<OwnProps> = ({
  rating = 0,
  size = '16px',
  hexagonsCount = 5,
}) => {
  const theme = useTheme();

  return (
    <Row justifyContent="flex-start">
      {[...Array(hexagonsCount)].map((_, index) => (
        <Icon
          key={index}
          icon="rate-ico"
          fill={index < rating ? theme.color.dark : 'none'}
          stroke={theme.color.dark}
          width={size}
          height={size}
          style={{ margin: '0 1px' }}
        />
      ))}
    </Row>
  );
};

export default StaleRatingHexagons;
