import { FC } from 'react';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import { Icon, Paragraph, Row, Title } from 'components';
import { useTheme } from 'styled-components';
import { IGroupedInvoicesFromSearch } from 'types';
import Button from 'components/shared/Button/Button';
import useGroupGroupedInvoicesFromSearchByApprovalActions from 'pages/Invoices/components/GroupedInvoicesTable/hooks/useGroupGroupedInvoicesFromSearchByApprovalActions';
import Card from 'components/shared/Card/Card.styles';
import GroupedContactCell from '../GroupedContactCell/GroupedContactCell';
import { useStoreState } from 'state';
import { parseIntoCurrencyString } from 'utils';

interface OwnProps {
  tab: string | null;
  data: IGroupedInvoicesFromSearch;
  onViewDetails: (groupedInvoices: IGroupedInvoicesFromSearch) => void;
  onLoadMoreItems: () => Promise<void>;
  isLoadingMoreItems: boolean;
  hasMoreToLoad: boolean;
  loadingThreshold: number;
}
// TODO: add dynamic loading
const GroupedInvoiceTile: FC<OwnProps> = ({ tab, data, onViewDetails }) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const {
    key: recipientName,
    docsCount: count,
    results: invoices,
    totalAmountDueOrTotal,
  } = data;

  const currencyCode = invoices[0].currency;
  const currency = currencyByCode(currencyCode);

  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();

  const {
    submittable,
    approvable,
  } = useGroupGroupedInvoicesFromSearchByApprovalActions({
    invoices: data,
  });

  return (
    <Card flex={1} flexDirection="column" padding={theme.spacing.m}>
      <GroupedContactCell
        value={recipientName}
        docsCount={count}
        invoices={invoices}
      />

      <Row
        mt
        mtValue={theme.spacing.xs}
        justifyContent="flex-start"
        gap={theme.spacing.xs}
        alignItems="flex-end"
      >
        {!!currency && (
          <Icon mb mbValue={theme.spacing.xs} icon={currency.countryCode} />
        )}

        <Title variant="h2">
          {parseIntoCurrencyString(totalAmountDueOrTotal)}
        </Title>
        <Paragraph mb mbValue={theme.spacing.xxs}>
          {currencyCode}
        </Paragraph>
      </Row>

      <Row mt flex={1} gap={theme.spacing.xs}>
        <Button variant="secondary" onClick={() => onViewDetails(data)}>
          See invoices
        </Button>

        {tab === 'outstanding' && submittable.length > 0 && (
          <Button
            variant="secondary"
            disabled={isUpdatingInvoicesApprovalStatus}
            onClick={(event) => {
              event.stopPropagation();
              updateInvoicesApprovalStatus({
                invoiceIds: submittable,
                approvalStatus: 'submitted',
              });
            }}
          >
            Submit all
          </Button>
        )}

        {tab === 'submitted' && approvable.length > 0 && (
          <Button
            variant="secondary"
            disabled={isUpdatingInvoicesApprovalStatus}
            onClick={(event) => {
              event.stopPropagation();
              updateInvoicesApprovalStatus({
                invoiceIds: approvable,
                approvalStatus: 'approved',
              });
            }}
          >
            Approve all
          </Button>
        )}
      </Row>
    </Card>
  );
};

export default GroupedInvoiceTile;
