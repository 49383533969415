import { FC } from 'react';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form7';
import { Paragraph, Title, Popup, Col, Row } from 'components';
import Button from 'components/shared/Button/Button';
import { TCashflowAtRiskNew } from 'types';
import InputBase from 'components/shared/InputBase/InputBase';
import Field from 'components/shared/Field/Field.styles';
import { DB_MONTH_FORMAT, MONTH_DATE_FORMAT } from 'variables';
import { updateEntityBudgetRates } from 'services/firebase/analysis';
import { notNullTypeGuard } from 'utils';
import { errorHandler } from 'utils/errors';
import useUrlValues from 'hooks/useUrlValues';
import { TRisksDataPeriod } from 'pages/Risks/types';

interface OwnProps {
  onClose: () => void;
  currencyCode: string;
  cashflowsRisksData: TCashflowAtRiskNew[];
  getCashflowsRisksPerCurrency: (
    currencyCode: string,
    period: TRisksDataPeriod
  ) => Promise<void>;
}

type InputValues = Record<string, string | null>;

const BudgetRatePopup: FC<OwnProps> = ({
  onClose,
  currencyCode,
  cashflowsRisksData,
  getCashflowsRisksPerCurrency,
}) => {
  const theme = useTheme();
  const { period } = useUrlValues('period');
  const defaultValues = cashflowsRisksData.reduce(
    (acc, { date, budgetRate }) => ({
      ...acc,
      [date]: budgetRate?.toString() ?? null,
    }),
    {} as InputValues
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<InputValues>({
    mode: 'all',
    defaultValues,
  });

  const onSubmit = async (values: InputValues) => {
    try {
      if (!period) {
        return;
      }

      const validValues = Object.keys(values).reduce<Record<string, string>>(
        (acc, date) => {
          const value = values[date];

          if (notNullTypeGuard(value)) {
            return { ...acc, [date]: value };
          }
          return acc;
        },
        {}
      );

      await Promise.all(
        Object.keys(validValues).map(async (date) => {
          const value = validValues[date];

          if (notNullTypeGuard(value)) {
            await updateEntityBudgetRates({
              date: dayjs(date, 'YYYY-MM-DD').format(DB_MONTH_FORMAT),
              currencyCode,
              budgetRate: Number(value),
            });
          }
        })
      );

      onClose();

      await getCashflowsRisksPerCurrency(
        currencyCode,
        period as TRisksDataPeriod
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Popup
      HeaderContent={<Title variant="h3">Budget rates - {currencyCode}</Title>}
      FooterContent={
        <Row flex={1}>
          <Button
            type="submit"
            form="update-budget-rate"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Row>
      }
      width="439px"
      height="760px"
      onClose={onClose}
    >
      <Paragraph mb>When do you want to send payment(s)?</Paragraph>

      <form id="update-budget-rate" onSubmit={handleSubmit(onSubmit)}>
        <Col gap={theme.spacing.m}>
          {cashflowsRisksData.map(({ date }) => (
            <Field key={date}>
              <Controller
                name={date}
                control={control}
                render={({ field: { value, name, onChange } }) => (
                  <InputBase
                    id={name}
                    label={dayjs(date, DB_MONTH_FORMAT).format(
                      MONTH_DATE_FORMAT
                    )}
                    value={value ?? undefined}
                    onChange={onChange}
                    type="number"
                  />
                )}
              />
            </Field>
          ))}
        </Col>
      </form>
    </Popup>
  );
};

export default BudgetRatePopup;
