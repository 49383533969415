import { FC } from 'react';
import { Paragraph, Row, StaleInfo } from 'components';

interface OwnProps {
  title: string;
  info: string;
}

const InfoTitle: FC<OwnProps> = ({ title, info }) => (
  <Row flex={1.2} justifyContent="flex-start">
    <Paragraph mr>{title}</Paragraph>
    <StaleInfo infoSize="16px" mode="hover">
      <Paragraph color="white">{info}</Paragraph>
    </StaleInfo>
  </Row>
);

export default InfoTitle;
