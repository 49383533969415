import { FC } from 'react';
import { useStoreState } from 'state';
import BalanceRow from 'components/shared/BalanceRow/BalanceRow';
import PrebookedRow from '../PrebookedRow/PrebookedRow';
import ExpectedRow from '../ExpectedRow/ExpectedRow';
import InvoicesRow from '../InvoicesRow/InvoicesRow';
import { AccountsContentWrapper, AccountTile } from './AccountsTiles.styles';
import { IAccountsTableDataItem } from 'types/bff';

interface OwnProps {
  data: IAccountsTableDataItem[];
}

const AccountsTiles: FC<OwnProps> = ({ data }) => {
  const { balances } = useStoreState((state) => state.BalancesState);
  const {
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
  } = useStoreState(({ UserState }) => UserState);
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );

  return (
    <AccountsContentWrapper>
      {data.map(({ outstanding, hedges, externalHedges, currency }) => {
        const { symbol, countryCode, precision } =
          currencyByCode(currency) ?? {};
        const balance =
          balances.find((item) => item.currency === currency)?.amount || 0;

        return (
          <AccountTile key={currency} flex={1}>
            <BalanceRow
              currencyCode={currency}
              currencySymbol={symbol}
              currencyCountryCode={countryCode}
              balance={balance}
              currencyPrecision={precision}
              hasBalance={!!balance}
            />
            <ExpectedRow
              amount={outstanding.value}
              currencyCode={currency}
              currencySymbol={symbol}
            />
            {!isAutomationPackageEnabled && !isFxManagementPackageEnabled && (
              <PrebookedRow
                currencyCode={currency}
                currencySymbol={symbol}
                prebookedAndHedged={hedges + externalHedges}
                withPrebookingsButton={!!(hedges + externalHedges)}
              />
            )}
            {isAutomationPackageEnabled && (
              <InvoicesRow currencySymbol={symbol} currencyCode={currency} />
            )}
          </AccountTile>
        );
      })}
    </AccountsContentWrapper>
  );
};

export default AccountsTiles;
