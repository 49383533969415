import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

const typeHorizontal = () => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a,
  button {
    flex: 0 0 48%;
    max-width: 48%;
    margin: 0 !important;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

/** @deprecated Use Row instead */
const StaleBtnGroup = styled.div<{
  container?: boolean;
  horizontal?: boolean;
}>(
  ({ theme, container = true, horizontal = false }) => css`
    position: relative;
    margin-top: ${!container && 'auto'};
    text-align: center;
    color: ${theme.color.greyDark};
    background: ${container && theme.color.white};
    ${horizontal && typeHorizontal};

    ${container &&
    (isMobile
      ? css`
          box-shadow: ${theme.shadow};
          padding: 16px;
        `
      : css`
          padding: 40px 0 38px;
        `)}
  `
);

export default StaleBtnGroup;
