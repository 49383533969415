import { IEntity } from 'state/user';
import { TInputs } from './ApprovalSettings';

const mapFormListsOnEntityForSelect = (list?: { id: string; name: string }[]) =>
  list?.map((item) => ({ label: item.name, value: item.id }));

export const getFormDefaultValues = ({
  userEntity,
}: {
  userEntity: IEntity;
}) => ({
  // level 1
  autoApproveEnabled: userEntity.approvalSettings?.autoApproveEnabled || false,
  autoApproveAmount: userEntity.approvalSettings?.autoApproveAmount ?? 1000,
  autoApproveRecipientList:
    mapFormListsOnEntityForSelect(
      userEntity.approvalSettings?.autoApproveRecipientList
    ) ?? [],
  // level 2
  approver1List:
    mapFormListsOnEntityForSelect(userEntity.approvalSettings?.approver1List) ??
    [],
  twoApprovers: userEntity.approvalSettings?.requiredNumberOfApprovals === 2,
  approver2List:
    mapFormListsOnEntityForSelect(userEntity.approvalSettings?.approver2List) ??
    [],
  // level 3
  exceptionalApprovalEnabled: !!userEntity.approvalSettings
    ?.exceptionalApprovalEnabled,
  exceptionalAmount: userEntity.approvalSettings?.exceptionalAmount ?? 10000,
  exceptionalRecipientList:
    mapFormListsOnEntityForSelect(
      userEntity.approvalSettings?.exceptionalRecipientList
    ) ?? [],
  exceptionalApproverList:
    mapFormListsOnEntityForSelect(
      userEntity.approvalSettings?.exceptionalApproverList
    ) ?? [],
});

export const getValuesFromInputs = (values: TInputs) => {
  const autoApproveAmount = Number(values.autoApproveAmount);
  const exceptionalAmount = Number(values.exceptionalAmount);
  const requiredNumberOfApprovals = !!values.twoApprovers ? 2 : 1;

  const autoApproveRecipientList = values.autoApproveEnabled
    ? values.autoApproveRecipientList?.map((recipient) => ({
        id: recipient.value,
        name: recipient.label,
      }))
    : [];
  const approver1List =
    values.approver1List?.map((approver) => ({
      id: approver.value,
      name: approver.label,
    })) ?? [];
  const approver2List = requiredNumberOfApprovals
    ? values.approver2List?.map((approver) => ({
        id: approver.value,
        name: approver.label,
      }))
    : [];
  const exceptionalRecipientList = values.exceptionalApprovalEnabled
    ? values.exceptionalRecipientList?.map((recipient) => ({
        id: recipient.value,
        name: recipient.label,
      }))
    : [];
  const exceptionalApproverList = values.exceptionalApprovalEnabled
    ? values.exceptionalApproverList?.map((approver) => ({
        id: approver.value,
        name: approver.label,
      }))
    : [];

  return {
    requiredNumberOfApprovals,
    autoApproveAmount,
    exceptionalAmount,
    autoApproveRecipientList,
    approver1List,
    approver2List,
    exceptionalRecipientList,
    exceptionalApproverList,
  };
};
