import styled from 'styled-components';
import { Row } from 'components/shared/Row/Row';
import Card from 'components/shared/Card/Card.styles';

export const StyledRow = styled(Row)`
  column-gap: ${({ theme }) => theme.spacing.m};
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.m}`};
`;

export const StyledCard = styled(Card)`
  padding: 0;
  align-self: stretch;
  justify-content: space-between;
  min-width: ${({ theme }) => theme.cardSizes.xxs};
`;
