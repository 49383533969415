import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import {
  NetWorthSectionExpansionContent,
  NetWorthSectionMainContent,
  NetWorthSectionWrapper,
  NetWorthSectionWrapperProps,
} from './NetWorthSection.styles';

interface OwnProps extends Pick<NetWorthSectionWrapperProps, 'zIndex'> {
  expansionContent?: ReactNode;
  renderContent: (
    setIsExpanded: Dispatch<SetStateAction<boolean>>,
    isExpanded: boolean
  ) => ReactNode;
}

const NetWorthSection: FC<OwnProps> = ({
  expansionContent,
  zIndex,
  renderContent,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <NetWorthSectionWrapper isExpanded={isExpanded} zIndex={zIndex}>
      <NetWorthSectionMainContent>
        {renderContent(setIsExpanded, isExpanded)}
      </NetWorthSectionMainContent>
      {!!expansionContent && (
        <NetWorthSectionExpansionContent>
          {expansionContent}
        </NetWorthSectionExpansionContent>
      )}
    </NetWorthSectionWrapper>
  );
};

export default NetWorthSection;
