import { ChangeEvent, ReactNode, useEffect, useState } from 'react';

import { OptionsWrapper, OptionWrapper, Wrapper } from './InputSelect.styles';
import StaleInputNew, { InputNewProps } from '../StaleInput/StaleInputNew';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDebounce } from 'hooks';
import { Nullable } from 'types';

interface OwnProps<T> {
  inputProps: InputNewProps;
  data: T[];
  selected: Nullable<T>;
  selectedValueLabel?: string;
  labelKey?: keyof T;
  withSearch?: boolean;
  onSelect?: (selected: T) => unknown;
  onSearch?: (searchValue: string) => unknown;
  renderOption?: (item: T) => ReactNode;
  disabled?: boolean;
  error?: string;
}

const InputSelect = <T extends { id: string }>({
  inputProps,
  data,
  selected,
  withSearch,
  selectedValueLabel,
  labelKey,
  onSelect,
  onSearch,
  renderOption,
  disabled,
  error,
}: OwnProps<T>) => {
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const debounceSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    onSearch?.(debounceSearchValue);
  }, [debounceSearchValue, onSearch]);

  const onFocus = () => {
    setFocused(true);
    setOpen(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const getInputValue = () => {
    if (withSearch && focused) {
      return searchValue;
    }

    if (selected && selectedValueLabel) {
      return selectedValueLabel;
    }

    return '';
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onSelectHandler = (item: any) => {
    onSelect?.(item);

    setOpen(false);

    if (searchValue) {
      setSearchValue('');
    }
  };

  const onClickOutside = () => {
    setFocused(false);
    setOpen(false);
    setSearchValue('');
  };

  const handleKeyDown = (item: any) => (e: any) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();

        onSelectHandler(item);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <Wrapper>
      <StaleInputNew
        {...inputProps}
        type={withSearch ? 'text' : 'button'}
        value={getInputValue()}
        onChange={withSearch ? onChange : undefined}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={handleListKeyDown}
        disabled={disabled}
        error={error}
      />

      {open && (
        <OutsideClickHandler onOutsideClick={onClickOutside}>
          <OptionsWrapper onKeyDown={handleListKeyDown}>
            {data.map((item) => (
              <OptionWrapper
                key={item.id}
                onClick={() => onSelectHandler(item)}
                onKeyDown={handleKeyDown(item)}
              >
                {renderOption?.(item) ?? (labelKey ? item[labelKey] : null)}
              </OptionWrapper>
            ))}
          </OptionsWrapper>
        </OutsideClickHandler>
      )}
    </Wrapper>
  );
};

export default InputSelect;
