import { Row, Loader } from 'components';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import { displayChatWidget } from './utils';

const Auth: FC = ({ children }) => {
  const history = useHistory();
  const { isAuthLoading, userId, entityId, userEmail } = useStoreState(
    ({ UserState }) => UserState
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthLoading && (userId || entityId)) {
      const handleAuth = async () => {
        setIsLoading(false);

        await displayChatWidget(userEmail);
      };

      handleAuth();
    }

    if (!isAuthLoading && !userId && !entityId) {
      history.push('/login', {
        from: {
          pathname: `${history.location.pathname}${
            history.location.search ?? ''
          }`,
        },
      });
    }
  }, [entityId, history, isAuthLoading, userId, userEmail]);

  return (
    <>
      {isAuthLoading || isLoading ? (
        <Row justifyContent="center" alignSelf="stretch" flex={1}>
          <Loader size="large" />
        </Row>
      ) : (
        children
      )}
    </>
  );
};

export default Auth;
