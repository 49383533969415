export const RADIO_DATA = [
  {
    id: '1',
    value: 'paymentToMake',
    name: 'Payment to make',
  },
  {
    id: '2',
    value: 'receiptToCollect',
    name: 'Receipt to collect',
  },
] as const;
