import { Dispatch, FC, SetStateAction } from 'react';
import { Controller, Control, UseFormMethods } from 'react-hook-form';
import {
  IAccountData,
  IIntegrationSettingsAccount,
  isXeroPlatform,
  TPlatformNames,
} from 'types';
import { generateIntegrationEngineBankFeesAccountData } from 'utils/integrations';
import Button from '../Button/Button';
import { Col } from '../Col/Col';
import Field from '../Field/Field.styles';
import { Row } from '../Row/Row';
import StaleInputSelect, {
  ISelectItem,
} from '../StaleInputSelect/StaleInputSelect';

interface IOwnProps {
  setFormBankTransferAccounts: Dispatch<SetStateAction<string[]>>;
  formBankTransferAccounts: string[];
  bankTransferAccounts?: Record<string, IAccountData[]>;
  currenciesSelectData: ISelectItem[];
  control: Control;
  watch: UseFormMethods['watch'];
  errors: any;
  bankTransfersPermission: boolean;
  isRadioButton3Enabled: boolean;
  isAddBankTransferAccountButtonEnabled: boolean;
  platformName: TPlatformNames;
}

const BankTransferAccountsFields: FC<IOwnProps> = ({
  setFormBankTransferAccounts,
  formBankTransferAccounts,
  bankTransferAccounts,
  currenciesSelectData,
  control,
  watch,
  errors,
  bankTransfersPermission,
  isRadioButton3Enabled,
  isAddBankTransferAccountButtonEnabled,
  platformName,
}) => {
  const formBankTransferAccountsWatch = watch('formBankTransferAccounts');

  const getSelectedBankTransferAccount = (
    value: IIntegrationSettingsAccount
  ) => {
    if (!value) {
      return null;
    }

    if (isXeroPlatform(platformName)) {
      return generateIntegrationEngineBankFeesAccountData(value, value.code);
    }

    return generateIntegrationEngineBankFeesAccountData(value, value.id);
  };

  return (
    <Col>
      {formBankTransferAccounts.length > 0 && (
        <>
          {formBankTransferAccounts.map((accountRowID) => {
            let bankAccountsData: IAccountData[] = [];

            const isCurrencySelected = !!formBankTransferAccountsWatch?.[
              accountRowID
            ]?.currency;

            const selectedCurrency =
              formBankTransferAccountsWatch?.[accountRowID]?.currency;

            if (selectedCurrency) {
              const filteredBankAccounts =
                bankTransferAccounts?.[selectedCurrency];

              if (filteredBankAccounts) {
                bankAccountsData = filteredBankAccounts;
              }
            }

            return (
              <Row key={accountRowID} mb alignItems="flex-start">
                <Field flex="unset" flexDirection="column">
                  <Controller
                    name={`formBankTransferAccounts.${accountRowID}.currency`}
                    control={control}
                    defaultValue={null}
                    render={({ onChange, value, name }) => (
                      <StaleInputSelect
                        id={name}
                        data={currenciesSelectData}
                        label={isCurrencySelected ? undefined : 'CCY'}
                        view="moving"
                        selected={value}
                        onSelect={(item) => onChange(item.value)}
                        style={{ minWidth: '132px' }}
                      />
                    )}
                  />
                </Field>

                <Field ml fluid flexDirection="column">
                  <Controller
                    name={`formBankTransferAccounts.${accountRowID}.account`}
                    control={control}
                    disabled={!isCurrencySelected}
                    defaultValue={null}
                    rules={{
                      validate: (value: IAccountData | null) => {
                        if (bankTransfersPermission && !value) {
                          return `Choose account for transfers`;
                        }
                        return true;
                      },
                    }}
                    render={({ onChange, value, name }) => (
                      <StaleInputSelect
                        id={name}
                        name={name}
                        label={
                          isCurrencySelected
                            ? value
                              ? undefined
                              : `Please select ${selectedCurrency} account`
                            : 'Please select CCY'
                        }
                        view="moving"
                        data={bankAccountsData}
                        selected={getSelectedBankTransferAccount(value)}
                        onSelect={(item) => onChange(item.value)}
                        strategy="fixed"
                        error={
                          errors?.formBankTransferAccounts?.[accountRowID]
                            ?.account?.message
                        }
                        style={{ width: '100%' }}
                        disabled={!isCurrencySelected}
                      />
                    )}
                  />
                </Field>

                <Button
                  mt
                  variant="link"
                  disabled={!isRadioButton3Enabled}
                  onClick={() =>
                    setFormBankTransferAccounts((prevState) =>
                      prevState.filter((el) => el !== accountRowID)
                    )
                  }
                  icon="remove-ico"
                />
              </Row>
            );
          })}
        </>
      )}

      <Button
        variant="link"
        onClick={(e) => {
          e.preventDefault();
          setFormBankTransferAccounts((prevState) => [
            ...prevState,
            `bankAccountRow_${prevState.length + 1}`,
          ]);
        }}
        icon="round-plus-ico"
        disabled={!isAddBankTransferAccountButtonEnabled}
      >
        Add account
      </Button>
    </Col>
  );
};

export default BankTransferAccountsFields;
