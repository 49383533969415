import { useStoreState } from 'state';
import { TPermissionActions, TPermissionResources } from 'types';

type TOwnProps = {
  action: TPermissionActions & {};
  resource: TPermissionResources & {};
};

const usePermissionsCheck = ({ action, resource }: TOwnProps) => {
  const { permissions } = useStoreState(({ UserState }) => UserState);

  return permissions.includes(`${resource}:${action}`);
};

export default usePermissionsCheck;
