import { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from 'styled-components';

import {
  StaleBtnGroup,
  Title,
  StaleRadioButton,
  StaleInputFile,
  Paragraph,
  Row,
} from 'components';
import { SignUpContent } from '../../../CompanyRegistration/components';
import { isMobile } from 'react-device-detect';
import { Firebase } from 'services';
import { useStoreActions, useStoreState } from 'state';
import { asyncForEach, Notify } from 'utils';
import Button from 'components/shared/Button/Button';
import Link from 'components/shared/Link/Link';
import { StyledButton } from 'components/shared/Button/Button.styles';

type Inputs = {
  files: {
    id: string;
    file: File;
  }[];
};

type DownloadFileInfo = {
  id: string;
  name: string;
  downloadUrl: string;
};

interface OwnProps {
  stepOneValues: any;
  stepTwoValues: any;
}

const StepFour: FC<OwnProps> = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrls, setDownloadUrls] = useState<DownloadFileInfo[]>([]);
  const [uploadingFilesIds, setUploadingFilesIds] = useState<string[]>([]);
  const [failedUploadFilesIds, setFailedUploadFilesIds] = useState<string[]>(
    []
  );

  const { userId, entityId } = useStoreState((state) => state.UserState);
  const { getUserEntity } = useStoreActions((actions) => actions.UserState);
  const { control, handleSubmit, watch, setValue } = useForm<Inputs>();

  const canProvideFiles = watch('canProvideFiles');
  const canReceivePayments = watch('canReceivePayments');
  const files = watch('files');
  const isCanProvideFilesAnswered = canProvideFiles !== undefined;

  useEffect(() => {
    const filesToUpload = files?.filter(
      (item) =>
        !downloadUrls.find(
          (uploadedItem) =>
            uploadedItem.id === item.id ||
            uploadedItem.downloadUrl.includes(item.file.name)
        )
    );

    if (userId && filesToUpload?.length) {
      const uploadFiles = async (filesToUpload: any) => {
        const urlsToDownload: DownloadFileInfo[] = [];

        setUploadingFilesIds(filesToUpload.map((item: any) => item.id));

        await asyncForEach(filesToUpload, async (itemFile: any) => {
          try {
            if (!entityId) {
              return;
            }

            const data = await Firebase.uploadCompanyFile({
              userId,
              entityId,
              fileToUpload: itemFile.file,
            });

            if (data) {
              urlsToDownload.push({
                id: itemFile.id,
                name: itemFile.file.name,
                downloadUrl: data,
              });

              setFailedUploadFilesIds((prevState) =>
                prevState.filter((item) => item !== itemFile.id)
              );
              setUploadingFilesIds((prevState) =>
                prevState.filter((item) => item !== itemFile.id)
              );
            }
          } catch (error: any) {
            setFailedUploadFilesIds((prevState) => [...prevState, itemFile.id]);
          }
        });

        setDownloadUrls((prevState) => [...prevState, ...urlsToDownload]);
      };

      uploadFiles(filesToUpload);
    }
  }, [files, userId, entityId, downloadUrls]);

  const onRemoveFile = async (file: any) => {
    if (!userId || !entityId) return;

    await Firebase.removeCompanyFile({
      userId,
      entityId,
      filename: file.file.name,
    });

    setValue(
      'files',
      files.filter((item) => item.id !== file.id)
    );

    setDownloadUrls((prevState) =>
      prevState.filter((item) => item.id !== file.id)
    );
  };

  const onSubmit = async (values: any) => {
    if (!entityId) {
      return;
    }

    setIsLoading(true);

    const response = await Firebase.updateCompanyFilesStatus({
      invoicesAndReceivesPayments: values.canReceivePayments,
      canProvideInvoicesAndBankStatements: values.canProvideFiles,
      fileDetails: downloadUrls.map((item) => ({
        name: item.name,
        location: item.downloadUrl,
      })),
    });

    setIsLoading(false);

    if (response?.success) {
      await getUserEntity({
        entityId,
      });
    } else {
      Notify.error(response?.message ?? '');
    }
  };

  return (
    <>
      <SignUpContent>
        {!isMobile && (
          <StyledButton variant="link" className="cross">
            <svg width="24" height="24">
              <use xlinkHref="#cross-ico" />
            </svg>
          </StyledButton>
        )}

        <div className="overflow">
          <div className="block">
            {isMobile ? (
              <Title mb variant="h5">
                Please answer questions below
              </Title>
            ) : (
              <Title mb>Please answer questions below</Title>
            )}

            <form>
              <div className="quiz">
                <Paragraph>
                  We are required to check that payments you plan to make or
                  collect come from legitimate sources and commercial purposes
                  only. Please answer questions below: asdas
                </Paragraph>
                <br />
                <Paragraph>
                  Do you invoice your customers and receive payments for goods
                  or services in the UK or abroad?
                </Paragraph>

                <Row mtValue={theme.spacing.xs} mt>
                  <Controller
                    name="canReceivePayments"
                    control={control}
                    defaultValue={null}
                    render={({ name, onChange }) => (
                      <StaleRadioButton
                        onChange={(item: any) => onChange(item.value)}
                        list={[
                          {
                            id: 'invoice-yes',
                            value: true,
                            checked: !!canReceivePayments,
                            name: <p>Yes</p>,
                          },
                          {
                            id: 'invoice-no',
                            value: false,
                            checked: canReceivePayments === false,
                            name: <p>No</p>,
                          },
                        ]}
                        name={name}
                        cardCol
                      />
                    )}
                  />
                </Row>
              </div>

              <div className="quiz">
                <Paragraph>
                  Can you provide a copy of a recent invoice to a customer and a
                  bank statement showing the receipt of payment for such
                  invoice?
                </Paragraph>

                <Row mtValue={theme.spacing.xs} mt>
                  <Controller
                    name="canProvideFiles"
                    control={control}
                    defaultValue={null}
                    render={({ name, onChange }) => (
                      <StaleRadioButton
                        name={name}
                        onChange={(item: any) => onChange(item.value)}
                        list={[
                          {
                            id: 'provide-yes',
                            value: true,
                            checked: !!canProvideFiles,
                            name: <p>Yes</p>,
                          },
                          {
                            id: 'provide-no',
                            value: false,
                            checked: canProvideFiles === false,
                            name: <p>No</p>,
                          },
                        ]}
                        cardCol
                      />
                    )}
                  />
                </Row>
              </div>

              {canProvideFiles && (
                <div className="quiz">
                  <Paragraph>
                    Please upload Recent Invoice and Bank Statement{' '}
                  </Paragraph>

                  <Paragraph
                    style={{
                      fontStyle: 'italic',
                      fontSize: 12,
                    }}
                  >
                    (Allowed file types are PDF, JPG, JPEG, PNG, XLS, XLSX, DOC,
                    DOCX, CSV, ODS and ODT)
                  </Paragraph>

                  <div className="field">
                    <Controller
                      control={control}
                      name="files"
                      defaultValue={[]}
                      render={({ onChange, value }) => (
                        <StaleInputFile
                          accept=".pdf, .jpg, .jpeg, .png, .xls, .xlsx, .doc, .docx, .ods, .odt, .csv"
                          uploadingFilesIds={uploadingFilesIds}
                          failedUploadFilesIds={failedUploadFilesIds}
                          files={value}
                          onChange={(values) => {
                            const newValues = values.filter(
                              (item: any) =>
                                !downloadUrls.find((existingItem) =>
                                  existingItem.downloadUrl.includes(
                                    item.file.name
                                  )
                                )
                            );

                            onChange([...newValues, ...value]);
                          }}
                          onRemoveFile={onRemoveFile}
                        />
                      )}
                    />
                  </div>
                </div>
              )}

              {isCanProvideFilesAnswered && !canProvideFiles && (
                <Paragraph mt error>
                  We require the above-mentioned documents to complete the
                  onboarding review. Please send us this information to{' '}
                  <Link
                    href="mailto:support@hedgeflows.com"
                    target="_blank"
                    rel="noreferrer"
                    display="inline"
                  >
                    support@hedgeflows.com
                  </Link>{' '}
                  if you are unable to upload this information at this moment.
                </Paragraph>
              )}
            </form>

            {!isMobile && (
              <StaleBtnGroup>
                <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
                  Continue
                  {isLoading && (
                    <svg width="16px" height="16px" className="loader">
                      <use xlinkHref="#loader-ico" />
                    </svg>
                  )}
                </Button>
              </StaleBtnGroup>
            )}
          </div>
        </div>
      </SignUpContent>

      {isMobile && (
        <StaleBtnGroup>
          <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
            Continue
            {isLoading && (
              <svg width="16px" height="16px" className="loader">
                <use xlinkHref="#loader-ico" />
              </svg>
            )}
          </Button>
        </StaleBtnGroup>
      )}
    </>
  );
};

export default StepFour;
