import { useCallback, useEffect, useState } from 'react';
import { createAndOpenDownloadLinkToInMemoryFileData } from 'utils';
import { useStoreState } from 'state';
import {
  getPaymentRunInvoicesAsCSVFile,
  subscribeToDraftPaymentRun,
} from 'services/paymentRuns';
import { errorHandler } from 'utils/errors';
import { IPaymentRun } from 'types/paymentRuns';

const useDraftPaymentRun = () => {
  const entityId = useStoreState(({ UserState }) => UserState.entityId);
  const [draftPaymentRun, setDraftPaymentRun] = useState<IPaymentRun>();
  const [
    isLoadingPaymentRunInvoicesCSV,
    setIsLoadingPaymentRunInvoicesCSV,
  ] = useState(false);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (entityId) {
      unsubscribe = subscribeToDraftPaymentRun({
        entityId,
        callback: (paymentRun) => {
          setDraftPaymentRun(paymentRun);
        },
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [entityId]);

  const getPaymentRunInvoicesAsCsv = useCallback(async () => {
    if (draftPaymentRun) {
      try {
        setIsLoadingPaymentRunInvoicesCSV(true);
        const response = await getPaymentRunInvoicesAsCSVFile(
          draftPaymentRun.id
        );
        createAndOpenDownloadLinkToInMemoryFileData(
          response.data,
          `payment_run_${draftPaymentRun.id}.csv`,
          'text/csv'
        );
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingPaymentRunInvoicesCSV(false);
      }
    }
  }, [draftPaymentRun]);

  return {
    paymentRun: draftPaymentRun,
    getPaymentRunInvoicesAsCsv,
    isLoadingPaymentRunInvoicesCSV,
    paymentRunInvoicesQuantity:
      draftPaymentRun?.instructions.invoiceIds.length ?? 0,
  };
};

export default useDraftPaymentRun;
