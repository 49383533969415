import { FC, useState, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';

import {
  StaleBtnGroup,
  StaleInput,
  StaleInputCurrency,
  Title,
  StaleSelectMenu,
  Overflow,
} from 'components';
import { SignUpContent } from '..';
import { ICountry, TBusinessNatureUnion } from 'types';
import { useStoreActions, useStoreState } from 'state';
import { useHistory } from 'react-router-dom';
import orderBy from 'lodash.orderby';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';
import { StyledButton } from 'components/shared/Button/Button.styles';

type Inputs = {
  businessNature: TBusinessNatureUnion;
  expectedAnnualTurnover: string;
  // TODO: correct type
  expectedForeignCurrencyTurnover: string;
  countries: ICountry[];
  accountPurpose: string;
  otherNatureOfBusinessLabel?: string;
};

interface OwnProps {
  onSaveValues: (values: any) => void;
  onContinue: () => void;
  savedValues: any;
}

const StepOne: FC<OwnProps> = ({ onSaveValues, onContinue, savedValues }) => {
  const history = useHistory();

  const [businessSearchValue, setBusinessSearchValue] = useState('');
  const [countriesSearchValue, setCountriesSearchValue] = useState('');
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { countries, naturesOfBusiness } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { getCountries, getNaturesOfBusiness } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );

  const entityCurrencySymbol = currencyByCode(entityCurrencyCode)?.symbol;
  const parsedCountries = useMemo(
    () =>
      orderBy(
        Object.values(countries).map((item) => ({
          value: item,
          label: item.name,
          icon: item.alpha2,
        })),
        'label'
      ),
    [countries]
  );

  useEffect(() => {
    getCountries();
    getNaturesOfBusiness();
  }, [getCountries, getNaturesOfBusiness]);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
  } = useForm<Inputs>({
    mode: 'onChange',
  });

  const businessNatureWatch = watch('businessNature');
  const isBusinessNatureOther = businessNatureWatch?.code === 'other';
  const monthlyTurnoverOptions = [
    {
      value: {
        expectedFxTurnoverFrom: null,
        expectedFxTurnoverUpperLimit: 25000,
      },
      label: `Less than ${entityCurrencySymbol} 25K`,
    },
    {
      value: {
        expectedFxTurnoverFrom: 25001,
        expectedFxTurnoverUpperLimit: 100000,
      },
      label: `${entityCurrencySymbol} 25K - 100K`,
    },
    {
      value: {
        expectedFxTurnoverFrom: 100001,
        expectedFxTurnoverUpperLimit: 250000,
      },
      label: `${entityCurrencySymbol} 100K - 250K`,
    },
    {
      value: {
        expectedFxTurnoverFrom: 250001,
        expectedFxTurnoverUpperLimit: 500000,
      },
      label: `${entityCurrencySymbol} 250K - 500K`,
    },
    {
      value: {
        expectedFxTurnoverFrom: 500001,
        expectedFxTurnoverUpperLimit: 1000000,
      },
      label: `${entityCurrencySymbol} 500K - 1M`,
    },
    {
      value: {
        expectedFxTurnoverFrom: 1000001,
        expectedFxTurnoverUpperLimit: 2000000,
      },
      label: `Over ${entityCurrencySymbol} 1M`,
    },
  ];

  const onSubmit = (values: any) => {
    onSaveValues(values);
    onContinue();
  };

  const onError = () => {
    // TODO: discuss how to handle errors
  };

  return (
    <>
      <SignUpContent>
        {!isMobile && (
          <StyledButton
            variant="link"
            className="cross"
            onClick={() => history.push('/app/dashboard')}
          >
            <svg width="24" height="24">
              <use xlinkHref="#cross-ico" />
            </svg>
          </StyledButton>
        )}

        <Overflow>
          <div className="block">
            {isMobile ? (
              <Title variant="h5">Please fill your business info</Title>
            ) : (
              <Title>Please fill your company information </Title>
            )}

            <form
              id="company-registration-step-one-form"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <div className="field">
                <Controller
                  control={control}
                  name="businessNature"
                  defaultValue={savedValues.businessNature}
                  rules={{ required: ERROR_MESSAGES.requiredField }}
                  render={({ onChange, value, name }) => {
                    return (
                      <StaleSelectMenu
                        id="business nature"
                        name={name}
                        label="Nature of business"
                        isMulti={false}
                        data={naturesOfBusiness}
                        value={value}
                        onChange={onChange}
                        // TODO: add debounce for search
                        inputValue={businessSearchValue}
                        onInputChange={setBusinessSearchValue}
                        autoFocus
                      />
                    );
                  }}
                />
              </div>

              {isBusinessNatureOther && (
                <div className="field">
                  <StaleInput
                    id="otherNatureOfBusinessLabel"
                    label="Your nature of business"
                    view="moving"
                    type="text"
                    name="otherNatureOfBusinessLabel"
                    defaultValue={savedValues.otherNatureOfBusinessLabel}
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                    }}
                  />
                </div>
              )}

              <div className="field">
                <StaleInputCurrency
                  id="expected-annual-turnover"
                  label="Expected Annual Turnover"
                  view="moving"
                  type="text"
                  name="expectedAnnualTurnover"
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  control={control}
                  defaultValue={savedValues.expectedAnnualTurnover}
                  currencyPrefix={entityCurrencyCode}
                />
              </div>

              <div className="field">
                <Controller
                  control={control}
                  name="expectedMonthlyForeignCurrencyTurnover"
                  defaultValue={
                    savedValues.expectedMonthlyForeignCurrencyTurnover
                  }
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  render={({ onChange, value, name }) => {
                    return (
                      <StaleSelectMenu
                        id="expected-foreign-currency-turnover"
                        label="Expected Monthly Foreign Currency Turnover"
                        name={name}
                        isMulti={false}
                        data={monthlyTurnoverOptions}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>

              <div className="field">
                <Controller
                  control={control}
                  name="countries"
                  defaultValue={savedValues.countries}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  render={({ onChange, value, name }) => {
                    return (
                      <StaleSelectMenu
                        id="countries"
                        label="Countries you'll be sending money to"
                        name={name}
                        isMulti={true}
                        data={parsedCountries}
                        value={value}
                        onChange={(value) => {
                          if (!value.length) {
                            onChange(null);
                          } else {
                            onChange(value);
                          }
                        }}
                        // TODO: add debounce for search
                        inputValue={countriesSearchValue}
                        onInputChange={setCountriesSearchValue}
                      />
                    );
                  }}
                />
              </div>

              <div className="field">
                <StaleInput
                  id="account-purpose"
                  label="Purpose of Account"
                  view="moving"
                  type="text"
                  name="accountPurpose"
                  defaultValue={savedValues.accountPurpose}
                  control={control}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                />
              </div>
            </form>

            {!isMobile && (
              <StaleBtnGroup>
                <Button
                  disabled={!isValid}
                  form="company-registration-step-one-form"
                >
                  Continue
                </Button>
              </StaleBtnGroup>
            )}
          </div>
        </Overflow>
      </SignUpContent>

      {isMobile && (
        <StaleBtnGroup>
          <Button disabled={!isValid} form="company-registration-step-one-form">
            Continue
          </Button>
        </StaleBtnGroup>
      )}
    </>
  );
};

export default StepOne;
