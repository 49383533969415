import { FC } from 'react';
import { components } from 'react-select';

const SingleValue: FC<any> = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {props.data.icon && (
      <svg width="24px" height="24px">
        <use xlinkHref={`#${props.data.icon}`} />
      </svg>
    )}
    {children}
  </components.SingleValue>
);

export default SingleValue;
