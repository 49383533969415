import styled, { CSSProperties } from 'styled-components';

type TStyledFormProps = Pick<
  CSSProperties,
  | 'flexDirection'
  | 'justifyContent'
  | 'alignItems'
  | 'gap'
  | 'flexWrap'
  | 'flex'
  | 'alignSelf'
>;

export const StyledForm = styled.form<TStyledFormProps>`
  display: flex;
  flex-direction: ${({ flexDirection = 'column' }) => flexDirection};
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  align-items: ${({ alignItems = 'flex-start' }) => alignItems};
  flex-wrap: ${({ flexWrap = 'nowrap' }) => flexWrap};
  gap: ${({ gap = '0' }) => gap};
  flex: ${({ flex = '0 1 auto' }) => flex};
  align-self: ${({ alignSelf = 'auto' }) => alignSelf};
`;
