import styled from 'styled-components';
import { Flex } from '../Flex/Flex.styles';

export const InnerDetailsWrapper = styled(Flex)`
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoint.mediumMin}px) {
    flex-direction: row;
  }
`;
