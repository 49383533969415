import { ICountry, IEntityDetails, Nullable, IContact } from 'types';

interface IsContactCountryInCountriesSendingMoneyToProps {
  entityKnownCountries?: IEntityDetails['countriesSendingMoneyTo'];
  recipientCountry?: ICountry;
  recipientForEdit: Nullable<Partial<IContact>>;
}

export const isContactCountrySuspiciousCheck = ({
  entityKnownCountries,
  recipientCountry,
  recipientForEdit,
}: IsContactCountryInCountriesSendingMoneyToProps) => {
  if (!recipientCountry) {
    return false;
  }

  if (
    recipientForEdit &&
    (!recipientForEdit.recipientCountry ||
      recipientForEdit.recipientCountry === recipientCountry.alpha2)
  ) {
    return false;
  }

  return (
    entityKnownCountries &&
    !entityKnownCountries.includes(recipientCountry.alpha2)
  );
};
