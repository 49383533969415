import { FC, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'hooks';
import { SliderWrapper } from './RiskSettings.styles';
import { RISK_APPETITE_SLIDER_DATA } from 'variables';
import StepOne from './components/StepOne/StepOne';
import StepTwo from './components/StepTwo/StepTwo';
import StepThree from './components/StepThree/StepThree';
import StepFour from './components/StepFour/StepFour';
import StepFive from './components/StepFive/StepFive';
import { Paragraph, StaleCarousel, Loader } from 'components';
import TwoSidesTemplate from 'components/pageTemplates/TwoSidesTemplate/TwoSidesTemplate';
import HorizontalSteps from 'components/shared/HorizontalSteps/HorizontalSteps';
import { GradientTitle } from 'components/shared/GradientTitle/GradientTitle.styles';
import { getRiskSettingsPageLink } from 'utils/links';

const RiskSettings: FC<RouteComponentProps> = ({ history }) => {
  const URLQuery = useQuery();
  const activeStep = URLQuery.get('step');

  const goToStep = useCallback(
    (step: string) => history.push(getRiskSettingsPageLink({ step })),
    [history]
  );

  const renderContent = (step: string) => {
    switch (parseInt(step)) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo />;
      case 3:
        return <StepThree />;
      case 4:
        return <StepFour />;
      case 5:
        return <StepFive />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!activeStep) {
      goToStep('1');
    }
  }, [activeStep, goToStep]);

  if (!activeStep) {
    return <Loader size="large" />;
  }

  return (
    <TwoSidesTemplate
      onClose={() => history.push('/app/dashboard')}
      leftColumn={
        <>
          <HorizontalSteps
            activeStep={parseInt(activeStep)}
            data={[
              {
                title: 'Risk tolerance',
                onClick: () => goToStep('1'),
              },
              {
                title: 'Committed Cashflows',
                onClick: () => goToStep('2'),
              },
              {
                title: 'Future Cashflows',
                onClick: () => goToStep('3'),
              },
            ]}
          />
          <GradientTitle variant="h1">
            About risks from foreign trade and your finances
          </GradientTitle>
          <Paragraph color="white">
            Tell us how your business views financial risk from foreign trade
            and what you prefer to do about it
          </Paragraph>
          <SliderWrapper>
            <StaleCarousel data={RISK_APPETITE_SLIDER_DATA} />
          </SliderWrapper>
        </>
      }
      rightColumn={activeStep && renderContent(activeStep)}
    />
  );
};

export default RiskSettings;
