import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Button, Col, Icon, Row, Title } from 'components';
import Card from 'components/shared/Card/Card.styles';
import {
  ConnectionTileCardContentWrapper,
  ConnectionTileCTAWrapper,
  ConnectionTileTextWrapper,
  SeparatorLine,
} from './ConnectionTile.styles';
import { useHistory } from 'react-router';
import { getSettingsPageLink } from 'utils';

const ConnectionTile: FC = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Col gap={theme.spacing.m} alignSelf="stretch">
      <Title variant="h4">Connect your system</Title>

      <Card padding={theme.spacing.m}>
        <ConnectionTileCardContentWrapper>
          <ConnectionTileTextWrapper>
            <Icon
              width={theme.iconSizes.xl}
              height={theme.iconSizes.xl}
              icon="prebook"
            />
            <Title variant="h4">Popular integrations:</Title>
          </ConnectionTileTextWrapper>

          <Row gap={theme.spacing.m} flex={1} justifyContent="center">
            <Icon
              width={theme.iconSizes.xl}
              height={theme.iconSizes.xl}
              icon="xero-ico"
            />
            <SeparatorLine />
            <Icon
              width={theme.iconSizes.xl}
              height={theme.iconSizes.xl}
              icon="dynamics-365-ico"
            />
            <SeparatorLine />
            <Icon
              width={theme.iconSizes.xl}
              height={theme.iconSizes.xl}
              icon="netsuite-ico"
            />
            <SeparatorLine />
            <Icon
              width={theme.iconSizes.xl}
              height={theme.iconSizes.xl}
              icon="quick-books-ico"
            />
          </Row>

          <ConnectionTileCTAWrapper>
            <Button
              onClick={() =>
                history.push(getSettingsPageLink({ tab: 'integrations' }))
              }
            >
              Connect
            </Button>
          </ConnectionTileCTAWrapper>
        </ConnectionTileCardContentWrapper>
      </Card>
    </Col>
  );
};

export default ConnectionTile;
