export const FAQ_DATA = [
  {
    id: '1',
    title: 'Who can create a HedgeFlows account?',
    text:
      'Any representative of a business that needs to manage foreign currency payments, invoices and cashflowscan open an online account with HedgeFlows.',
  },
  {
    id: '2',
    title: 'How long does it take to open an account?',
    text:
      'Registering your business is 100% online and should not take you more than 10-15 minutes. Once we have all the necessary information, we normally process your application in 24-48 hours.',
  },
  {
    id: '3',
    title: 'Is it another bank account?',
    text:
      'No, your account with HedgeFlows is a payments account that helps businesses transfer or collect funds worldwide in more than 30 currencies. HedgeFlows is not a bank, but we are regulated by the Financial Conduct Authority (FRN: 935021) as a Payment Institution. We use new technologies such as Application Programming Interfaces (APIs) and Open Banking to safely streamline your finances so you can keep most of your funds with your bank.',
  },
  {
    id: '4',
    title: 'Payment timelines and cut-off times?',
    text:
      'The times listed in the guides are an accurate reflection of the UK cutoff times, regardless of seasonal changes, as well as a minimum number of days to process payment (SWIFT payments may take longer, particularly if intermediary (correspondent) banks are involved.',
  },
  {
    id: '5',
    title: 'How do you keep my data and money safe?',
    text:
      'Your data is stored securely with world-class providers, and account access is protected behind multiple layers of authentication. We comply with the second Payment Services Directive (PSD2), the standard for payment authentication. We segregate all customer funds from our operational accounts in line with the Payment Services Regulations.',
  },
];
