import styled from 'styled-components';
import { TextHint } from '../Typography/Typography';

export const BetaTagWrapper = styled.span`
  position: relative;
`;

export const StyledTextHint = styled(TextHint)`
  position: absolute;
  top: ${({ theme }) => `-${theme.spacing.m}`};
  right: ${({ theme }) => `-${theme.spacing.s}`};
`;
