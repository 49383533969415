import { Loader, Popup } from 'components';
import InvoiceDetailsContent from 'components/shared/InvoiceDetailsContent/InvoiceDetailsContent';
import InvoiceDetailsFooter from 'components/shared/InvoiceDetailsFooter/InvoiceDetailsFooter';
import InvoiceDetailsHeader from 'components/shared/InvoiceDetailsHeader/InvoiceDetailsHeader';
import useCurrencyRate from 'hooks/useCurrencyRate';
import useGetDetailedInvoice from 'hooks/useGetDetailedInvoice';
import useInvoiceRecord from 'hooks/useInvoiceRecord';
import { FC } from 'react';
import { useStoreState } from 'state';

interface IOwnProps {
  invoiceId: string;
  onClose: () => void;
}

const PopupInvoice: FC<IOwnProps> = ({ invoiceId, onClose }) => {
  const {
    isLoading,
    detailedInvoice,
    getDetailedInvoice,
  } = useGetDetailedInvoice(invoiceId);

  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const { isPayable } = useInvoiceRecord({
    record: detailedInvoice,
  });
  const { rate } = useCurrencyRate({
    buyCurrency: isPayable ? detailedInvoice?.currency : entityCurrencyCode,
    sellCurrency: isPayable ? entityCurrencyCode : detailedInvoice?.currency,
    predicate: !!detailedInvoice,
    withCancel: false,
  });

  return (
    <Popup
      width="800px"
      HeaderContent={
        detailedInvoice ? (
          <InvoiceDetailsHeader invoice={detailedInvoice} />
        ) : null
      }
      FooterContent={
        detailedInvoice ? (
          <InvoiceDetailsFooter
            invoice={detailedInvoice}
            rate={rate}
            getDetailedInvoice={getDetailedInvoice}
          />
        ) : null
      }
      onClose={onClose}
    >
      {isLoading && <Loader size="large" />}
      {!isLoading && detailedInvoice && (
        <InvoiceDetailsContent invoice={detailedInvoice} />
      )}
    </Popup>
  );
};

export default PopupInvoice;
