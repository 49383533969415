import { AxiosPrivateFirebaseInstance } from 'settings';
import {
  IResponse,
  TPartialRecipientWithId,
} from 'types';

export const getOpenBankingPartialRecipientsForCurrency = async (
  currency: string
) =>
  AxiosPrivateFirebaseInstance.get<IResponse<TPartialRecipientWithId[]>>(
    `openbanking/accounts/beneficiaries?currency=${currency}`
  );
