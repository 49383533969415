import { ICurrency } from 'types';

interface IDetectCurrenciesFromFundingsParams {
  fundingCurrencies: string[];
  currencies: ICurrency[];
}

export const detectCurrenciesFromFundings = ({
  fundingCurrencies,
  currencies,
}: IDetectCurrenciesFromFundingsParams) => {
  return fundingCurrencies.filter((currencyCode) =>
    currencies.some(
      (currency) => currency.code === currencyCode && currency.enabled
    )
  );
};
